import React from "react";
import { Modal } from "antd";
import { Player } from "video-react";
import { useRef } from "react";
import SellerVideo from '../../../assets/seller.mp4';
import AssignVideo from '../../../assets/assign.mp4';
import CourseVideo from '../../../assets/course.mp4';
import "video-react/dist/video-react.css";

export const VideoPlayer = ({ videoType, visible, setVisible, }) => {
    const videoRef = useRef(null);

    const pause = () => {
        videoRef.pause();
    };

    return (
        <Modal
            title={videoType === "seller" ? "Adding Seller" : videoType === "course" ? "Course Selection" : "Assign Tickets" || "Help"}
            open={visible}
            footer={null}
            onCancel={setVisible}
            afterClose={pause}
            width={900}
            bodyStyle={{ padding: 0 }}
        >
            <Player
                autoPlay
                ref={videoRef}
            >
                <source
                    src={videoType === "seller" ? SellerVideo : videoType === "course" ? CourseVideo : AssignVideo}
                    type="video/mp4"
                />
            </Player>
        </Modal>
    )
}