import { Breadcrumb } from 'antd';
import React, { } from 'react';
const BreadcrumbLayout = () => {
    return (
        <Breadcrumb
            style={{
                margin: '8px 0',
            }}
        >
            {/* <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>List</Breadcrumb.Item>
        <Breadcrumb.Item>App</Breadcrumb.Item> */}
        </Breadcrumb>
    )
};

export default BreadcrumbLayout;