import React, { useState, useEffect } from 'react';
import { Layout, Popover, Avatar, Drawer, Menu, Tooltip } from 'antd';
import { SoundOutlined, ApartmentOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from "react-router-dom";
import { logout, getFullName, showAvtarName, getUserData } from "../../../utils/functions";
import { MenuUnfoldOutlined } from '@ant-design/icons';
import MainLogo from '../../../assets/streamline_logo.png';
import FundraiserAdminModal from '../../common/modal/fundraiserAdminModal';
import { IoMdRefreshCircle, IoMdArrowRoundBack } from 'react-icons/io';
const { Header } = Layout;

const Headers = (props) => {

    const navigate = useNavigate();

    const location = useLocation();
    const [active, setActive] = useState('')
    const [items1, setItem1] = useState([]);
    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(false);

    const onLogoutClick = () => {
        logout();
        navigate('/login');
    }

    const content = (
        <div>
            <p>{getFullName()}</p>
            <p><span className='header-menu pointer' onClick={() => onLogoutClick()}>Logout</span></p>
        </div>
    );


    useEffect(() => {
        setItem1([
            {
                key: "",
                label: "Fundraising Campaigns",
                icon: <SoundOutlined />,
            },
            {
                key: "organizations",
                label: "Organizations",
                icon: <ApartmentOutlined />,
            },
            {
                key: "sellers",
                label: "Sellers",
                icon: <UsergroupAddOutlined />,
            },


        ]);
    }, [])

    useEffect(() => {
        if (location.pathname.includes('')) {
            setActive('');
        } else if (location.pathname.includes('organizations')) {
            setActive('organizations');
        } else if (location.pathname.includes('sellers')) {
            setActive('sellers');
        }
    }, [location]);




    return (
        <>
            <Header className="header">
                <div className='d-flex'>
                    <div className="main-logo" >
                        {props?.role === "fundraiser" &&
                            <MenuUnfoldOutlined
                                onClick={() => setOpen(true)}
                                className='pointer'
                            />}
                        <img src={MainLogo} onClick={() => navigate('/')} className="pointer" alt="Strealine Fundraiser Logo "></img>
                    </div>
                    <div className='header-menu d-flex-a-center'>
                        <Tooltip placement="bottom" title={"Back"} trigger="hover">
                            <IoMdArrowRoundBack color='white' onClick={() => navigate(-1)} size={35} className="mr5 pointer" />
                        </Tooltip>
                        <Tooltip placement="bottom" title={"Refresh"} trigger="hover">
                            <IoMdRefreshCircle color='white' size={35} className="mr5 pointer" onClick={() => window.location.reload()} />
                        </Tooltip>
                        <Popover placement="bottom" content={content} trigger="hover" onClick={() => setVisible(true)}>
                            <Avatar size="large" className='pointer' >{showAvtarName()}</Avatar>
                        </Popover>
                    </div>
                </div>
                <Drawer
                    placement={"left"}
                    closable={false}
                    onClose={() => setOpen(false)}
                    open={open}
                    width={300}
                    key={"left"}
                >
                    <Menu
                        mode="inline"
                        selectedKeys={[active]}
                        onClick={(e) => {
                            setOpen(false);
                            setActive(e.key);
                            navigate(`/${e.key}`);
                        }}
                        defaultOpenKeys={['sub1']}
                        items={items1}
                        style={{
                            height: '100%',
                            borderRight: 0,
                        }}
                    >
                    </Menu>
                </Drawer>
                {visible &&
                    <FundraiserAdminModal
                        visible={visible}
                        setVisible={() => {
                            setVisible(false);
                        }}
                    />
                }
            </Header>

        </>
    );
};

export default Headers;