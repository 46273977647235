import React, { useState } from "react";
import { Upload, message, Button, Image } from "antd";
import noImage from "../../../src/assets/uploadimg.png";
import { useDispatch } from 'react-redux';

const SingleImageUpload = (props) => {

    const { uploadImageURL } = props
    const [imageUrl, setImageUrl] = useState();
    const dispatch = useDispatch();
    const handleChangeBookPicture = (info) => {

        if (info.file.status === "uploading") {
            return;
        }
        if (info.file.status === "done") {
            getBase64(info.file.originFileObj, (imageUrl) => {
                setImageUrl(imageUrl);
            });
            const formData = new FormData();
            formData.append("image", info.file.originFileObj);
            dispatch({ type: 'UPLOAD_IMAGE_REQUEST', formData: formData });
        }
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const uploadButton = (
        <div className="parent-img">
            <div>
                <img src={noImage} alt={'upload'} className="mb5" />
                <p>Drag and drop files here or</p>
                <Button
                    type="primary"
                    size="large"
                    className="br20 mt5"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Select File
                </Button>
            </div>

        </div>
    );

    return (
        <>
            <div className="individualImage">
                {/* {(imageUrl || uploadImageURL) &&
                    <div className="project-building-image image">
                        <img src={imageUrl || uploadImageURL} alt={"Organziation Logo"} />
                    </div>
                }
                {noImage && !imageUrl && !uploadImageURL &&
                    <div className="project-building-image no-image">
                        <img src={imageUrl || uploadImageURL || noImage} alt={"Organziation Logo"} />
                    </div>
                } */}

                <Upload
                    name="avatar"
                    className="avatar-uploader"
                    onChange={handleChangeBookPicture}
                    showUploadList={false}
                    customRequest={({ file, onSuccess }) =>
                        setTimeout(() => onSuccess("ok"), 0)
                    }
                >
                    {imageUrl || uploadImageURL ? (
                        <div className="parent-img">
                            <img
                                className="uploaded-img"
                                src={imageUrl || uploadImageURL}
                                alt="avatar"
                            />
                        </div>
                    ) : (
                        uploadButton
                    )}
                </Upload>
            </div>
        </>
    );
};

export default SingleImageUpload;
