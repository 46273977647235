import React, { useEffect, useState } from "react";
import { Input, Card, Spin, Modal, Form, Row, Col, Select, Avatar, Popover } from 'antd';
import searchImg from '../../../assets/search.png';
import otherImg from '../../../assets/other.png';
import { MoreOutlined } from '@ant-design/icons';
import StepTemplate from './stepTemplate';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import UploadImage from '../../common/upload';
import { getUserData, showAvtarOrgName } from '../../../utils/functions';
import { FiEdit, FiEye } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import DeleteModal from '../../common/deleteModal';
import SingleImageUpload from "../../common/singleImageUpload";
import OrganizationModal from "../../common/organizationModal";
import NextPrevButton from "./nextPrevButton";

const { Option } = Select;
const Step4 = (props) => {
    const { isFOrganizationLoading, fundraiserOrganizationList, isGetFundraiserLoading, fundraiserData, isFOrganizationUpdateLoading, statusMsg, isOrganizationCreateLoading, updateMsg } = useSelector(state => state.fundraiser);
    const { isCityLoading, cityList, provinceList, isProvinceLoading, isDeleteLoading, deleteMsg, uploadImageURL } = useSelector(state => state.common);

    const dispatch = useDispatch();
    const { id } = useParams();
    const [form] = Form.useForm();

    const [selectedId, setSelectedId] = useState("");
    const [showModal, setShowModal] = useState(false);

    const [visible, setVisible] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [updateImageId, setUpdateImageId] = useState("");
    const [visibleImageChange, setVisibleImageChange] = useState(false);
    const [provinceId, setProvinceId] = useState("");
    const [cityId, setCityId] = useState("");

    const [rowData, setRowData] = useState("");
    const [modalType, setModalType] = useState("");
    const [visibleOrgModal, setVisibleOrgModal] = useState(false);

    const openModal = (record, type) => {
        setVisibleOrgModal(true);
        setRowData(record);
        if (type === "view") {
            dispatch({ type: "GET_ORGANIZATION_DETAIL_REQUEST", orgId: record.id });
        }
        setModalType(type);
    }

    useEffect(() => {
        getAllOrganization({});
        if (id) {
            dispatch({ type: 'GET_FUNDRAISER_DATA_REQUEST', formData: { id: id } });
        }
    }, []);


    const callCities = (e) => {
        setCityId("");
        setProvinceId(e);
        form.setFieldsValue({ city_id: "" });
        dispatch({ type: 'CITY_LIST_REQUEST', formData: { per_page: 5000 }, provinceId: e });
    }

    const getAllOrganization = (params) => {
        let tmpParams = { ...params };
        tmpParams.per_page = 5000;
        tmpParams.status = 'active';
        dispatch({ type: 'FUNDRAISER_ORGANIZATION_LIST_REQUEST', formData: tmpParams, userId: getUserData().id });

    }

    useEffect(() => {
        if (!isGetFundraiserLoading && Object.keys(fundraiserData).length > 0) {
            setSelectedId(fundraiserData?.organization_id)
        }
    }, [isGetFundraiserLoading, fundraiserData]);

    const pageNext = () => {
        if (id) {
            dispatch({ type: 'UPDATE_FUNDRAISER_ORGANIZATION_REQUEST', formData: { 'organization_id': selectedId }, 'requestType': 'put', id: id });
        }
    }

    useEffect(() => {
        if (!isFOrganizationUpdateLoading && statusMsg === "success") {
            dispatch({ type: 'CLEAR_FUNDRAISER_STATUS' });
            props.handleNext();
        }
    }, [isFOrganizationUpdateLoading, statusMsg]);
    useEffect(() => {
        if (!isOrganizationCreateLoading && updateMsg === "success") {
            dispatch({ type: 'CLEAR_FUNDRAISER_STATUS' });
            getAllOrganization({});
            setShowModal(false);
            setVisibleOrgModal(false);
            setVisibleImageChange(false);
            dispatch({ type: 'CLEAR_COMMON' });
        }
    }, [isFOrganizationUpdateLoading, updateMsg]);


    useEffect(() => {
        if (provinceList.length === 0) {
            dispatch({ type: 'PROVINCE_LIST_REQUEST', formData: { per_page: 5000 } });
        }
    }, [])

    useEffect(() => {
        if (!isDeleteLoading && deleteMsg === "success") {
            getAllOrganization();
            dispatch({ type: 'CLEAR_COMMON' });
            setVisible(false);
        }
    }, [isDeleteLoading, deleteMsg])

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                let tmpValues = {};
                for (const property in values) {
                    if (values[property] !== undefined) {
                        tmpValues[property] = values[property];
                    }
                }
                tmpValues.image = uploadImageURL;

                dispatch({ type: 'UPDATE_ORGANIZATION_REQUEST', formData: tmpValues, 'requestType': 'post' });
                form.resetFields();
                console.log(tmpValues);
            })
            .catch((errorInfo) => {
                console.log(errorInfo)
            });
    }
    const popverContent = (items = "") => {
        return (
            <div className="org-tooltip">
                <p><span onClick={() => openModal(items, 'edit')} ><FiEdit />&nbsp;&nbsp;Edit Details</span></p>
                <p><span onClick={() => openModal(items, 'view')}><FiEye />&nbsp;&nbsp;View Details</span></p>
                <p><span onClick={() => {
                    setUpdateImageId(items.id);
                    setVisibleImageChange(true)
                }}><FiEdit />&nbsp;&nbsp;Change fundraiser Image</span></p>
                <p>
                    <span onClick={() => {
                        setDeleteId(items.id);
                        setVisible(true)
                    }}>
                        <RiDeleteBin6Line />
                        &nbsp;&nbsp;Remove</span></p>
            </div>)
    };

    const filterOrg = (e) => {
        getAllOrganization({ search: e });
    }


    const onDelete = (e) => {
        dispatch({ type: 'DELETE_REQUEST', id: e, deleteType: 'organizations' });
    }

    const changeImage = () => {
        dispatch({ type: 'UPDATE_ORGANIZATION_REQUEST', formData: { image: uploadImageURL }, 'requestType': 'put', id: updateImageId });
    }


    return (
        <>
            <StepTemplate
                handleNext={pageNext}
                handleBack={props.handleBack}
                step={props.step}
                isLoading={isFOrganizationUpdateLoading}
            >
                <NextPrevButton
                    handleNext={pageNext}
                    handleBack={props.handleBack}
                    step={props.step}
                    isLoading={isFOrganizationUpdateLoading}
                />
                <div className="content-container">
                    <p className="title mb2">What organization will you be raising money for?</p>
                    <p className="sub-title">Create a new organization below or select an existing organization. You must have permission from the organization to fundraise using their name or the fundraiser may be cancelled.<br></br>
                        Select “Not Affiliated” if you are not affiliated with an organization or do not have permission from an organization.</p>
                    <div className="mt10">
                        <Card style={{ width: 700 }} className="mb4 main-card">
                            <div className="camp-card step4">
                                <div className="section1">
                                    <img src={searchImg} width={40} className="icon" alt="icon" />
                                    <p className="mb0 text">Select your organization or <span className="underline pointer"
                                        onClick={() => openModal("", "add")}
                                    >create new</span></p>
                                </div>
                                <div className="mt5 pl18 pr15">
                                    {/* <Input placeholder="Organization Name" onChange={(e) => filterOrg(e.target.value)} /> */}
                                    <div className={`mt5 org-box`}>
                                        {isFOrganizationLoading &&
                                            <Spin style={{
                                                textAlign: "center", width: "100%", marginTop: "5px"
                                            }} />
                                        }
                                        {!isFOrganizationLoading && fundraiserOrganizationList.length > 0 && fundraiserOrganizationList.map((item) => {
                                            return (
                                                <div className={`org-list pointer ${(selectedId === item.id) ? 'active' : ''}`} key={item.id} >
                                                    <div className="box1" onClick={() => setSelectedId(item.id)}>
                                                        {(item.image === null || item.image === "") &&
                                                            <Avatar size="large" width={40} className='pointer' >{showAvtarOrgName(item?.name || "")}</Avatar>
                                                        }
                                                        {(item.image !== null && item.image !== "") &&
                                                            <img src={item.image} width={40} className="icon" alt="icon" />
                                                        }
                                                    </div>
                                                    <div className="box2" onClick={() => setSelectedId(item.id)}>
                                                        <p className="mb0 text">{item?.name || ""}</p>
                                                        <p className="mb0 sub-text"> {item?.phone} {`${item.email && item.phone ? ` • ` : ''}`} {item.email}</p>
                                                    </div>
                                                    <div className="box3">
                                                        <Popover placement="left" content={popverContent(item)} trigger="hover">
                                                            <MoreOutlined />
                                                        </Popover>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <Card style={{ width: 700 }} className={`mb4 main-card ${(selectedId === "" || selectedId === null) ? 'active' : ''}`} onClick={() => setSelectedId("")}>
                            <div className="camp-card step4">
                                <div className="section1">
                                    <img src={otherImg} width={40} className="icon" alt="icon" />
                                    <p className="mb0 text">Not affiliated with any organization</p>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                {showModal &&
                    <Modal
                        className="prize-modal step4-modal"
                        title="Create new organization"
                        visible={showModal}
                        onOk={() => handleSubmit()}
                        onCancel={() => {
                            dispatch({ type: "CLEAR_COMMON" });
                            setShowModal(false);
                        }}
                        centered
                        width={650}
                        okText="Save"
                        okButtonProps={{ disabled: isOrganizationCreateLoading, loading: isOrganizationCreateLoading }}
                        cancelText="Cancel"
                        maskClosable={false}

                    >
                        <div className="mt4">
                            <Form
                                name="basic"
                                initialValues={{ remember: true }}
                                form={form}
                                layout="vertical"
                            >
                                <Form.Item
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter organization name'
                                        },
                                        {
                                            max: 100,
                                            message: 'Maximum 100 characters only allowed!',
                                        }]
                                    }
                                >
                                    <Input size="large" placeholder={"Organization Name"} />
                                </Form.Item>

                                <UploadImage uploadImageURL={uploadImageURL} />

                                <Form.Item
                                    className="mt5"
                                    name="email"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter email address'
                                        },
                                        {
                                            type: 'email',
                                            message: 'Please enter valid email address',
                                        },
                                    ]}
                                >
                                    <Input size="large" placeholder={"Email Address"} autoComplete="off" />
                                </Form.Item>
                                <Form.Item
                                    name="phone"
                                    rules={[{ required: false, message: 'Please enter your campaign description' }]}
                                >
                                    <Input type="number" size="large" prefix="(+1)" placeholder={"Phone"} />
                                </Form.Item>

                                <Form.Item
                                    name="address"
                                    rules={[
                                        {
                                            max: 200,
                                            message: 'Maximum 200 characters only allowed!',
                                        }]
                                    }
                                >
                                    <Input size="large" placeholder={"Address"} />
                                </Form.Item>

                                <Row gutter={30} style={{ marginBottom: "24px" }}>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="">
                                        <Form.Item
                                            label="Province"
                                            name="province_id"
                                            rules={[{ required: true, message: 'Please select province' }]}
                                        >
                                            <Select
                                                loading={isProvinceLoading}
                                                placeholder="Select Province"
                                                size="large"
                                                showSearch
                                                autoComplete={"off"}
                                                defaultValue={provinceId}
                                                onChange={(e) => callCities(e)}
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {!isProvinceLoading && provinceList.map((item, index) => (
                                                    <Option key={index} value={item.id}>{item.name}</Option>))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="">
                                        <Form.Item
                                            label={"City"}
                                            name="city_id"
                                            rules={[{ required: true, message: 'Please select city' }]}
                                        >

                                            <Select
                                                loading={isCityLoading}
                                                placeholder="Select city"
                                                size="large"
                                                showSearch
                                                autoComplete={"off"}
                                                defaultValue={cityId}
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            // value={primaryStructureValue}
                                            // onChange={(e) => onChange(e, 'primaryStructure')}
                                            >
                                                {!isCityLoading && cityList.map((item, index) => (
                                                    <Option key={index} value={item.id}>{item.name}</Option>))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </Form>
                        </div>

                    </Modal>
                }
                <DeleteModal
                    visible={visible}
                    deleteId={deleteId}
                    subTitle={"You want to delete this organization"}
                    setVisible={() => { setVisible(false) }}
                    onDelete={(e) => onDelete(e)}
                    isDeleteLoading={isDeleteLoading}
                />

                {visibleImageChange &&
                    <Modal
                        className="prize-modal step4-modal"
                        title="Change fundraiser image for Organization name"
                        visible={visibleImageChange}
                        onOk={() => changeImage()}
                        onCancel={() => {
                            dispatch({ type: "CLEAR_COMMON" });
                            setVisibleImageChange(false);
                        }}
                        centered
                        width={650}
                        okText="Save"
                        okButtonProps={{ disabled: isOrganizationCreateLoading || uploadImageURL === "", loading: isOrganizationCreateLoading }}
                        cancelText="Cancel"
                        maskClosable={false}

                    >
                        <div className="mt4">
                            <SingleImageUpload uploadImageURL={uploadImageURL} />
                        </div>

                    </Modal>
                }
                {visibleOrgModal &&
                    <OrganizationModal
                        visible={visibleOrgModal}
                        rowData={rowData}
                        modalType={modalType}
                        setVisible={() => {
                            setRowData("");
                            setVisibleOrgModal(false);
                        }}
                    />
                }
            </StepTemplate>
        </>
    );
};
export default Step4;
