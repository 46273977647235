import { put, takeLatest, call } from 'redux-saga/effects'
import { message } from 'antd';
//Import API Service.
import { getRequestAPI, postRequestAPI, putRequestAPI } from '../../services/API/api';

function* getFundraiserCampaignList({ formData, fundraiserFor = "admin" }) {
    try {
        let response = "";
        if (fundraiserFor === "my") {
            response = yield call(getRequestAPI, { url: `fundraisers/my-fundraisers`, params: formData });
        } else {
            response = yield call(getRequestAPI, { url: `fundraisers`, params: formData });
        }
        if (response.status === 200) {
            yield put({ type: 'FUNDRAISER_CAPMAIGN_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'FUNDRAISER_CAPMAIGN_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'FUNDRAISER_CAPMAIGN_LIST_ERROR', error: error.message || "" });
    }
}

function* getFundraiserTypeList({ formData }) {
    try {

        const response = yield call(getRequestAPI, { url: `fundraiser-types`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'FUNDRAISER_TYPE_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'FUNDRAISER_TYPE_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'FUNDRAISER_TYPE_LIST_ERROR', error: error.message || "" });
    }
}

function* getFundraiserData({ formData }) {
    try {

        const response = yield call(getRequestAPI, { url: `fundraisers/${formData.id}` });
        if (response.status === 200) {
            yield put({ type: 'GET_FUNDRAISER_DATA_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'GET_FUNDRAISER_DATA_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'GET_FUNDRAISER_DATA_ERROR', error: error.message || "" });
    }
}

function* postCreateFundraiserType({ formData, requestType, id }) {
    try {
        let response;
        if (requestType === "post") {
            response = yield call(postRequestAPI, { url: `fundraisers`, data: formData });
        } else if (requestType === 'put') {
            response = yield call(putRequestAPI, { url: `fundraisers/${id}`, data: formData });
        }

        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'CREATE_FUNDRAISER_TYPE_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong", className: 'error-class', duration: 3 });
            yield put({ type: 'CREATE_FUNDRAISER_TYPE_ERROR', error: response });
        }
    } catch (error) {
        message.error(error?.message || "Something went wrong");
        yield put({ type: 'CREATE_FUNDRAISER_TYPE_ERROR', error: error?.message || "Something went wrong" });
    }
}


function* getFundraiserPackageList({ formData }) {
    try {

        const response = yield call(getRequestAPI, { url: `fundraiser-packages`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'FUNDRAISER_PACKAGE_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'FUNDRAISER_PACKAGE_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'FUNDRAISER_PACKAGE_LIST_ERROR', error: error.message || "" });
    }
}

function* getFundraiserCouponList({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `coupon`, params: formData });
        if (response.status === 200 && response.data.coupons.data.length) {
            yield put({ type: 'FUNDRAISER_COUPON_LIST_SUCCESS', data: response.data.coupons.data || [] });
        } else {
            yield put({ type: 'FUNDRAISER_COUPON_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'FUNDRAISER_COUPON_LIST_ERROR', error: error.message || "" });
    }
}

function* postCreateFundraiserPackage({ formData, requestType, id }) {
    try {
        let response;
        if (requestType === "post") {
            response = yield call(postRequestAPI, { url: `fundraisers`, data: formData });
        } else if (requestType === 'put') {
            response = yield call(putRequestAPI, { url: `fundraisers/${id}/package`, data: formData });
        }
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'CREATE_FUNDRAISER_PACKAGE_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong", className: 'error-class', duration: 3 });
            yield put({ type: 'CREATE_FUNDRAISER_PACKAGE_ERROR', error: response });
        }
    } catch (error) {
        message.error(error?.message || "Something went wrong");
        yield put({ type: 'CREATE_FUNDRAISER_PACKAGE_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* postCreateFundraiserDetail({ formData, requestType, id }) {
    try {

        const response = yield call(putRequestAPI, { url: `fundraisers/${id}/basic-details`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'CREATE_FUNDRAISER_DETAIL_SUCCESS', data: response.data.data || "" });
            yield put({ type: 'CLEAR_COMMON' })
        } else {
            message.error({ content: response.message || "Something went wrong", className: 'error-class', duration: 3 });
            yield put({ type: 'CREATE_FUNDRAISER_DETAIL_ERROR', error: response });
        }
    } catch (error) {
        message.error(error?.message || "Something went wrong");
        yield put({ type: 'CREATE_FUNDRAISER_DETAIL_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* getFundraiserOrganizationList({ formData, userId }) {
    try {

        const response = yield call(getRequestAPI, { url: `users/${userId}/organizations`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'FUNDRAISER_ORGANIZATION_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'FUNDRAISER_ORGANIZATION_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'FUNDRAISER_ORGANIZATION_LIST_ERROR', error: error.message || "" });
    }
}

function* updateFundraiserOrganization({ formData, requestType, id }) {
    try {

        const response = yield call(putRequestAPI, { url: `fundraisers/${id}/organization`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'UPDATE_FUNDRAISER_ORGANIZATION_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong", className: 'error-class', duration: 3 });
            yield put({ type: 'UPDATE_FUNDRAISER_ORGANIZATION_ERROR', error: response });
        }
    } catch (error) {
        message.error(error?.message || "Something went wrong");
        yield put({ type: 'UPDATE_FUNDRAISER_ORGANIZATION_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* updateOrganization({ formData, requestType, id }) {
    try {
        let response = "";
        if (requestType === "post") {
            response = yield call(postRequestAPI, { url: `organizations`, data: formData });
        } else if (requestType === "put") {
            response = yield call(putRequestAPI, { url: `organizations/${id}`, data: formData });
        }
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'UPDATE_ORGANIZATION_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong", className: 'error-class', duration: 3 });
            yield put({ type: 'UPDATE_ORGANIZATION_ERROR', error: response });
        }
    } catch (error) {
        message.error(error?.message || "Something went wrong");
        yield put({ type: 'UPDATE_ORGANIZATION_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* postStep6({ formData, requestType, id }) {
    try {
        let response = "";
        if (requestType === "put") {
            response = yield call(putRequestAPI, { url: `fundraisers/${id}/add-ticket`, data: formData });
        }
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'STEP_6_CREATE_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong", className: 'error-class', duration: 3 });
            yield put({ type: 'STEP_6_CREATE_ERROR', error: response });
        }
    } catch (error) {
        message.error(error?.message || "Something went wrong");
        yield put({ type: 'STEP_6_CREATE_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* getOrganizationDetails({ orgId }) {
    try {

        const response = yield call(getRequestAPI, { url: `organizations/${orgId}`, params: {} });
        if (response.status === 200) {
            yield put({ type: 'GET_ORGANIZATION_DETAIL_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'GET_ORGANIZATION_DETAIL_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'GET_ORGANIZATION_DETAIL_ERROR', error: error.message || "" });
    }
}

function* watchFundraiserRequests() {
    yield takeLatest('FUNDRAISER_CAPMAIGN_LIST_REQUEST', getFundraiserCampaignList);
    yield takeLatest('FUNDRAISER_TYPE_LIST_REQUEST', getFundraiserTypeList); // Step1 - get
    yield takeLatest('CREATE_FUNDRAISER_TYPE_REQUEST', postCreateFundraiserType);  //Step1 - post / Put
    yield takeLatest('GET_FUNDRAISER_DATA_REQUEST', getFundraiserData);  //Step1 - get Individual type data 
    yield takeLatest('FUNDRAISER_PACKAGE_LIST_REQUEST', getFundraiserPackageList); // Step2 - get
    yield takeLatest('FUNDRAISER_COUPON_LIST_REQUEST', getFundraiserCouponList); // Step2.5 - get

    yield takeLatest('CREATE_FUNDRAISER_PACKAGE_REQUEST', postCreateFundraiserPackage);  //Step2 - post  / Put
    yield takeLatest('CREATE_FUNDRAISER_DETAIL_REQUEST', postCreateFundraiserDetail);  //Step3 - post  / Put

    yield takeLatest('FUNDRAISER_ORGANIZATION_LIST_REQUEST', getFundraiserOrganizationList);  //Step4 - get
    yield takeLatest('UPDATE_FUNDRAISER_ORGANIZATION_REQUEST', updateFundraiserOrganization);  //Step4 - update
    yield takeLatest('GET_ORGANIZATION_DETAIL_REQUEST', getOrganizationDetails);
    yield takeLatest('UPDATE_ORGANIZATION_REQUEST', updateOrganization);  //Step4 - update

    yield takeLatest('STEP_6_CREATE_REQUEST', postStep6);
}

function* Fundraiser() {
    yield watchFundraiserRequests();
}

export default Fundraiser;