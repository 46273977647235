/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input, Select, Row, Col, Spin } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EditFilled, CloseOutlined } from '@ant-design/icons';

const GenerateTicketModal = (props) => {
    const { visible, setVisible, rowData } = props;
    const { isDeleteLoading } = useSelector(state => state.common);
    const { isFundPackageLoading, fundPackageDetail, isFundPackageUpdating } = useSelector(state => state.admin);
    const { isTicketGenerating } = useSelector(state => state.ticket);

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [allowEdit, setAllowEdit] = useState(false);
    const [numberOfTicket, setNumnerOfTicket] = useState(0);


    const handleSubmit = () => {
        if (numberOfTicket !== 0) {
            let generateObj = {
                "generate_quantity_type": "full",
                "quantity_from": 1,
                "quantity_to": numberOfTicket
            }
            dispatch({ type: 'GENERATE_TICKET_REQUEST', formData: generateObj, 'requestType': 'post', id: rowData.id });
        }

    }

    useEffect(() => {
        setNumnerOfTicket(
            getDefaultValue({
                no_tickets_allowed: rowData.fundraiser_package?.no_tickets_allowed || 0,
                no_bonus_tickets: rowData?.fundraiser_package?.no_bonus_tickets || 0
            }));
    }, [])
    const onInputChange = (e) => {
        setNumnerOfTicket(e.target.value > 0 ? e.target.value : 0);
    }


    const getDefaultValue = (data) => {
        console.log("GenerateTicketModal")
        console.log(rowData.paid_status)

        let totalTicket = data?.no_tickets_allowed || 0;
        if (rowData.paid_status === "fully_paid") {
            if (data.no_bonus_tickets !== undefined && data.no_bonus_tickets !== null) {
                totalTicket = totalTicket + data.no_bonus_tickets;
            }
        }
        return totalTicket;
    }
    return (
        <Modal
            className={`prize-modal step4-modal org-modal`}
            title={`Generate tickets`}
            open={visible}
            onOk={() => handleSubmit()}
            onCancel={() => {
                dispatch({ type: "CLEAR_COMMON" });
                setVisible(false);
                form.resetFields();
            }}
            centered
            width={650}
            okText={`Confirm`}
            okButtonProps={{ disabled: (allowEdit && numberOfTicket === 0) || isTicketGenerating, loading: isTicketGenerating }}
            cancelText="Cancel"

        >

            <div className="mt4">
                {isFundPackageLoading && <div className='no-data'><Spin /></div>}
                {!isFundPackageLoading &&
                    <>
                        <Row className=''>
                            <Col xs={24} sm={24} md={20} lg={20} xl={20} className="org-view">
                                <p className='mb2'><b>Selected Package:</b>&nbsp;&nbsp;{rowData?.fundraiser_package?.name}</p>
                                <p className='mb2'><b>Number of sellers:</b>&nbsp;&nbsp;{rowData?.fundraiser_package?.no_sellers_allowed} </p>
                                <p className='mb2'><b>Number of tickets:</b>&nbsp;&nbsp;{rowData?.fundraiser_package?.no_tickets_allowed} </p>
                                <p className='mb8'><b>Number of bonus tickets:</b>&nbsp;&nbsp;{rowData?.fundraiser_package?.no_bonus_tickets} </p>
                                <p className='mb5'><b>Tickets to be generated:</b>
                                    &nbsp;&nbsp;
                                    {allowEdit && <span>
                                        <Input
                                            type='number'
                                            style={{ width: "25%" }}
                                            defaultValue={getDefaultValue(rowData?.fundraiser_package)}
                                            onChange={(e) => onInputChange(e)}>
                                        </Input>
                                        &nbsp;
                                        <CloseOutlined onClick={() => {

                                            setAllowEdit(false);
                                        }} />
                                    </span>}
                                    {!allowEdit &&
                                        <>
                                            <span className='fs18 bold'>{getDefaultValue(rowData?.fundraiser_package)}</span>
                                            &nbsp;<EditFilled className='pointer' onClick={() => {
                                                setNumnerOfTicket(getDefaultValue(rowData?.fundraiser_package));
                                                setAllowEdit(true)
                                            }}></EditFilled>
                                        </>
                                    }
                                </p>
                            </Col>
                        </Row>
                    </>
                }
            </div>

        </Modal>
    );
};

export default GenerateTicketModal;