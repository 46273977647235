/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Input, Table, Space, Tag, Tooltip, DatePicker } from "antd";
import { EyeOutlined, DeleteFilled } from '@ant-design/icons';
import { PER_PAGE } from '../../../utils/constant';
import { displayStatusText, formatDate, getGameStatusColor } from '../../../utils/functions';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import moment from 'moment';

const { Search } = Input;
const { Column } = Table;

let filterParams = { status: "", start_date: "", end_date: "", search: "" };
let initialFilter = { status: "", start_date: "", end_date: "", search: "" };

const ReportedTickets = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const { isRTloading, reportedTicketsList } = useSelector(state => state.player);

    useEffect(() => { getReportedTickets({}) }, []);

    const getReportedTickets = (params = {}) => {
        dispatch({ type: 'ADMIN_REPORTED_TICEKTS_LIST_REQUEST', formData: { ...filterParams, per_page: 5000, ...params } });
    }

    useEffect(() => {
        if (!isRTloading && reportedTicketsList?.length) {
            let tmpCmapList = [];
            reportedTicketsList.map((item) => {
                let tmpObj = item;
                tmpObj.status = item?.status;
                tmpObj.key = item?.id;
                tmpObj.display_status = displayStatusText(item?.status || "");
                tmpCmapList.push(tmpObj);
                return 1;
            });
            setTableData(tmpCmapList);
        } else {
            if (!isRTloading && reportedTicketsList.length === 0) {
                setTableData([]);
            }
        }
    }, [isRTloading, reportedTicketsList]);


    const onChange = (e, type) => {
        filterParams.page = 1;

        if (type === "search") {
            filterParams[type] = e;
        } else if (type === "status") {
            if (e === "all") {
                filterParams[type] = "";
            } else {
                filterParams[type] = e;
            }
        } else if (type === 'start_date') {
            filterParams[type] = e !== null && e !== "" ? moment(e).format("YYYY-MM-DD") : "";
        } else if (type === 'end_date') {
            filterParams[type] = e !== null && e !== "" ? moment(e).format("YYYY-MM-DD") : "";
        } else if (type === 'clear') {
            filterParams = initialFilter;
        }
        getReportedTickets(filterParams)
    }

    return (
        <div>
            <div className="sub-header">
                <p className="mb0 title">
                    Reported Tickets
                </p>

            </div>
            <div className="filter mt8">
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Search
                            defaultValue={filterParams['search']}
                            size="large"
                            allowClear
                            placeholder="Search"
                            style={{ width: "60%" }}
                            enterButton
                            onSearch={(e) => onChange(e, 'search')}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fr text-right date-filter">
                        <DatePicker
                            size={"large"}
                            className="mr5"
                            style={{ width: "40%" }}
                            placeholder={"From Date"}
                            format={'YYYY-MM-DD'}
                            value={(filterParams['start_date'] !== undefined && filterParams['start_date'] !== "" && filterParams['start_date'] !== null) ? moment(filterParams['start_date'], 'YYYY-MM-DD') : null}
                            onChange={(e) => onChange(e, 'start_date')}
                        />
                        <DatePicker
                            size={"large"}
                            style={{ width: "40%" }}
                            placeholder={"To Date"}
                            format={'YYYY-MM-DD'}
                            value={(filterParams['end_date'] !== undefined && filterParams['end_date'] !== "" && filterParams['end_date'] !== null) ? moment(filterParams['end_date'], 'YYYY-MM-DD') : null}
                            onChange={(e) => onChange(e, 'end_date')}
                        />
                    </Col>
                </Row>
            </div>

            <Row className="mt8">
                <Table
                    style={{ width: "100%" }}
                    dataSource={tableData}
                    loading={isRTloading}
                    pagination={true}
                    scroll={{ x: "max-content" }}
                >

                    <Column
                        title="Ticket#"
                        dataIndex="ticket"
                        key="ticket"
                        width={"100px"}
                    />
                    <Column
                        title="Campaign name"
                        dataIndex="campaign_name"
                        key="campaign_name"
                        width={"250px"}
                    />
                    <Column
                        title="Fundraising admin"
                        dataIndex="fundraising_admin"
                        key="fundraising_admin"
                        width={"200px"}
                    />
                    <Column
                        title="Reported on"
                        dataIndex="reported_on"
                        key="reported_on"
                        render={(text, record) => formatDate(text)}
                        width={"200px"}
                    />
                    {/* <Column
                        title="Reported by"
                        dataIndex="reported_by"
                        key="reported_by"
                        width={"200px"}
                    /> */}
                    <Column
                        title="Status"
                        dataIndex="display_status"
                        key="display_status"
                        width={"100px"}
                        render={(_, { display_status, status }) => {
                            let color = getGameStatusColor(status)
                            return (
                                <Space size="middle">
                                    <Tag color={color} key={status}>
                                        {display_status}
                                    </Tag>
                                </Space>)
                        }}
                    />

                    {/* <Column
                        title={"Action"}
                        key='action'
                        fixed='right'
                        width={"100px"}
                        render={(_, record) => {
                            return (
                                <Space size="middle" className={`cursor ${record.isDisabled ? 'disabled-edit' : 'not-disabled'}`}>
                                    <Tooltip placement="top" title={"View Detail"}>
                                        <EyeOutlined
                                            onClick={() => navigate(`/admin/game/${record.id}`)}
                                            disabled={record.isDisabled}
                                            className={`pointer cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                            style={{ fontSize: "18px" }}
                                        />
                                    </Tooltip>
                                </Space>
                            )
                        }}
                    /> */}
                </Table>
            </Row>
        </div>
    );
};
export default ReportedTickets;