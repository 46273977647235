import React from 'react';
import { Layout, } from 'antd';
import MainLogo from '../../../assets/streamline_logo.png';
const { Header } = Layout;

const ValidateHeader = (props) => {


    return (
        <>
            <Header className="header">
                <div className='d-flex'>
                    <div className="main-logo" >
                        <img src={MainLogo} className="pointer" alt="Strealine Fundraiser Logo "></img>
                    </div>
                </div>
            </Header>

        </>
    );
};

export default ValidateHeader;