const initialState = {
    isCampaignLoading: false,
    campgaignList: [],
    error: "",
    paginationMeta: {},
    isStatusCountLoading: false,
    statusCounts: {},
};

const reducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case "CAMPGAIGNS_LIST_REQUEST":
            state = {
                ...state,
                isCampaignLoading: true,
                campgaignList: [],
                paginationMeta: {}
            };
            break;

        case "CAMPGAIGNS_LIST_SUCCESS":
            state = {
                ...state,
                isCampaignLoading: false,
                campgaignList: action.data.data,
                paginationMeta: action.data?.meta,
                // statusCounts: action.data?.stats
            };
            break;

        case "CAMPGAIGNS_LIST_ERROR":
            state = {
                ...state,
                isCampaignLoading: false,
                campgaignList: [],
                paginationMeta: {},
                error: action.error
            };
            break;

        case "STATUS_COUNT_REQUEST":
            state = {
                ...state,
                isStatusCountLoading: true,
                statusCounts: {}
            };
            break;

        case "STATUS_COUNT_SUCCESS":
            state = {
                ...state,
                isStatusCountLoading: false,
                statusCounts: action.data?.status_counts || {}
            };
            break;

        case "STATUS_COUNT_ERROR":
            state = {
                ...state,
                isStatusCountLoading: false,
                statusCounts: {},
            };
            break;


        default:

    }
    return state;
};

export default reducer;