import React, { useEffect } from "react";
import { Select, Spin } from "antd";
import StepTemplate from './stepTemplate';
import { useState } from "react";
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import NextPrevButton from "./nextPrevButton";

const { Option } = Select;

const Step7 = (props) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { isGetFundraiserLoading, fundraiserData } = useSelector(state => state.fundraiser);
    const { updateObjectMsg, isTypeUpdating, } = useSelector(state => state.common);

    const [selectedPaymentType, setSelectedPaymentType] = useState('');

    useEffect(() => {
        if (id) {
            dispatch({ type: 'GET_FUNDRAISER_DATA_REQUEST', formData: { id: id } });
        }
    }, []);

    useEffect(() => {
        if (!isTypeUpdating && updateObjectMsg === "success") {
            dispatch({ type: "CLEAR_UPDATE_MSG" });
            props.handleNext();
        }
    }, [isTypeUpdating, updateObjectMsg]);

    useEffect(() => {
        if (!isGetFundraiserLoading && Object.keys(fundraiserData).length > 0) {
            setSelectedPaymentType(fundraiserData.payment_status);
        }
    }, [isGetFundraiserLoading, fundraiserData]);

    const loadPaymetType = (item) => {
        return (
            <>
                <Option value="">
                    <div className="payment-card">
                        <div className="">
                            <p className="mb0 text">Select Payment Type</p>
                        </div>
                    </div>
                </Option>
                <Option value="fully_paid">
                    <div className="payment-card">
                        <div className="">
                            <p className="mb0 text">Full Payment - ${item.fundraiser_package?.regular_fees}</p>
                            <p className="mb0 sub-text">{item.fundraiser_package?.full_payment_description || ""}</p>
                        </div>
                    </div>
                </Option>
                <Option value="partially_paid">
                    <div className="payment-card">
                        <div className="">
                            <p className="mb1 text">Partial Payment - ${item.fundraiser_package?.reduced_fees}</p>
                            <p className="mb0 sub-text">{item.fundraiser_package?.partial_payment_description || ""}</p>
                        </div>
                    </div>
                </Option>
            </>
        )
    }

    const onTypeChange = (e) => {
        setSelectedPaymentType(e);
        dispatch({ type: 'UPDATE_PAYMENT_TYPE', data: e });
    }
    const handleNext = () => {
        dispatch({ type: 'UPDATE_PAYMENT_TYPE_REQUEST', formData: { 'payment_status': selectedPaymentType }, id: id });
    }

    return (
        <>
            <StepTemplate
                handleNext={handleNext}
                handleBack={props.handleBack}
                isLoading={isTypeUpdating}
                isDisabled={isTypeUpdating || (selectedPaymentType === "" || selectedPaymentType === "pending")}
            >
                <NextPrevButton
                    handleNext={handleNext}
                    handleBack={props.handleBack}
                    isLoading={isTypeUpdating}
                    isDisabled={isTypeUpdating || (selectedPaymentType === "" || selectedPaymentType === "pending")}
                />
                <div className="step7 content-container">
                    <p className="title mb2">Payment details</p>
                    <p className="sub-title">There are two payment options:
                        <ul className="mt2">
                            <li>Full payment. When full payment is made today, you receive additional tickets free of charge. The number of additional tickets varies with the fundraiser package size. This increases your potential funds raised.</li>
                            <li>Partial payment. The remaining payment is required 2 days after the fundraiser closes.</li>
                        </ul>
                    </p>
                    <p className="title mb2 mt6 fs16">How much would you like to pay today?</p>
                    {isGetFundraiserLoading && <div className="no-data mt10 mb5"><Spin /> </div>}
                    {!isGetFundraiserLoading && Object.keys(fundraiserData).length > 0 &&
                        <div className="mt7 mb7 payment-card-section">
                            <Select
                                style={{ width: "700px", heigth: "auto" }}
                                optionFilterProp="children"
                                onChange={(e) => onTypeChange(e)}
                                defaultValue={selectedPaymentType === "pending" ? "" : selectedPaymentType}
                            >
                                {loadPaymetType(fundraiserData)}
                            </Select>
                        </div>
                    }
                </div>
            </StepTemplate>
        </>
    );
};
export default Step7;
