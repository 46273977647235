import React from "react";
import { Button } from "antd";
import newImg from '../../assets/new_fundraising.png';
import { useNavigate } from "react-router-dom";
const NoData = (props) => {
    const navigate = useNavigate();
    return (
        <div className="new-fundraising">
            <div className="new-fundraising_child">
                <img src={newImg} alt="new strealine"></img>
                <p className="mt8 title">Time to get fundraising</p>
                <p className="mt4 sub-title">Payment is necessary to complete your fundraiser application.</p>
                {props.isSearch ?
                    <p className="mt8 title">No search result found, try another search</p> : <Button
                        type="primary"
                        size="large"
                        className="br30 mt5 new-btn"
                        htmlType="submit"
                        onClick={() => navigate('/new-fundraising?step=1')}
                    >
                        New Fundraiser
                    </Button>}

            </div>
        </div>
    );
};
export default NoData;
