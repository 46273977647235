/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Select, Input, Button, message } from "antd";
import { FUNDRAISER_UPDATE_STATUS, UPDATE_STATUS_REASON } from "../../../utils/constant";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ContactSupportModal from "../../fundraising/fundraisingDetails/modals/contactSupportModal";
import TicketPreviewModal from "../../common/modal/ticketPreviewModal";
const { Option } = Select;
const { TextArea } = Input;

const UpdateStatus = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { isSupportLoading, supportMsg } = useSelector(state => state.common);
    const { updateMsg, isFundStatusUplading, isError } = useSelector(state => state.admin);
    const { isGetFundraiserLoading, fundraiserData } = useSelector(state => state.fundraiser);
    const [isContactVisible, setIsContactVisible] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [showTicket, setShowTicket] = useState(false);

    useEffect(() => {
        if (!isSupportLoading && supportMsg === "success") {
            setIsContactVisible(false);
            message.success("Support request sent successfully!");
            dispatch({ type: 'CLEAR_COMMON' });
        }
    }, [isSupportLoading, supportMsg]);

    useEffect(() => {
        if (!isFundStatusUplading && updateMsg === "success" && !isError) {
            dispatch({ type: 'ADMIN_DATA_CLEAR' });
            form.resetFields();
        } else if (!isFundStatusUplading && updateMsg === "success" && isError) {
            message.error({ content: "Something wen't wrong, may ticket details not configured properly", className: 'error-class', duration: 3 });
            dispatch({ type: 'ADMIN_DATA_CLEAR' });
        }
    }, [isFundStatusUplading, updateMsg, isError]);


    const onFinish = (values) => {
        console.log('Success:', values);
        let filterType = FUNDRAISER_UPDATE_STATUS.filter(e => e.key === values.status);
        dispatch({ type: 'UPDATE_STATUS_REQUEST', formData: values, id: id, updateFor: filterType[0].type });

    };


    return (
        <>
            <div className="update-left">
                <div className="left-child">
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        layout="vertical"
                        onFinish={onFinish}
                        form={form}
                    >

                        <Form.Item
                            name="status"
                            label="Action"
                            rules={[{ required: true, message: 'Select action' }]}
                        >

                            <Select
                                placeholder="Select"
                                size="large"
                                showSearch
                                autoComplete="new-state"
                                onChange={(e) => {
                                    setSelectedStatus(e)
                                }}
                            >
                                {FUNDRAISER_UPDATE_STATUS.map((item) =>
                                    <Option key={item.item} value={item.key}>{item.title}</Option>
                                )}
                            </Select>
                        </Form.Item>

                        {selectedStatus === "fundraiser_declined" &&
                            <Form.Item
                                extra={"Not in the list, select other and comment"}
                                name="decline_reason"
                                label="Reason"
                                rules={[{ required: true, message: 'Select reason' }]}
                            >
                                <Select
                                    placeholder="Select Reason"
                                    size="large"
                                    showSearch
                                    autoComplete="new-state"
                                >
                                    {UPDATE_STATUS_REASON.map((item, index) =>
                                        <Option key={index} value={item.key}>{item.title}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        }

                        <Form.Item
                            label={"Comment"}
                            name="comment"
                            rules={[{ max: 1000, message: 'Maximum 1000 characters only allowed!' }]}
                        >
                            <TextArea rows={3} placeholder={"Add the comment"} />
                        </Form.Item>

                        <div className="button">
                            <Button
                                type="secondray"
                                size="large"
                                danger
                                className="br1 mt5 mr5"
                                onClick={() => setIsContactVisible(true)}
                            >
                                Contact fundraising admin
                            </Button>
                            <Button
                                type="primary"
                                size="large"
                                className="br1 mt5 w80"
                                htmlType="submit"
                                disabled={isFundStatusUplading}
                                loading={isFundStatusUplading}
                            >
                                Update Status
                            </Button>
                        </div>
                    </Form>
                </div>
                <p className="links pointer mt8" onClick={() => setShowTicket(true)}>Preview Ticket</p>
            </div>
            {isContactVisible &&
                <ContactSupportModal
                    onCancel={(type) => {
                        setIsContactVisible(false)
                    }}
                    type="admin"
                    isContactVisible={isContactVisible}
                />
            }
            {showTicket &&
                <TicketPreviewModal
                    visible={showTicket}
                    onCancel={() => setShowTicket(false)}
                    data={fundraiserData}
                />
            }
        </>
    );
};
export default UpdateStatus;
