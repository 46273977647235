import React, { useEffect, useState } from "react";
import { Form, Input, Button, message } from 'antd';
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import "./style.scss";

const ForgetPassword = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { isForgetPasswordLoading, forgetMsg } = useSelector(state => state.auth);
    const [fieldValue, setFieldValue] = useState({})

    const onFinish = (values) => {
        setFieldValue(values)
        dispatch({ type: 'FORGET_PASSWORD_REQUEST', formData: values });
    };

    useEffect(() => {
        if (!isForgetPasswordLoading && forgetMsg === "success") {
            message.success("Password reset mail sent successfully");
            dispatch({ type: "CLEAR_INVITE" });
            navigate('/reset-password?email=' + fieldValue.email)
        } else if (!isForgetPasswordLoading && forgetMsg?.errors) {
            let msg = forgetMsg?.message;
            msg = msg.replace('selected', 'given');
            message.error({ content: msg || "Email address not exists", className: 'error-class', duration: 3 });
        } else if (!isForgetPasswordLoading && forgetMsg?.message) {
            message.error({ content: forgetMsg?.message, className: 'error-class', duration: 3 });
        }
        dispatch({ type: "CLEAR_INVITE" });
    }, [isForgetPasswordLoading, forgetMsg]);

    const onFinishFailed = (errorInfo) => { console.log('Failed:', errorInfo) };

    return (
        <div className="forget-template">
            <div className="center-section">
                <div className="center-section-child">
                    <p className="mb0 title text-center">Streamline Fundraising</p>
                    <p className="mb0 sub-title text-center">Reset Password</p>
                    <p className="mb0 sub-title-2">Enter the email associated with your account and we'll send you a link to reset your password. </p>
                    <div className="mt10">
                        <Form
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            form={form}
                        >
                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'Please enter email address' }]}
                            >
                                <Input type={"email"} size="large" placeholder={"Email Address"} autoComplete="off" />
                            </Form.Item>
                            <Form.Item className="mt10">
                                <div className="login-btn-section">
                                    <div>
                                        <Link to={'/login'} className="forget-text">Back to Login</Link>
                                    </div>
                                    <Button
                                        type="primary"
                                        size="large"
                                        className="login-btn br1"
                                        htmlType="submit"
                                        disabled={isForgetPasswordLoading}
                                        loading={isForgetPasswordLoading}
                                    >
                                        Reset Password
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ForgetPassword;
