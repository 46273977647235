import moment from 'moment';
import { SALT_KEY, WIND_DIRECTION, PAYMENT_STATUS, FUNDRAISER_STATUS, GAME_STATUS, CURRENT_ROLE_NAME, FUNDRAISER_ADMIN_TRAKING, FUNDRAISER_STATUS_COLOR, E_TRANSFER_STATUS_COLOR } from './constant';
import { message } from 'antd';
export const logout = () => {
    localStorage.removeItem('loggedInUser');
    localStorage.removeItem('jwt');
    localStorage.removeItem('remember');
    localStorage.removeItem('redirectLink');
    localStorage.removeItem('expires_at');
}

export const isLogin = () => {
    if (localStorage.getItem('jwt') !== null && localStorage.getItem('jwt')) {
        if (isAuthenticated()) {
            return true;
        } else {
            message.error({ content: "login has been expired, please login again", className: 'error-class', duration: 3 });
            logout();
            return false;
        }
    } else {
        return false;
    }
}

export const isMenuVisible = (key = "", type = "all") => {
    if (CURRENT_ROLE_NAME === "streamline_admin") {
        return 'block';
    } else {
        if (type === "admin") {
            return 'none';
        } else {
            return checkPermission(key) ? 'block' : 'none';
        }
    }
}

export const getUserData = () => {
    if (checkAndReturnLoginUser(true)) {
        return JSON.parse(checkAndReturnLoginUser());
    }
    return ""
};

export const checkAndReturnLoginUser = (returnBolean = false) => {
    if (returnBolean) {
        return localStorage.getItem('loggedInUser') !== null && localStorage.getItem('jwt');
    } else {
        return localStorage.getItem('loggedInUser') !== null && localStorage.getItem('jwt') ? decrypt(localStorage.getItem('loggedInUser')) : ""
    }
}

export const accessToken = () => {
    if (localStorage.getItem('jwt') !== null && localStorage.getItem('jwt')) {
        return localStorage.getItem('jwt')
    } else {
        return "";
    }
};

export const role = () => {
    if (checkAndReturnLoginUser(true)) {
        let userData = JSON.parse(checkAndReturnLoginUser());
        if (userData?.role?.name) {
            if (userData.role.name === 'fundraising_admin') {
                return userData.role.name;
            } else {
                return 'streamline_admin';
            }
        }
        return 'streamline_admin';
    }
    return 'streamline_admin';
};
export const roleName = () => {
    if (checkAndReturnLoginUser(true)) {
        let userData = JSON.parse(checkAndReturnLoginUser());
        if (userData?.role?.name) {
            return userData.role.name;
        }
        return 'streamline_admin';
    }
    return 'streamline_admin';
};

export const getFullName = () => {
    if (checkAndReturnLoginUser(true)) {
        let userData = JSON.parse(checkAndReturnLoginUser());
        return userData?.first_name + " " + userData?.last_name;
    }
    return '';
};

export const showAvtarName = () => {
    let name = getFullName();
    if (name !== "") {
        let fullName = name.split(' ');
        let initials = "";
        if (fullName.length > 0) {
            if (fullName[0] !== undefined && fullName[0] !== "") {
                initials = fullName[0].charAt(0);
            }
            if (fullName[1] !== undefined && fullName[1] !== "") {
                initials = initials + fullName[1].charAt(0)
            }
        }
        return initials !== "" ? initials.toUpperCase() : "";
    }
    return "";
}

export const showAvtarOrgName = (name) => {
    if (name !== "") {
        let fullName = name.split(' ');
        let initials = "";
        if (fullName.length > 0) {
            if (fullName[0] !== undefined && fullName[0] !== "") {
                initials = fullName[0].charAt(0);
            }
            if (fullName[1] !== undefined && fullName[1] !== "") {
                initials = initials + fullName[1].charAt(0)
            }
        }
        return initials !== "" ? initials.toUpperCase() : "";
    }
    return "";
}
export const isHomePage = (type) => {
    if (type === '/' || type === '/home') {
        return true;
    }
    return false;
};
export const getStepTitle = (steps, current) => {
    if (steps?.length > 0) {
        return steps.filter(item => item.step === current)[0];
    } else {
        return { stepTitle: "", nextStepTitle: "" };
    }
};

export const formatAmount = (amount, decimal = 2) => {
    if (amount !== undefined && amount !== null && amount && amount !== "") {
        amount = parseFloat(amount);
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: decimal,
        }).format(amount);
    } else if (amount === null) {
        return '$0.00';
    } else {
        return amount;
    }
}

export const formatDate = (date) => {
    if (date) {
        return moment(date).format("D MMM, YYYY");
    } else {
        return date;
    }
}

export const getClaimedDate = (item) => {
    let res = '';
    if (item.claimed_status === "unclaimed") {
        res = `Claim before ${moment(item.claim_until).format("Do MMM, YYYY")}`
    } else if (item.claimed_status === "claimed") {
        res = `Claimed on ${moment(item.claimed_on).format("Do MMM, YYYY")}`
    }
    return res;
}

export const closeDate = (date) => {
    if (date) {
        return moment(date).format("MMM D, YYYY");
    } else {
        return date;
    }
}
export const closeDateTicket = (date) => {
    if (date) {
        return moment(date).format("MMM D, YYYY");
    } else {
        return date;
    }
}
export const formatDateTime = (date) => {
    if (date) {
        return moment(date).format("D MMM, YYYY hh:mm:ss");
    } else {
        return date;
    }
}

export const formateDurationData = (start, end) => {
    let resDate = "";
    if (start !== null) {
        resDate += moment(start).format("DD/MM/YYYY");
    }
    if (end !== null) {
        resDate += " till " + moment(end).format("DD/MM/YYYY");
    }
    return resDate;

}
export const displayStartEndDate = (data) => {
    let tmpDate = "";
    if (data.start_date !== null) {
        tmpDate = moment(data.start_date).format("D MMM, YYYY");
    }
    if (data.end_date !== null) {
        tmpDate += " - ";
        tmpDate += moment(data.end_date).format("D MMM, YYYY");
    }
    return tmpDate;
}

export const startInDays = (date) => {
    let tmpDate = "Starts in ";
    if (date !== null) {
        tmpDate += moment(date).fromNow(true);
    }
    return tmpDate;
}

export const startInDaysAndEnd = (fundData) => {
    let tmpDate = "";
    if (fundData.status === 'fundraiser_started' || (fundData.force_started_at !== undefined && fundData.force_started_at !== null)) {
        tmpDate = "Started";
    } else if (fundData.status === 'fundraiser_closed' || fundData.status === 'prizes_shipped') {
        tmpDate = "Closed";
    } else {
        tmpDate = "Starts in ";
        if (fundData.start_date !== null) {
            tmpDate += moment(fundData.start_date).fromNow(true);
        }
    }
    return tmpDate;
}

export const inputFormatDate = (date) => {
    if (date) {
        return moment(date).format("YYYY-MM-DD");
    } else {
        return date;
    }
}
export const getWinDirectionName = (key) => {

    if (key !== "") {
        let tmpName = WIND_DIRECTION.filter(e => e.key === key);
        return tmpName.length > 0 ? tmpName[0].title : key;
    }
    return key;
}
export const displayPaymentText = (status) => {
    let resText = status;
    if (status !== null && status !== "") {
        if (status === PAYMENT_STATUS.PENDING) {
            resText = "Pending";
        } else if (status === PAYMENT_STATUS.FULLY_PAIED) {
            resText = "Paid in full";
        } else if (status === PAYMENT_STATUS.PARTAILY_PAIED) {
            resText = "Partial payment received";
        }
    }
    return resText
}

export const displayPaymentTextTable = (status) => {
    let resText = status;
    if (status !== null && status !== "") {
        if (status === PAYMENT_STATUS.PENDING) {
            resText = "Pending";
        } else if (status === PAYMENT_STATUS.FULLY_PAIED) {
            resText = "Fully Paid";
        } else if (status === PAYMENT_STATUS.PARTAILY_PAIED) {
            resText = "Partial Paid";
        }
    }
    return resText
}

export const displayStatusText = (status) => {
    let resText = status;
    if (status !== null && status !== "") {
        resText = resText.replace("fundraiser_", "");
        resText = resText.replaceAll("_", " ");
        resText = toTitleCase(resText);
    }
    return resText
}
export const toTitleCase = (str) => {
    return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
}

export const checkStatusWithDate = (date) => {
    // let resText = {
    //     color: 'green',
    //     status: 'In Progress'
    // }
    // let date = data.start_date;
    // const currentDate = moment(Date.now()).format('YYYY-MM-DD');
    // const dateToTest = moment(date).format('YYYY-MM-DD');

    // let result = moment(currentDate).diff(dateToTest, 'days');
    // if (result >= 0) {
    //     console.log('cama start')
    // } else {
    //     resText = {
    //         color: 'red',
    //         status: 'Closed'
    //     }
    // }
    let resText = 'In Progress';
    if (date !== null && moment().isAfter(date)) {
        resText = 'Closed';
    }
    return resText;

}

export const checkIsEanbleToStart = (fundProgress, fundData) => {

    if (fundData?.tickets_total > 0 && fundProgress['fundraiser_created'] &&
        fundProgress['fundraiser_approved'] &&
        fundProgress['course_order_picked'] &&
        fundProgress['ticket_approved'] &&
        fundProgress['ticket_printed'] &&
        fundProgress['ticket_shipped'] &&
        !fundProgress['fundraiser_started'] &&
        !fundProgress['fundraiser_closed'] &&
        !fundProgress['prizes_shipped']) {
        return true;
    }
    return false;
}

export const checkIsEanbleToClose = (fundProgress, fData, assignTickets = 0) => {
    if (fundProgress['fundraiser_started'] && fData.status !== "fundraiser_closed" && fData.tickets_played !== 0 && fData.tickets_total !== 0 && (fData.tickets_played + fData.tickets_blocked + fData.tickets_stolen) >= fData.tickets_total) {
        return true
    }
    return false
}

export const isFullyPaid = (status) => {
    if (status === "fully_paid") {
        return false;
    }
    return true;
}

export const getFundraiserStatus = (status, paymentStatus) => {
    if (status === "fundraiser_declined") {
        return "Declined";
    } else if (status === "fundraiser_completed") {
        return "Closed";
    } else if (status === "fundraiser_pending") {
        if (paymentStatus === "pending") {
            return "Incomplete";
        } else {
            return "Pending";
        }
    } else if (status === "fundraiser_approved") {
        if (paymentStatus === "pending") {
            return "Incomplete";
        } else {
            return "Approved";
        }
    } else if (status === "pending_payment_approval") {
        return 'Pending E-Transfer Approval'
    } else {
        let filterStatus = FUNDRAISER_ADMIN_TRAKING.filter(e => e.key === status);
        if (filterStatus.length > 0) {
            return filterStatus[0].title2;
        }
    }
    return status;
}

export const ticketStatus = (status) => {
    if (status !== null) {
        if (status === "not_played") {
            return "Not Played";
        } else if (status === "played") {
            return "Played";
        } else if (status === "stolen") {
            return "Stolen/Lost";
        } else {
            return sankeCaseToTitleCase(status);
        }
    }
    return status;
}

export const getAllTicketCount = (tmpState, fData) => {
    if (tmpState !== undefined && tmpState !== null && tmpState !== "") {
        let sum = tmpState.tickets_assigned_count + tmpState.tickets_reported_stolen + fData.tickets_played + fData.tickets_blocked
        return sum;
    } else {
        return 0;
    }
}

export const percentageCalculate = (totalVal, minusValue) => {
    let ans = parseFloat((100 * minusValue) / totalVal).toFixed();
    return !isNaN(ans) ? ans : 0;
}

export const isApproved = (status) => {
    return (status === "fundraiser_pending") ? true : false;
}
export const getColor = (status) => {
    let getStatus = FUNDRAISER_STATUS.filter((item) => item.key === status);
    return getStatus.length > 0 ? getStatus[0].color : 'default';
}

export const getStatusColor = (status) => {
    let getStatus = FUNDRAISER_STATUS_COLOR.filter((item) => item.key === status);
    return getStatus.length > 0 ? getStatus[0].color : 'default';
}
export const getPaymentColor = (status) => {
    if (PAYMENT_STATUS.PENDING === "status") {
        return "red";
    } else {
        return "green";
    }
}

export const getGameStatusColor = (status) => {
    let getStatus = GAME_STATUS.filter((item) => item.key === status);
    return getStatus.length > 0 ? getStatus[0].color : 'default';
}

export const getETransferStatusColor = (status) => {
    let getStatus = E_TRANSFER_STATUS_COLOR.filter((item) => item.key === status);
    return getStatus.length > 0 ? getStatus[0].color : 'default';
}

export const checkedIsExist = (record, roleList, type) => {
    let tmpRolelist = roleList.length > 0 ? roleList[0] : [];
    let replyReturn = false;
    if (roleList.length > 0) {
        for (let i = 0; i < tmpRolelist.permissions.length; i++) {
            if (tmpRolelist.permissions[i].name === record.name) {
                replyReturn = true;
                break;
            } else {
                replyReturn = false;
                continue;
            }
        }
    }

    return replyReturn;

}

export const isImage = (url) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}
export const getFileName = (url) => {
    if (url !== undefined && url !== null && url !== "") {
        console.log(url);
        let tmpURL = url.split('/');
        tmpURL = tmpURL.reverse();
        return tmpURL[0];
    }
    return url;
}

export const showOrgNameWithDetail = (data) => {
    let res = "";
    if (data.phone) {
        res += data.phone;
    }
    if (data.email) {
        if (data.phone) {
            res += ` • `;
        }
        res += `${data.email}`;
    }
    if (data.address) {
        if (data.email || data.phone) {
            res += ` • `;
        }
        res += `${data.address}`;
    }
    if (data.province.name) {
        if (data.phone || data.email || data.address) {
            res += ` • `;
        }
        res += `${data.province.name}`;
    }
    return res;
}

export const sankeCaseToTitleCase = (s) => {
    if (s !== null && s !== "") {
        return s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())
    }
    return s;
}

export const getStatusComment = (key, data) => {
    if (key !== "" && data.length > 0) {
        let foundData = data.filter((e) => e.status === key);
        if (foundData.length) {
            return <div>
                <p className='mb0'><b>Comment:</b> {foundData[0].comment !== null ? foundData[0].comment : ""}</p>
                <p className='mb0'><b>Updated By:</b> {`${foundData[0]?.user?.first_name} ${foundData[0]?.user?.last_name}`}</p>
                <p className='mb0'><b>Updated At:</b> {formatDateTime(foundData[0].updated_at)}</p>
            </div>;
        } else {
            return "";
        }
    }
    return "";
}

export const checkPermission = (key, type) => {
    if (checkAndReturnLoginUser(true)) {
        let userData = JSON.parse(checkAndReturnLoginUser());
        let permissions = userData?.permissions;
        if (permissions.length > 0) {
            return permissions.includes(key);
        } else {
            return false;
        }
    }
    return false;
}


export const isAuthorized = (allowedList = "", type = "") => {
    if (CURRENT_ROLE_NAME === "streamline_admin") {
        return true;
    } else {
        if (type === "admin") {
            return false;
        } else {
            return checkPermission(allowedList)
        }
    }
}
export const getCityName = (data) => {
    if (typeof data === null) {
        return "";
    } else if (typeof data === 'string') {
        return data;
    }
}

export const encrypt1 = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}

export const decrypt1 = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}

// To create a cipher
export const encrypt = encrypt1('OWN_SALT_KEY');
//To decipher, you need to create a decipher and use it:
export const decrypt = decrypt1('OWN_SALT_KEY');


export const getDisabledDays = (fundraiserData) => {
    if (fundraiserData.fundraiser_package !== undefined) {
        let daysForPackage = "",
            // amount = fundraiserData?.fundraiser_package?.regular_fees || 0,
            duration = fundraiserData?.fundraiser_package?.duration || 0;
        // if (amount <= 999) {
        //     daysForPackage = 28;
        // } else if (amount >= 1000 && amount <= 3999) {
        //     daysForPackage = 28; // 4 Weeks 
        // } else if (amount >= 4000 && amount <= 9999) {
        //     daysForPackage = 42; // 6 Weeks 
        // } else if (amount >= 10000) {
        //     daysForPackage = 56; // 8 Weeks 
        // }
        daysForPackage = 90; // 90 days
        if (duration !== undefined) {
            if (duration <= daysForPackage) {
                return duration;
            } else {
                return daysForPackage;
            }
        } else {
            return daysForPackage;
        }
    } else {
        return 24;
    }
}

export const setWithExpiry = (key, value, ttl) => {

    let current = moment().valueOf();
    let updateTime = moment(current).add(24, 'hours').valueOf();
    localStorage.setItem('expires_at', updateTime);

}

export const isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
}

export const getErrorStatus = (code, type) => {
    console.log(code);
    let res = {};
    if (code === "TICKET_NOT_MATCHING") {
        res.title = "DO NOT PURCHASE."
        res.description = "Ticket information does not match."
    } else if (code === "TICKET_STOLEN") {
        res.title = "DO NOT PURCHASE."
        res.description = "Ticket has been reported lost/stolen."
    } else if (code === "TICKET_BLOCKED") {
        res.title = "DO NOT PURCHASE."
        res.description = "Ticket currently suspended due to lost/stolen report."
    } else if (code === "FUNDRAISER_CLOSED") {
        res.title = "DO NOT PURCHASE."
        res.description = "This fundraiser is already closed."
    } else if (code === "TICKET_PLAYED") {
        res.title = "DO NOT PURCHASE."
        res.description = "Ticket already played."
    } else {
        res.title = "DO NOT PURCHASE."
        res.description = "Ticket information does not match."
    }

    return res;
}

export const checkIsAllPaid = (filterData) => {
    let isAllowed = {
        status: true,
        data: []
    };
    if (filterData.length > 0) {
        let notPaid = filterData.filter(e => (e.paid_status !== "fully_paid"));
        if (notPaid.length > 0) {
            isAllowed = {
                status: false,
                data: []
            };
            notPaid.map((item) => {
                isAllowed.data.push(item);
            });
        }
    }
    return isAllowed;
}

export const isAllowEditDetail = (fundProgress) => {
    if (fundProgress['ticket_approved'] ||
        fundProgress['ticket_printed'] ||
        fundProgress['ticket_shipped'] ||
        fundProgress['fundraiser_started'] ||
        fundProgress['fundraiser_closed'] ||
        fundProgress['prizes_shipped']) {
        return false;
    }
    return true;
}