import React, { useEffect, useState } from "react";
import { Row, Col, Skeleton, Popover } from "antd";
import { CheckOutlined, CommentOutlined } from '@ant-design/icons';
import { FUNDRAISER_ADMIN_TRAKING } from '../../../utils/constant';
import { getStatusComment } from '../../../utils/functions';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import DetailView from './detailView';
import UpdateStatus from "./updateStatus";

let filterParams = { status: "", start: "", end: "", search: "" };
const CampgaignDetail = (props) => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const [progress, setProgress] = useState({});
    const { isProgressLoading, fundProgress, isCommentLoading, fundProgressComments } = useSelector(state => state.common);
    const { updateMsg, isFundStatusUplading } = useSelector(state => state.admin);

    useEffect(() => {
        getAllCampaignsList({})
        getFundraisingProgress();
        getFundraisingProgressComment();
    }, []);

    const getAllCampaignsList = (params = {}) => {
        dispatch({ type: 'CAMPGAIGNS_LIST_REQUEST', formData: params });
    }
    const getFundraisingProgress = (params = {}) => {
        dispatch({ type: 'FUNDRAISING_PROGRESS_REQUEST', formData: params, id: id });
    }
    const getFundraisingProgressComment = (params = {}) => {
        dispatch({ type: 'FUNDRAISING_PROGRESS_COMMENT_REQUEST', formData: params, id: id });
    }

    useEffect(() => {
        if (!isProgressLoading && Object.keys(fundProgress).length > 0) {
            setProgress(fundProgress);
        }
    }, [isProgressLoading, fundProgress])

    useEffect(() => {
        if (!isFundStatusUplading && updateMsg === "success") {
            getFundraisingProgress();
            getFundraisingProgressComment();
        }
    }, [isFundStatusUplading, updateMsg]);

    const loadProgress = (progress) => {

        let tmpHtml = [];

        FUNDRAISER_ADMIN_TRAKING.map((item) => {
            let isDone = false;
            if (progress.hasOwnProperty(item.key)) {
                isDone = progress[item.key];
            }
            let getComment = getStatusComment(item.key, fundProgressComments);
            tmpHtml.push(
                <div key={item.id} className={`child ${isDone ? 'done' : ''} `} >
                    <p className={`icon`}>
                        {getComment !== "" &&
                            <Popover placement="top" overlayClassName="comment-tooltip" content={getComment} trigger={'hover'} >
                                <span className="comment pointer">
                                    <CommentOutlined />
                                </span>
                            </Popover>
                        }
                        {isDone ? <CheckOutlined /> : <CheckOutlined className="blank" />}
                    </p>
                    <p className="title">{item.title}</p>
                </div>
            );
            return 1;
        });

        return tmpHtml;


    }

    return (
        <div className="admin-fundraiser-detail">
            <div className="sub-header">
                <p className="mb0 title">
                    Fundraiser details
                </p>
            </div>
            <div className="filter mt8">
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        {isProgressLoading &&
                            <Skeleton active paragraph={{ rows: 1, width: '100%' }} title={{ width: '100%' }} />
                        }
                        {!isProgressLoading && Object.keys(fundProgress).length > 0 &&
                            <div className="rigt-navigation">
                                <div className="parent">
                                    {loadProgress(fundProgress)}
                                </div>
                            </div>
                        }
                    </Col>
                </Row>
                <Row className="mt10">
                    <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                        <DetailView fundProgress={fundProgress}/>
                    </Col>
                    <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                        <UpdateStatus />
                    </Col>
                </Row>

            </div>

            <Row className="mt5">

            </Row>
        </div>
    );
};
export default CampgaignDetail;