import { put, takeLatest, call } from 'redux-saga/effects'
import { message } from 'antd';
//Import API Service.
import { deleteRequestAPI, getRequestAPI, postRequestAPI, putRequestAPI } from '../../services/API/api';

function* getCityList({ formData, provinceId = "" }) {
    try {
        let response = "";
        if (provinceId === "") {
            response = yield call(getRequestAPI, { url: `cities`, params: formData });
        } else {
            response = yield call(getRequestAPI, { url: `provinces/${provinceId}/cities`, params: formData });
        }
        if (response.status === 200) {
            yield put({ type: 'CITY_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'CITY_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'CITY_LIST_ERROR', error: error.message || "" });
    }
}

function* getProvinceList({ formData }) {
    try {

        const response = yield call(getRequestAPI, { url: `provinces`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'PROVINCE_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'PROVINCE_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'PROVINCE_LIST_ERROR', error: error.message || "" });
    }
}

function* deleteRequest({ id, deleteType }) {
    try {
        let deleteUrl = "";
        if (deleteType === "organizations") {
            deleteUrl = "organizations";
        } else if (deleteType === "sellers") {
            deleteUrl = "sellers";
        } else if (deleteType === "fundraisingType") {
            deleteUrl = "fundraiser-types";
        } else if (deleteType === "fundraisingPackage") {
            deleteUrl = "fundraiser-packages";
        } else if (deleteType === "prizes") {
            deleteUrl = "prizes";
        } else if (deleteType === "users") {
            deleteUrl = "users";
        } else if (deleteType === "fundraiser") {
            deleteUrl = "fundraisers";
        } else if (deleteType === "coupon") {
            deleteUrl = "coupon";
        }
        const response = yield call(deleteRequestAPI, { url: `${deleteUrl}/${id}` });
        if (response.status === 200 || response.status === 204) {
            yield put({ type: 'DELETE_SUCCESS' });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'DELETE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'DELETE_ERROR', error: error.message || "" });
    }
}

function* uploadImage({ formData }) {
    try {

        const response = yield call(postRequestAPI, { url: `upload-image`, data: formData });
        if (response.status === 200) {
            yield put({ type: 'UPLOAD_IMAGE_SUCCESS', data: response.data?.data?.url || "" });
        } else {
            yield put({ type: 'UPLOAD_IMAGE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'UPLOAD_IMAGE_ERROR', error: error.message || "" });
    }
}

function* uploadDocument({ formData }) {
    try {

        const response = yield call(postRequestAPI, { url: `upload-document`, data: formData });
        if (response.status === 200) {
            yield put({ type: 'UPLOAD_IMAGE_SUCCESS', data: response.data?.data?.url || "" });
        } else {
            yield put({ type: 'UPLOAD_IMAGE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'UPLOAD_IMAGE_ERROR', error: error.message || "" });
    }
}

function* uploadNewDocument({ formData }) {
    try {

        const response = yield call(postRequestAPI, { url: `upload-document`, data: formData });
        if (response.status === 200) {
            yield put({ type: 'UPLOAD_NEW_DOCUMENT_SUCCESS', data: response.data?.data?.url || "" });
        } else {
            yield put({ type: 'UPLOAD_NEW_DOCUMENT_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'UPLOAD_NEW_DOCUMENT_ERROR', error: error.message || "" });
    }
}


function* getCourseList({ formData }) {
    try {

        const response = yield call(getRequestAPI, { url: `golf-courses`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'GET_COURSE_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'GET_COURSE_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'GET_COURSE_LIST_ERROR', error: error.message || "" });
    }
}

function* getWindDirectionList({ formData }) {
    try {

        const response = yield call(getRequestAPI, { url: `wind-directions`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'GET_WIND_DIRECTION_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'GET_WIND_DIRECTION_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'GET_WIND_DIRECTION_LIST_ERROR', error: error.message || "" });
    }
}

function* getExistCourseList({ id }) {
    try {

        const response = yield call(getRequestAPI, { url: `fundraisers/${id}/golf-courses` });
        if (response.status === 200) {
            yield put({ type: 'GET_EXIST_COURSE_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'GET_EXIST_COURSE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'GET_EXIST_COURSE_ERROR', error: error.message || "" });
    }
}

// function* getPaymentIntent({ id }) {
//     try {

//         const response = yield call(getRequestAPI, { url: `fundraisers/${id}/payment/intent`, params: {} });
//         // const response = yield call(getRequestAPI, { url: `payment/success`, params: {} });
//         if (response.status === 200) {
//             yield put({ type: 'GET_PAYMENT_INTENT_SUCCESS', data: response.data.data || [] });
//         } else {
//             yield put({ type: 'GET_PAYMENT_INTENT_ERROR', error: "No record found" });
//         }
//     } catch (error) {
//         yield put({ type: 'GET_PAYMENT_INTENT_ERROR', error: error.message || "" });
//     }
// }


function* getPaymentIntent({ id }) {
    try {

        const response = yield call(postRequestAPI, { url: `fundraisers/${id}/moneris/preload`, data: {} });
        if (response.status === 200) {
            yield put({ type: 'GET_PAYMENT_INTENT_SUCCESS', data: response.data.response || [] });
        } else {
            yield put({ type: 'GET_PAYMENT_INTENT_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'GET_PAYMENT_INTENT_ERROR', error: error.message || "" });
    }
}

function* updatePaymentDetail({ formData, id }) {
    try {

        const response = yield call(postRequestAPI, { url: `fundraisers/${id}/moneris/receipt`, data: formData });
        if (response.status === 200 && response?.data.response?.success === "true") {
            yield put({ type: 'PAYMENT_UPDATE_SUCCESS', data: "" });
        } else {
            message.error({ content: "Something went wrong", className: 'error-class', duration: 5 });
            yield put({ type: 'PAYMENT_UPDATE_ERROR', error: "No record found" });
        }
    } catch (error) {
        message.error({ content: error.message, className: 'error-class', duration: 5 });
        yield put({ type: 'PAYMENT_UPDATE_ERROR', error: error.message || "" });
    }
}

function* updatePaymentType({ formData, id }) {
    try {

        const response = yield call(putRequestAPI, { url: `fundraisers/${id}/update-payment-status`, data: formData });
        if (response.status === 200) {
            yield put({ type: 'UPDATE_PAYMENT_TYPE_SUCCESS', data: "" });
        } else {
            yield put({ type: 'UPDATE_PAYMENT_TYPE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'UPDATE_PAYMENT_TYPE_ERROR', error: error.message || "" });
    }
}

function* updateCourseData({ formData, id }) {
    try {

        const response = yield call(putRequestAPI, { url: `fundraisers/${id}/golf-courses`, data: formData });
        if (response.status === 200) {
            yield put({ type: 'UPDATE_COURSE_DATA_SUCCESS', data: "" });
        } else {
            yield put({ type: 'UPDATE_COURSE_DATA_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'UPDATE_COURSE_DATA_ERROR', error: error.message || "" });
    }
}


function* getSellerList({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `sellers`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'GET_SELLER_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'GET_SELLER_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'GET_SELLER_LIST_ERROR', error: error.message || "" });
    }
}

function* createSeller({ formData, requestType, id }) {
    try {
        let response = "";
        if (requestType === "post") {
            response = yield call(postRequestAPI, { url: `sellers`, data: formData });
        } else if (requestType === "put") {
            response = yield call(putRequestAPI, { url: `sellers/${id}`, data: formData });
        }
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'CREATE_SELLER_SUCCESS', data: response.data || "" });
        } else {
            yield put({ type: 'CREATE_SELLER_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'CREATE_SELLER_ERROR', error: error.message || "" });
    }
}

function* contactSupport({ formData, contactFor = "", id = "" }) {
    try {
        let response = "";
        if (contactFor === "admin") {
            response = yield call(postRequestAPI, { url: `fundraisers/${id}/contact-fundraising-admin`, data: formData });
        } else {
            response = yield call(postRequestAPI, { url: `contact`, data: formData });
        }
        if (response.status === 200) {
            yield put({ type: 'CONTACT_SUPPORT_SUCCESS', data: response.data || "" });
        } else {
            yield put({ type: 'CONTACT_SUPPORT_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'CONTACT_SUPPORT_ERROR', error: error.message || "" });
    }
}


function* importSellerList({ formData }) {
    try {
        console.log(formData);
        const response = yield call(postRequestAPI, { url: `sellers/import`, data: formData });
        if (response.status === 200) {
            yield put({ type: 'SELLER_IMPORT_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'SELLER_IMPORT_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'SELLER_IMPORT_ERROR', error: error.message || "" });
    }
}

function* closeFundraiser({ id }) {
    try {
        const response = yield call(putRequestAPI, { url: `fundraisers/${id}/close`, data: {} });
        if (response.status === 200) {
            yield put({ type: 'CLOSE_FUNDRAISER_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'CLOSE_FUNDRAISER_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'CLOSE_FUNDRAISER_ERROR', error: error.message || "" });
    }
}

function* startFundraiser({ id }) {
    try {
        const response = yield call(putRequestAPI, { url: `fundraisers/${id}/update-for-play-testing`, data: {} });
        if (response.status === 200) {
            yield put({ type: 'START_FUNDRAISER_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'START_FUNDRAISER_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'START_FUNDRAISER_ERROR', error: error.message || "" });
    }
}

function* getFundraisingProgress({ formData, id }) {
    try {
        const response = yield call(getRequestAPI, { url: `fundraisers/${id}/show-progress`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'FUNDRAISING_PROGRESS_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'FUNDRAISING_PROGRESS_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'FUNDRAISING_PROGRESS_ERROR', error: error.message || "" });
    }
}

function* getFundraisingProgressComment({ id }) {
    try {
        const response = yield call(getRequestAPI, { url: `fundraisers/${id}/status-comments`, params: {} });
        if (response.status === 200) {
            yield put({ type: 'FUNDRAISING_PROGRESS_COMMENT_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'FUNDRAISING_PROGRESS_COMMENT_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'FUNDRAISING_PROGRESS_COMMENT_ERROR', error: error.message || "" });
    }
}

function* getReferenceList({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `references`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'REFERENCES_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'REFERENCES_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'REFERENCES_LIST_ERROR', error: error.message || "" });
    }
}

function* getFundraisingPayments({ formData, id }) {
    try {
        const response = yield call(getRequestAPI, { url: `fundraisers/${id}/fundraiser-orders`, params: formData });

        if (response.status === 200) {
            yield put({ type: 'FUNDRAISER_PAYMENT_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'FUNDRAISER_PAYMENT_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'FUNDRAISER_PAYMENT_ERROR', error: error.message || "" });
    }
}


function* getPaymentToken({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `paypal/client-token`, params: formData });

        if (response.status === 200) {
            yield put({ type: 'PAYMENT_TOKEN_SUCCESS', data: response.data?.client_token || "" });
        } else {
            yield put({ type: 'PAYMENT_TOKEN_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'PAYMENT_TOKEN_ERROR', error: error.message || "" });
    }
}

function* getPaymentAmount({ id }) {
    try {
        const response = yield call(getRequestAPI, { url: `fundraisers/${id}/payment-with-taxes`, params: {} });

        if (response.status === 200) {
            yield put({ type: 'PAYMENT_AMOUNT_SUCCESS', data: response.data?.data || "" });
        } else {
            yield put({ type: 'PAYMENT_AMOUNT_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'PAYMENT_AMOUNT_ERROR', error: error.message || "" });
    }
}

function* getPaymentCouponAmount({ id }) {
    try {
        const response = yield call(getRequestAPI, { url: `fundraiser/${id}/coupon`, params: {} });

        if (response.status === 200) {
            yield put({ type: 'PAYMENT_COUPON_SUCCESS', data: response.data?.coupon || "" });
        } else {
            yield put({ type: 'PAYMENT_COUPON_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'PAYMENT_COUPON_ERROR', error: error.message || "" });
    }
}

function* createETransfer({ id, formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `fundraiser/${id}/e-transfer/payment/create`, data: formData });
        if (response.status === 200) {
            yield put({ type: 'E_TRANSFER_CREATE_SUCCESS', data: response.data || [] });
        } else {
            yield put({ type: 'E_TRANSFER_CREATE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'E_TRANSFER_CREATE_ERROR', error: error.message || "" });
    }
}

function* addPaymentCoupon({ id, formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `fundraiser/${id}/coupon`, data: formData });
        if (response.status === 201) {
            yield put({ type: 'PAYMENT_COUPON_APPLY_SUCCESS', data: response.data || [] });
            message.success({ content: "Coupon Applied Successfully", className: 'success-class', duration: 5 })
        } else {
            message.error({ content: response?.message || 'Invalid Code.', className: 'error-class', duration: 5 })
            yield put({ type: 'PAYMENT_COUPON_APPLY_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'PAYMENT_COUPON_APPLY_ERROR', error: error.message || "" });
    }
}

function* removePaymentCoupon({ id, formData, couponId }) {
    try {
        const response = yield call(postRequestAPI, { url: `fundraiser/${id}/coupon/${couponId}`, data: formData });
        if (response.status === 200) {
            yield put({ type: 'PAYMENT_COUPON_REMOVE_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'PAYMENT_COUPON_REMOVE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'PAYMENT_COUPON_REMOVE_ERROR', error: error.message || "" });
    }
}



function* watchCommonSagaRequests() {
    yield takeLatest('CITY_LIST_REQUEST', getCityList);
    yield takeLatest('PROVINCE_LIST_REQUEST', getProvinceList);
    yield takeLatest('UPLOAD_IMAGE_REQUEST', uploadImage);
    yield takeLatest('UPLOAD_DOCUMENT_REQUEST', uploadDocument);
    yield takeLatest('UPLOAD_NEW_DOCUMENT_REQUEST', uploadNewDocument);

    yield takeLatest('DELETE_REQUEST', deleteRequest);
    yield takeLatest('GET_COURSE_LIST_REQUEST', getCourseList);
    yield takeLatest('GET_WIND_DIRECTION_LIST_REQUEST', getWindDirectionList);
    yield takeLatest('GET_EXIST_COURSE_REQUEST', getExistCourseList);
    yield takeLatest('UPDATE_COURSE_DATA_REQUEST', updateCourseData);
    yield takeLatest('UPDATE_PAYMENT_TYPE_REQUEST', updatePaymentType); // Step 7
    yield takeLatest('GET_PAYMENT_INTENT_REQUEST', getPaymentIntent); // Payment related
    yield takeLatest('PAYMENT_UPDATE_REQUEST', updatePaymentDetail); // Payment related
    yield takeLatest('FUNDRAISER_PAYMENT_REQUEST', getFundraisingPayments); // Payment related

    yield takeLatest('GET_SELLER_LIST_REQUEST', getSellerList);
    yield takeLatest('CREATE_SELLER_REQUEST', createSeller);
    yield takeLatest('SELLER_IMPORT_REQUEST', importSellerList);
    yield takeLatest('CONTACT_SUPPORT_REQUEST', contactSupport);

    yield takeLatest('CLOSE_FUNDRAISER_REQUEST', closeFundraiser);
    yield takeLatest('START_FUNDRAISER_REQUEST', startFundraiser);
    yield takeLatest('FUNDRAISING_PROGRESS_REQUEST', getFundraisingProgress);
    yield takeLatest('FUNDRAISING_PROGRESS_COMMENT_REQUEST', getFundraisingProgressComment);
    yield takeLatest('REFERENCES_LIST_REQUEST', getReferenceList);

    //Stripe Routes
    yield takeLatest('PAYMENT_TOKEN_REQUEST', getPaymentToken);
    yield takeLatest('PAYMENT_AMOUNT_REQUEST', getPaymentAmount);
    yield takeLatest('PAYMENT_COUPON_REQUEST', getPaymentCouponAmount);
    yield takeLatest('PAYMENT_COUPON_APPLY_REQUEST', addPaymentCoupon)
    yield takeLatest('PAYMENT_COUPON_REMOVE_REQUEST', removePaymentCoupon)
    yield takeLatest('E_TRANSFER_CREATE_REQUEST', createETransfer)

}

function* CommonSaga() {
    yield watchCommonSagaRequests();
}

export default CommonSaga;