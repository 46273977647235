/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Row, Col, Input, Table, Space, Tag, Tooltip } from "antd";
import { DownloadOutlined, EyeOutlined, DeleteFilled } from '@ant-design/icons';
import { GAME_STATUS, PER_PAGE } from '../../../utils/constant';
import { displayStatusText, formatDate, getGameStatusColor, isAuthorized } from '../../../utils/functions';
import GameTabs from '../../common/gameTabs';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from 'moment';

const { Search } = Input;
const { Column } = Table;

let filterParams = { status: "", search: "" };
let initialFilter = { status: "", search: "" };
const AdminGames = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const { isGameLoading, gameList, gamePaginationMeta } = useSelector(state => state.player);
    const { isExportLoading, exportData } = useSelector(state => state.admin);
    const [activeTab, setActiveTab] = useState('all');
    const [perPage,] = useState(15);
    const [pageNo, setPageNo] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [tmpExportData, setTmpExportData] = useState("");
    const [exportLoading, setIsexportLoading] = useState(isExportLoading);

    useEffect(() => { getAllGamesList({}) }, []);

    const getAllGamesList = (params = {}) => {
        dispatch({ type: 'ADMIN_GAME_LIST_REQUEST', formData: { ...filterParams, per_page: perPage, ...params } });
    }

    useEffect(() => {
        if (!isGameLoading && gameList?.length) {
            let tmpCmapList = [];
            gameList.map((item) => {
                let tmpObj = item;
                tmpObj.status = item?.running_status;
                tmpObj.key = item?.id;
                tmpObj.display_status = displayStatusText(item?.running_status || "In-Progress");
                tmpCmapList.push(tmpObj);
                return 1;
            });
            setTotalRecord(gamePaginationMeta.total);
            setTableData(tmpCmapList);
        } else {
            if (!isGameLoading && gameList.length === 0) {
                setTableData([]);
                setTotalRecord(0);
                setPageNo(1);
            }
        }
    }, [isGameLoading, gameList]);


    const onChange = (e, type) => {
        filterParams.page = 1;
        setPageNo(1);

        if (type === "search") {
            filterParams[type] = e;
        } else if (type === "status") {
            setActiveTab(e);
            if (e === "all") {
                filterParams[type] = "";
            } else {
                filterParams[type] = e;
            }
        } else if (type === 'clear') {
            filterParams = initialFilter;
        }
        getAllGamesList(filterParams)
    }

    const handlePagination = (e) => {
        setPageNo(e);
        getAllGamesList({ page: e })
    }

    const exportGames = () => {
        setIsexportLoading(true);
        dispatch({ type: 'EXPORT_REQUEST', formData: {}, exportFor: 'games' });
    }

    useEffect(() => {
        if (!isExportLoading && exportData !== "") {
            setTmpExportData(exportData);
            setTimeout(() => {
                dispatch({ type: "ADMIN_DATA_CLEAR" });
                setIsexportLoading(isExportLoading);
                document.getElementById('exportToCSVLink').click();
            }, 1000);
        }
    }, [isExportLoading, exportData]);

    return (
        <div>
            <div className="sub-header">
                <p className="mb0 title">
                    Games
                </p>
                <div>
                    <Button
                        type="primary"
                        size="large"
                        className="br1 mr4"
                        htmlType="submit"
                        id="export-fundraiser"
                        loading={exportLoading}
                        disabled={exportLoading}
                        onClick={() => exportGames()}
                        icon={<DownloadOutlined />}
                    >
                        Export Games
                    </Button>
                    <CSVLink id="exportToCSVLink" filename="Games.csv" data={tmpExportData} />
                </div>
            </div>
            <div className="filter mt8">
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>

                        <Search
                            defaultValue={filterParams['search']}
                            size="large"
                            allowClear
                            placeholder="Search"
                            style={{ width: "60%" }}
                            enterButton
                            onSearch={(e) => onChange(e, 'search')}
                        />
                    </Col>
                </Row>
            </div>
            <Row className='campaign-table mt10'>
                <GameTabs
                    onChange={(e) => onChange(e, 'status')}
                    item={GAME_STATUS}
                    active={activeTab}
                />
            </Row>
            <Row className="mt5">
                <Table
                    style={{ width: "100%" }}
                    dataSource={tableData}
                    loading={isGameLoading}
                    // pagination={true}
                    pagination={{
                        current: pageNo,
                        onChange: handlePagination,
                        pageSize: perPage,
                        total: totalRecord
                    }}
                    scroll={{ x: "max-content" }}
                >

                    <Column
                        title="Campaign title"
                        dataIndex="campaign_title"
                        key="campaign_title"
                        width={"250px"}
                    />
                    <Column
                        title="Start date"
                        dataIndex="start_date"
                        key="start_date"
                        render={(text, record) => formatDate(text)}
                    />
                    <Column
                        title="Close Date"
                        dataIndex="close_date"
                        key="close_date"
                        render={(text, record) => formatDate(text)}
                    />
                    <Column
                        title="Tickets Generated"
                        dataIndex="tickets_generated"
                        key="tickets_generated"
                        width={"180px"}
                    />
                    <Column
                        title="Tickets Used"
                        dataIndex="tickets_used"
                        key="tickets_used"
                        width={"140px"}
                    />
                    <Column
                        title="# of Players"
                        dataIndex="no_of_players"
                        key="no_of_players"
                        width={"120px"}
                    />
                    <Column
                        title="Status"
                        dataIndex="display_status"
                        key="display_status"
                        width={"100px"}
                        render={(_, { display_status, status }) => {
                            let color = getGameStatusColor(status)
                            return (
                                <Space size="middle">
                                    <Tag color={color} key={status}>
                                        {display_status}
                                    </Tag>
                                </Space>)
                        }}
                    />

                    <Column
                        title={"Action"}
                        key='action'
                        fixed='right'
                        width={"100px"}
                        render={(_, record) => {
                            return (
                                <Space size="middle" className={`cursor ${record.isDisabled ? 'disabled-edit' : 'not-disabled'}`}>
                                    {isAuthorized('view_games') &&
                                        <Tooltip placement="top" title={"View Detail"}>
                                            <EyeOutlined
                                                onClick={() => navigate(`/admin/game/${record.id}`)}
                                                disabled={record.isDisabled}
                                                className={`pointer cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }
                                    {/* {isAuthorized('delete_games') &&
                                        <Tooltip placement="top" color={"red"} title={"Delete"}>
                                            <DeleteFilled
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    } */}
                                </Space>
                            )
                        }}
                    />
                </Table>
            </Row>
        </div>
    );
};
export default AdminGames;