/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input, Select, Row, Col, Checkbox, Spin, Tag } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import noImage from "../../../src/assets/noimage.png";
import { formatDate, getCityName, getFileName, isImage } from '../../utils/functions';
import SingleFileUpload from './singleFileUpload';
import UploadImage from './upload';
import { EyeOutlined, DownloadOutlined } from '@ant-design/icons';

const { Option } = Select;

const OrganizationModal = (props) => {
    const { visible, setVisible, modalType, rowData, from = "" } = props;
    const { isCityLoading, cityList, provinceList, isProvinceLoading, uploadImageURL, isDeleteLoading, docURL, updateMsg, isDocUploadLoading, isImageUploading } = useSelector(state => state.common);
    const { isOrganizationCreateLoading, isOrgDetailLoading, orgDetail } = useSelector(state => state.fundraiser);

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [imgUrl, setImgUrl] = useState("");
    const [pageOrgDetail, setPageOrgDetail] = useState({});
    const [provinceId, setProvinceId] = useState("");
    const [cityId, setCityId] = useState("");
    const [fileName, setFileName] = useState("");
    const [approveDocURL, setApproveDocURL] = useState("");

    useEffect(() => {
        if (modalType === "view") {
            if (!isOrgDetailLoading && Object.keys(orgDetail).length > 0) {
                setPageOrgDetail(orgDetail);
            }
        }
    }, [isOrgDetailLoading, orgDetail]);

    useEffect(() => {
        form.resetFields();
        dispatch({ type: 'CLEAR_COMMON' });
    }, [])

    useEffect(() => {
        if (!isOrganizationCreateLoading && updateMsg === "success") {
            setFileName("");
        }
    }, [isOrganizationCreateLoading, updateMsg])

    const callCities = (e) => {
        setCityId("");
        setProvinceId(e);
        form.setFieldsValue({ city_id: "" });
        dispatch({ type: 'CITY_LIST_REQUEST', formData: { per_page: 5000 }, provinceId: e });
    }

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                let tmpValues = {};
                for (const property in values) {
                    if (values[property] !== undefined) {
                        tmpValues[property] = values[property];
                    }
                }
                tmpValues.image = uploadImageURL || imgUrl;
                tmpValues.approving_use_document = fileName !== "" ? (docURL || approveDocURL) : "";
                if (modalType === "edit") {
                    dispatch({ type: 'UPDATE_ORGANIZATION_REQUEST', formData: tmpValues, 'requestType': 'put', id: rowData.id });
                } else if (modalType === "delete") {
                    dispatch({ type: 'DELETE_REQUEST', id: rowData.id, deleteType: 'organizations' });
                } else {
                    dispatch({ type: 'UPDATE_ORGANIZATION_REQUEST', formData: tmpValues, 'requestType': 'post' });
                }
                setTimeout(() => {
                    form.resetFields();
                    setImgUrl("");
                }, 1000);
            })
            .catch((errorInfo) => {
                console.log(errorInfo)
            });
    }
    useEffect(() => {
        setImgUrl("");
    }, [])

    useEffect(() => {
        if (modalType === "view" && from === "detail-page") {
            dispatch({ type: "GET_ORGANIZATION_DETAIL_REQUEST", orgId: rowData?.id });
        }
    }, [modalType, from])
    useEffect(() => {
        if (rowData !== "" && Object.keys(rowData).length > 0) {
            form.setFieldsValue({
                name: rowData?.name || "",
                email: rowData?.email || "",
                phone: rowData?.phone || "",
                address: rowData?.address || "",
                province_id: rowData?.province?.id || "",
                city: getCityName(rowData.city) 
            });
            console.log("rowData--------------")
            console.log(rowData)
            setImgUrl(rowData.image !== null && rowData.image !== "" ? rowData.image : "")
            setFileName(rowData.approving_use_document !== null && rowData.approving_use_document !== "" ? rowData.approving_use_document : "")
            setApproveDocURL(rowData.approving_use_document !== null && rowData.approving_use_document !== "" ? rowData.approving_use_document : "")
        }
    }, [rowData])

    return (
        <Modal
            className={`prize-modal step4-modal org-modal ${modalType === "view" ? "view-modal" : ""}`}
            title={`${modalType === "view" ? "" : modalType === "add" ? "Create New Organization" : modalType === "edit" ? "Edit Organization" : modalType === "delete" ? "Are you sure?" : ""}`}
            open={visible}
            onOk={() => handleSubmit()}
            onCancel={() => {
                dispatch({ type: "CLEAR_COMMON" });
                setVisible(false);
                form.resetFields();
            }}
            centered
            width={700}
            okText={`${modalType === "delete" ? "Delete" : modalType === "edit" ? 'Update' : "Save"}`}
            okButtonProps={{ disabled: isOrganizationCreateLoading || isDeleteLoading || isDocUploadLoading || isImageUploading, loading: isOrganizationCreateLoading || isDeleteLoading }}
            cancelText="Cancel"
            maskClosable={modalType === "view" ? true : false}

        >
            {(modalType === "edit" || modalType === "add") &&
                <div className="mt4">
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        form={form}
                        layout="vertical"
                    >
                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter organization name'
                                },
                                {
                                    max: 100,
                                    message: 'Maximum 100 characters only allowed!',
                                }]
                            }
                        >
                            <Input size="large" placeholder={"Organization Name"} />
                        </Form.Item>

                        <UploadImage uploadImageURL={uploadImageURL || imgUrl} />

                        <Form.Item
                            className='mt5'
                            name="copy"
                            valuePropName="checked"
                            initialValue={true}
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject(new Error('Please checked checkbox')),
                                },
                            ]}
                        >
                            <Checkbox defaultChecked={true}>
                                I accept that i have rights to use above image
                            </Checkbox>
                        </Form.Item>

                        <Form.Item
                            className="mt5"
                            name="email"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please enter email address'
                                },
                                {
                                    type: 'email',
                                    message: 'Please enter valid email address',
                                },
                            ]}
                        >
                            <Input size="large" placeholder={"Email Address"} autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            name="phone"
                            rules={[{ required: false, message: 'Please enter your campaign description' }]}
                        >
                            <Input type="number" size="large" prefix="(+1)" placeholder={"9876543210"} />
                        </Form.Item>

                        <Form.Item
                            name="address"
                            rules={[
                                {
                                    max: 200,
                                    message: 'Maximum 200 characters only allowed!',
                                }]
                            }
                        >
                            <Input size="large" placeholder={"Address"} />
                        </Form.Item>

                        <Row gutter={30}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="">
                                <Form.Item
                                    label="Province"
                                    name="province_id"
                                    rules={[{ required: true, message: 'Please select province' }]}
                                >
                                    <Select
                                        loading={isProvinceLoading}
                                        placeholder="Select Province"
                                        size="large"
                                        showSearch
                                        autoComplete="new-state"
                                        defaultValue={provinceId}
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    >
                                        {!isProvinceLoading && provinceList.map((item, index) => (
                                            <Option key={index} value={item.id}>{item.name}</Option>))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="">
                                <Form.Item
                                    label="City"
                                    name="city"
                                    rules={[{ required: true, message: 'Please enter city' }]}
                                >
                                    <Input size="large" placeholder={"City"} autoComplete="off" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Please provide documentation that you received from the organization that gives you permission to use their logo and information">
                            <SingleFileUpload setfileName={setFileName} />
                            {fileName !== "" && <p className='mt2'>Uploaded File: {getFileName(fileName)}</p>}
                        </Form.Item>

                    </Form>
                </div>
            }
            {modalType === "view" &&
                <div className="mt4">
                    {isOrgDetailLoading && <div className='no-data'><Spin /></div>}
                    {!isOrgDetailLoading &&
                        <>
                            <Row className='section1'>
                                <Col xs={24} sm={24} md={14} lg={14} xl={14} className="org-view">
                                    <p className='title'>{pageOrgDetail.name}</p>
                                    <p className='mb2'>
                                        <Tag color={pageOrgDetail.status === "active" ? 'green' : 'default'}>
                                            {pageOrgDetail.status === "active" ? "Active" : "Archived"}
                                        </Tag>
                                    </p>
                                    <p className='mb1'><b>Email:</b>&nbsp;&nbsp;{pageOrgDetail?.email}</p>
                                    <p className='mb1'><b>Phone:</b>&nbsp;&nbsp;{pageOrgDetail?.phone}</p>
                                    <p className='mb1'><b>Address:</b>&nbsp;&nbsp;{pageOrgDetail?.address}</p>
                                    <p className='mb1'><b>Province:</b>&nbsp;&nbsp;{pageOrgDetail?.province?.name}</p>
                                    <p className='mb2'><b>City:</b>&nbsp;&nbsp;{pageOrgDetail?.city}</p>
                                    <p >
                                        <b>Approving Document:</b>
                                        {pageOrgDetail.approving_use_document !== null &&
                                            <span className="link" onClick={() => {
                                                window.open(pageOrgDetail.approving_use_document, '_blank');
                                            }}>
                                                {pageOrgDetail.document_title}&nbsp;&nbsp;
                                                {isImage(pageOrgDetail.approving_use_document) &&
                                                    <>
                                                        <span>{getFileName(pageOrgDetail.approving_use_document)}</span>&nbsp;
                                                        <EyeOutlined
                                                            onClick={() => window.open(pageOrgDetail.approving_use_document, '_blank')}
                                                            className={`pointer cursor`}
                                                            style={{ fontSize: "16px" }}
                                                        />
                                                    </>
                                                }
                                                {!isImage(pageOrgDetail.approving_use_document) &&
                                                    <>
                                                        <span>{getFileName(pageOrgDetail.approving_use_document)}</span>&nbsp;
                                                        <DownloadOutlined
                                                            onClick={() => window.open(pageOrgDetail.approving_use_document, '_blank')}
                                                            className={`pointer cursor`}
                                                            style={{ fontSize: "16px" }}
                                                        />
                                                    </>
                                                }

                                            </span>
                                        }
                                    </p>
                                </Col>
                                <Col xs={24} sm={24} md={10} lg={10} xl={10} className="">
                                    <div className='org-img-section mb2'>
                                        <img src={pageOrgDetail.image || noImage} alt={"Organziation Logo"} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className=''>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="org-view">
                                    <p className='fs18 bold mt5'>Fundraisers</p>
                                    {pageOrgDetail.fundraisers !== undefined && pageOrgDetail.fundraisers !== null && pageOrgDetail.fundraisers.length > 0 && pageOrgDetail.fundraisers.map((fData) => {
                                        return (
                                            <div className=''>
                                                <p className='mb0 title fs16'>{fData?.title}</p>
                                                <p className="mb4 date-style">{
                                                    fData.start_date !== null ? `${formatDate(fData.start_date)} • ${formatDate(fData.end_date)}` : ''}
                                                </p>
                                            </div>
                                        )
                                    })}
                                    {pageOrgDetail.fundraisers !== undefined && pageOrgDetail.fundraisers !== null && pageOrgDetail.fundraisers.length === 0 &&
                                        <p>Not used in any fundraiser</p>
                                    }
                                </Col>
                            </Row>
                        </>
                    }
                </div>
            }
            {modalType === "delete" &&
                <div className="mt2">
                    <p>You want to delete this organization</p>
                </div>
            }


        </Modal>
    );
};

export default OrganizationModal;