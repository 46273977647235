
import React from "react";
import { CURRENT_ROLE, ROLE_NAME } from '../utils/constant';
import { isLogin } from "../utils/functions";


// import Dashboard from "./admin";
import Dashboard from '../components/admin/campaigns';
import FundraiserDashboard from "./fundraising/index";

export function MainPage(children) {

    if (isLogin() && CURRENT_ROLE === ROLE_NAME.ADMIN) {
        return <Dashboard />;
    } else if (isLogin() && CURRENT_ROLE === ROLE_NAME.FUNDRAISER) {
        return <FundraiserDashboard />;
    }
}
export default MainPage;
