import React from "react";
import { Modal, Button } from 'antd';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';

const SuccessModal = ({ onCancel, isSuccessModal }) => {
    return (
        <Modal
            className="success-modal-parent"
            footer={false}
            visible={isSuccessModal}
            onCancel={() => onCancel(false)}
            width={600}
            maskClosable={false}
        >
            <div className="success-modal-child">
                <IoCheckmarkCircleSharp size={60} />
                <p className="fs18 bold mt5 mb4">Great News! Your fundraiser has been created successfully</p>
                {/* <p className="mb6 text">Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.</p> */}
                <Button
                    type="primary"
                    size="large"
                    className="br20 pr8 pl8"
                    htmlType="submit"
                    onClick={() => onCancel(false)}
                >
                    Close
                </Button>
            </div>
        </Modal>
    );
};
export default SuccessModal;
