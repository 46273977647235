import React, { useEffect, useState } from "react";
import { Card, Spin } from "antd";
import StepTemplate from './stepTemplate';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { formatAmount } from "../../../utils/functions";
import NextPrevButton from "./nextPrevButton";


const Step2 = (props) => {

    const dispatch = useDispatch();
    const { id } = useParams();

    const { isFPackageLoading, fundraiserPackageList, isFPackageCreateLoading, statusMsg, isGetFundraiserLoading, fundraiserData } = useSelector(state => state.fundraiser);
    const [selectedId, setSelectedId] = useState('');

    useEffect(() => {
        dispatch({ type: 'FUNDRAISER_PACKAGE_LIST_REQUEST', formData: { status: 'active' } });
        if (id) {
            dispatch({ type: 'GET_FUNDRAISER_DATA_REQUEST', formData: { id: id } });
        }
    }, []);

    const pageNext = () => {
        // props.handleNext();
        if (id) {
            dispatch({ type: 'CREATE_FUNDRAISER_PACKAGE_REQUEST', formData: { 'fundraiser_package_id': selectedId }, 'requestType': 'put', id: id });
        } else {

            dispatch({ type: 'CREATE_FUNDRAISER_PACKAGE_REQUEST', formData: { 'fundraiser_package_id': selectedId }, 'requestType': 'post' });
        }

    }

    useEffect(() => {
        if (!isFPackageCreateLoading && statusMsg === "success") {
            dispatch({ type: 'CLEAR_FUNDRAISER_STATUS' });
            props.handleNext();
        }
    }, [isFPackageCreateLoading, statusMsg]);


    useEffect(() => {
        if (!isGetFundraiserLoading && Object.keys(fundraiserData).length > 0) {
            setSelectedId(fundraiserData?.fundraiser_package_id)
        }
    }, [isGetFundraiserLoading, fundraiserData])

    return (
        <>
            <StepTemplate
                handleNext={pageNext}
                handleBack={props.handleBack}
                step={props.step}
                isDisabled={selectedId === ""}
                isLoading={isFPackageCreateLoading}
            >
                <NextPrevButton
                    handleNext={pageNext}
                    handleBack={props.handleBack}
                    step={props.step}
                    isDisabled={selectedId === ""}
                    isLoading={isFPackageCreateLoading}
                />
                <div className="step2 content-container">
                    <p className="title mb2">Pick a package</p>
                    <p className="sub-title">The amount raised, specified in each package below, is the net amount that your fundraiser will make after the cost of the fundraiser is paid and all tickets are sold.</p>
                    <div className="mt10">
                        {isFPackageLoading && <div className="no-data"><Spin /></div>}
                        {!isFPackageLoading && fundraiserPackageList.length > 0 && fundraiserPackageList.map((item) => {
                            return (
                                <Card style={{ width: 700 }} className={`mb4 main-card pointer ${selectedId === item.id ? 'active' : ''}`} onClick={() => setSelectedId(item.id)} >
                                    <div className="camp-card step2">
                                        <div className="">
                                            <p className="mb1 text">Raise up to {formatAmount(item.amount_to_be_generated || "")}</p>
                                            <p className="mb0 sub-text">{item.no_tickets_allowed} tickets typically  {item.no_sellers_allowed} sellers</p>
                                        </div>
                                        <div className="">
                                            <p className="mb0 text">Purchase Price</p>
                                            <p className="mb0 amount tr">{formatAmount(item.regular_fees, 0)}</p>
                                        </div>
                                    </div>
                                </Card>
                            )
                        })}

                    </div>
                </div>
            </StepTemplate>
        </>
    );
};
export default Step2;
