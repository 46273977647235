/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Form, Input, Row, DatePicker, Col, Radio } from 'antd';
import StepTemplate from './stepTemplate';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getDisabledDays, inputFormatDate, isImage } from '../../../utils/functions';
import FileUpload from "../../common/fileUpload";
import { EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import NextPrevButton from "./nextPrevButton";

const { TextArea } = Input;

const Step3 = (props) => {

    const [form] = Form.useForm();
    const { isFDetailCreateLoading, statusMsg, isGetFundraiserLoading, fundraiserData } = useSelector(state => state.fundraiser);
    const { uploadImageURL } = useSelector(state => state.common);

    const [endDateDisabled, setEndDateDisabled] = useState(moment().endOf('day'));
    const [startDateDisabled, setStartDateDisabled] = useState(moment().add(10, 'day'));
    const [defaultEndDate, setDefaultEndDate] = useState(null);
    const [selectedStartDate, setSelectedStartDate] = useState();
    const [isAutoApproved, setIsAutoApproved] = useState(true);
    const [fileName, setFileName] = useState("");
    const [disabledDays, setDisabledDays] = useState(24);


    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            dispatch({ type: 'GET_FUNDRAISER_DATA_REQUEST', formData: { id: id } });
        }
    }, []);

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values) => {
                if (id) {
                    if (!isAutoApproved) {
                        let tmpFileName = fileName.split(".");
                        if (fileName !== "" && fileName !== "" && tmpFileName.length > 0) {
                            values.document_title = tmpFileName[0];
                        }
                        if (uploadImageURL !== null && uploadImageURL !== "") {
                            values.document_url = uploadImageURL;
                        }
                        if (values.comment === undefined || values.comment === null || values.comment === "") {
                            delete values.comment;
                        }
                    }
                    values.start_date = inputFormatDate(values.start_date);
                    values.end_date = inputFormatDate(values.end_date);
                    dispatch({ type: 'CREATE_FUNDRAISER_DETAIL_REQUEST', formData: values, 'requestType': 'put', id: id });
                }
            })
            .catch((errorInfo) => {
                console.log(errorInfo)
            });
    };

    useEffect(() => {
        if (!isFDetailCreateLoading && statusMsg === "success") {
            dispatch({ type: 'CLEAR_FUNDRAISER_STATUS' });
            props.handleNext();
        }
    }, [isFDetailCreateLoading, statusMsg]);


    useEffect(() => {
        if (!isGetFundraiserLoading && Object.keys(fundraiserData).length > 0) {
            form.setFieldsValue({
                title: fundraiserData?.title || "",
                description: fundraiserData?.description || "",
                start_date: fundraiserData.start_date !== null ? moment(fundraiserData?.start_date) : "",
                end_date: fundraiserData.end_date !== null ? moment(fundraiserData?.end_date) : "",
                updates_frequency: fundraiserData?.updates_frequency,
                no_of_sellers: fundraiserData?.no_of_sellers || ""
            });
            setIsAutoApproved(fundraiserData?.fundraiser_type.auto_approve ? true : false)
            console.log(getDisabledDays(fundraiserData));
            setDisabledDays(getDisabledDays(fundraiserData))
        }
    }, [isGetFundraiserLoading, fundraiserData])

    const disabledEndDate = (current) => {

        return (
            current &&
            (current < moment(selectedStartDate) ||
                current >
                moment(selectedStartDate)
                    .add(disabledDays, 'day') || current <= endDateDisabled)
        );
        // return current <= endDateDisabled && moment(current).add(5, 'day') >= endDateDisabled;;
    };
    const disabledStartDate = (current) => {
        return current <= startDateDisabled;
    };

    return (
        <>
            <StepTemplate
                handleNext={handleFormSubmit}
                handleBack={props.handleBack}
                step={props.step}
                isLoading={isFDetailCreateLoading}
            >
                <NextPrevButton
                    handleNext={handleFormSubmit}
                    handleBack={props.handleBack}
                    step={props.step}
                    isLoading={isFDetailCreateLoading}
                />
                <div className="content-container step3">
                    <p className="title mb2">Tell us about your fundraiser campaign</p>
                    <p className="sub-title">Your campaign start and end dates can run for a maximum of 90 days. However, we recommend using shorter times as listed below
                        <ul className="mt2">
                            <li>$1000 to $3000 packages to run for 4 weeks</li>
                            <li>$4000 to $5000 packages to run for 6 weeks</li>
                            <li>$10,000 packages to run for 8 weeks</li>
                        </ul>
                    </p>
                    <div className="mt10">
                        <Form
                            name="basic"
                            initialValues={{ remember: true }}
                            form={form}
                            layout="vertical"
                        >
                            <Form.Item
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter campaign title'
                                    },
                                    { max: 60, message: 'Maximum 60 characters are allowed' }
                                ]
                                }
                            >
                                <Input size="large" placeholder={"Campaign Title"} />
                            </Form.Item>
                            <Form.Item
                                name="description"
                                rules={[{ required: true, message: 'Please enter your campaign description' }]}
                            >
                                <TextArea rows={4} placeholder={"Campaign Description"} />
                            </Form.Item>

                            <Row gutter={30}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="">
                                    <Form.Item
                                        name="start_date"
                                        rules={[{ type: 'object', required: true, message: 'Please select start date' }]}
                                    >
                                        <DatePicker
                                            popupClassName="new-fund-datepicker"
                                            format={'YYYY-MM-DD'}
                                            disabledDate={(e) => disabledStartDate(e)}
                                            size={"large"}
                                            style={{ width: "100%" }}
                                            placeholder={"Campaign Start Date"}
                                            onChange={(e) => {
                                                setSelectedStartDate(e);
                                                form.setFieldsValue({ end_date: null });
                                                setDefaultEndDate(null);
                                                setEndDateDisabled(e.endOf('day'))
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="">
                                    <Form.Item
                                        name="end_date"
                                        rules={[{ type: 'object', required: true, message: 'Please select end date' }]}
                                    >
                                        <DatePicker
                                            popupClassName="new-fund-datepicker"
                                            format={'YYYY-MM-DD'}
                                            disabledDate={(e) => disabledEndDate(e)}
                                            size={"large"}
                                            style={{ width: "100%" }}
                                            placeholder={"Campaign End Date"}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={30} style={{ marginBottom: "24px" }}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="">
                                    <Form.Item
                                        extra="Number of estimated sellers"
                                        // name="Number of estimated sellers"
                                        name="no_of_sellers"
                                        rules={[
                                            { max: 10, message: 'Maximum 10 characters are allowed' }
                                        ]
                                        }
                                    >
                                        <Input size="large" placeholder={"Number of estimated sellers"} />
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Form.Item
                                className="alter-field"
                                name="updates_frequency"
                                label="How frequent would you like us to send the progress of the fundraiser?"
                                rules={[{ required: true, message: 'Please select frequency' }]}
                            >
                                <Radio.Group>
                                    <Radio value="daily">Daily</Radio>
                                    <Radio value="weekly">Weekly</Radio>
                                    <Radio value="monthly">Monthly</Radio>
                                </Radio.Group>
                            </Form.Item>

                            {/* {!isAutoApproved &&
                                <>
                                    <Form.Item label="To facilitate our vetting process, kindly submit any related supporting documents/comments.">
                                        <FileUpload uploadImageURL={uploadImageURL} setfileName={setFileName} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Comments (optional)"
                                        name="comment"
                                        rules={[{ max: 5000, message: 'Maximum 5000 characters only allowed!' }]}
                                    >
                                        <TextArea rows={3} placeholder={"Add the description"} />
                                    </Form.Item>
                                </>
                            }
                            <Row gutter={[0, 8]} >

                                {fundraiserData?.fundraiser_comments && fundraiserData?.fundraiser_comments.length > 0 &&
                                    <>
                                        <Col span={8} className="display-text"><p>Previous Comment</p></Col>
                                        <Col span={16} className="display-subtext">
                                            {fundraiserData.fundraiser_comments.map((item, index) =>
                                                <>
                                                    {item.comment !== null && item.comment !== "" &&
                                                        <p key={`comment_${index}`} className={`${fundraiserData.fundraiser_documents.length - 1 !== index ? 'mb0' : ''}`}>
                                                            {item.comment || "Not Comments"}
                                                        </p>
                                                    }
                                                </>
                                            )}
                                        </Col>
                                    </>
                                }
                                {fundraiserData?.fundraiser_documents && fundraiserData?.fundraiser_documents.length > 0 &&
                                    <>
                                        <Col span={8} className="display-text"><p>Previous Document</p></Col>
                                        <Col span={16} className="display-subtext">
                                            {fundraiserData.fundraiser_documents.map((item, index) =>
                                                <>
                                                    {item.document_title !== null &&
                                                        <p key={`document_${index}`} className={`${fundraiserData.fundraiser_documents.length - 1 !== index ? 'mb0' : ''}`}>
                                                            <span className="link" onClick={() => {
                                                                window.open(item.document_url, '_blank');
                                                            }}>{item.document_title}&nbsp;&nbsp;
                                                                {isImage(item.document_url) &&
                                                                    <EyeOutlined
                                                                        onClick={() => window.open(item.document_url, '_blank')}
                                                                        className={`pointer cursor`}
                                                                        style={{ fontSize: "16px" }}
                                                                    />
                                                                }
                                                                {!isImage(item.document_url) &&
                                                                    <DownloadOutlined
                                                                        onClick={() => window.open(item.document_url, '_blank')}
                                                                        className={`pointer cursor`}
                                                                        style={{ fontSize: "16px" }}
                                                                    />
                                                                }
                                                            </span>
                                                        </p>
                                                    }
                                                </>
                                            )}
                                        </Col>
                                    </>
                                }
                            </Row> */}

                        </Form>
                    </div>
                </div>
            </StepTemplate>
        </>
    );
};
export default Step3;
