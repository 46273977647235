import { getRequestWithBlob, getRequest, postRequest, putRequest, deleteRequest } from '../../utils/http';

//Perform the Get request
export function getRequestAPI({ url = '', params = {} }) {
  return getRequest(url, params);
}

//Perform the Get request
export function getRequestAPIWithBlob({ url = '', params = {} }) {
  return getRequestWithBlob(url, params);
}

export function postRequestAPI({ url = '', data = {} }) {
  return postRequest(url, data);
}

export function putRequestAPI({ url = '', data = {} }) {
  return putRequest(url, data);
}

export function deleteRequestAPI({ url = '', data = {} }) {
  return deleteRequest(url, data);
}