import React, { useState, useEffect } from "react";
import { Row, Col, Progress, Tabs, Tooltip } from 'antd';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FUNDRAISER_TABS } from "../../../utils/constant";
import { IoNotifications } from 'react-icons/io5';
import Detail from './detail';
import Billing from './billing';
import CourseOrder from './course/index';
import Ticket from './ticket';
import { useNavigate } from 'react-router-dom';
const Components = {
    1: Detail,
    2: Billing,
    3: CourseOrder,
    4: Ticket
}

const TabLayout = (props) => {
    const { fData, setCurrentActiveTab, fundProgress, isProgressLoading, doReferesh } = props;
    const [searchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState("1");
    const navigate = useNavigate();
    useEffect(() => {
        if (searchParams.get('tab') !== null) {
            setActiveTab(searchParams.get('tab'));
            setCurrentActiveTab(searchParams.get('tab'));
        }
    }, [searchParams]);

    const onTabChange = (e)=>{
        setActiveTab(e)
        setCurrentActiveTab(e);
        navigate(`/fundraiser-details/${props.fundId}?tab=${e}`, { replace: true });
    }

    return (
        <div className="tab-layout mt8">
            <Tabs activeKey={activeTab} onChange={(e) => {
                onTabChange(e)
                
            }}>
                {FUNDRAISER_TABS.map((item) => {
                    const LoadComponent = Components[item.id];
                    let tmpTitle = item.title;
                    if (item.key !== undefined && item.key === "course") {
                        if (!isProgressLoading && Object.keys(fundProgress).length > 0 && !fundProgress['course_order_picked']) {
                            tmpTitle =
                                <span>{item.title}&nbsp;
                                    <Tooltip
                                        title="course order and wind speed, direction need to be selected">
                                        <IoNotifications
                                            size={16}
                                            color="#1B35B3"
                                            style={{ marginTop: "2px", position: "absolute" }}
                                        />
                                    </Tooltip>
                                </span>
                        }
                    } else if (item.key !== undefined && item.key === "ticket") {
                        if (fData.tickets_total === 0) {
                            tmpTitle =
                                <span>{item.title}&nbsp;
                                    <Tooltip
                                        title="Assign tickets to the seller">
                                        <IoNotifications
                                            size={16}
                                            color="#1B35B3"
                                            style={{ marginTop: "2px", position: "absolute" }} />
                                    </Tooltip>
                                </span>
                        }
                    }
                    return (
                        <Tabs.TabPane tab={tmpTitle} key={`${item.id}`} className="mt4">
                            <LoadComponent fData={fData} doReferesh={doReferesh} activeTab={activeTab}/>
                        </Tabs.TabPane>
                    )
                })}
            </Tabs>
        </div >
    );
};
export default TabLayout;
