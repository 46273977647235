/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Table, Space, Tag, Tooltip, Spin, Card } from "antd";
import { displayStatusText, formatAmount, formatDate, getGameStatusColor } from '../../../../utils/functions';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { PrinterOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";

const { Column } = Table;

let filterParams = {};

const PaymentDetails = (props) => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const { isGetFundraiserLoading, fundraiserData } = useSelector(state => state.fundraiser);
    const { isGetPaymentsLoading, paymentsData } = useSelector(state => state.common);
    const [selectedTickets, setSelectedTickets] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [paidAmount, setPaidAmount] = useState([]);

    const getPaymentData = (params = {}) => {
        dispatch({ type: 'FUNDRAISER_PAYMENT_REQUEST', formData: { 'per_page': 5000 }, id: id });
    }

    useEffect(() => {
        getPaymentData()
    }, []);

    useEffect(() => {
        if (id) {
            dispatch({ type: 'GET_FUNDRAISER_DATA_REQUEST', formData: { id: id } });
        }

    }, []);

    useEffect(() => {
        if (!isGetPaymentsLoading && paymentsData.length > 0) {
            let tmpArr = [];
            let tmpAmt = 0;
            paymentsData.map((item) => {
                let last4 = item.first6last4.toString().slice(-4);
                let obj = { ...item };
                obj.status = "Paid";
                obj.display_status = "Paid";
                obj.description = <p className="mb0">{`${item.card_type} - Ending ${last4}`} <br></br>{`Transaction ID: ${item.transaction_no}`}</p>;
                obj.date = formatDate(item.created_at);
                obj.amount = item.amount_paid;
                tmpAmt = tmpAmt + item.amount_paid;
                tmpArr.push(obj);
            })
            setPaidAmount(tmpAmt);
            setTableData(tmpArr);
        } else if (!isGetPaymentsLoading && paymentsData.length === 0) {
            setTableData([]);
        }
    }, [isGetPaymentsLoading, paymentsData])



    const onselectCheckbox = (checkedData) => {
        let tmpSelectedTicket = [...selectedTickets];
        if (!tmpSelectedTicket.includes(checkedData)) {
            tmpSelectedTicket.push(checkedData);
        } else {
            tmpSelectedTicket = tmpSelectedTicket.filter((item) => {
                return item !== checkedData
            });
        }
        setSelectedTickets(tmpSelectedTicket);
    }

    return (
        <div>
            <div className="sub-header">
                <p className="mb0 title">
                    Transactions &nbsp;{!isGetFundraiserLoading ? "| " + fundraiserData?.title : <Spin></Spin>}
                </p>

            </div>
            <div className="mt15 mr0 cards-box analytics text-center" >
                <Row gutter={30}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Card className="game-detail-analytics-card" >
                            <div className="parent">
                                <div className="mb0">
                                    <p className="cmp-title  b500 fs16 mb2">Amount Paid</p>
                                    <p className="cmp-title  b600 fs22 mb0">{isGetFundraiserLoading && <div className="no-data"><Spin></Spin></div>} {!isGetFundraiserLoading && formatAmount(paidAmount)}</p>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Card className="game-detail-analytics-card" >
                            <div className="parent">
                                <div className="mb0">
                                    <p className="cmp-title  b500 fs16 mb2">Current Amount Due</p>
                                    <p className="cmp-title  b600 fs22 mb0">
                                        {isGetFundraiserLoading && <div className="no-data"><Spin></Spin></div>}
                                        {!isGetFundraiserLoading && Object.keys(fundraiserData).length > 0 &&
                                            fundraiserData.pending_fees < 0 ? '$0.00' : formatAmount(fundraiserData.pending_fees)
                                        }
                                    </p>
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>

            <Row className="mt8">
                <Table
                    style={{ width: "100%" }}
                    dataSource={tableData}
                    loading={isGetPaymentsLoading}
                    pagination={false}
                    scroll={{ x: "max-content" }}
                >

                    <Column
                        title="Date"
                        dataIndex="date"
                        key="date"
                        width={"200px"}
                        render={(text, record) => formatDate(text)}

                    />

                    <Column
                        title="Description"
                        dataIndex="description"
                        key="description"
                        width={"250px"}
                    />

                    <Column
                        title="Status"
                        dataIndex="display_status"
                        key="display_status"
                        width={"100px"}
                        render={(_, { display_status, status }) => {
                            let color = getGameStatusColor(status)
                            return (
                                <Space size="middle">
                                    <Tag color={'green'} key={status}>
                                        {display_status}
                                    </Tag>
                                </Space>)
                        }}
                    />
                    <Column
                        title="Amount"
                        dataIndex="amount"
                        key="amount"
                        width={"150px"}
                        render={(_, record) => {
                            let amt = formatAmount(record.amount)
                            return (
                                <>
                                    {amt}
                                </>
                            )
                        }}
                    />
                </Table>
            </Row>
        </div>
    );
};
export default PaymentDetails;