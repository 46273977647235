const initialState = {
    isFCampgaignLoading: false,
    fundraiserCampgaignList: [],

    isFTypeLoading: false,
    fundraiserTypeList: [],

    isFTypeCreateLoading: false,
    statusMsg: '',

    isFPackageLoading: false,
    fundraiserPackageList: [],

    isFCouponLoading: false,
    fundraiserCouponList: [],

    isGetFundraiserLoading: false,
    fundraiserData: {},

    isFPackageCreateLoading: false,
    isFDetailCreateLoading: false,
    isFOrganizationUpdateLoading: false,

    isFOrganizationLoading: false,
    fundraiserOrganizationList: [],
    isStep6Loading: false,

    updateMsg: "",
    fundraiserId: "",

    isOrgDetailLoading: false,
    orgDetail: {},

    error: ""
};

const reducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case "FUNDRAISER_CAPMAIGN_LIST_REQUEST":
            state = {
                ...state,
                isFCampgaignLoading: true,
                fundraiserCampgaignList: [],
            };
            break;

        case "FUNDRAISER_CAPMAIGN_LIST_SUCCESS":
            state = {
                ...state,
                isFCampgaignLoading: false,
                fundraiserCampgaignList: action.data
            };
            break;

        case "FUNDRAISER_CAPMAIGN_LIST_ERROR":
            state = {
                ...state,
                isFCampgaignLoading: false,
                fundraiserCampgaignList: [],
                error: action.error
            };
            break;

        case "FUNDRAISER_TYPE_LIST_REQUEST":
            state = {
                ...state,
                isFTypeLoading: true,
                fundraiserTypeList: [],
            };
            break;

        case "FUNDRAISER_TYPE_LIST_SUCCESS":
            state = {
                ...state,
                isFTypeLoading: false,
                fundraiserTypeList: action.data
            };
            break;

        case "FUNDRAISER_TYPE_LIST_ERROR":
            state = {
                ...state,
                isFTypeLoading: false,
                fundraiserTypeList: [],
                error: action.error
            };
            break;

        case "GET_FUNDRAISER_DATA_REQUEST":
            state = {
                ...state,
                isGetFundraiserLoading: true,
                fundraiserData: {},
            };
            break;

        case "GET_FUNDRAISER_DATA_SUCCESS":
            state = {
                ...state,
                isGetFundraiserLoading: false,
                fundraiserData: action.data
            };
            break;

        case "GET_FUNDRAISER_DATA_ERROR":
            state = {
                ...state,
                isGetFundraiserLoading: false,
                fundraiserData: {},
                error: action.error
            };
            break;

        case "CREATE_FUNDRAISER_TYPE_REQUEST":
            state = {
                ...state,
                isFTypeCreateLoading: true,
                statusMsg: [],
                fundraiserId: ""
            };
            break;

        case "CREATE_FUNDRAISER_TYPE_SUCCESS":
            state = {
                ...state,
                isFTypeCreateLoading: false,
                statusMsg: 'success',
                fundraiserId: action.data.id
            };
            break;

        case "CREATE_FUNDRAISER_TYPE_ERROR":
            state = {
                ...state,
                isFTypeCreateLoading: false,
                statusMsg: ''
            };
            break;

        case "FUNDRAISER_PACKAGE_LIST_REQUEST":
            state = {
                ...state,
                isFPackageLoading: true,
                fundraiserPackageList: [],
            };
            break;

        case "FUNDRAISER_PACKAGE_LIST_SUCCESS":
            state = {
                ...state,
                isFPackageLoading: false,
                fundraiserPackageList: action.data
            };
            break;

        case "FUNDRAISER_PACKAGE_LIST_ERROR":
            state = {
                ...state,
                isFPackageLoading: false,
                fundraiserPackageList: [],
                error: action.error
            };
            break;

        case "FUNDRAISER_COUPON_LIST_REQUEST":
            state = {
                ...state,
                isFCouponLoading: true,
                fundraiserCouponList: []
            };
            break;

        case "FUNDRAISER_COUPON_LIST_SUCCESS":
            state = {
                ...state,
                isFCouponLoading: false,
                fundraiserCouponList: action.data
            };
            break;

        case "FUNDRAISER_COUPON_LIST_ERROR":
            state = {
                ...state,
                isFCouponLoading: false,
                fundraiserCouponList: [],
                error: action.error
            };
            break;

        case "FUNDRAISER_COUPON_FILTER_LIST_REQUEST":
            state = {
                ...state,
                fundraiserCouponList: state.fundraiserCouponList.filter(coupon =>
                    coupon.code.toLowerCase().includes(action.formData.toLowerCase())
                )
            };
            break;

        case "CREATE_FUNDRAISER_PACKAGE_REQUEST":
            state = {
                ...state,
                isFPackageCreateLoading: true,
                statusMsg: [],
            };
            break;

        case "CREATE_FUNDRAISER_PACKAGE_SUCCESS":
            state = {
                ...state,
                isFPackageCreateLoading: false,
                statusMsg: 'success'
            };
            break;

        case "CREATE_FUNDRAISER_PACKAGE_ERROR":
            state = {
                ...state,
                isFPackageCreateLoading: false,
                statusMsg: ''
            };
            break;

        case "CREATE_FUNDRAISER_DETAIL_REQUEST":
            state = {
                ...state,
                isFDetailCreateLoading: true,
                statusMsg: [],
            };
            break;

        case "CREATE_FUNDRAISER_DETAIL_SUCCESS":
            state = {
                ...state,
                isFDetailCreateLoading: false,
                statusMsg: 'success'
            };
            break;

        case "CREATE_FUNDRAISER_DETAIL_ERROR":
            state = {
                ...state,
                isFDetailCreateLoading: false,
                statusMsg: ''
            };
            break;

        case "FUNDRAISER_ORGANIZATION_LIST_REQUEST":
            state = {
                ...state,
                isFOrganizationLoading: true,
                fundraiserOrganizationList: [],
            };
            break;

        case "FUNDRAISER_ORGANIZATION_LIST_SUCCESS":
            state = {
                ...state,
                isFOrganizationLoading: false,
                fundraiserOrganizationList: action.data
            };
            break;

        case "FUNDRAISER_ORGANIZATION_LIST_ERROR":
            state = {
                ...state,
                isFOrganizationLoading: false,
                fundraiserOrganizationList: [],
                error: action.error
            };
            break;

        case "UPDATE_FUNDRAISER_ORGANIZATION_REQUEST":
            state = {
                ...state,
                isFOrganizationUpdateLoading: true,
                statusMsg: ''
            };
            break;

        case "UPDATE_FUNDRAISER_ORGANIZATION_SUCCESS":
            state = {
                ...state,
                isFOrganizationUpdateLoading: false,
                statusMsg: 'success'
            };
            break;

        case "UPDATE_FUNDRAISER_ORGANIZATION_ERROR":
            state = {
                ...state,
                isFOrganizationUpdateLoading: false,
                statusMsg: '',
                error: action.error
            };
            break;

        case "UPDATE_ORGANIZATION_REQUEST":
            state = {
                ...state,
                isOrganizationCreateLoading: true,
                updateMsg: ''
            };
            break;

        case "UPDATE_ORGANIZATION_SUCCESS":
            state = {
                ...state,
                isOrganizationCreateLoading: false,
                updateMsg: 'success'
            };
            break;

        case "UPDATE_ORGANIZATION_ERROR":
            state = {
                ...state,
                isOrganizationCreateLoading: false,
                updateMsg: '',
                error: action.error
            };
            break;

        case "GET_ORGANIZATION_DETAIL_REQUEST":
            state = {
                ...state,
                isOrgDetailLoading: true,
                orgDetail: ''
            };
            break;

        case "GET_ORGANIZATION_DETAIL_SUCCESS":
            state = {
                ...state,
                isOrgDetailLoading: false,
                orgDetail: action.data
            };
            break;

        case "GET_ORGANIZATION_DETAIL_ERROR":
            state = {
                ...state,
                isOrgDetailLoading: false,
                orgDetail: '',
                error: action.error
            };
            break;

        case "STEP_6_CREATE_REQUEST":
            state = {
                ...state,
                isStep6Loading: true,
                updateMsg: ''
            };
            break;

        case "STEP_6_CREATE_SUCCESS":
            state = {
                ...state,
                isStep6Loading: false,
                updateMsg: 'success'
            };
            break;

        case "STEP_6_CREATE_ERROR":
            state = {
                ...state,
                isStep6Loading: false,
                updateMsg: '',
                error: action.error
            };
            break;


        case "CLEAR_FUNDRAISER_STATUS":
            state = {
                ...state,
                isFTypeCreateLoading: false,
                statusMsg: '',
                updateMsg: ""
            };
            break;
        case "FUNDRAISER_DATA_CLEAR":
            state = {
                ...state,
                isFTypeCreateLoading: false,
                statusMsg: '',
                fundraiserData: {},
            };
            break;


        default:

    }
    return state;
};

export default reducer;