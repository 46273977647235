import React from "react";
import { Modal, Button, Avatar, Row, Col } from 'antd';
import { showAvtarOrgName } from '../../../utils/functions';

const OrganizationModalValidate = ({ showModal, onCancel, rowData }) => {
    console.log("rowData");
    console.log(rowData);
    return (
        <Modal
            className="success-modal-parent org"
            footer={false}
            visible={showModal}
            onCancel={() => onCancel(false)}
            width={600}
            maskClosable={false}
            title={<span className="center">Organization Info</span>}
        >
            <div className="success-modal-child">
                <div className="box1" >
                    {(rowData?.image === null || rowData?.image === "") &&
                        <Avatar size="large" width={200} className='pointer' >{showAvtarOrgName(rowData?.name || "")}</Avatar>
                    }
                    {(rowData?.image !== null && rowData?.image !== "") &&
                        <img src={rowData?.image} width={200} className="icon" alt="icon" />
                    }
                </div>
                <div className="mt5">
                    <Row className='section1'>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="org-view">
                            {rowData.name && <p className='title'><b>Title:&nbsp;&nbsp;</b>{rowData.name}</p>}
                            {rowData?.email && <p className='mt2'><b>Email:</b>&nbsp;&nbsp;{rowData?.email}</p>}
                            {rowData?.address && <p className='mt5'><b>Address:</b>&nbsp;&nbsp;{rowData?.address}</p>}
                        </Col>
                    </Row>
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                    <Button
                        type="primary"
                        size="large"
                        className="br20 pr8 pl8 center"
                        htmlType="submit"
                        onClick={() => onCancel(false)}
                    >
                        Close
                    </Button>
                </div>

            </div>
        </Modal>
    );
};
export default OrganizationModalValidate;
