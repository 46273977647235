import React, { useEffect, useState } from "react";
import { Row, Col, Input, Table, Space, Tooltip } from "antd";
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment'
import { FUNDRAISER_E_TRANSFER_STATUS } from "../../../utils/constant";
import Tabs from "../../common/tabs";
import { Spin } from "antd";
import { Modal } from "antd";
import { getETransferStatusColor } from "../../../utils/functions";
import { Tag } from "antd";

const { Search } = Input;
const { Column } = Table;

let filterParams = { status: "", comment: "" };
const ETransfer = () => {

    const dispatch = useDispatch();
    const { eTransferList, isGetETransferLoading, isETransferConfirmLoading, isETransferRejectLoading } = useSelector(state => state.admin);
    const [activeTab, setActiveTab] = useState('confirmed');
    const [tableData, setTableData] = useState([]);
    const [showETransferConfirmationModal, setShowETransferConfirmationModal] = useState(false);
    const [showETransferRejectionModal, setShowETransferRejectionModal] = useState(false);

    useEffect(() => {
        getAllETransfers();
        return () => {
            filterParams.comment = ''
        }
    }, []);

    const getAllETransfers = (params = { status: 'confirmed' }) => {
        let tmpParams = { ...params };
        tmpParams.per_page = 5000;
        dispatch({ type: 'GET_ALL_E_TRANSFER_REQUEST', formData: tmpParams })
    }

    useEffect(() => {
        if (!isGetETransferLoading && eTransferList?.length) {
            let tmpETransferList = [];
            eTransferList?.map((item) => {
                let tmpObj = item
                tmpETransferList.push(tmpObj);
                return 1;
            })
            setTableData(tmpETransferList);
        } else {
            setTableData([])
        }
    }, [isGetETransferLoading, eTransferList, isETransferConfirmLoading, isETransferRejectLoading]);

    const onChange = (e, type) => {

        filterParams.page = 1;
        if (type === "comment") {
            filterParams[type] = e;
        } else if (type === "status") {
            setActiveTab(e);
            filterParams["status"] = e;
        }
        getAllETransfers(filterParams)

    }

    const handleETransferConfirm = (record) => {
        const eTransferId = record.id
        dispatch({ type: 'CONFIRM_E_TRANSFER_REQUEST', eTransferId })
        filterParams.status = 'pending'
        setShowETransferConfirmationModal(false)
        setTimeout(() => {
            getAllETransfers(filterParams);
        }, [1000])
    }

    const handleETransferReject = (record) => {
        const eTransferId = record.id
        dispatch({ type: 'REJECT_E_TRANSFER_REQUEST', eTransferId })
        filterParams.status = 'pending'
        setShowETransferRejectionModal(false)
        setTimeout(() => {
            getAllETransfers(filterParams);
        }, [100])
    }

    return (
        <div>
            <div className="sub-header">
                <p className="mb0 title">
                    E-Transfer
                </p>
            </div>
            <div className="filter mt8">
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Search
                            defaultValue={filterParams['comment']}
                            placeholder="Search"
                            style={{ width: "60%" }}
                            enterButton
                            allowClear
                            onSearch={(e) => onChange(e, 'comment')}
                        />
                    </Col>
                </Row>
            </div>
            <Row className='campaign-table mt10'>
                <Tabs
                    onChange={(e) => onChange(e, 'status')}
                    isShowCount={true}
                    item={FUNDRAISER_E_TRANSFER_STATUS}
                    active={activeTab}
                    pageStatusCount={[]}
                />
            </Row>
            <Row className="mt5">
                <Table
                    style={{ width: "100%" }}
                    dataSource={tableData}
                    loading={isGetETransferLoading}
                    scroll={{ x: "max-content" }}
                    pagination={{
                        pageSize: 20,
                        showSizeChanger: false
                    }}
                >
                    <Column
                        title="Fundraiser"
                        dataIndex="comment"
                        key="comment"
                        width={"100px"}
                    />
                    <Column
                        title="Amount"
                        dataIndex="amount"
                        key="amount"
                        width={"20px"}
                        render={(val, rec) => {
                            return (
                                <div>{`${val} ${rec.currency_code}`}</div>
                            )
                        }}
                    />
                    <Column
                        title="Net Amount"
                        dataIndex="net_amount"
                        key="net_amount"
                        width={"20px"}
                        render={(val, rec) => {
                            return (
                                <div>{`${val} ${rec.currency_code}`}</div>
                            )
                        }}
                    />
                    <Column
                        title="Fee"
                        dataIndex="fee"
                        key="fee"
                        width={"20px"}
                        render={(val, rec) => {
                            return (
                                <div>{`${val} ${rec.currency_code}`}</div>
                            )
                        }}
                    />

                    <Column
                        title="Created Date"
                        dataIndex="created_at"
                        key="created_at"
                        width={"150px"}
                        render={(val, rec, index) => {
                            return (
                                <div>{moment(val).format('DD MMM, YYYY')}</div>
                            )
                        }}
                    // sorter={(a, b) => a.rank - b.rank}
                    />
                    {activeTab === 'pending' ? (
                        <Column
                            title={"Actions"}
                            key='actions'
                            width={"70px"}
                            render={(_, record) => {
                                return (
                                    <Space size="middle" className={`cursor not-disabled`}>
                                        {isGetETransferLoading ? (
                                            <Spin />
                                        ) : (
                                            <>
                                                <Tooltip placement="top" title={"Approve"} >
                                                    <CheckCircleFilled
                                                        className={record?.status !== 'pending' ? `cursor disabled` : `cursor not-disabled`}
                                                        style={{ fontSize: "20px", color: record?.status !== "pending" && "#cacaca" }}
                                                        onClick={() => setShowETransferConfirmationModal(true)}
                                                    />
                                                </Tooltip>
                                                <Tooltip placement="top" title={"Reject"} >
                                                    <CloseCircleFilled
                                                        className={record?.status !== 'pending' ? `cursor disabled` : `cursor not-disabled`}
                                                        style={{ fontSize: "20px", color: record?.status !== "pending" ? "#ff8080" : '#ff2222' }}
                                                        onClick={() => setShowETransferRejectionModal(true)}
                                                    />
                                                </Tooltip>
                                            </>
                                        )}
                                        <ETransferConfirmation visible={showETransferConfirmationModal} close={() => setShowETransferConfirmationModal(false)} onSuccess={() => handleETransferConfirm(record)} />
                                        <ETransferRejection visible={showETransferRejectionModal} close={() => setShowETransferRejectionModal(false)} onReject={() => handleETransferReject(record)} />
                                    </Space>
                                )
                            }}
                        />
                    ) : (
                        <Column
                            title={"Status"}
                            key='actions'
                            width={"70px"}
                            render={(_, record) => {
                                let color = getETransferStatusColor(record.status)
                                return (
                                    <Space size="middle">
                                        <Tag color={color} key={record.status}>
                                            {record?.status === 'confirmed' ? "Approved" : "Rejected"}
                                        </Tag>
                                    </Space>)
                            }}
                        />
                    )}
                </Table>
            </Row>
        </div>
    );
};
export default ETransfer;

const ETransferConfirmation = ({ visible, close, onSuccess }) => {
    return (
        <Modal title='E-Transfer Confirmation' className={`prize-modal step4-modal org-modal`} open={visible} onOk={() => onSuccess()} onCancel={() => close()} cancelText='No' okText='Yes' centered>
            Are you sure you want to approve this e-transfer?
        </Modal>
    )
}

const ETransferRejection = ({ visible, close, onReject }) => {
    return (
        <Modal title='E-Transfer Rejection' className={`prize-modal step4-modal org-modal`} open={visible} onOk={() => onReject()} onCancel={() => close()} cancelText='No' okText='Yes' centered>
            Are you sure you want to reject this e-transfer?
        </Modal>

    )
}