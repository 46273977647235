/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input, Select, Row, Col, Spin, Tag, InputNumber } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formatAmount, formatDate, formatDateTime } from '../../../utils/functions';

const { Option } = Select;
const { TextArea } = Input;

const FundraisingPackageModal = (props) => {
    const { visible, setVisible, modalType, rowData } = props;
    const { isDeleteLoading } = useSelector(state => state.common);
    const { isFundPackageLoading, fundPackageDetail, isFundPackageUpdating } = useSelector(state => state.admin);

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [pageFundraisingDetail, setPageFundraisingDetail] = useState({});

    useEffect(() => {
        if (modalType === "view") {
            if (!isFundPackageLoading && Object.keys(fundPackageDetail).length > 0) {
                setPageFundraisingDetail(fundPackageDetail);
            }
        }
    }, [isFundPackageLoading, fundPackageDetail])

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                console.log(values);
                let tmpValues = {};
                for (const property in values) {
                    if (values[property] !== undefined) {
                        tmpValues[property] = values[property];
                    }
                }
                if (modalType === "edit") {
                    dispatch({ type: 'UPDATE_FUNDRAISING_PACKAGE_REQUEST', formData: tmpValues, 'requestType': 'put', id: rowData.id });
                } else if (modalType === "delete") {
                    dispatch({ type: 'DELETE_REQUEST', id: rowData.id, deleteType: 'fundraisingPackage' });
                } else {
                    tmpValues.status = 'active';
                    dispatch({ type: 'UPDATE_FUNDRAISING_PACKAGE_REQUEST', formData: tmpValues, 'requestType': 'post' });
                }
                setTimeout(() => {
                    form.resetFields();
                }, 2000);
            })
            .catch((errorInfo) => {
                console.log(errorInfo)
            });
    }

    useEffect(() => {
        if (rowData !== "" && Object.keys(rowData).length > 0) {
            console.log(rowData);
            form.setFieldsValue({
                name: rowData?.name || "",
                amount_to_be_generated: rowData?.amount_to_be_generated,
                no_tickets_allowed: rowData?.no_tickets_allowed,
                no_sellers_allowed: rowData?.no_sellers_allowed,
                regular_fees: rowData?.regular_fees,
                no_bonus_tickets: rowData?.no_bonus_tickets,
                reduced_fees: rowData?.reduced_fees,
                duration: rowData?.duration,
                // description: rowData?.description,
                partial_payment_description: rowData?.partial_payment_description,
                full_payment_description: rowData?.full_payment_description,

            });
        }
    }, [rowData])

    return (
        <Modal
            className={`prize-modal step4-modal org-modal ${modalType === "view" ? "view-modal" : ""}`}
            title={`${modalType === "view" ? "" : modalType === "add" ? "Create New Fundraising Package" : modalType === "edit" ? "Edit Fundraising Package" : modalType === "delete" ? "Are you sure?" : ""}`}
            open={visible}
            onOk={() => handleSubmit()}
            onCancel={() => {
                dispatch({ type: "CLEAR_COMMON" });
                setVisible(false);
                form.resetFields();
            }}
            centered
            width={800}
            okText={`${modalType === "delete" ? "Delete" : modalType === "edit" ? 'Update' : "Save"}`}
            okButtonProps={{ disabled: isFundPackageUpdating || isDeleteLoading, loading: isFundPackageUpdating || isDeleteLoading }}
            cancelText="Cancel"
            maskClosable={modalType === "view" ? true : false}

        >
            {(modalType === "edit" || modalType === "add") &&
                <div className="mt4">
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        form={form}
                        layout="vertical"
                    >
                        <Form.Item
                            name="name"
                            label={"Package name"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter package name'
                                },
                                {
                                    max: 100,
                                    message: 'Maximum 100 characters only allowed!',
                                }]
                            }
                        >
                            <Input size="large" placeholder={"Package name"} />
                        </Form.Item>

                        {/* <Form.Item
                            label="Description"
                            name="description"
                            rules={[{ max: 5000, message: 'Maximum 5000 characters only allowed!' }]}
                        >
                            <TextArea rows={2} placeholder={"Description"} />
                        </Form.Item> */}
                        <Row gutter={[30]}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item
                                    name="no_tickets_allowed"
                                    label={"Number of tickets"}
                                    rules={[{ required: true, message: 'Please enter number of tickets' }]
                                    }
                                >
                                    <Input size="large" type='number' placeholder={"Number of tickets"} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item
                                    name="no_sellers_allowed"
                                    label={"Number of sellers"}
                                    rules={[{ required: true, message: 'Please enter number of sellers' }]
                                    }
                                >
                                    <Input size="large" placeholder={"Number of sellers (ex. 1-20)"} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[30]}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item
                                    name="regular_fees"
                                    label={"Full Payment"}
                                    rules={[{ required: true, message: 'Please enter amount' }]
                                    }
                                >
                                    <Input prefix={"$"} size="large" type='number' placeholder={"Amount"} />
                                </Form.Item>
                                <Form.Item
                                    name="no_bonus_tickets"
                                    label={"No of Bonus Ticket"}
                                    rules={[
                                        { pattern: new RegExp(/^[0-9]{0,5}$/), message: "Please enter valid number" },
                                        { required: false, message: 'Please enter no of bonus ticket' }]
                                    }
                                >
                                    <InputNumber st min={1} size="large" type='number' placeholder={"Amount"} />
                                </Form.Item>

                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item
                                    label="Full Payment Description"
                                    name="full_payment_description"
                                    rules={[{ max: 5000, message: 'Maximum 5000 characters only allowed!' }]}
                                >
                                    <TextArea rows={3} placeholder={"Full Payment Description"} />
                                </Form.Item>

                            </Col>
                        </Row>
                        <Row gutter={[30]}>

                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item
                                    name="reduced_fees"
                                    label={"Reduced fees - Initial payment"}
                                    rules={[{ required: true, message: 'Please enter reduced fees' }]
                                    }
                                >
                                    <Input prefix={"$"} size="large" type='number' placeholder={"Reduced Fees"} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item
                                    label="Partial Payment Description"
                                    name="partial_payment_description"
                                    rules={[{ max: 5000, message: 'Maximum 5000 characters only allowed!' }]}
                                >
                                    <TextArea rows={3} placeholder={"Partial Payment Description"} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item
                            name="duration"
                            label={"Duration (number of days)"}
                            rules={[{ required: true, message: 'Please enter duration' }]
                            }
                        >
                            <Input size="large" type='number' placeholder={"Duration"} />
                        </Form.Item>
                        <Form.Item
                            name="amount_to_be_generated"
                            label={"Amount to be generated"}
                            rules={[{ required: true, message: 'Please enter amount to be generated' }]
                            }
                        >
                            <Input size="large" type='number' placeholder={"Amount to be generated"} />
                        </Form.Item>
                    </Form>
                </div>
            }
            {modalType === "view" &&
                <div className="mt4">
                    {isFundPackageLoading && <div className='no-data'><Spin /></div>}
                    {!isFundPackageLoading &&
                        <>
                            <Row className='section1'>
                                <Col xs={24} sm={24} md={20} lg={20} xl={20} className="org-view">
                                    <p className='title'>{pageFundraisingDetail.name}</p>
                                    <p className='mb2'><b>Full payment description:</b>&nbsp;&nbsp;{pageFundraisingDetail?.full_payment_description}</p>
                                    <p className='mb2'><b>Partial payment description:</b>&nbsp;&nbsp;{pageFundraisingDetail?.partial_payment_description}</p>
                                    <p className='mb0'><b>Amount:</b>&nbsp;&nbsp;{formatAmount(pageFundraisingDetail?.regular_fees || "")} </p>
                                    <p className='mb0'><b>Reduce Amount:</b>&nbsp;&nbsp;{formatAmount(pageFundraisingDetail?.reduced_fees || "")} </p>
                                    <p className='mb02'><b>Amount to be generated:</b>&nbsp;&nbsp;{formatAmount(pageFundraisingDetail?.amount_to_be_generated || 0)} </p>
                                    <p className='mb0'><b>Number of tickets:</b>&nbsp;&nbsp;{pageFundraisingDetail?.no_tickets_allowed} </p>
                                    <p className='mb0'><b>Number of bonus tickets:</b>&nbsp;&nbsp;{pageFundraisingDetail?.no_bonus_tickets} </p>
                                    <p className='mb0'><b>Number of sellers:</b>&nbsp;&nbsp;{pageFundraisingDetail?.no_sellers_allowed} </p>
                                    <p className='mb2'><b>Duration:</b>&nbsp;&nbsp;{pageFundraisingDetail?.duration} </p>
                                    <p className='mb0'><b>Created at:</b>&nbsp;&nbsp;{formatDateTime(pageFundraisingDetail?.created_at)} </p>
                                    <p className='mb5'><b>Updated at:</b>&nbsp;&nbsp;{formatDateTime(pageFundraisingDetail?.updated_at)} </p>
                                </Col>
                                <Col xs={24} sm={24} md={4} lg={4} xl={4} className="org-view">
                                    <p className='mb2 mt2'>
                                        <Tag color={pageFundraisingDetail.status === "active" ? 'green' : 'default'}>
                                            {pageFundraisingDetail.status === "active" ? "Active" : "Archived"}
                                        </Tag>
                                    </p>
                                </Col>
                            </Row>
                            <Row className=''>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="org-view">
                                    <p className='fs18 bold mt5'>Fundraisers</p>
                                    {pageFundraisingDetail.fundraisers !== undefined && pageFundraisingDetail.fundraisers !== null && pageFundraisingDetail.fundraisers.length > 0 && pageFundraisingDetail.fundraisers.map((fData) => {
                                        return (
                                            <div className=''>
                                                <p className='mb0 title fs16'>{fData?.title}</p>
                                                <p className="mb4 date-style">{
                                                    fData.start_date !== null ? `${formatDate(fData.start_date)} • ${formatDate(fData.end_date)}` : ''}
                                                </p>
                                            </div>
                                        )
                                    })}
                                    {pageFundraisingDetail.fundraisers !== undefined && pageFundraisingDetail.fundraisers !== null && pageFundraisingDetail.fundraisers.length === 0 &&
                                        <p>Not used in any fundraiser</p>
                                    }
                                </Col>
                            </Row>
                        </>
                    }
                </div>
            }
            {modalType === "delete" &&
                <div className="mt2">
                    <p>You want to delete this fundraising package</p>
                </div>
            }


        </Modal>
    );
};

export default FundraisingPackageModal;