/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input, Row, Col, Spin, Tabs, Card, Space } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formatDateTime } from '../../../utils/functions';
import LoadGames from '../../admin/manageUsers/loadGames';


const PlayerModal = (props) => {
    const { visible, setVisible, modalType, rowData, userType } = props;
    const { isDeleteLoading } = useSelector(state => state.common);

    const { isCommonUpdateLoading } = useSelector(state => state.admin);
    const { isPlayerDetailLoading, playerDetail } = useSelector(state => state.player);

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [pageplayerDetail, setpageplayerDetail] = useState({});
    const [activeTab, setActiveTab] = useState('Details');


    useEffect(() => {
        if (modalType === "view") {
            if (!isPlayerDetailLoading && Object.keys(playerDetail).length > 0) {
                console.log(playerDetail);
                setpageplayerDetail(playerDetail);
            }
        }
        setpageplayerDetail(rowData);
    }, [rowData, isPlayerDetailLoading, playerDetail])

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                console.log(values)
                let tmpValues = {};
                for (const property in values) {
                    if (values[property] !== undefined) {
                        tmpValues[property] = values[property];
                    }
                }
                if (modalType === "edit") {
                    dispatch({ type: 'UPDATE_COMMON_REQUEST', formData: tmpValues, 'requestType': 'put', id: rowData.id, updateFor: 'userType' });
                } else if (modalType === "delete") {
                    dispatch({ type: 'DELETE_REQUEST', id: rowData.id, deleteType: 'users' });
                } else {
                    dispatch({ type: 'UPDATE_COMMON_REQUEST', formData: tmpValues, 'requestType': 'post', updateFor: 'userType' });
                }
                setTimeout(() => {
                    form.resetFields();
                }, 1000);
            })
            .catch((errorInfo) => {
                console.log(errorInfo)
            });
    }

    useEffect(() => {
        dispatch({ type: "CLEAR_COMMON" });
    }, []);

    useEffect(() => {
        if (rowData !== "" && Object.keys(rowData).length > 0) {
            form.setFieldsValue({
                first_name: rowData?.first_name || "",
                last_name: rowData?.last_name || "",
                email: rowData?.email || "",
                phone: rowData?.phone || "",
                city_id: rowData?.city || "",
                province_id: rowData?.province?.id || "",
                role_id: rowData?.role?.id || "",
            });
            //TODO : remove
            setpageplayerDetail(rowData);
        }
    }, [rowData]);

    const renderTabs = () => {
        return (
            <>
                <p
                    className={`mb0 ${'Details' === activeTab ? 'active' : ''}`}
                    onClick={() => setActiveTab('Details')}
                >
                    <span>Details</span>
                </p>
                <p
                    className={`mb0 ${'Games' === activeTab ? 'active' : ''}`}
                    onClick={() => setActiveTab('Games')}
                >
                    <span>Games</span>
                </p>
            </>
        )
    }

    return (
        <Modal
            className={`prize-modal user-modal org-modal ${modalType === "view" ? "view-modal" : ""}`}
            title={`${modalType === "view" ? "" : modalType === "add" ? "Create New User" : modalType === "edit" ? "Edit User" : modalType === "delete" ? "Are you sure?" : ""}`}
            open={visible}
            onOk={() => handleSubmit()}
            onCancel={() => {
                dispatch({ type: "CLEAR_COMMON" });
                setVisible(false);
                form.resetFields();
            }}

            width={modalType === "view" ? 1200 : 650}
            okText={`${modalType === "delete" ? "Delete" : modalType === "edit" ? 'Update' : "Save"}`}
            okButtonProps={{ disabled: isCommonUpdateLoading || isDeleteLoading, loading: isCommonUpdateLoading || isDeleteLoading }}
            cancelText="Cancel"
            maskClosable={modalType === "view" ? true : false}

        >
            {(modalType === "edit" || modalType === "add") &&
                <div className="mt4">
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        form={form}
                        layout="vertical"
                    >
                        <Row gutter={[30]}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item
                                    name="first_name"
                                    label={"First Name"}
                                    rules={[
                                        { required: true, message: 'Please enter first name' },
                                        { max: 100, message: 'Maximum 100 characters only allowed!' }]
                                    }
                                >
                                    <Input size="large" placeholder={"First name"} />
                                </Form.Item>

                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item
                                    name="last_name"
                                    label={"Last Name"}
                                    rules={[
                                        { required: true, message: 'Please enter last name' },
                                        { max: 100, message: 'Maximum 100 characters only allowed!' }
                                    ]}
                                >
                                    <Input size="large" placeholder={"Last name"} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item
                            name="email"
                            label={"Email"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter email address'
                                },
                                {
                                    type: 'email',
                                    message: 'Please enter valid email address',
                                },
                            ]}
                        >
                            <Input size="large" placeholder={"Email Address"} autoComplete="off" />
                        </Form.Item>
                    </Form>
                </div>
            }
            {modalType === "view" &&
                <div className="mt4">
                    {isPlayerDetailLoading && <div className='no-data'><Spin /></div>}
                    {!isPlayerDetailLoading &&
                        <>
                            <Row className='section1' style={{ borderBottom: 'none' }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="org-view">
                                    <p className='title'>{pageplayerDetail?.first_name || ""} {pageplayerDetail?.last_name || ""}</p>
                                    {userType === "fundraisingUser" &&
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="count-box">
                                            <Space align="center">
                                                <Card className="card-child ticket" >
                                                    <div className="parent">
                                                        <div className="mb0">
                                                            <p className="cmp-title  b500 fs15 mb1">Number of Fundraisers</p>
                                                            <p className="cmp-title  b600 fs15 mb0">{pageplayerDetail?.fundraisers_count || 0}</p>
                                                        </div>
                                                    </div>
                                                </Card>
                                                <Card className="card-child ticket" >
                                                    <div className="parent">
                                                        <div className="mb0">
                                                            <p className="cmp-title  b500 fs15 mb1">Number of participants</p>
                                                            <p className="cmp-title  b600 fs15 mb0">{pageplayerDetail?.number_of_participants || 0}</p>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Space>
                                        </Col>
                                    }
                                    <div className='tab'>
                                        {renderTabs()}
                                    </div>
                                    <Tabs activeKey={activeTab} className="mt8 mb8">
                                        <Tabs.TabPane key={`Details`} >
                                            <p className='mb1'><b>Full Name:</b>&nbsp;&nbsp;{pageplayerDetail?.full_name}</p>
                                            <p className='mb1'><b>Email:</b>&nbsp;&nbsp;{pageplayerDetail?.email}</p>
                                            <p className='mb3'><b>Phone:</b>&nbsp;&nbsp;{pageplayerDetail?.phone}</p>
                                            <p className='mb3'><b>Number of fundraisers participated:</b>&nbsp;&nbsp;{pageplayerDetail?.number_of_fundraisers_participated}</p>
                                           
                                            <p className='mb1'><b>Created at:</b>&nbsp;&nbsp;{formatDateTime(pageplayerDetail?.created_at)} </p>
                                            {/* <p className='mb5'><b>Updated at:</b>&nbsp;&nbsp;{formatDateTime(pageplayerDetail?.updated_at)} </p> */}
                                        </Tabs.TabPane>
                                        <Tabs.TabPane key={"Games"} >
                                            <LoadGames userType={userType} playerId={rowData.id} orgList={rowData?.organizations || []} />
                                        </Tabs.TabPane>
                                    </Tabs>

                                </Col>
                            </Row>

                        </>
                    }
                </div>
            }
            {modalType === "delete" &&
                <div className="mt2">
                    <p>You want to delete this player</p>
                </div>
            }


        </Modal>
    );
};

export default PlayerModal;