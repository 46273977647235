/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import SkeletonImg from '../../assets/skeleton_ticket_final.jpg';
import { closeDate, closeDateTicket } from '../../utils/functions';
import "./random.scss";

const PreviewTicket = ({ data, type = "", ticketNumber = "" }) => {
    const [firstRank, setFirstRank] = useState("");
    const [secondRank, setSecondRank] = useState("");
    const [thirdRank, setThirdRank] = useState("");

    useEffect(() => {
        if (data.prizes.length > 0) {
            let rank1 = data.prizes.filter(item => item.rank === 1);
            let rank2 = data.prizes.filter(item => item.rank === 2);
            let rank3 = data.prizes.filter(item => item.rank === 3);
            setFirstRank(rank1.length ? rank1[0].name : "")
            setSecondRank(rank2.length ? rank2[0].name : "")
            setThirdRank(rank3.length ? rank3[0].name : "")
        }
    }, [data])
    return (
        <div className={`preview-ticket-design bg ${type !== "" ? 'valid' : ''}`}>
            <div>
                <div style={{ width: data?.title?.length > 20 ? 450 : 400 }} className='pa ticket-title'>{type !== "" && <p className='ticket-round cam'>2</p>}{data.title}</div>
                {type !== "" && <div className='pa img-round'><p className='ticket-round cam3'>3</p></div>}
                <div className='pa close-date'>
                    <p className='mb0 date'>{closeDateTicket(data.end_date)}</p>
                    <p className='mb0 text'>CLOSING DATE</p>
                </div>
                <img src={SkeletonImg} alt={'skeleton'}></img>
                {secondRank !== "" && <p className='mb0 second'>{secondRank}</p>}
                {thirdRank !== "" && <p className='mb0 third'>{thirdRank}</p>}
                {firstRank !== "" && <p className='mb0 first'>{firstRank}</p>}
                {type !== "" && <p className='mb0 ticket-no'><p className='ticket-round'>1</p> {`Ticket ${ticketNumber} out of ${data.tickets_total}`}</p>}
            </div>
        </div>
    );
};

export default PreviewTicket;
