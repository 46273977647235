import React, { useState, useEffect } from "react";
import { Form, Input, Row, DatePicker, Col, Modal } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getDisabledDays, inputFormatDate, isImage } from '../../../utils/functions';
const { TextArea } = Input;

const FundraisingDetailModal = (props) => {
    const { visible, setVisible, fundraiserData, doReferesh } = props;

    const [form] = Form.useForm();
    const { isFDetailCreateLoading, statusMsg } = useSelector(state => state.fundraiser);
    const { isFundUpdating, fundUpdateMsg } = useSelector(state => state.admin);

    const [endDateDisabled, setEndDateDisabled] = useState(moment().endOf('day'));
    const [defaultEndDate, setDefaultEndDate] = useState(null);


    const dispatch = useDispatch();
    const { id } = useParams();

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values) => {
                if (id) {

                    values.fundraiser_start_date = inputFormatDate(values.fundraiser_start_date);
                    values.fundraiser_end_date = inputFormatDate(values.fundraiser_end_date);
                    dispatch({ type: 'ADMIN_UPDATE_FUND_REQUEST', formData: values, id: id });
                }
            })
            .catch((errorInfo) => {
                console.log(errorInfo)
            });
    };

    useEffect(() => {
        console.log("isFundUpdating")
        console.log(isFundUpdating)
        if (!isFundUpdating && fundUpdateMsg === "success") {
            dispatch({ type: 'ADMIN_DATA_CLEAR' });
            doReferesh()
            setVisible(false);
        }
    }, [isFundUpdating, fundUpdateMsg]);


    useEffect(() => {
        if (Object.keys(fundraiserData).length > 0) {
            form.setFieldsValue({
                fundraiser_title: fundraiserData?.title || "",
                fundraiser_description: fundraiserData?.description || "",
                fundraiser_start_date: fundraiserData.start_date !== null ? moment(fundraiserData?.start_date) : "",
                fundraiser_end_date: fundraiserData.end_date !== null ? moment(fundraiserData?.end_date) : "",
            });
            // setDisabledDays(getDisabledDays(fundraiserData))
        }
    }, [fundraiserData])

    const disabledEndDate = (current) => {
        return current <= endDateDisabled;
    };

    return (
        <Modal
            className={`prize-modal step4-modal org-modal}`}
            title={`Update Fundraising Details`}
            open={visible}
            onOk={() => handleFormSubmit()}
            onCancel={() => {
                dispatch({ type: "ADMIN_DATA_CLEAR" });
                setVisible(false);
                form.resetFields();
            }}
            centered
            width={700}
            okText={`Update`}
            okButtonProps={{ disabled: isFundUpdating, loading: isFundUpdating }}
            cancelText="Cancel"

        >

            <div className="content-container step3">

                <div className="mt5">
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        form={form}
                        layout="vertical"
                    >
                        <Form.Item
                            label="Title"
                            name="fundraiser_title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter campaign title'
                                },
                                { max: 60, message: 'Maximum 60 characters are allowed' }
                            ]
                            }
                        >
                            <Input size="large" placeholder={"Campaign Title"} />
                        </Form.Item>
                        <Form.Item
                            label="Campign Description"
                            name="fundraiser_description"
                            rules={[{ required: true, message: 'Please enter your campaign description' }]}
                        >
                            <TextArea rows={4} placeholder={"Campaign Description"} />
                        </Form.Item>

                        <Row gutter={30}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="">
                                <Form.Item
                                    label={"Start Date"}
                                    name="fundraiser_start_date"
                                    rules={[{ type: 'object', required: true, message: 'Please select start date' }]}
                                >
                                    <DatePicker
                                        placeholder={"Start Date"}
                                        style={{
                                            width: '100%',
                                        }}
                                        onChange={(e) => {
                                            form.setFieldsValue({ fundraiser_end_date: null });
                                            setDefaultEndDate(null);
                                            setEndDateDisabled(e.endOf('day'))
                                        }}
                                    />
                                    {/* <DatePicker
                                        popupClassName="new-fund-datepicker"
                                        format={'YYYY-MM-DD'}
                                        disabledDate={(e) => disabledStartDate(e)}
                                        size={"large"}
                                        style={{ width: "100%" }}
                                        placeholder={"Campaign Start Date"}
                                        onChange={(e) => {
                                            setSelectedStartDate(e);
                                            form.setFieldsValue({ end_date: null });
                                            setDefaultEndDate(null);
                                            setEndDateDisabled(e.endOf('day'))
                                        }}
                                    /> */}
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="">
                                <Form.Item
                                    label="End Date"
                                    name="fundraiser_end_date"
                                    rules={[{ type: 'object', required: true, message: 'Please select end date' }]}
                                >

                                    <DatePicker
                                        popupClassName="new-fund-datepicker"
                                        format={'YYYY-MM-DD'}
                                        disabledDate={(e) => disabledEndDate(e)}
                                        size={"large"}
                                        style={{ width: "100%" }}
                                        placeholder={"Campaign End Date"}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </div>
            </div>

        </Modal>
    );
};

export default FundraisingDetailModal;