import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Layout } from 'antd';
import { CURRENT_ROLE, CURRENT_ROLE_NAME, ROLE_NAME } from './utils/constant';
import { useSearchParams } from "react-router-dom";

// Layout Component
import Header from './components/pages/layout/header';
import FundraiserHeader from './components/pages/layout/fundraising-header';
import ValidateHeader from "./components/pages/layout/validate-header";
import Sidebar from './components/pages/layout/sidebar';
import Breadcrumb from './components/pages/layout/breadcrumb';

//Public routes without login access
import Login from './components/auth/login';
import SignUp from "./components/auth/Signup";
import ForgetPassword from './components/auth/ForgetPassword';
import ResetPassword from './components/auth/ResetPassword';

// Protected routes Need access
import Template from './components/index';
import Dashboard from './components/admin';
import Campgaigns from './components/admin/campaigns';
import CampgaignsDetail from './components/admin/campaigns/detail';
import TicketDetails from './components/admin/campaigns/ticket/index';
import PaymentDetails from "./components/admin/campaigns/payment";

import { useSelector } from 'react-redux';
import Players from './components/admin/manageUsers/players';
import UserPrivilages from './components/admin/manageUsers/privileges';
import NewFundraising from './components/fundraising/newFundraising';
import AdminNewFundraising from './components/fundraising/newFundraising/admin';
import FundraisingDetails from './components/fundraising/fundraisingDetails';
import AdminOrganizations from "./components/admin/organizations";
import AdminFundraisingTypes from "./components/admin/fundraising/fundraisingTypes";
import AdminFundraisingPackage from "./components/admin/fundraising/fundraisingPackage";
import AdminPrizes from "./components/admin/fundraising/prizes";
import AdminCoupons from "./components/admin/fundraising/coupons";
import AdminETransfer from "./components/admin/fundraising/eTransfer";
import AdminGames from "./components/admin/games";
import AdminGameDetails from './components/admin/games/gameDetail';
import AdminAnalyticsDashboard from './components/admin/dashboard/analytics';
import AdminReportedTickets from './components/admin/dashboard/reportedTickets';
import AadminPrizeToBeDelivered from './components/admin/dashboard/prizeToBeDelivered';
import AdminManageUser from "./components/admin/manageUsers/users";
import AdminFundraisingUsers from "./components/admin/manageUsers/fundraisingUser";
import Organizations from './components/fundraising/organizations';
import Sellers from './components/fundraising/sellers';
import Forbidden from "./components/pages/Error/403";
import ValidateTicket from "./components/validate";
import PrivacyPolicy from "./components/common/privacy-policy";
import TermsConditions from "./components/common/terms-conditions";

import { isLogin, isAuthorized } from "./utils/functions";

import "./App.scss";
import "./index.scss";


const { Content, Footer } = Layout;


function App() {

  const ProtectedRoute = (props) => {
    const { currentStep } = useSelector(state => state.common);
    const [searchParams] = useSearchParams();
    if (!isLogin()) {
      if (searchParams.get('payment') === "true") {
        localStorage.setItem('redirectLink', window.location.href);
      }
      return <Navigate to="/login" replace />;
    } else {
      if (isLogin() && CURRENT_ROLE === ROLE_NAME.ADMIN) {
        if (CURRENT_ROLE_NAME === "streamline_admin") {
          return (
            <Layout className="main-layout">
              <Header />
              <Layout>
                <Sidebar />
                <Layout
                  style={{
                    padding: '0 24px 24px',
                  }}
                  className="content-layout"
                >
                  <Breadcrumb />
                  <Content
                    className="site-layout-background admin-content"
                    style={{
                      padding: "20px 15px",
                      margin: 0,
                      minHeight: 280,
                    }}
                  >
                    {props.children}
                  </Content>
                </Layout>
              </Layout>
              {/* <Version /> */}
            </Layout>
          )

        } else {
          if (isAuthorized(props.allowed)) {
            return (
              <Layout className="main-layout">
                <Header />
                <Layout>
                  <Sidebar />
                  <Layout
                    style={{
                      padding: '0 24px 24px',
                    }}
                    className="content-layout"
                  >
                    <Breadcrumb />
                    <Content
                      className="site-layout-background admin-content"
                      style={{
                        padding: "20px 15px",
                        margin: 0,
                        minHeight: 280,
                      }}
                    >
                      {props.children}
                    </Content>
                  </Layout>
                </Layout>
                {/* <Version /> */}
              </Layout>
            )
          } else {
            return (
              <Layout className="main-layout">
                <Header />
                <Layout>
                  <Sidebar />
                  <Layout
                    style={{
                      padding: '0 24px 24px',
                    }}
                    className="content-layout"
                  >
                    <Breadcrumb />
                    <Content
                      className="site-layout-background admin-content"
                      style={{
                        padding: "20px 15px",
                        margin: 0,
                        minHeight: 280,
                      }}
                    >
                      <Forbidden />
                    </Content>
                  </Layout>
                </Layout>
                {/* <Version /> */}
              </Layout>
            )
          }
        }


      } else if (isLogin() && CURRENT_ROLE === ROLE_NAME.FUNDRAISER) {
        return (
          <Layout className="main-layout">
            {props.showHeader !== false ? <Header role={'fundraiser'} /> : <FundraiserHeader />}
            <Layout className={`current_step_main_${currentStep}`}>
              {/* <Sidebar /> */}
              <Layout
                style={{
                  paddingTop: '50px',
                }}
                className={`content-layout current_step_${currentStep}`}
              >
                {/* <Breadcrumb /> */}
                <Content
                  className={`site-layout-background fundraising-content current_step_content${currentStep}`}
                  style={{
                    margin: 0,
                    minHeight: 280,
                  }}
                >
                  {props.children}
                </Content>
              </Layout>
            </Layout>
            {/* <Version /> */}
          </Layout>
        )
      }

    }

  };

  const PublicRoutes = ({ children }) => {
    if (!isLogin()) {
      return <Layout>{children}</Layout>;
    } else {
      return <Navigate to="/" replace />
    }
  };

  const ValidateRoutes = ({ children }) => {
    return (
      <Layout className="main-layout validate-page">
        <ValidateHeader />
        <Layout className={`current_step_main`}>
          <Content
            className={`content-layout site-layout-background fundraising-content current_step_content`}
            style={{
              margin: 0,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        </Layout>
        {/* <Footer style={{ position: "sticky", bottom: "0" }}>
          Button
        </Footer> */}
        {/* <Version /> */}
      </Layout>
    )
  };

  // type 0 = Public
  // type 1 = Protected
  const routesList = [
    {
      path: "/",
      type: 1,
      element: <Template />,
      role: 'view_fundraisers',
    },
    {
      path: "/admin",
      type: 1,
      element: <Dashboard />,
      role: 'view_fundraisers',
    },
    {
      path: "/admin/campaigns",
      type: 1,
      element: <Campgaigns />,
      role: 'view_fundraisers',
    },
    {
      path: "/admin/analytics",
      type: 1,
      element: <AdminAnalyticsDashboard />,
      role: [],
    },
    {
      path: "/admin/reported-tickets",
      type: 1,
      element: <AdminReportedTickets />,
      role: [],
    },
    {
      path: "/admin/prize-to-delivered",
      type: 1,
      element: <AadminPrizeToBeDelivered />,
      role: [],
    },
    {
      path: "/admin/campaign/:id",
      type: 1,
      element: <CampgaignsDetail />,
      role: 'view_fundraisers',
    },
    {
      path: "/admin/campaign/:id/ticket",
      type: 1,
      element: <TicketDetails />,
      role: 'view_fundraisers',
    },
    {
      path: "/admin/campaign/:id/payment",
      type: 1,
      element: <PaymentDetails />,
      role: 'view_fundraisers',
    },
    {
      path: "/admin/organizations",
      type: 1,
      element: <AdminOrganizations />,
      role: 'view_organizations',
    },
    {
      path: "/admin/fundraising-type",
      type: 1,
      element: <AdminFundraisingTypes />,
      role: 'view_fundraiser_types',
    },
    {
      path: "/admin/fundraising-package",
      type: 1,
      element: <AdminFundraisingPackage />,
      role: 'view_fundraiser_packages',
    },
    {
      path: "/admin/prizes",
      type: 1,
      element: <AdminPrizes />,
      role: 'view_prizes',
    },
    {
      path: "/admin/coupons",
      type: 1,
      element: <AdminCoupons />,
      role: 'view_prizes',
    },
    {
      path: "/admin/e-transfer",
      type: 1,
      element: <AdminETransfer />,
      role: 'view_prizes',
    },

    {
      path: "/admin/games",
      type: 1,
      element: <AdminGames />,
      role: 'view_games',
    },
    {
      path: "/admin/game/:id",
      type: 1,
      element: <AdminGameDetails />,
      role: 'view_games',
    },
    {
      path: "/admin/users",
      type: 1,
      element: <AdminManageUser />,
      role: 'view_users',
    },
    {
      path: "/admin/fundraising-user",
      type: 1,
      element: <AdminFundraisingUsers />,
      role: 'view_users',
    },
    {
      path: "/admin/players",
      type: 1,
      element: <Players />,
      role: 'view_users',
    },
    {
      path: "/admin/users/privileges",
      type: 1,
      element: <UserPrivilages />,
      role: [],
    },
    {
      path: "/login",
      type: 0,
      element: <Login />,
      role: [],
    },
    {
      path: "/validate",
      type: 2,
      element: <ValidateTicket />,
      role: [],
    },
    {
      path: "/privacy-policy",
      type: 1,
      element: <PrivacyPolicy />,
      role: [],
    },
    {
      path: "/terms-conditions",
      type: 1,
      element: <TermsConditions />,
      role: [],
    },
    {
      path: "/signup",
      type: 0,
      element: <SignUp />,
      role: [],
    },
    {
      path: "/forget-password",
      type: 0,
      element: <ForgetPassword />,
      role: [],
    },
    {
      path: "/set-password",
      type: 0,
      element: <ResetPassword type={"set"} />,
      role: [],
    },
    {
      path: "/reset-password",
      type: 0,
      element: <ResetPassword type={"reset"} />,
      role: [],
    },
    //Fundraising Admin Routes
    {
      path: "/new-fundraising",
      type: 1,
      element: <NewFundraising />,
      role: [],
      showHeader: false
    },
    {
      path: "/new-fundraising/:id",
      type: 1,
      element: <AdminNewFundraising />,
      role: [],
      showHeader: false
    },
    {
      path: "/admin/new-fundraising/:id",
      type: 1,
      element: <NewFundraising />,
      role: [],
      showHeader: false
    },
    {
      path: "/fundraiser-details/:id",
      type: 1,
      element: <FundraisingDetails />,
      role: [],
      showHeader: true
    },
    {
      path: "/organizations",
      type: 1,
      element: <Organizations />,
      role: [],
      showHeader: true
    },
    {
      path: "/sellers",
      type: 1,
      element: <Sellers />,
      role: [],
      showHeader: true
    }
  ]

  return (
    <BrowserRouter>
      <Routes>
        {/* type  
        type=0- Public routes
        type=1- Protected routes
        type=2 - Validate routes
        */}
        {routesList.map((item) => {
          if (item.type === 0) {
            return (
              <Route
                key={item.type}
                path={item.path}
                element={
                  <PublicRoutes>
                    {item.element}
                  </PublicRoutes>
                }>
              </Route>
            )
          } else if (item.type === 2) {
            return (
              <Route
                key={item.type}
                path={item.path}
                element={
                  <ValidateRoutes {...item} allowed={item.role}>
                    {item.element}
                  </ValidateRoutes>
                }>
              </Route>
            )
          } else {
            return (
              <Route
                key={item.type}
                path={item.path}
                element={
                  <ProtectedRoute {...item} allowed={item.role}>
                    {item.element}
                  </ProtectedRoute>
                }>
              </Route>
            )
          }
        })}

      </Routes >
    </BrowserRouter >
  );
}

// export default withTranslation()(App);
export default (App);
