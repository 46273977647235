/* eslint-disable radix */
import React from 'react';
import { formatAmount } from '../../utils/functions';

function PrintPDFDocument({ amountData }) {
    return (
        <div className='print-invoice'>
            <div className='table1'>
                <table border={1}>
                    <tr>
                        <th></th>
                        <th>Price</th>
                    </tr>
                    <tr>
                        <td>Package Price</td>
                        <td><b>{formatAmount(amountData.amount)}</b></td>
                    </tr>
                    <tr>
                        <td>Prize amount held in Trust</td>
                        <td>-{formatAmount(amountData.amount_held_in_trust)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>SubTotal</td>
                        <td><b>{formatAmount(amountData.amount - amountData.amount_held_in_trust)}</b></td>
                    </tr>
                    {amountData.discount !== 0 &&
                        <tr style={{ color: 'red' }}>
                            <td><b>Discount</b></td>
                            <td><b>{`- ${formatAmount(amountData.discount)}`}</b></td>
                        </tr>}
                    {amountData.pst !== null &&
                        <tr>
                            <td>{`PST (${amountData.pst}%)`}</td>
                            <td>{formatAmount(amountData.pst_amount)}</td>
                        </tr>
                    }
                    {amountData.gst !== null &&
                        <tr>
                            <td>{`GST (${amountData.gst}%)`}</td>
                            <td>{formatAmount(amountData.gst_amount)}</td>
                        </tr>
                    }
                    {amountData.hst !== null &&
                        <tr>
                            <td>{`HST (${amountData.hst}%)`}</td>
                            <td>{formatAmount(amountData.hst_amount)}</td>
                        </tr>
                    }

                    <tr>
                        <td>Total (Prize Excluded)</td>
                        <td>{formatAmount(amountData.sub_total_amount_including_taxes)}</td>
                    </tr>
                    <tr>
                        <td>Prize Amount</td>
                        <td>{formatAmount(amountData.amount_held_in_trust)}</td>
                    </tr>
                    <tr>
                        <td>Total Owing</td>
                        <td>{formatAmount(amountData.total_owing)}</td>
                    </tr>
                    <tr>
                        <td>Today's Payment Amount</td>
                        <td><b>{formatAmount(amountData.paid_amount_with_taxes)}</b></td>
                    </tr>
                </table>
            </div>
        </div>
    );
}

export default PrintPDFDocument;