/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input, Select } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const DeleteModal = (props) => {
    const { visible, setVisible, rowData, onReferesh, deleteType, deleteText } = props;

    const { isDeleteLoading, deleteMsg } = useSelector(state => state.common);

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [confirmText, setconfirmText] = useState("");
    const [validationState, setValidationState] = useState("");

    const handleSubmit = () => {

        if (confirmText === "Delete") {
            dispatch({ type: 'DELETE_REQUEST', id: rowData.id, deleteType: deleteType });
            setValidationState("");
        } else {
            setValidationState("error");
        }
    }

    useEffect(() => {
        if (!isDeleteLoading && deleteMsg === "success") {
            dispatch({ type: "CLEAR_COMMON" });
            onReferesh();
            setVisible(false);
        }
    }, [isDeleteLoading, deleteMsg])

    return (
        <Modal
            className={`prize-modal step4-modal org-modal`}
            title={`Are you sure?`}
            open={visible}
            onOk={() => handleSubmit()}
            onCancel={() => {
                dispatch({ type: "CLEAR_COMMON" });
                setVisible(false);
                form.resetFields();
            }}
            centered
            width={550}
            okText={`Confirm`}
            okButtonProps={{ disabled: isDeleteLoading, loading: isDeleteLoading }}
            cancelText="Cancel"

        >
            <div className="mt2">
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    form={form}
                    layout="vertical"
                >
                    <Form.Item
                        className='delete-field'
                        name="delete_text"
                        extra={<span>Type the exact word <b><i>Delete</i></b> to confirm</span>}
                        label={`You want to delete this ${deleteText}?`}
                        validateStatus={validationState}
                    >
                        <Input size="large"
                            value={confirmText}
                            onChange={(e) => {
                                setValidationState("");
                                setconfirmText(e.target.value)
                            }} />
                    </Form.Item>
                </Form>
                <p></p>
            </div>
        </Modal>
    );
};

export default DeleteModal;