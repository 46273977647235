/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Row, Col, Input, DatePicker, Table, Space, Tag, Tooltip, message } from "antd";
import { DownloadOutlined, PlusOutlined, EyeOutlined, DeleteFilled, PlayCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import { BASE_URL, CURRENT_ROLE_NAME, FUNDRAISER_STATUS, PER_PAGE } from '../../../utils/constant';
import { displayPaymentTextTable, displayStatusText, formateDurationData, formatDate, getColor, isAuthorized, getStatusColor, accessToken } from '../../../utils/functions';
import Tabs from '../../common/tabs';
import DeleteModal from "../../common/modal/deleteModal";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import StartFundraiserEarly from "../../common/modal/startFundraiserEarly";

const { Search } = Input;
const { Column } = Table;

let filterParams = { status: "", start_date: "", end_date: "", search: "" };
let initialFilter = { status: "", start_date: "", end_date: "", search: "" };
const Campgaigns = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const { isCampaignLoading, campgaignList, paginationMeta, isStatusCountLoading, statusCounts } = useSelector(state => state.campgaigns);
    const { isExportLoading, exportData, updateMsg, isFundStatusUplading, isError } = useSelector(state => state.admin);

    const { isStart, statusMsg } = useSelector(state => state.common);
    const [activeTab, setActiveTab] = useState('all');
    const [perPage,] = useState(PER_PAGE);
    const [pageNo, setPageNo] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [tmpExportData, setTmpExportData] = useState("");
    const [exportLoading, setIsexportLoading] = useState(isExportLoading);
    const [pageStatusCount, setPageStatusCount] = useState("");
    const [isStartVisible, setIsStartVisible] = useState(false);
    const [fundraiserId, setFundraiserId] = useState("");


    const [visible, setVisible] = useState(false);
    const [rowData, setRowData] = useState("");

    useEffect(() => { getAllCampaignsList({}) }, []);

    const getAllCampaignsList = (params = {}) => {
        dispatch({ type: 'CAMPGAIGNS_LIST_REQUEST', formData: { ...filterParams, per_page: perPage, ...params } });
        dispatch({ type: 'STATUS_COUNT_REQUEDT', formData: { ...filterParams, ...params } });
    }

    const startFundraiserEarly = (fundId) => {
        // dispatch({ type: 'START_FUNDRAISER_REQUEST', id: fundId });
    }

    useEffect(() => {
        if (!isCampaignLoading && campgaignList?.length) {
            let tmpCmapList = [];
            campgaignList.map((item) => {
                let tmpObj = item;
                tmpObj.admin_name = `${item?.user?.first_name} ${item?.user?.last_name}`;
                tmpObj.title = `${item?.title || "No Campaign Title (Draft)"}`;
                tmpObj.org_name = `${item?.organization?.name || ""}`;
                tmpObj.package = `${item?.fundraiser_package?.name || ""}`;
                tmpObj.duration = formateDurationData(item.start_date, item.end_date);
                tmpObj.location = `${item?.organization?.city || ""} ${item?.organization?.province?.name || ""}`;
                tmpObj.status = item?.status;
                tmpObj.display_status = displayStatusText(item?.status);
                tmpObj.display_paid_status = displayStatusText(item?.paid_status);
                tmpCmapList.push(tmpObj);
                return 1;
            });
            setTotalRecord(paginationMeta.total);
            setTableData(tmpCmapList);
        } else {
            if (!isCampaignLoading && campgaignList.length === 0) {
                setTableData([]);
                setTotalRecord(0);
                setPageNo(1);
            }
        }
    }, [isCampaignLoading, campgaignList]);

    useEffect(() => {
        if (!isStatusCountLoading && Object.keys(statusCounts).length > 0) {
            setPageStatusCount(statusCounts)
        }
    }, [isStatusCountLoading, statusCounts]);

    useEffect(() => {
        if (!isStart && statusMsg === "success") {
            dispatch({ type: "CLEAR_COMMON" });
            getAllCampaignsList({});
            message.success("Fundraiser has been started now")
        }
    }, [isStart, statusMsg]);


    const onChange = (e, type) => {
        console.log("e, type")
        console.log(e, type)
        filterParams.page = 1;
        setPageNo(1);

        if (type === "search") {
            filterParams[type] = e;
        } else if (type === "status") {
            setActiveTab(e);
            if (e === "all") {
                filterParams[type] = "";
            } else {
                filterParams[type] = e;
            }
        } else if (type === 'start_date') {
            filterParams[type] = e !== null && e !== "" ? moment(e).format("YYYY-MM-DD") : "";
        } else if (type === 'end_date') {
            filterParams[type] = e !== null && e !== "" ? moment(e).format("YYYY-MM-DD") : "";
        } else if (type === 'clear') {
            filterParams = initialFilter;
        }
        getAllCampaignsList(filterParams)
    }

    const handlePagination = (e) => {
        setPageNo(e);
        getAllCampaignsList({ page: e })
    }

    const exportFundraiser = () => {
        setIsexportLoading(true);
        let tmpFilterParas = filterParams;
        delete tmpFilterParas.per_page;
        delete tmpFilterParas.page;
        window.open(`${BASE_URL}api/export-fundraisers?token=${accessToken()}`, '_blank');
        setIsexportLoading(false);
        // dispatch({ type: 'EXPORT_REQUEST', formData: tmpFilterParas, exportFor: 'fundraiser' });
    }

    useEffect(() => {
        if (!isExportLoading && exportData !== "") {
            setTmpExportData(exportData);
            setTimeout(() => {
                dispatch({ type: "ADMIN_DATA_CLEAR" });
                setIsexportLoading(isExportLoading);
                document.getElementById('exportToCSVLink').click();
            }, 1000);
        }
    }, [isExportLoading, exportData]);

    const openModal = (record) => {
        setVisible(true);
        setRowData(record);
    }

    useEffect(() => {
        if (!isFundStatusUplading && updateMsg === "success" && !isError) {
            dispatch({ type: 'ADMIN_DATA_CLEAR' });
            getAllCampaignsList({});
            setIsStartVisible(false);
            setFundraiserId("");
        } else if (!isFundStatusUplading && updateMsg === "success" && isError) {
            message.error({ content: "Something wen't wrong, may ticket details not configured properly", className: 'error-class', duration: 3 });
            dispatch({ type: 'ADMIN_DATA_CLEAR' });
            setIsStartVisible(false);
            setFundraiserId("");
        }

    }, [isFundStatusUplading, updateMsg, isError]);

    const dateContent = () => {
        return (
            <div>
                <p className="mb3"><b>Based on Date Created</b></p>
                <p className="mb2">
                    <b>From Date -</b> Filter all future data based on selected date
                </p>
                <p className="mb2">
                    <b>To Date -</b> Filter all past data based on selected date
                </p>
                <p className="mb2">
                    <b>From Date and To Date -</b> Filter between From and To date data
                </p>
            </div>
        )
    }

    return (
        <div>
            <div className="sub-header">
                <p className="mb0 title">
                    Fundraising Campaigns
                </p>
                <div>
                    <Button
                        type="primary"
                        size="large"
                        className="br1 mr4"
                        htmlType="submit"
                        id="export-fundraiser"
                        loading={exportLoading}
                        disabled={exportLoading}
                        onClick={() => exportFundraiser()}
                        icon={<DownloadOutlined />}
                    >
                        Export Fundraisers
                    </Button>
                    {/* <Button
                        type="primary"
                        size="large"
                        className="br1"
                        htmlType="submit"
                        icon={<PlusOutlined />}
                    >
                        New Fundraiser
                    </Button> */}
                    <CSVLink id="exportToCSVLink" filename="Fundraisers.csv" data={tmpExportData} />
                </div>
            </div>
            <div className="filter mt8">
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>

                        <Search
                            defaultValue={filterParams['search']}
                            size="large"
                            allowClear
                            placeholder="Search"
                            style={{ width: "60%" }}
                            enterButton
                            onSearch={(e) => onChange(e, 'search')}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fr text-right date-filter">
                        {/* <Tooltip placement="top" title={"Clear Filter"}>
                            <RiFilterOffLine size={28} className="clear-filter pointer" onClick={() => onChange('', 'clear')} />
                        </Tooltip> */}
                        <Tooltip title={dateContent} placement="left">
                            <InfoCircleOutlined size={20} />
                        </Tooltip>

                        <DatePicker
                            size={"large"}
                            className="ml5 mr5"
                            style={{ width: "40%" }}
                            placeholder={"From Date"}
                            format={'YYYY-MM-DD'}
                            value={(filterParams['start_date'] !== undefined && filterParams['start_date'] !== "" && filterParams['start_date'] !== null) ? moment(filterParams['start_date'], 'YYYY-MM-DD') : null}
                            onChange={(e) => onChange(e, 'start_date')}
                        />
                        <DatePicker
                            size={"large"}
                            style={{ width: "40%" }}
                            placeholder={"To Date"}
                            format={'YYYY-MM-DD'}
                            value={(filterParams['end_date'] !== undefined && filterParams['end_date'] !== "" && filterParams['end_date'] !== null) ? moment(filterParams['end_date'], 'YYYY-MM-DD') : null}
                            onChange={(e) => onChange(e, 'end_date')}
                        />
                    </Col>
                </Row>
            </div>
            <Row className='campaign-table mt10'>
                <Tabs
                    onChange={(e) => onChange(e, 'status')}
                    isShowCount={true}
                    item={FUNDRAISER_STATUS}
                    active={activeTab}
                    pageStatusCount={pageStatusCount}
                />
            </Row>
            <Row className="mt5">
                <Table
                    style={{ width: "100%" }}
                    dataSource={tableData}
                    loading={isCampaignLoading}
                    // pagination={true}
                    pagination={{
                        current: pageNo,
                        onChange: handlePagination,
                        pageSize: perPage,
                        total: totalRecord
                    }}
                    scroll={{ x: "max-content" }}
                >

                    <Column
                        title="Campaign Name"
                        dataIndex="title"
                        key="title"
                        width={"250px"}
                    />
                    <Column
                        title="Created on"
                        dataIndex="created_at"
                        key="created_at"
                        render={(text, record) => formatDate(text)}
                    />
                    <Column
                        title="Fundraising administrator"
                        dataIndex="admin_name"
                        key="admin_name"
                        width={"150px"}
                    />
                    <Column
                        title="Fundraising organization"
                        dataIndex="org_name"
                        key="org_name"
                        width={"150px"}
                    />
                    <Column
                        title="Package"
                        dataIndex="package"
                        key="package"
                        width={"150px"}
                    />
                    <Column
                        title="Duration"
                        dataIndex="duration"
                        key="duration"
                        width={"150px"}
                    />

                    <Column
                        title="Status"
                        dataIndex="display_status"
                        key="display_status"
                        width={"100px"}
                        render={(_, { display_status, status }) => {
                            let color = getStatusColor(status)
                            return (
                                <Space size="middle">
                                    <Tag color={color} key={status}>
                                        {display_status}
                                    </Tag>
                                </Space>)
                        }}
                    />
                    <Column
                        title="Payment Status"
                        key='paid_status'
                        dataIndex='paid_status'
                        width={"100px"}
                        render={(_, { paid_status, display_paid_status }) => {
                            let color = (paid_status !== 'pending' || paid_status !== 'pending') ? "green" : "red";
                            return (
                                <Space size="middle">
                                    <Tag color={color} key={paid_status}>
                                        {display_paid_status}
                                    </Tag>
                                </Space>)
                        }}
                    />
                    <Column
                        title="Location"
                        dataIndex="location"
                        key="location"
                        width={"150px"}
                    />

                    <Column
                        title={"Action"}
                        key='action'
                        fixed='right'
                        width={"100px"}
                        render={(_, record) => {
                            return (
                                <Space size="middle" className={`cursor ${record.isDisabled ? 'disabled-edit' : 'not-disabled'}`}>
                                    <Tooltip placement="top" title={"View Detail"}>
                                        <EyeOutlined
                                            onClick={() => navigate(`/admin/campaign/${record.id}`)}
                                            disabled={record.isDisabled}
                                            className={`pointer cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                            style={{ fontSize: "18px" }}
                                        />
                                    </Tooltip>
                                    <Tooltip placement="top" title={"Ticket Management"}>
                                        <QrcodeOutlined
                                            onClick={() => navigate(`/admin/campaign/${record.id}/ticket`)}
                                            disabled={record.isDisabled}
                                            className={`pointer cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                            style={{ fontSize: "17px" }}
                                        />
                                    </Tooltip>
                                    {CURRENT_ROLE_NAME === "streamline_admin" && (record.status !== "fundraiser_started" && record.status !== "fundraiser_closed" && record.status !== "prizes_shipped" && record.status !== "fundraiser_declined") &&
                                        <Tooltip placement="top" color={"red"} title={"Start Fundraiser Early"}>
                                            <PlayCircleOutlined
                                                onClick={() => {
                                                    setFundraiserId(record.id)
                                                    setIsStartVisible(true);
                                                }}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }

                                    {isAuthorized('delete_fundraisers') &&
                                        <Tooltip placement="top" color={"red"} title={"Delete"}>
                                            <DeleteFilled
                                                onClick={() => openModal(record)}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }
                                </Space>
                            )
                        }}
                    />
                </Table>
            </Row>

            {visible &&
                <DeleteModal
                    visible={visible}
                    rowData={rowData}
                    setVisible={() => {
                        setRowData("");
                        setVisible(false);
                    }}
                    deleteType="fundraiser"
                    deleteText="fundraiser"
                    onReferesh={() => getAllCampaignsList()}
                />
            }

            {isStartVisible &&
                <StartFundraiserEarly
                    setVisible={(type) => {
                        setIsStartVisible(false)
                    }}
                    visible={isStartVisible}
                    fundId={fundraiserId}
                    type="admin"
                />
            }
        </div>
    );
};
export default Campgaigns;