import React, { useState, useEffect } from "react";
import { Row, Col, Button, Progress, Spin, Avatar, Card, Divider } from "antd";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CheckOutlined, CloseOutlined, CloseCircleOutlined, CheckCircleFilled, CloseCircleFilled, RightOutlined, LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { displayStartEndDate, percentageCalculate, startInDays, showAvtarOrgName, getErrorStatus, startInDaysAndEnd, } from '../../utils/functions';
import { PRIZE_LIST } from '../../utils/constant';
import RulesAndRegulationModal from "../common/modal/RulesAndRegulationModal";
import ValidateOrganizationModal from "../common/modal/organizationModal";
import PrizeImg from '../../assets/prize.png';
import Slider from "react-slick";
import PrintTicket from "../common/printTicket";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const ValidateTicket = (props) => {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const { isTicketValidating, validateMsg, validErrorMsg, isFundraiserLoading, fundData, validTicketData } = useSelector(state => state.ticket);

    const [currentView, setCurrentView] = useState("validate");
    const [isValidTicket, setIsValidTicket] = useState(false);
    const [viewRules, setViewRules] = useState(false);
    const [showOrgModal, setShowOrgModal] = useState(false);
    const [tmpValidErrorMsg, setTmpValidErrorMsg] = useState("");
    const [ticketNumber, setTicketNumber] = useState("");
    const [settings, setSettings] = useState({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    });

    useEffect(() => {
        if (searchParams.get('id') !== null) {
            dispatch({ type: 'TICKET_FUNDRAISER_DATA_REQUEST', id: searchParams.get('id') });
        }
        onValidate();
    }, [])


    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (

            <div className="arrow-right" onClick={onClick}>
                <RightCircleFilled />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div className="arrow-left" onClick={onClick} >
                <LeftCircleFilled />
            </div>
        );
    }

    const onValidate = () => {
        let params = {
            id: searchParams.get('id'),
            uuid: searchParams.get('uuid')
        }
        dispatch({ type: 'VALIDATE_TICKET_REQUEST', formData: params });
    }


    useEffect(() => {
        console.log("validErrorMsg-", validErrorMsg);
        console.log("validateMsg-", validateMsg);
        console.log("validTicketData-", validTicketData);
        if (!isTicketValidating && validateMsg === "success" && Object.keys(validTicketData).length > 0) {
            dispatch({ type: 'CLEAR_TICKET' });
            if (validTicketData.is_valid) {
                setTicketNumber(validTicketData.ticket_number)
            }
            // setIsValidTicket(true);
            // setCurrentView('detail');

        } else if (!isTicketValidating && validateMsg === "error" && validErrorMsg !== "") {
            setTicketNumber(validTicketData?.ticket_number)
            setTmpValidErrorMsg(validTicketData?.code || "");
            dispatch({ type: 'CLEAR_TICKET' });
            setIsValidTicket(false);
            setCurrentView('detail');
        }

    }, [isTicketValidating, validateMsg, validErrorMsg, validTicketData]);

    const onNoClick = () => {
        dispatch({ type: 'VALIDATE_MATHC_TICKET_REQUEST', formData: { uuid: searchParams.get('uuid'), is_matching: false } });
        setTmpValidErrorMsg("TICKET_NOT_MATCHING");
        setIsValidTicket(false);
        setCurrentView('detail');
        // dispatch({ type: 'CLEAR_TICKET' });
    }

    const onYesClick = () => {

        dispatch({ type: 'VALIDATE_MATHC_TICKET_REQUEST', formData: { uuid: searchParams.get('uuid'), is_matching: true } });

        dispatch({ type: 'CLEAR_TICKET' });
        setIsValidTicket(true);
        setCurrentView('detail');
    }

    const displayPrize = (item, item2) => {
        if (item.id === item2.rank) {
            return (<>
                <div className="">
                    <img style={{ height: "180px", width: "290px", backgroundSize: "cover" }} src={item2?.image !== undefined && item2?.image !== null ? item2.image : PrizeImg} alt="prize" />
                    <p className="mb2 text-title">{item2.name}</p>
                    <p className="sub-text-title">{item2.description}</p>
                </div>
            </>)
        } else {
            return null;
        }
    }

    const getCount = (prizes = [], rank) => {
        if (prizes.length > 0) {
            let indiPrize = prizes.filter(item => item.rank === rank);
            return indiPrize.length;
        }
        return 0;
    }

    return (
        <>

            {currentView === "validate" &&
                <>
                    <div className={`${currentView} validate-section`}>
                        {(isFundraiserLoading || isTicketValidating) && <div className="no-data"><Spin /></div>}
                        {(!isFundraiserLoading && !isTicketValidating) && Object.keys(fundData).length > 0 &&
                            <>
                                <p className="title">Validate your ticket before purchasing</p>
                                <p className="description">Confirm that the ticket details and design match the info below. if it doesn't match, don't buy the ticket</p>
                                <div className="check">
                                    <div className="section">
                                        <div>
                                            <p className="icon">1</p>
                                        </div>
                                        <div>
                                            <p className="main">Ticket Number</p>
                                            <p className="sub-main">
                                                {ticketNumber}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div>
                                            <p className="icon">2</p>
                                        </div>
                                        <div>
                                            <p className="main">Event Title</p>
                                            <p className="sub-main">
                                                {fundData?.title}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div>
                                            <p className="icon">3</p>
                                        </div>
                                        <div>
                                            <p className="main">Ticket Watermark</p>
                                            <p className="sub-main">Ensure ticket has reflective area</p>
                                        </div>
                                    </div>
                                </div>

                            </>
                        }
                        {/* {(isFundraiserLoading || isTicketValidating) && Object.keys(fundData).length === 0 && <p>Something wen't wrong, Try again!</p>} */}
                        {(!isFundraiserLoading && !isTicketValidating) && Object.keys(fundData).length > 0 &&
                            <div className="custom-div-ticket">
                                <PrintTicket data={fundData} type="validate" ticketNumber={ticketNumber} />
                            </div>
                        }
                    </div>

                </>
            }
            {currentView === "detail" &&
                <>
                    <div className={`${isValidTicket ? 'info-success' : 'info-error'} validate-message`}>
                        {isValidTicket && <>
                            <p className="ticket-icon"><CheckCircleFilled /></p>
                            <p className="ticket-title">Ticket #{ticketNumber}</p>
                            <p className="ticket-msg">The ticket is valid</p>
                        </>}
                        {!isValidTicket &&
                            <>
                                <p className="ticket-icon"><CloseCircleFilled /></p>
                                <p className="ticket-title">Ticket #{ticketNumber}</p>
                                <p className="ticket-msg">{getErrorStatus(tmpValidErrorMsg).title}
                                    <br></br>
                                    {getErrorStatus(tmpValidErrorMsg).description}
                                </p>
                            </>
                        }
                    </div>
                    {!isValidTicket &&
                        <div style={{ padding: "25px" }}>
                            <p>Please contact customer service at:<br></br>customerservice@streamlinefundraising.com</p>
                        </div>
                    }
                    {isValidTicket &&
                        <>
                            <div className={`${currentView} validate-section`}>
                                <div className="mt5">
                                    {isFundraiserLoading && <div className="no-data"><Spin /></div>}
                                    {!isFundraiserLoading && Object.keys(fundData).length > 0 &&
                                        <Row className="main-container minH">
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="left">
                                                <div className="top-section">
                                                    <p className="mb2 title-text">{fundData?.title}</p>
                                                    <p className="mb2 title-date">{displayStartEndDate(fundData)}<span>{` (${startInDaysAndEnd(fundData)})`}</span></p>
                                                    <p className="mt3 mb0">
                                                        <Progress
                                                            strokeWidth={11}
                                                            strokeColor={{
                                                                '0%': '#1B35B3',
                                                                '100%': '#A32B83',
                                                            }}
                                                            percent={percentageCalculate(fundData?.tickets_total, fundData?.tickets_played)}

                                                        />
                                                    </p>
                                                    <p className="mb0 ticket-text">
                                                        {`${fundData?.tickets_played}/${fundData?.tickets_total}`} Tickets played
                                                    </p>
                                                    <div className="mt5">
                                                        <p className="description">{fundData?.description || ""}</p>
                                                        <div className="preview-org pointer mb8 mt7" >
                                                            {(fundData.organization !== null) ?
                                                                <>
                                                                    <div className="box1" onClick={() => setShowOrgModal(true)}>
                                                                        {(fundData?.organization.image === null || fundData?.organization.image === "") &&
                                                                            <Avatar size="large" width={40} className='pointer' >{showAvtarOrgName(fundData?.organization?.name || "")}</Avatar>
                                                                        }
                                                                        {(fundData?.organization.image !== null && fundData?.organization.image !== "") &&
                                                                            <img src={fundData?.organization.image} width={40} className="icon" alt="icon" />
                                                                        }
                                                                    </div>
                                                                    <div className="box2" onClick={() => setShowOrgModal(true)}>
                                                                        <p className="mb0 black fs14 bold">{fundData?.organization?.name || ""}</p>
                                                                        <p className="mb0 sub-text">
                                                                            {fundData?.organization?.phone} {`${(fundData?.organization?.phone && fundData?.organization?.email) ? ` • ` : ''}`} {fundData?.organization.email}
                                                                        </p>
                                                                    </div>
                                                                </>
                                                                :
                                                                <div className="preview-org step4">
                                                                    <div className="box1">
                                                                        <Avatar size="large" width={40} className='pointer' >•••</Avatar>
                                                                    </div>
                                                                    <div className="box2">
                                                                        <p className="mb0 text">Not affiliated with any organization</p>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>
                                    }
                                </div>
                            </div>
                            {!isFundraiserLoading && Object.keys(fundData).length > 0 &&
                                <div className="validate-container">
                                    <div className="outer-box"></div>
                                    <div className="inner-box overlay">
                                        <div className="inner-data">
                                            <div className="mt0 validate-prize">
                                                <p className="mb3 fs22 bold rule-text">Play and win great prizes!</p>
                                                <p className="mb8 fs14 "><span className="rule-text" onClick={() => setViewRules(true)}>View rules and regulations&nbsp;<RightOutlined size={12} /></span></p>

                                                <Card style={{ width: "100%" }} className="mb4 prize-card maxw100">
                                                    {PRIZE_LIST.map((item, index) => {
                                                        return (
                                                            <div className="prize-card-child step5 mb8">
                                                                <div className="">
                                                                    <p className="mb1 text prize-win-title">{item.validate_title}</p>
                                                                    {getCount(fundData.prizes, item.id) > 1 &&
                                                                        <p className="mb1 text prize-win-count">{getCount(fundData.prizes, item.id)} Choices available</p>
                                                                    }
                                                                    {getCount(fundData.prizes, item.id) === 0 &&
                                                                        <p className="mb1 text prize-win-count"> Prize not available</p>
                                                                    }
                                                                </div>
                                                                <Slider {...settings}>
                                                                    {fundData.prizes.length > 0 && fundData.prizes.map((item2, index) => {
                                                                        return displayPrize(item, item2, index);
                                                                    })
                                                                    }
                                                                </Slider>
                                                                {index !== 2 &&
                                                                    <Divider plain className="divider"></Divider>
                                                                }
                                                            </div>
                                                        )
                                                    })}
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>}
                </>
            }
            {(!isFundraiserLoading && !isTicketValidating) &&
                <div className="ticket-section">
                    {currentView === "validate" &&
                        <>
                            <p className="title">Do the ticket details match?</p>
                            <Button
                                type="secondary"
                                size="large"
                                className="btn validate-no mr3"
                                htmlType="submit"
                                id="export-fundraiser"
                                onClick={() => onNoClick()}
                                icon={<CloseOutlined />}
                            >
                                No
                            </Button>
                            <Button
                                type="primary"
                                size="large"
                                className="btn validate-yes ml3"
                                htmlType="submit"
                                id="export-fundraiser"
                                onClick={() => onYesClick()}
                                disabled={searchParams.get('id') === null || searchParams.get('id') === null || isTicketValidating}
                                loading={isTicketValidating}
                                icon={<CheckOutlined />}
                            >
                                Yes
                            </Button>
                        </>
                    }
                    {/* {currentView === "detail" &&
                <>

                    <Button
                        type="primary"
                        size="large"
                        className="btn validate-another ml3"
                        htmlType="submit"
                        id="export-fundraiser"
                        disabled={searchParams.get('id') === null || searchParams.get('id') === null || isTicketValidating}
                        loading={isTicketValidating}
                        icon={<CheckOutlined />}
                    >
                        Validate another ticket
                    </Button>
                </>
            } */}
                </div>
            }

            {viewRules &&
                <RulesAndRegulationModal
                    fundId={searchParams.get('id')}
                    showModal={viewRules}
                    onCancel={() => setViewRules(false)}
                />
            }
            {showOrgModal &&
                <ValidateOrganizationModal
                    showModal={showOrgModal}
                    onCancel={() => setShowOrgModal(false)}
                    rowData={fundData?.organization}
                />
            }
        </>
    );
};
export default ValidateTicket;
