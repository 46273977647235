/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input, message, } from 'antd';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import OtpInput from "react-otp-input";
import { useState } from 'react';
import { useEffect } from 'react';
const interval = 60000;
const OtpModal = (props) => {
    const { visible, setVisible, phoneNo } = props;
    const { isOtpLoading, optStatus, signupData, isOtpVerifyLoading } = useSelector(state => state.auth);
    const [otp, setOtp] = useState("");
    const [isSendEnabled, setIsSendEnabled] = useState(false);
    const [remaingTime, setRemaingTime] = useState(60);
    const [toggleSend, setToggleSend] = useState(false);

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
            var remaining = localStorage.endTime - new Date();
            if (remaining >= 0) {
                setRemaingTime(Math.floor(remaining / 1000));
            } else {
                localStorage.removeItem('endTime');
                setIsSendEnabled(true);
            }
        }, 1000)

        return () => clearInterval(intervalId); //This is important

    }, [toggleSend])

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                let tmpValues = {};
                tmpValues.phone = signupData?.fundraising_admin_phone || phoneNo;
                tmpValues.otp = otp;
                dispatch({ type: 'OTP_VERIFY_REQUEST', formData: tmpValues });
            })
            .catch((errorInfo) => {
                console.log(errorInfo)
            });
    }

    const resendOTP = () => {
        // startTimer();
        if (!localStorage.endTime) {
            localStorage.endTime = +new Date() + interval;
        }
        setToggleSend(!toggleSend);
        setIsSendEnabled(false);
        // dispatch({ type: "OTP_REQUEST", formData: { phone: "+917405472492" } });
        dispatch({ type: "OTP_REQUEST", formData: { phone: signupData?.fundraising_admin_phone || phoneNo }, isResend: true });
    }

    useEffect(() => {
        if (!isOtpLoading && optStatus === "success") {
            // startTimer();
            dispatch({ type: "CLEAR_INVITE" })
            message.success("OTP has been sent to phone number");
        }
    }, [isOtpLoading, optStatus])

    return (
        <Modal
            className={`prize-modal org-modal otp-modal`}
            open={visible}
            onOk={() => handleSubmit()}
            onCancel={() => {
                dispatch({ type: "CLEAR_COMMON" });
                setVisible(false);
                form.resetFields();
            }}
            centered
            width={650}
            okText={`Verify`}
            okButtonProps={{ disabled: (otp === "" || otp.length !== 6) || isOtpVerifyLoading, loading: isOtpVerifyLoading }}
            cancelText="Cancel"
            maskClosable={false}
        >
            <div className="mt5 mb5 pr5 pl5">
                <p className='bold fs18'>OTP Verification</p>
                <p>{`You should have received a text message with a 6 digit OTP code at ${signupData?.fundraising_admin_phone || phoneNo}`}</p>
                <div className='otp-section'>
                    <OtpInput
                        onChange={otp => setOtp(otp)}
                        numInputs={6}
                        value={otp}
                        shouldAutoFocus={true}
                        separator={<span>&nbsp;-&nbsp;</span>}
                    />
                </div>
                <div className='mt5'>
                    {!isSendEnabled && remaingTime !== 60 && <span className=''>
                        {remaingTime === 0 ? 'Resend OTP' : `Resend OTP in ${remaingTime} seconds`}
                    </span>}
                    {isSendEnabled && <span className='link pointer' onClick={() => resendOTP()}>
                        Resend OTP
                    </span>}

                </div>

            </div>

        </Modal>
    );
};

export default OtpModal;