import React from "react";
import { Modal, Form, Row, Col, Input, Select } from 'antd';
import { CONTACT_SUPPORT } from '../../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const { Option } = Select;
const { TextArea } = Input;

const ContactSupportModal = ({ onCancel, isContactVisible, type = "" }) => {
    const { id } = useParams();

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { isSupportLoading, supportMsg } = useSelector(state => state.common);

    const onFinishFailed = () => {
        console.log('call');
    }
    const onFinish = () => {
        form.validateFields()
            .then((values) => {
                dispatch({ type: 'CONTACT_SUPPORT_REQUEST', formData: values, contactFor: type === "admin" ? "admin" : "fundraiser", id: id });
            })
            .catch((errorInfo) => {
                console.log(errorInfo)
            });

    }
    const headrContent = (
        <>
            <p className="title fs22 mt5 bold">
           {type === "admin" ? "Send Email to Fundraising Admin" : "Send email to Customer Service"}</p>
            {/* <p className="sub-title fs14">Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator. </p> */}
        </>
    );
    return (
        <Modal
            title={headrContent}
            visible={isContactVisible}
            onCancel={() => onCancel(false)}
            width={600}
            onOk={() => onFinish()}
            className="contact-modal-parent"
            maskClosable={false}
            okButtonProps={{ disabled: isSupportLoading, loading: isSupportLoading }}
            okText={'Submit'}
            cancelText={'Cancel'}
        >
            <Form
                name="basic"
                initialValues={{ remember: true }}
                form={form}
            >
                <Row gutter={20} >
                    {type !== "admin" &&
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="red">
                            <Form.Item
                                className='form-field'
                                name="inquiry_type"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select type",
                                    }
                                ]}
                            >
                                <Select
                                    placeholder={"Inquiry Type"}
                                    style={{ width: "100%" }}
                                    size="large"
                                >
                                    {CONTACT_SUPPORT.map((item) =>
                                        <Option value={item.key} key={item.id}>{item.title}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="red">
                        <Form.Item
                            name='message'
                            className='form-field'
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter message",
                                },
                                {
                                    max: 5000,
                                    message: "Maximum 5000 characters are allowed",
                                }
                            ]}
                        >
                            <TextArea
                                autoSize={{ minRows: 5 }}
                                placeholder={"Message"} />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal>
    );
};
export default ContactSupportModal;
