const initialState = {
    isClientsLoading: false,
    clientsList: [],

    isInviteSending: false,
    inviteMsg: "",

    isRequestSending: false,
    statusMsg: "",

    isForgetPasswordLoading: false,
    forgetMsg: "",

    isResendLoading: false,
    resendMsg: "",

    isUpdateExpiryLoading: false,
    updateExpiryMsg: "",

    isUnlinkLoading: false,
    unlinkMsg: "",

    isLoginLoading: false,
    loginMsg: "",

    loginData: {},

    isUpdateLoading: false,
    updateMsg: "",

    isRoleLoading: false,
    roleList: [],
    isPermissionLoading: false,
    permissionList: {},
    isPermissionUpdating: false,
    permissionStatus: "",

    isSignupLoading: false,
    signupStatus: "",
    signupData: {},

    isOtpVerifyLoading: false,
    otpVerifyStatus: "",

    error: "",
    isMyDataLoading: false,
    myData: {}


};

const reducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case "CLIENTS_LIST_REQUEST":
            state = {
                ...state,
                isClientsLoading: true,
                clientsList: []

            };
            break;

        case "CLIENTS_LIST_SUCCESS":
            state = {
                ...state,
                isClientsLoading: false,
                clientsList: action.data.data

            };
            break;

        case "CLIENTS_LIST_ERROR":
            state = {
                ...state,
                isClientsLoading: false,
                error: action.error
            };
            break;

        /*------------ */
        case "CLIENT_INVITE_REQUEST":
            state = {
                ...state,
                isInviteSending: true,
                inviteMsg: ""

            };
            break;

        case "CLIENT_INVITE_SUCCESS":
            state = {
                ...state,
                isInviteSending: false,
                inviteMsg: action.msg

            };
            break;

        case "CLIENT_INVITE_ERROR":
            state = {
                ...state,
                isInviteSending: false,
                inviteMsg: action.error
            };
            break;

        /*------------ */
        case "SET_PASSWORD_REQUEST":
            state = {
                ...state,
                isRequestSending: true,
                statusMsg: ""

            };
            break;

        case "SET_PASSWORD_SUCCESS":
            state = {
                ...state,
                isRequestSending: false,
                statusMsg: "success"

            };
            break;

        case "SET_PASSWORD_ERROR":
            console.log(action)
            state = {
                ...state,
                isRequestSending: false,
                statusMsg: action.error
            };
            console.log(state)
            break;

        /*------------ */
        case "FORGET_PASSWORD_REQUEST":
            state = {
                ...state,
                isForgetPasswordLoading: true,
                forgetMsg: ""
            };
            break;

        case "FORGET_PASSWORD_SUCCESS":
            state = {
                ...state,
                isForgetPasswordLoading: false,
                forgetMsg: "success"
            };
            break;

        case "FORGET_PASSWORD_ERROR":
            state = {
                ...state,
                isForgetPasswordLoading: false,
                forgetMsg: action.error
            };
            break;

        /*------------ */
        case "RESEND_CODE_REQUEST":
            state = {
                ...state,
                isResendLoading: true,
                resendMsg: ""
            };
            break;

        case "RESEND_CODE_SUCCESS":
            state = {
                ...state,
                isResendLoading: false,
                resendMsg: "success"
            };
            break;

        case "RESEND_CODE_ERROR":
            state = {
                ...state,
                isResendLoading: false,
                resendMsg: action.error
            };
            break;

        /*------------ */
        case "UPDATE_EXPIRY_REQUEST":
            state = {
                ...state,
                isUpdateExpiryLoading: true,
                updateExpiryMsg: ""
            };
            break;

        case "UPDATE_EXPIRY_SUCCESS":
            state = {
                ...state,
                isUpdateExpiryLoading: false,
                updateExpiryMsg: "success"
            };
            break;

        case "UPDATE_EXPIRY_ERROR":
            state = {
                ...state,
                isUpdateExpiryLoading: false,
                updateExpiryMsg: action.error
            };
            break;

        /*------------ */
        case "UNLINK_USER_REQUEST":
            state = {
                ...state,
                isUnlinkLoading: true,
                unlinkMsg: ""
            };
            break;

        case "UNLINK_USER_SUCCESS":
            state = {
                ...state,
                isUnlinkLoading: false,
                unlinkMsg: "success"
            };
            break;

        case "UNLINK_USER_ERROR":
            state = {
                ...state,
                isUnlinkLoading: false,
                unlinkMsg: action.error
            };
            break;

        /*------------ */
        case "LOGIN_REQUEST":
            state = {
                ...state,
                isLoginLoading: true,
                loginMsg: ""
            };
            break;

        case "LOGIN_SUCCESS":
            state = {
                ...state,
                isLoginLoading: false,
                loginMsg: action.loginMsg,
                loginData: action.data
            };
            break;

        case "LOGIN_ERROR":
            state = {
                ...state,
                isLoginLoading: false,
                loginMsg: action.error
            };
            break;

        /*------------ */
        case "GET_LOGGED_IN_USER_REQUEST":
            state = {
                ...state,
                isMyDataLoading: true,
                myData: {}
            };
            break;

        case "GET_LOGGED_IN_USER_SUCCESS":
            state = {
                ...state,
                isMyDataLoading: false,
                myData: action.data,
            };
            break;

        case "GET_LOGGED_IN_USER_ERROR":
            state = {
                ...state,
                isMyDataLoading: false,
                myData: {}
            };
            break;

        /*------------ */
        case "USER_DETAIL_UPDATE_REQUEST":
            state = {
                ...state,
                isUpdateLoading: true,
                updateMsg: ""
            };
            break;

        case "USER_DETAIL_UPDATE_SUCCESS":
            state = {
                ...state,
                isUpdateLoading: false,
                updateMsg: "success",
                loginData: action.data
            };
            break;

        case "USER_DETAIL_UPDATE_ERROR":
            state = {
                ...state,
                isUpdateLoading: false,
                updateMsg: action.error
            };
            break;

        /*------------ */
        case "ROLE_LIST_REQUEST":
            state = {
                ...state,
                isRoleLoading: true,
                roleList: []
            };
            break;

        case "ROLE_LIST_SUCCESS":
            state = {
                ...state,
                isRoleLoading: false,
                roleList: action.data
            };
            break;

        case "ROLE_LIST_ERROR":
            state = {
                ...state,
                isRoleLoading: false,
                roleList: []
            };
            break;

        /*------------ */
        case "PERMISSION_LIST_REQUEST":
            state = {
                ...state,
                isPermissionLoading: true,
                permissionList: {}
            };
            break;

        case "PERMISSION_LIST_SUCCESS":
            state = {
                ...state,
                isPermissionLoading: false,
                permissionList: action.data
            };
            break;

        case "PERMISSION_LIST_ERROR":
            state = {
                ...state,
                isPermissionLoading: false,
                permissionList: {}
            };
            break;

        /*------------ */
        case "PERMISSION_TOGGLE_REQUEST":
            state = {
                ...state,
                isPermissionUpdating: true,
                permissionStatus: ""
            };
            break;

        case "PERMISSION_TOGGLE_SUCCESS":
            state = {
                ...state,
                isPermissionUpdating: false,
                permissionStatus: "success"
            };
            break;

        case "PERMISSION_TOGGLE_ERROR":
            state = {
                ...state,
                isPermissionUpdating: false,
                permissionStatus: "error",
            };
            break;

        /*------------ */
        case "OTP_REQUEST":
            state = {
                ...state,
                isOtpLoading: true,
                optStatus: ""
            };
            break;

        case "OTP_SUCCESS":
            state = {
                ...state,
                isOtpLoading: false,
                optStatus: action.data
            };
            break;

        case "OTP_ERROR":
            state = {
                ...state,
                isOtpLoading: false,
                optStatus: action.data
            };
            break;

        /*------------ */
        case "SIGNUP_REQUEST":
            state = {
                ...state,
                isSignupLoading: true,
                signupData: {},
                signupStatus: ""
            };
            break;

        case "SIGNUP_SUCCESS":
            state = {
                ...state,
                isSignupLoading: false,
                signupData: action.data,
                signupStatus: action.status
            };
            break;

        case "SIGNUP_ERROR":
            state = {
                ...state,
                isSignupLoading: false,
                signupStatus: action.status,
                signupData: action.data
            };
            break;

        /*------------ */
        case "OTP_VERIFY_REQUEST":
            state = {
                ...state,
                isOtpVerifyLoading: true,
                otpVerifyStatus: "",
            };
            break;

        case "OTP_VERIFY_SUCCESS":
            state = {
                ...state,
                isOtpVerifyLoading: false,
                otpVerifyStatus: action.data
            };
            break;

        case "OTP_VERIFY_ERROR":
            state = {
                ...state,
                isOtpVerifyLoading: false,
                otpVerifyStatus: action.data
            };
            break;

        case "CLEAR_INVITE":
            state = {
                ...state,
                isInviteSending: false,
                inviteMsg: "",
                statusMsg: "",
                forgetMsg: "",
                resendMsg: "",
                updateExpiryMsg: "",
                unlinkMsg: "",
                updateMsg: "",
                permissionStatus: "",
                optStatus: "",
            };
            break;

        case "CLEAR_LOGIN_DATA":
            state = {
                ...state,
                loginData: {},
                loginMsg: "",
                isSignupLoading: false,
                signupStatus: "",
                signupData: {},
                isOtpVerifyLoading: false,
                otpVerifyStatus: ""
            };
            break;
        case "CLEAR_OTP_MESSAGE":
            state = {
                ...state,
                otpVerifyStatus: ""
            };
            break;

        default:

    }
    return state;
};

export default reducer;