import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
const NoData = (props) => {
    const navigate = useNavigate();
    return (
        <div className="new-fundraising">
            <div className="new-fundraising_child">
                {props.isSearch ?
                    <p className="mt8 title">No search result found, try another search</p> : <Button
                        type="primary"
                        size="large"
                        className="br30 mt5 new-btn"
                        htmlType="submit"
                        onClick={() => props.addNewOrg()}
                    >
                        New Organization
                    </Button>}
            </div>
        </div>
    );
};
export default NoData;
