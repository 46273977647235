import React from "react";
import { Modal, Button, Spin } from 'antd';
import Iframe from 'react-iframe';
const PrivacyPolicy = ({ showModal, onCancel, type = "rules" }) => {
    return (
        <Modal
            className="rule-modal-parent"
            footer={false}
            visible={showModal}
            onCancel={() => onCancel(false)}
            width={1000}
            style={{
                top: 20,
            }}
            maskClosable={true}
        // title={type}
        >
            <div className="success-modal-child rules-regulations">
                <div className="mt0 mb10">
                    <Iframe
                        url={`${type === "privacy" ?
                         "https://app.termly.io/document/privacy-policy/5ae1c657-03bb-4050-a21a-8977586a0fb4" : "https://app.termly.io/document/terms-of-use-for-website/17a3a351-468a-42d9-a181-f24076dd08b4"}`}
                        width="100%"
                        className="iframe"
                        id=""
                        display="block"
                        position="relative"
                        frameBorder={0}
                    />

                </div>
            </div>
        </Modal>
    );
};
export default PrivacyPolicy;
