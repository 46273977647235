import React from "react";
import { Row, Card, Button, Layout, Col } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
const { Footer } = Layout;

const NextPrevButton = (props) => {
    const { children, handleNext, handleBack, isLoading = false, isDisabled = false, step = 0, nextBtnText = "" } = props;
    return (
        <>
            <div className="top-footer">
                <div className="item-inline">
                    <div>
                        <Button
                            onClick={() => handleBack()}
                            type="link"
                            className="btn-back"
                            disabled={step === 1}
                        ><ArrowLeftOutlined />Back</Button>
                    </div>
                    <div>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="btn-next"
                            loading={isLoading}
                            disabled={isDisabled}
                            onClick={() => handleNext()}
                        >
                            {nextBtnText !== "" ? nextBtnText : 'Next'}    &nbsp; <span><ArrowRightOutlined /></span>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};
export default NextPrevButton;
