/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Row, Col, Input, Table, Space, Tooltip, Switch } from "antd";
import { DownloadOutlined, EditFilled, DeleteFilled, EyeOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import OrganizationModal from "../../common/organizationModal";
import SwitchToggle from "../../common/SwitchToggle";
import { CSVLink } from "react-csv";
import { isAuthorized } from "../../../utils/functions";

const { Search } = Input;
const { Column } = Table;

let filterParams = { search: "" };
const AdminOrganizations = (props) => {

    const dispatch = useDispatch();
    const { orgList, isOrgListLoading, isToggleLoading, toggleStatus, isExportLoading, exportData } = useSelector(state => state.admin);
    const { isOrganizationCreateLoading, updateMsg } = useSelector(state => state.fundraiser);
    const { isDeleteLoading, deleteMsg, provinceList } = useSelector(state => state.common);
    const [tableData, setTableData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [rowData, setRowData] = useState("");
    const [modalType, setModalType] = useState("");
    const [tmpExportData, setTmpExportData] = useState("");
    const [exportLoading, setIsexportLoading] = useState(isExportLoading);

    useEffect(() => {
        getAllOrganization({});
    }, []);

    useEffect(() => {
        if (!isOrgListLoading && orgList?.length) {
            let tmpOrgList = [];
            orgList.map((item) => {
                let tmpObj = item;
                tmpObj.admin_name = `${item?.user?.first_name} ${item?.user?.last_name}`;
                tmpObj.city_province = `${item?.city} ${item?.province?.name}`;
                tmpOrgList.push(tmpObj);
                return 1;
            })
            console.log(orgList);
            setTableData(orgList);
        }
    }, [isOrgListLoading, orgList]);

    const getAllOrganization = (params = {}, isSearch = false) => {
        let tmpParams = { ...params };
        tmpParams.per_page = 5000;
        dispatch({ type: 'ADMIN_ORGANIZATION_LIST_REQUEST', formData: tmpParams });
    }

    useEffect(() => {
        if (provinceList.length === 0) {
            dispatch({ type: 'PROVINCE_LIST_REQUEST', formData: { per_page: 5000 } });
        }
    }, [])

    const openModal = (record, type) => {
        setVisible(true);
        if (type === "view") {
            setRowData("");
            dispatch({ type: "GET_ORGANIZATION_DETAIL_REQUEST", orgId: record.id });
        } else {
            setRowData(record);
        }
        setModalType(type);
    }

    useEffect(() => {
        if (!isOrganizationCreateLoading && updateMsg === "success") {
            dispatch({ type: "CLEAR_FUNDRAISER_STATUS" });
            getAllOrganization({});
            setVisible(false);
            setModalType("");
        }
    }, [isOrganizationCreateLoading, updateMsg])

    useEffect(() => {
        if (!isDeleteLoading && deleteMsg === "success") {
            dispatch({ type: "CLEAR_COMMON" });
            getAllOrganization({});
            setVisible(false);
            setModalType("");
        }
    }, [isDeleteLoading, deleteMsg]);

    const updateStatus = (orgId) => {
        dispatch({ type: "TOGGLE_REQUEST", id: orgId, toggleFor: 'organization' })
    }

    useEffect(() => {
        if (!isToggleLoading && toggleStatus === "success") {
            dispatch({ type: "TOGGLE_CLEAR" })
        }
    }, [isToggleLoading, toggleStatus]);

    const onChange = (e, type) => {
        if (type === "search") {
            filterParams[type] = e;
        }
        getAllOrganization(filterParams)
    }

    const exportFundraiser = () => {
        setIsexportLoading(true);
        dispatch({ type: 'EXPORT_REQUEST', formData: filterParams, exportFor: 'organization' });
    }

    useEffect(() => {
        if (!isExportLoading && exportData !== "") {
            setTmpExportData(exportData);
            setTimeout(() => {
                dispatch({ type: "ADMIN_DATA_CLEAR" });
                setIsexportLoading(isExportLoading);
                document.getElementById('exportToCSVLink').click();
            }, 1000);
        }
    }, [isExportLoading, exportData]);

    return (
        <div>
            <div className="sub-header">
                <p className="mb0 title">
                    Organizations
                </p>
            </div>
            <div className="filter mt8">
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Search
                            placeholder="Search"
                            style={{ width: "60%" }}
                            enterButton
                            onSearch={(e) => onChange(e, 'search')}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fr text-right">

                        <Button
                            type="primary"
                            size="large"
                            className="br1"
                            htmlType="submit"
                            loading={exportLoading}
                            disabled={exportLoading}
                            onClick={() => exportFundraiser()}
                            icon={<DownloadOutlined />}
                        >
                            Export
                        </Button>
                        <CSVLink id="exportToCSVLink" filename="Organizations.csv" data={tmpExportData} />
                    </Col>
                </Row>
            </div>
            <Row className="mt5">
                <Table
                    style={{ width: "100%" }}
                    dataSource={tableData}
                    loading={isOrgListLoading}
                    pagination={true}
                    scroll={{ x: "max-content" }}
                >

                    <Column
                        title="Name"
                        dataIndex="name"
                        key="name"
                        width={"200px"}
                    />
                    <Column
                        title="Fundraising admin"
                        dataIndex="admin_name"
                        key="admin_name"
                        width={"150px"}
                    />
                    <Column
                        title="Email address"
                        dataIndex="email"
                        key="email"
                        width={"150px"}
                    />
                    <Column
                        title="Phone"
                        dataIndex="phone"
                        key="phone"
                        width={"120px"}
                    />
                    <Column
                        title="City & Province"
                        dataIndex="city_province"
                        key="city_province"
                        width={"100px"}
                    />
                    <Column
                        title="Fund. count"
                        dataIndex="fundraisers_count"
                        key="fundraisers_count"
                        width={"70px"}
                    />
                    <Column
                        title="Unpaid fund."
                        dataIndex="unpaid_fundraisers_count"
                        key="unpaid_fundraisers_count"
                        width={"70px"}
                    />
                    <Column
                        title="Status"
                        dataIndex="status"
                        key="status"
                        width={"100px"}
                        render={(_, record) => {
                            let status = record.status === "active" ? true : false;
                            return (
                                <SwitchToggle
                                    record={record}
                                    status={status}
                                    onChange={(id) => updateStatus(id)}
                                    loading={isToggleLoading}
                                    isDisabled={!isAuthorized('update_organizations')}
                                />
                            )
                        }}
                    />
                    <Column
                        title={"Actions"}
                        key='actions'
                        width={"70px"}
                        render={(_, record) => {
                            return (
                                <Space size="middle" className={`cursor ${record.isDisabled ? 'disabled-edit' : 'not-disabled'}`}>
                                    {isAuthorized('view_organizations') &&
                                        <Tooltip placement="top" title={"View Detail"}>
                                            <EyeOutlined
                                                onClick={() => openModal(record, 'view')}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }
                                    {isAuthorized('update_organizations') &&
                                        <Tooltip placement="top" title={"Edit"} >
                                            <EditFilled
                                                onClick={() => openModal(record, 'edit')}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }
                                    {isAuthorized('delete_organizations') &&
                                        <Tooltip placement="top" color={"red"} title={"Delete"}>
                                            <DeleteFilled
                                                onClick={() => openModal(record, 'delete')}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }
                                </Space>
                            )
                        }}
                    />
                </Table>
            </Row>

            <OrganizationModal
                visible={visible}
                rowData={rowData}
                modalType={modalType}
                setVisible={() => {
                    setRowData("");
                    setVisible(false);
                }}
            />
        </div>
    );
};
export default AdminOrganizations;