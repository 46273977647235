import React, { useState } from "react";
import { Upload, Button } from "antd";
import noImage from "../../../src/assets/noimage.png";
import { useDispatch } from 'react-redux';

const SingleFileUpload = ({  setfileName = {} }) => {

    const dispatch = useDispatch();

    const handleFileChange = (info) => {

        if (info.file.status === "uploading") {
            return;
        }
        if (info.file.status === "done") {
            console.log(info.file);
            setfileName(info.file.name);
            const formData = new FormData();
            formData.append("document", info.file.originFileObj);
            dispatch({ type: 'UPLOAD_NEW_DOCUMENT_REQUEST', formData: formData });
        }
    };

    return (
        <>
            <div className="project-building-image-parent">
                <Upload
                    name="avatar"
                    className="avatar-uploader"
                    onChange={handleFileChange}
                    showUploadList={true}
                    maxCount={1}
                    listType="picture"
                    onRemove={() => setfileName("")}
                    customRequest={({ file, onSuccess }) =>
                        setTimeout(() => onSuccess("ok"), 0)
                    }
                >
                    <Button>Choose File</Button>
                </Upload>
            </div>
        </>
    );
};

export default SingleFileUpload;
