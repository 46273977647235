/* eslint-disable react-hooks/exhaustive-deps */
import { Switch } from 'antd';
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

const SwitchToggle = (props) => {
    const { isToggleLoading, toggleStatus } = useSelector(state => state.admin);
    const { isDisabled = false } = props;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!isToggleLoading && toggleStatus === "success") {
            setLoading(false)
        }
    }, [isToggleLoading, toggleStatus])
    return (
        <Switch
            checkedChildren={`Active`}
            unCheckedChildren="Archived"
            defaultChecked={props.status}
            onChange={() => {
                setLoading(true);
                props.onChange(props.record.id)
            }}
            disabled={isDisabled}
            loading={loading}
        />
    );
};

export default SwitchToggle;