import { combineReducers } from 'redux';
import auth from './auth';
import campgaigns from './campaigns';
import fundraiser from './fundraiser';
import common from './common';
import step5 from './step5';
import ticket from './ticket';
import admin from './admin';
import player from './player';

export default combineReducers({
    auth,
    campgaigns,
    fundraiser,
    common,
    step5,
    ticket,
    admin,
    player
})