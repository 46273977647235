/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Table, Avatar, Space, Tag } from "antd";
import { showAvtarOrgName } from '../../../utils/functions'
import { useSelector, useDispatch } from 'react-redux';

const { Column } = Table;

const LoadOrganizations = (props) => {
    const { userId } = props;
    const dispatch = useDispatch();

    const [tableData, setTableData] = useState([]);

    const { isFOrganizationLoading, fundraiserOrganizationList } = useSelector(state => state.fundraiser);

    useEffect(() => {
        getAllOrganization({}, false);
    }, [userId]);

    const getAllOrganization = (params = {}) => {
        let tmpParams = { ...params };
        tmpParams.per_page = 5000;
        dispatch({ type: 'FUNDRAISER_ORGANIZATION_LIST_REQUEST', formData: tmpParams, userId: userId });
    }

    useEffect(() => {
        let tmpOrgList = [];
        if (!isFOrganizationLoading && fundraiserOrganizationList.length > 0) {
            fundraiserOrganizationList.map((item) => {
                let tmpObj = item;
                console.log(item);
                tmpObj.city_province = `${item?.city} ${item?.province?.name}`;
                tmpOrgList.push(tmpObj);
                return 1;
            })
            setTableData(tmpOrgList);
        } else if (!isFOrganizationLoading && fundraiserOrganizationList.length === 0) {
            setTableData([]);
        }
    }, [isFOrganizationLoading, fundraiserOrganizationList]);



    return (
        <div>

            <Row className="mt5">
                <Table
                    style={{ width: "100%" }}
                    dataSource={tableData}
                    loading={isFOrganizationLoading}
                    pagination={true}
                    scroll={{ x: "max-content" }}
                >

                    <Column
                        title="Image"
                        dataIndex="image"
                        key="image"
                        width={"100px"}
                        render={(text, record) => {
                            return (
                                <>
                                    {(text !== null || text !== "") &&
                                        <Avatar size="large" width={40} className='pointer' >{showAvtarOrgName(record?.name || "")}</Avatar>
                                    }
                                    {(text === null && text === "") &&
                                        <img src={text} width={40} className="icon" alt="icon" />
                                    }
                                </>
                            )
                        }}
                    />
                    <Column
                        title="Name"
                        dataIndex="name"
                        key="name"
                        width={"200px"}
                    />
                    <Column
                        title="Email address"
                        dataIndex="email"
                        key="email"
                        width={"150px"}
                    />
                    <Column
                        title="Phone"
                        dataIndex="phone"
                        key="phone"
                        width={"120px"}
                    />
                    <Column
                        title="Fund. count"
                        dataIndex="fundraisers_count"
                        key="fundraisers_count"
                        width={"70px"}
                    />
                    <Column
                        title="Unpaid fund."
                        dataIndex="unpaid_fundraisers_count"
                        key="unpaid_fundraisers_count"
                        width={"70px"}
                    />
                    <Column
                        title="Status"
                        dataIndex="status"
                        key="status"
                        width={"100px"}
                        render={(_, { status }) => {
                            let color = status === "active" ? 'green' : 'red';
                            return (
                                <Space size="middle">
                                    <Tag color={color} key={status}>
                                        {status === "active" ? 'Active' : 'Archived'}
                                    </Tag>
                                </Space>)
                        }}
                    />
                </Table>
            </Row>


        </div>
    );
};
export default LoadOrganizations;