/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form } from 'antd';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

const ConfirmationModal = (props) => {
    const { visible, setVisible, deactivateKey } = props;

    const { isDeactivateLoading } = useSelector(state => state.ticket);

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const handleSubmit = () => {
        dispatch({ type: 'DEACTIVATE_TICKET_REQUEST', id: deactivateKey.id, actionType: deactivateKey.type });
    }

    return (
        <Modal
            className={`prize-modal step4-modal org-modal`}
            title={`Are you sure?`}
            open={visible}
            onOk={() => handleSubmit()}
            onCancel={() => {
                dispatch({ type: "CLEAR_COMMON" });
                setVisible(false);
                form.resetFields();
            }}
            centered
            width={550}
            okText={`Confirm`}
            okButtonProps={{ disabled: isDeactivateLoading, loading: isDeactivateLoading }}
            cancelText="Cancel"

        >
            <div className="mt2">
                <p>You want to {deactivateKey.type === "deactive" ? "Block" : "Activate"} this ticket?</p>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;