import { role, roleName } from "./functions";

export const API_URL = process.env.REACT_APP_API_URL; // API base URL
// export const API_URL = "https://dev-api.streamline.thedemo.co/api/"; // API base URL
export const BASE_URL = process.env.REACT_APP_API_BASE_URL; // API base URL
export const STRIPE_KEY = process.env.REACT_APP_API_STRIPE_KEY || "pk_test_51IOh7PFXrwOaWJuNroGz2LOrdI75YGMtNgOfCymxcqlgSXnm2UB9qMTueqazHDRgzRqwqrl6NMFekMBRcXdLWqMa0075vLArgA"; // Stripe Public Key

export const PER_PAGE = 20; // API base URL

export const VERSION_NUMBER = process.env.REACT_APP_VERSION || "0.0.0.1"

export const CURRENT_ROLE = role();
export const CURRENT_ROLE_NAME = roleName();
export const STRIPE_CLIENT_ID = process.env.REACT_APP_STRIPE_CLIENT_ID || "AdGdRev3weoim4IhDuH4SUDignY_pkH9oyOWvCcqdIGEbTQcuEeG9iflzaOUVXfbjWDJqEISUCUGWvwh";
export const STRIPE_SECRET_ID = process.env.REACT_APP_STRIPE_SECRET_ID || "EDEt7gtRUEi796er_0c74eLwoLyb3eDWCe3JWjCmOeqPEwIjG2os15MHR1e-wEr-KqLhYPxefzy9yUNb";

export const SALT_KEY = 'ownkey';

export const ROLE_NAME = {
    "ADMIN": "streamline_admin",
    "FUNDRAISER": "fundraising_admin",
}

export const FUNDRAISER_UPDATE_STATUS = [
    {
        title: "Approved",
        key: "fundraiser_approved",
        type: 'fundraiser'
    },
    {
        title: "Declined",
        key: "fundraiser_declined",
        type: 'fundraiser'
    },
    {
        title: "Ticket design approved",
        key: "ticket_approved",
        type: 'ticket'
    },
    {
        title: "Ticket design printed",
        key: "ticket_printed",
        type: 'ticket'
    },
    {
        title: "Ticket shipped",
        key: "ticket_shipped",
        type: 'ticket'
    },
    {
        title: "Start Fundraiser",
        key: "fundraiser_started",
        type: 'fundraiser'
    },
    {
        title: "Close Fundraiser",
        key: "fundraiser_closed",
        type: 'fundraiser'
    },
    {
        title: "Prizes shipped",
        key: "prizes_shipped",
        type: 'fundraiser'
    },



];

export const FUNDRAISER_E_TRANSFER_STATUS = [
    {
        title: "Approved",
        key: "confirmed",
        color: 'orange',
    },
    {
        title: "Pending",
        key: "pending",
        color: 'orange',
    },
    {
        title: "Rejected",
        key: "rejected",
        color: 'orange',
    },
]

export const FUNDRAISER_STATUS = [
    {
        title: "Pending",
        key: "fundraiser_pending",
        color: 'orange',
    },
    {
        title: "Approved",
        key: "fundraiser_approved",
        color: 'green',
    },
    {
        title: "Ticket Approved",
        key: "ticket_approved",
        color: 'orange',
    },
    {
        title: "Ticket Printed",
        key: "ticket_printed",
        color: 'orange',
    },
    {
        title: "Ticket Shipped",
        key: "ticket_shipped",
        color: 'orange',
    },
    {
        title: "Fundraiser Started",
        key: "fundraiser_started",
        color: '#87d068',
    },
    {
        title: "Fundraiser Closed",
        key: "fundraiser_closed",
        color: '#87d068',
    },
    {
        title: "Prizes Shipped",
        key: "prizes_shipped",
        color: '#108ee9',
    },
    {
        title: "Declined",
        key: "fundraiser_declined",
        color: 'red',
    },
    {
        title: "All",
        key: 'all',
        color: 'yellow'
    }
];

export const E_TRANSFER_STATUS_COLOR = [
    {
        key: "confirmed",
        color: 'green',

    },
    {
        key: "rejected",
        color: 'red',
    }
]

export const FUNDRAISER_STATUS_COLOR = [
    {
        key: "fundraiser_pending",
        color: 'orange',
    },
    {
        key: "fundraiser_approved",
        color: 'green',
    },
    {
        key: "fundraiser_completed",
        color: '#87d068',
    },
    {
        key: "fundraiser_declined",
        color: 'red',
    },
    {
        key: 'all',
        color: 'yellow'
    },
    {
        key: 'fundraiser_created',
        color: 'orange',
    },
    {
        key: 'course_order_picked',
        color: 'yellow'
    },
    {
        key: "ticket_approved",
        color: 'orange',
    },
    {
        key: "ticket_printed",
        color: 'orange',
    },
    {
        key: "ticket_shipped",
        color: 'orange',
    },
    {
        key: "fundraiser_started",
        color: '#87d068',
    },
    {
        key: "fundraiser_closed",
        color: '#f50',
    },
    {
        key: "prizes_shipped",
        color: '#108ee9',
    }
];

export const GAME_STATUS = [
    {
        title: "Upcoming",
        key: "upcoming",
        status: "upcoming",
        color: 'orange',
    },
    {
        title: "Completed",
        key: "completed",
        status: "completed",
        color: '#87d068',
    },
    {
        title: "Active",
        key: "active",
        status: "active",
        color: 'green',
    },
    {
        title: "All",
        key: 'all',
        color: 'yellow',
        status: "all",
    }
];

export const GAME_DETAIL_TABS = [
    {
        title: "Fundraiser details",
        key: "fundraiserDetail",
        status: "fundraiserDetail"
    },
    {
        title: "Players ranking",
        key: "playersRanking",
        status: "playersRanking"
    },
    {
        title: "Winner prizes",
        key: "winnerPrizes",
        status: "winnerPrizes"
    },

];

export const TICKET_DETAIL_TAG = [
    {
        title: "Print tickets",
        key: "printTickets",
        status: ""
    },
    {
        title: "Lost/Stolen",
        key: "lostStolen",
        status: "stolen"
    },
    {
        title: "Not Played",
        key: "printTickets",
        status: "not_played"
    },
    {
        title: "Currently Playing",
        key: "printTickets",
        status: "currently_playing"
    },
    {
        title: "Blocked",
        key: "printTickets",
        status: "blocked"
    },
    {
        title: "Ready to Play",
        key: "printTickets",
        status: "ready_to_play"
    },
    {
        title: "Played",
        key: "printTickets",
        status: "played"
    }
];


export const UPDATE_STATUS_REASON = [
    {
        title: "Doesn't meet vetting criteria",
        key: "Doesn't meet vetting criteria"
    },
    {
        title: "Policy violation",
        key: "Policy violation"
    },
    {
        title: "Other",
        key: "other"
    }
];

export const STEP_NAME = [
    {
        step: 1,
        stepTitle: "Select Type",
        nextStepTitle: "Next: Pick Package",
    },
    {
        step: 2,
        stepTitle: "Pick a package",
        nextStepTitle: "Next: Organization details"
    },
    {
        step: 3,
        stepTitle: "Pick Organization",
        nextStepTitle: "Next: Fundraiser details"
    },
    {
        step: 4,
        stepTitle: "Fundraiser details",
        nextStepTitle: "Next: Select prizes"
    },
    {
        step: 5,
        stepTitle: "Select Prizes",
        nextStepTitle: "Next: Ticket details"
    },
    {
        step: 6,
        stepTitle: "Ticket details",
        nextStepTitle: "Next: Payment details"
    },
    {
        step: 7,
        stepTitle: "Payment details",
        nextStepTitle: "Next: Confirm Details"
    },
    {
        step: 8,
        stepTitle: "Confirm Details",
        nextStepTitle: ""
    },
]

export const PREVIEW_TITLE = [
    {
        id: 1,
        previewTitle: 'Fundraiser Details',
        stepId: 4
    },
    {
        id: 2,
        previewTitle: 'Package Selected',
        stepId: 2
    },
    {
        id: 3,
        previewTitle: 'Organization Details',
        stepId: 3
    },
    {
        id: 4,
        previewTitle: 'Prizes',
        stepId: 5
    },
    {
        id: 5,
        previewTitle: 'Ticket Information',
        stepId: 6
    },
    {
        id: 6,
        previewTitle: 'Payment Information',
        stepId: 7
    },

]

export const PREVIEW_ADMIN_VIEW = [
    {
        id: 1,
        previewTitle: 'Fundraiser Details',
        stepId: 3
    },
    {
        id: 4,
        previewTitle: 'Ticket Information',
        stepId: 6
    },
    {
        id: 2,
        previewTitle: 'Payment Information',
        stepId: 7
    },
    {
        id: 3,
        previewTitle: 'Prizes',
        stepId: 5
    },

]

export const PRIZE_LIST = [
    {
        id: 1,
        title: '1st Place Winner',
        validate_title: '1ST PLACE',
        subTitle: ""
    },
    {
        id: 2,
        title: '2nd Place Winner',
        validate_title: '2ND PLACE',
    },
    {
        id: 3,
        title: '3rd Place Winner',
        validate_title: '3RD PLACE',
    }
]


export const PAYMENT_STATUS = {
    "PENDING": "pending",
    "FULLY_PAIED": "fully_paid",
    "PARTAILY_PAIED": "partially_paid",
}

export const PAYMENT_STATUS_DISPLAY = {
    "pending": "Pending",
    "fully_paid": "Fully Paid",
    "partially_paid": "PARTAILY PAIED",
}

export const FUNDRAISER_TABS = [
    {
        id: 1,
        title: "DETAILS"
    },
    {
        id: 2,
        title: "BILLING"
    },
    {
        id: 3,
        title: "FAIRWAY SELECTION",
        key: "course"
    },
    {
        id: 4,
        title: "TICKETS",
        key: "ticket"
    }
]

export const FUNDRAISER_TRAKING = [
    {
        id: 1,
        title: "Fundraiser created",
    },
    {
        id: 2,
        title: "Admin Approved"
    },
    {
        id: 3,
        title: "Ticket design approved"
    },
    {
        id: 4,
        title: "Ticket design printed"
    },
    {
        id: 5,
        title: "Tickets shipped"
    },
    {
        id: 6,
        title: "Fundraiser started"
    },
    {
        id: 7,
        title: "Fundraiser closed"
    },
    {
        id: 8,
        title: "Prizes shipped"
    }
]

export const FUNDRAISER_ADMIN_TRAKING = [
    {
        id: 1,
        title: "Fundraiser created",
        key: 'fundraiser_created',
        title2: "Pending",
        requiredForStart: 'yes',
    },
    {
        id: 3,
        title: "Admin approved",
        key: 'fundraiser_approved',
        title2: "Approved",
        requiredForStart: 'yes',
    },
    {
        id: 2,
        title: "Course order selected",
        key: 'course_order_picked',
        title2: "Course Selected",
        requiredForStart: 'yes',
    },
    {
        id: 4,
        title: "Ticket design approved",
        key: "ticket_approved",
        title2: "Ticket Design Approved",
        requiredForStart: 'yes',
    },
    {
        id: 5,
        title: "Ticket design printed",
        key: "ticket_printed",
        title2: "Ticket Design Printed",
        requiredForStart: 'yes',
    },
    {
        id: 6,
        title: "Tickets shipped",
        key: "ticket_shipped",
        title2: "Ticket Shipped",
        requiredForStart: 'yes',
    },
    {
        id: 7,
        title: "Fundraiser started",
        key: "fundraiser_started",
        title2: "Active",
    },
    {
        id: 8,
        title: "Fundraiser closed",
        key: "fundraiser_closed",
        title2: "Closed",
    },
    {
        id: 9,
        title: "Prizes shipped",
        key: "prizes_shipped",
        title2: "Prize Shipped",
    }
]

export const WIND_DIRECTION = [
    {
        id: 1,
        key: 'north',
        title: "North"
    },
    {
        id: 2,
        key: 'south',
        title: "South"
    },
    {
        id: 3,
        key: 'east',
        title: "East"
    },
    {
        id: 4,
        key: 'west',
        title: "West"
    }
]

export const REASSIGN_LIST = [
    {
        id: 1,
        key: 'full_batch',
        title: "All tickets in the batch ({count} Tickets)"
    },
    {
        id: 2,
        key: 'partial_batch',
        title: "A group of tickets in the batch"
    },
    {
        id: 3,
        key: 'list',
        title: "Individual tickets"
    }
];

export const CONTACT_SUPPORT = [
    {
        id: 1,
        key: 'Support',
        title: "Support"
    },
    {
        id: 2,
        key: 'Ticket',
        title: "Ticket"
    },
    {
        id: 3,
        key: 'Course',
        title: "Course"
    },
    {
        id: 4,
        key: 'Other',
        title: "Other"
    }
]

export const WIND_SPEED = [
    {
        id: 1,
        key: '5',
        title: "5"
    },
    {
        id: 2,
        key: '10',
        title: "10"
    },
    {
        id: 3,
        key: '15',
        title: "15"
    },
    {
        id: 4,
        key: '20',
        title: "20"
    }
]

export const HEAR_ABOUT = [
    {
        id: 1,
        title: "Facebook"
    },
    {
        id: 2,
        title: "Instagram"
    },
    {
        id: 3,
        title: "Website"
    },
    {
        id: 4,
        title: "Email"
    },
    {
        id: 5,
        key: '40',
        title: "Other"
    }
]
