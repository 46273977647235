import React from "react";
import { Modal, Button, Tag, Spin } from 'antd';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';

import { useDispatch, useSelector } from "react-redux";
const UnAssignTicketModal = ({ visible, setVisible }) => {
    const { isUnassignLoading, unAssignTicketList, isTicketStateLoading, ticketStats } = useSelector(state => state.ticket);
    console.log("isTicketStateLoading, ticketStats");
    console.log(isTicketStateLoading, ticketStats);
    return (
        <Modal
            className="success-modal-parent"
            footer={false}
            visible={visible}
            onCancel={() => setVisible(false)}
            width={600}
            maskClosable={false}
        >
            <div className="success-modal-child">
                <p className="fs16 bold mb6">List of the Unassigned Tickets</p>
                {isUnassignLoading && <div className="no-data"><Spin></Spin></div>}
                {!isUnassignLoading && unAssignTicketList.length > 0 &&
                    unAssignTicketList.map((item) => {
                        return (<Tag style={{ width: "35px", textAlign: "center", marginBottom: "5px" }}>{item.ticket_number}</Tag>)
                    })
                }
                {Object.keys(ticketStats).length > 0 && ticketStats.tickets_allowed_count !== 0 && !isUnassignLoading && unAssignTicketList.length === 0 &&
                    <p>All tickets are assigned</p>
                }
                {Object.keys(ticketStats).length > 0 && ticketStats.tickets_allowed_count === 0 &&
                    <p>Tickets not generated yet</p>
                }
                <div style={{ clear: "both" }}></div>
                {/* <p className="mb6 text">Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.</p> */}
                <div>
                    <Button
                        type="primary"
                        size="large"
                        className="mt5 br20 pr8 pl8"
                        htmlType="submit"
                        onClick={() => setVisible(false)}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
export default UnAssignTicketModal;
