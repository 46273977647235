import React from "react";
import Iframe from 'react-iframe';
const PrivacyPolicy = (props) => {
    return (
        <div className="mt0 mb10">
            <Iframe url="https://app.termly.io/document/privacy-policy/5ae1c657-03bb-4050-a21a-8977586a0fb4"
                width="100%"
                className="iframe"
                id=""
                display="block"
                position="relative"
                frameBorder={0}
            />

        </div>
    );
};
export default PrivacyPolicy;
