/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input, Row, Col, Spin, Tabs, Select, Button, message } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CURRENT_ROLE_NAME } from '../../../utils/constant';
import { COUNTRIES } from '../../../utils/countries';
import { getUserData } from '../../../utils/functions';
import Password from '../password';

const { Option } = Select;

const FundraiserAdminModal = (props) => {
    const { visible, setVisible } = props;
    const { isDeleteLoading } = useSelector(state => state.common);
    const { isMyDataLoading, myData } = useSelector(state => state.auth);
    const { provinceList, isProvinceLoading, } = useSelector(state => state.common);
    const { isCommonUpdateLoading, updateMsg, isChangePassLoading, changePassMsg } = useSelector(state => state.admin);
    const { isPlayerDetailLoading, playerDetail } = useSelector(state => state.player);

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [pageplayerDetail, setpageplayerDetail] = useState({});
    const [activeTab, setActiveTab] = useState('Details');
    const [rowData, setRowData] = useState({});


    useEffect(() => {
        if (!isPlayerDetailLoading && Object.keys(playerDetail).length > 0) {
            console.log(playerDetail);
            setpageplayerDetail(playerDetail);
        }
        setpageplayerDetail();
    }, [isPlayerDetailLoading, playerDetail])

    useEffect(() => {
        dispatch({ type: "GET_LOGGED_IN_USER_REQUEST" });
    }, []);

    useEffect(() => {
        if (provinceList.length === 0) {
            dispatch({ type: 'PROVINCE_LIST_REQUEST', formData: { per_page: 5000 } });
        }
    }, []);


    useEffect(() => {
        if (!isCommonUpdateLoading && updateMsg === "success") {
            dispatch({ type: "ADMIN_DATA_CLEAR" });
            setVisible(false);
        }
    }, [isCommonUpdateLoading, updateMsg])
    useEffect(() => {
        if (!isChangePassLoading && changePassMsg === "success") {
            message.success("Password Change Successfully");
            dispatch({ type: "ADMIN_DATA_CLEAR" });
            setVisible(false);
        } else if (!isChangePassLoading && changePassMsg === "error") {
            dispatch({ type: "ADMIN_DATA_CLEAR" });
        }
    }, [isCommonUpdateLoading, changePassMsg])


    const onFinish = () => {
        form.validateFields()
            .then((values) => {
                console.log(values)

                if (activeTab === "Change Password") {
                    dispatch({
                        type: 'CHANGE_PASSWORD_REQUEST',
                        formData: values,
                        requestType: 'post',
                    });
                } else {
                    dispatch({
                        type: 'UPDATE_COMMON_REQUEST',
                        formData: values,
                        id: getUserData().id,
                        requestType: 'put',
                        updateFor: 'userType'
                    });
                }



            })
            .catch((errorInfo) => {
                console.log(errorInfo)
            });
    }

    useEffect(() => {
        if (!isMyDataLoading && Object.keys(myData).length > 0) {
            form.setFieldsValue({
                first_name: myData?.first_name || "",
                last_name: myData?.last_name || "",
                email: myData?.email || "",
                phone: myData?.phone || "",
                business_name: myData?.business_name || "",
                province_id: myData?.province_id || "",
            });
        }
    }, [isMyDataLoading, myData]);

    useEffect(() => {
        dispatch({ type: "CLEAR_COMMON" });
    }, []);

    useEffect(() => {
        if (rowData !== "" && Object.keys(rowData).length > 0) {

            //TODO : remove
            setpageplayerDetail(rowData);
        }
    }, [rowData]);


    const renderTabs = () => {
        return (
            <>
                <p
                    className={`mb0 ${'Details' === activeTab ? 'active' : ''}`}
                    onClick={() => setActiveTab('Details')}
                >
                    <span>Details</span>
                </p>
                <p
                    className={`mb0 ${'Change Password' === activeTab ? 'active' : ''}`}
                    onClick={() => setActiveTab('Change Password')}
                >
                    <span>Change Password</span>
                </p>
            </>
        )
    }

    return (
        <Modal
            className={`prize-modal user-modal view-modal}`}
            title={`Profile`}
            open={visible}
            footer={false}
            onCancel={() => {
                dispatch({ type: "CLEAR_COMMON" });
                setVisible(false);
                form.resetFields();
            }}
            width={700}
            maskClosable={true}
        >
            <div className="mt4">
                <div className='tab'>
                    {renderTabs()}
                </div>
                <Tabs activeKey={activeTab} className="mt8 mb8">
                    <Tabs.TabPane key={`Details`} >
                        <div>
                            <Form
                                name="basic"
                                initialValues={{ country_code: "1|CA" }}
                                onFinish={onFinish}
                                form={form}
                                layout="vertical"
                            >
                                <Row gutter={[15]}>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            label="First Name"
                                            name="first_name"
                                            rules={[
                                                {
                                                    pattern: new RegExp(/[A-za-z _]$/),
                                                    message: "Please enter only alphabets characters",
                                                },
                                                { required: true, message: 'Please enter first name' },
                                                { max: 100, message: 'Maximum 100 characters only allowed!' }]
                                            }
                                        >
                                            <Input size="large" type="text" placeholder={"First name"} />
                                        </Form.Item>

                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            label="Last Name"
                                            name="last_name"
                                            rules={[
                                                {
                                                    pattern: new RegExp(/[A-za-z _]$/),
                                                    message: "Please enter only alphabets characters",
                                                },
                                                { required: true, message: 'Please enter last name' },
                                                { max: 100, message: 'Maximum 100 characters only allowed!' }
                                            ]}
                                        >
                                            <Input size="large" type="text" placeholder={"Last name"} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your email address'
                                        },
                                        {
                                            type: 'email',
                                            message: 'Please enter valid email address',
                                        }
                                    ]}
                                >
                                    <Input size="large" placeholder={"Email Address"} autoComplete="new-state" />
                                </Form.Item>

                                {CURRENT_ROLE_NAME !== "streamline_admin" &&
                                    <>
                                        {/* <Row gutter={[15]}>
                                            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                                                <Form.Item
                                                    name="country_code"
                                                >
                                                    <Select
                                                        size="large"
                                                        showSearch
                                                    >
                                                        {COUNTRIES.map((item, index) =>
                                                            <Option value={`${item.code}|${item.iso}`} key={index + item.code}>{`${item.country} + (${item.code})`}</Option>
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                                                <Form.Item
                                                    name="fundraising_admin_phone"
                                                    rules={[
                                                        { required: true, message: "Please enter phone" },
                                                        {
                                                            pattern: new RegExp(/^[0-9]{8,12}$/),
                                                            message: "Please enter valid phone",
                                                        },

                                                    ]}
                                                >
                                                    <Input size="large" placeholder={"Phone"} autoComplete="new-state" />
                                                </Form.Item>
                                            </Col>
                                        </Row> */}
                                        <Form.Item label="Business Name" name="business_name" >
                                            <Input size="large" placeholder={"Business name (optional)"} />
                                        </Form.Item>

                                        <Form.Item
                                            label="Province"
                                            name="province_id"
                                            rules={[{ required: true, message: 'Please select province' }]}
                                        >
                                            <Select
                                                loading={isProvinceLoading}
                                                placeholder="Select Province"
                                                size="large"
                                                showSearch
                                                autoComplete={"off"}
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {/* <Option key={'index'} value={668}>Ontario</Option> */}
                                                {!isProvinceLoading && provinceList.map((item, index) => (
                                                    <Option key={index} value={item.id}>{item.name}</Option>))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </>
                                }
                                <Form.Item className="mt10">
                                    <div className="login-btn-section tc">
                                        <Button
                                            style={{ width: "30%", }}
                                            type="primary"
                                            size="large"
                                            htmlType="submit"
                                            className="login-btn br20"
                                            loading={isCommonUpdateLoading}
                                            disabled={isCommonUpdateLoading}
                                        >
                                            Update Profile
                                        </Button>
                                    </div>
                                </Form.Item>

                            </Form>
                        </div>
                        {/* <p className='mb1'><b>Full Name:</b>&nbsp;&nbsp;{pageplayerDetail?.full_name}</p>
                        <p className='mb1'><b>Email:</b>&nbsp;&nbsp;{pageplayerDetail?.email}</p>
                        <p className='mb3'><b>Phone:</b>&nbsp;&nbsp;{pageplayerDetail?.phone}</p>
                        <p className='mb3'><b>Number of fundraisers participated:</b>&nbsp;&nbsp;{pageplayerDetail?.number_of_fundraisers_participated}</p>

                        <p className='mb1'><b>Created at:</b>&nbsp;&nbsp;{formatDateTime(pageplayerDetail?.created_at)} </p> */}
                        {/* <p className='mb5'><b>Updated at:</b>&nbsp;&nbsp;{formatDateTime(pageplayerDetail?.updated_at)} </p> */}
                    </Tabs.TabPane>
                    <Tabs.TabPane key={"Change Password"} >
                        <div>
                            <Form
                                name="basic"
                                onFinish={onFinish}
                                form={form}
                                layout={"vertical"}
                            >
                                <Form.Item
                                    label="Old Password"
                                    name="old_password"
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your old password',
                                        }
                                    ]}
                                >
                                    <Input.Password placeholder="Old Password" size='large' />
                                </Form.Item>
                                <Password type="reset" isLable={true}/>
                                <Form.Item
                                    label="Confirm Password"
                                    name="password_confirmation"
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password size="large" placeholder="Confirm Password" />
                                </Form.Item>

                                <Row gutter={24} className={"mt5"}>

                                    <Col span={24} className="tc">
                                        <Button
                                            style={{ width: "30%" }}
                                            type="primary"
                                            size="large"
                                            className="login-btn mt4"
                                            htmlType="submit"
                                            loading={isChangePassLoading}
                                            disabled={isChangePassLoading}
                                        >
                                            Change Password
                                        </Button>
                                    </Col>
                                </Row>

                            </Form>
                        </div>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </Modal>
    );
};

export default FundraiserAdminModal;