import React from "react";

const Tabs = ({ item, active, onChange }) => {
    const renderTabs = () => {
        return item.map((data) => {
            return (
                <p
                    key={data.key}
                    className={`mb0 ${data.status === active ? 'active' : ''}`}
                    onClick={() => onChange(data.status, 'type')}
                >
                    <span>{data.title}</span>
                </p>
            )
        })
    }
    return (
        <div className='tab'>
            {renderTabs()}
        </div>
    )
}
export default Tabs;