import React, { useEffect, useState } from "react";
import { Card, Collapse, Space, Row, Col, Spin, Avatar, Tag } from "antd";
import { PRIZE_LIST, PAYMENT_STATUS, PREVIEW_ADMIN_VIEW } from '../../../utils/constant';
import { displayStartEndDate, showAvtarOrgName, displayStatusText, getColor, isImage, formatAmount, getStatusColor, isAllowEditDetail } from '../../../utils/functions';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import otherImg from '../../../assets/other.png';
import PrizeImg from '../../../assets/prize.png';
import { EyeOutlined, DownloadOutlined, WalletOutlined, EditFilled } from '@ant-design/icons';
import FundraisingDetailModal from "../../common/modal/fundraisingDetailModal";
const { Panel } = Collapse;

const DetailView = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { isGetFundraiserLoading, fundraiserData } = useSelector(state => state.fundraiser);
    const { updateMsg, isFundStatusUplading } = useSelector(state => state.admin);
    const [editModal, setEditModal] = useState(false);

    useEffect(() => {
        if (id) {
            dispatch({ type: 'GET_FUNDRAISER_DATA_REQUEST', formData: { id: id } });
        }
    }, []);

    useEffect(() => {
        if (!isFundStatusUplading && updateMsg === "success") {
            if (id) {
                dispatch({ type: 'GET_FUNDRAISER_DATA_REQUEST', formData: { id: id } });
            }
        }
    }, [isFundStatusUplading, updateMsg]);

    const doReferesh = () => {
        if (id) {
            dispatch({ type: 'GET_FUNDRAISER_DATA_REQUEST', formData: { id: id } });
        }
        dispatch({ type: 'FUNDRAISING_PROGRESS_REQUEST', formData: {}, id: id });
    }

    const loadExtra = (item) => {
        if (item.id === 4) {
            return (
                <p className="mb0"
                    onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/admin/campaign/${fundraiserData.id}/ticket`)
                    }}
                >
                    <EyeOutlined />&nbsp;Ticket Management
                </p>
            )
        } else if (item.id === 2) {

            return (

                <p className="mb0"
                    onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/admin/campaign/${fundraiserData.id}/payment`)
                    }}
                >
                    <WalletOutlined />&nbsp;Payment Management
                </p>
            )
        } else if (item.id === 1) {
            if (isAllowEditDetail(props.fundProgress)) {
                return (
                    <p className="mb0"
                        onClick={(e) => {
                            e.stopPropagation();
                            setEditModal(true);
                        }}
                    >
                        <EditFilled />&nbsp;Edit Details
                    </p>
                )
            }

        }
    }
    const displayPreview = () => {
        return (
            <>
                {PREVIEW_ADMIN_VIEW.map((item, index) => {
                    return (
                        <Collapse defaultActiveKey={[index]} style={{ width: "700px" }}>
                            <Panel header={`${item.previewTitle}`}
                                key={index}
                                extra={loadExtra(item)}
                            >
                                {loadStepData(item)}
                            </Panel>
                        </Collapse>
                    )
                })}
            </>
        )
    }

    const displayPrize = (item, item2) => {
        if (item.id === item2.rank) {
            return (<>
                <div className="existing-prize">
                    <img src={item2?.image !== undefined && item2?.image !== null ? item2.image : PrizeImg} alt="prize" />
                    <div>
                        <p className="mb2 text">{item2.name}</p>
                        <p className="sub-text">{item2.description}</p>
                    </div>
                </div>
            </>)
        } else {
            return null;
        }
    }

    const loadStepData = (item) => {
        if (item.id === 1) { // fundraiser details
            return (
                <Row gutter={[0, 8]} >
                    <Col span={8} className="display-text"><p>Fundraising Status</p></Col>
                    <Col span={16} className="display-subtext">
                        <p>
                            <Tag color={(getStatusColor(fundraiserData?.status))} key={fundraiserData?.status}>
                                {displayStatusText(fundraiserData?.status || "")}
                            </Tag>
                        </p>
                    </Col>

                    <Col span={8} className="display-text"><p>Fundraising Administrator</p></Col>
                    <Col span={16} className="display-subtext"><p><p>{`${fundraiserData?.user?.first_name} ${fundraiserData?.user?.last_name}` || ""}</p></p></Col>

                    <Col span={8} className="display-text"><p>Fundraising Type</p></Col>
                    <Col span={16} className="display-subtext"><p>{fundraiserData?.fundraiser_type?.name || ""}</p></Col>

                    <Col span={8} className="display-text"><p>Campaign Title</p></Col>
                    <Col span={16} className="display-subtext"><p><p>{fundraiserData?.title || ""}</p></p></Col>

                    <Col span={8} className="display-text"><p>Campaign Description</p></Col>
                    <Col span={16} className="display-subtext"><p><p>{fundraiserData?.description || ""}</p></p></Col>

                    <Col span={8} className="display-text"><p>Number of Estimated Sellers</p></Col>
                    <Col span={16} className="display-subtext"><p><p>{fundraiserData?.no_of_sellers || ""}</p></p></Col>

                    <Col span={8} className="display-text"><p>Start / End Date</p></Col>
                    <Col span={16} className="display-subtext"><p>{displayStartEndDate(fundraiserData)}</p></Col>

                    {/* {fundraiserData?.fundraiser_comments && fundraiserData?.fundraiser_comments.length > 0 &&
                        <>
                            <Col span={8} className="display-text"><p>Fundraiser Comment</p></Col>
                            <Col span={16} className="display-subtext">
                                {fundraiserData.fundraiser_comments.map((item, index) =>
                                    <p key={`comment_${index}`} className={`${fundraiserData.fundraiser_documents.length - 1 !== index ? 'mb0' : ''}`}>
                                        {item.comment || "Not Comments"}
                                    </p>
                                )}
                            </Col>
                        </>
                    }
                    {fundraiserData?.fundraiser_documents && fundraiserData?.fundraiser_documents.length > 0 &&
                        <>
                            <Col span={8} className="display-text"><p>Fundraiser Document</p></Col>
                            <Col span={16} className="display-subtext">
                                {fundraiserData.fundraiser_documents.map((item, index) =>
                                    <p key={`document_${index}`} className={`${fundraiserData.fundraiser_documents.length - 1 !== index ? 'mb0' : ''}`}>
                                        <span className="link" onClick={() => {
                                            window.open(item.document_url, '_blank');
                                        }}>{item.document_title}&nbsp;&nbsp;
                                            {isImage(item.document_url) &&
                                                <EyeOutlined
                                                    onClick={() => window.open(item.document_url, '_blank')}
                                                    className={`pointer cursor`}
                                                    style={{ fontSize: "16px" }}
                                                />
                                            }
                                            {!isImage(item.document_url) &&
                                                <DownloadOutlined
                                                    onClick={() => window.open(item.document_url, '_blank')}
                                                    className={`pointer cursor`}
                                                    style={{ fontSize: "16px" }}
                                                />
                                            }
                                        </span>
                                    </p>
                                )}
                            </Col>
                        </>
                    } */}


                    <Col span={8} className="display-text title-case"><p>Progress frequency</p></Col>
                    <Col span={16} className="display-subtext title-case"><p>{fundraiserData?.updates_frequency || "Not selected"}</p></Col>

                    <Col span={8} className="display-text"><p>Organization</p></Col>
                    <Col span={16} className="display-subtext">
                        <div className="preview-org pointer mb5" key={item.id} >
                            {(fundraiserData.organization !== null) ?
                                <>
                                    <div className="box1">
                                        {(fundraiserData?.organization.image === null || fundraiserData?.organization.image === "") &&
                                            <Avatar size="large" width={40} className='pointer' >{showAvtarOrgName(fundraiserData?.organization?.name || "")}</Avatar>
                                        }
                                        {(fundraiserData?.organization.image !== null && fundraiserData?.organization.image !== "") &&
                                            <img src={fundraiserData?.organization.image} width={40} className="icon" alt="icon" />
                                        }
                                    </div>
                                    <div className="box2">
                                        <p className="mb0 black fs14 bold">{fundraiserData?.organization?.name || ""}</p>
                                        <p className="mb0 sub-text"> {fundraiserData?.organization?.phone} {`${fundraiserData?.organization?.email ? ` • ` : ''}`} {fundraiserData?.organization.email}</p>
                                    </div>
                                </>
                                :
                                <div className="preview-org step4">
                                    <div className="box1">
                                        <img src={otherImg} width={40} className="icon" alt="icon" />
                                    </div>
                                    <div className="box2">
                                        <p className="mb0 text">Not affiliated with any organization</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </Col>

                    <Col span={8} className="display-text"><p>Package Selected</p></Col>
                    <Col span={16} className="display-subtext">
                        <Card style={{ width: 700 }} className={`mb4 main-card pointer maxw100`} >
                            <div className="camp-card step2">
                                <div className="">
                                    {/* <p className="mb1 black fs16">{fundraiserData?.fundraiser_package?.name}</p> */}
                                    <p className="mb1 black fs16">Raise up to {formatAmount(fundraiserData?.fundraiser_package?.amount_to_be_generated || 0)}</p>
                                    <p className="mb0 sub-text">{fundraiserData?.fundraiser_package?.no_tickets_allowed} tickets typically  {fundraiserData?.fundraiser_package?.no_sellers_allowed} sellers</p>
                                </div>
                                <div className="section2">
                                    <p className="mb0 amount">${fundraiserData?.fundraiser_package?.regular_fees}</p>
                                </div>
                            </div>
                        </Card>
                    </Col>

                </Row>

            )
        } else if (item.id === 2) {
            return (
                <>
                    <div className="preview-payment-card">
                        <div className="">
                            <p className="mb0 text">
                                {PAYMENT_STATUS.FULLY_PAIED === fundraiserData.payment_status ? "Full Payment" : "Partial Payment"} -
                                {PAYMENT_STATUS.FULLY_PAIED === fundraiserData.payment_status ? "$" + fundraiserData.fundraiser_package?.regular_fees : "$" + fundraiserData.fundraiser_package?.reduced_fees}</p>
                            <p className="mb0 sub-text">{fundraiserData.fundraiser_package?.description}</p>

                        </div>
                        <div className="">
                            {fundraiserData.paid_status === "unpaid" &&
                                <Tag color={"red"} className={"status-success"}>Pending</Tag>
                            }
                            {fundraiserData.paid_status === "paid" &&
                                <Tag color={"green"} className={"status-success"}>Paid</Tag>
                            }
                        </div>
                    </div>
                </>
            )
        } else if (item.id === 3) { // Prize
            return (<div>
                {isGetFundraiserLoading && <Spin />}
                {!isGetFundraiserLoading && PRIZE_LIST.map((item) => {
                    return (
                        <Card style={{ width: 700 }} className="mb4 prize-card maxw100">
                            <div className="prize-card-child step5">
                                <div className="flex-center">
                                    <p className="mb1 text black">{item.title}</p>

                                </div>
                                {fundraiserData.prizes.length > 0 && fundraiserData.prizes.map((item2, index) => {
                                    return displayPrize(item, item2, index);
                                })
                                }
                            </div>
                        </Card>
                    )
                })}
            </div>)
        } else if (item.id === 4) {
            return (
                <Row >
                    <Col span={8} className="display-text"><p>Ticket Title</p></Col>
                    <Col span={16} className="display-subtext"><p>{fundraiserData?.ticket?.title || ""}</p></Col>

                    <Col span={8} className="display-subtext">
                        <p className="mb0"><b>Shipping</b></p>
                        <p className="mb0 ml3"><b>{`${fundraiserData?.user?.first_name} ${fundraiserData?.user?.last_name}` || ""}</b><br></br>
                            {fundraiserData?.ticket?.suite_apt_no_unit || ""} {fundraiserData?.ticket?.shipping_address || ""}<br></br>
                            {fundraiserData?.ticket?.city || ""} {fundraiserData?.ticket?.province?.name || ""}<br></br>
                            {fundraiserData?.ticket?.postal_code || ""}</p>
                    </Col>

                </Row>

            )
        }
    }

    return (
        <>

            <div className="content-container">
                {isGetFundraiserLoading && <Spin style={{ textAlign: "center", width: "100%" }} />}
                {!isGetFundraiserLoading && Object.keys(fundraiserData).length > 0 &&
                    <Space size="large" direction="vertical">
                        {displayPreview()}
                    </Space>
                }
                {editModal &&
                    <FundraisingDetailModal
                        visible={editModal}
                        setVisible={() => setEditModal(false)}
                        fundraiserData={!isGetFundraiserLoading ? fundraiserData : {}}
                        doReferesh={() => doReferesh()}
                    />
                }
            </div>
        </>
    );
};
export default DetailView;
