import React, { useState, useEffect } from "react";
import { Avatar, Select, Card } from 'antd';
import { showAvtarOrgName } from '../../../../utils/functions';
import { PRIZE_LIST } from '../../../../utils/constant';
import PrizeImg from '../../../../assets/prize.png';
import { RightOutlined } from '@ant-design/icons';
import RulesAndRegulationModal from "../../../common/modal/RulesAndRegulationModal";
import PrivacyPolicy from "../../../common/modal/privacyAndConditionsModal";
import OrganizationModal from "../../../common/organizationModal";

const { Option } = Select;

const Detail = (props) => {
    const { fData } = props;
    const [viewRules, setViewRules] = useState(false);
    const [privacyModal, setPrivacyModal] = useState(false);
    const [type, setType] = useState(false);

    const [visible, setVisible] = useState(false);
    const [rowData, setRowData] = useState("");
    const [modalType, setModalType] = useState("");


    const setShowOrgModal = () => {
        setVisible(true);
        setRowData(fData.organization);
        setModalType('view');
    }

    const displayPrize = (item, item2) => {
        if (item.id === item2.rank) {
            return (<>
                <div className="existing-prize">
                    <img src={item2?.image !== undefined && item2?.image !== null ? item2.image : PrizeImg} alt="prize" />
                    <div>
                        <p className="mb2 text">{item2.name}</p>
                        <p className="sub-text">{item2.description}</p>
                    </div>
                </div>
            </>)
        } else {
            return null;
        }
    }

    return (
        <div className="detail-section step8">
            <p className="description">{fData?.description || ""}</p>
            <div className="preview-org pointer mb8 mt7" >
                {(fData.organization !== null) ?
                    <>
                        <div className="box1" onClick={() => setShowOrgModal(true)}>
                            {(fData?.organization.image === null || fData?.organization.image === "") &&
                                <Avatar size="large" width={40} className='pointer' >{showAvtarOrgName(fData?.organization?.name || "")}</Avatar>
                            }
                            {(fData?.organization.image !== null && fData?.organization.image !== "") &&
                                <img src={fData?.organization.image} width={40} className="icon" alt="icon" />
                            }
                        </div>
                        <div className="box2" onClick={() => setShowOrgModal(true)}>
                            <p className="mb0 black fs14 bold">{fData?.organization?.name || ""}</p>
                            <p className="mb0 sub-text">
                                {fData?.organization?.phone} {`${(fData?.organization?.phone && fData?.organization?.email) ? ` • ` : ''}`} {fData?.organization.email}
                            </p>
                        </div>
                    </>
                    :
                    <div className="preview-org step4">
                        <div className="box1">
                            <Avatar size="large" width={40} className='pointer' >•••</Avatar>
                        </div>
                        <div className="box2">
                            <p className="mb0 text">Not affiliated with any organization</p>
                        </div>
                    </div>
                }
            </div>

            <div className="notification-card">
                <p className="mb0 black fs16">Receive progress notifications when fundraiser starts</p>
                <span className="title-case">{fData.updates_frequency}</span>
                {/* <Select
                    optionFilterProp="children"
                    defaultValue={fData.updates_frequency}
                    style={{ width: "150px" }}
                >
                    <Option value="daily">Daily</Option>
                    <Option value="weekly">Weekly</Option>
                    <Option value="monthly">Monthly</Option>
                </Select> */}
            </div>

            <div className="mt10">
                <p className="mb3 fs18 bold">Play and win great prizes!</p>
                <p className="mb5 fs14"><span className="rule-text" onClick={() => { setViewRules(true) }}>View rules and regulations&nbsp;<RightOutlined size={12} /></span></p>

                {PRIZE_LIST.map((item) => {
                    return (
                        <Card style={{ width: "100%" }} className="mb4 prize-card maxw100">
                            <div className="prize-card-child step5">
                                <div className="flex-center">
                                    <p className="mb1 text black">{item.title}</p>
                                </div>
                                {fData.prizes.length > 0 && fData.prizes.map((item2, index) => {
                                    return displayPrize(item, item2, index);
                                })
                                }
                            </div>
                        </Card>
                    )
                })}
                {viewRules &&
                    <RulesAndRegulationModal
                        showModal={viewRules}
                        onCancel={() => setViewRules(false)}
                    />
                }
            </div>
            <div className="mt10" style={{ bottom: 0 }}>
                <p className="mb5 fs14"><span className="rule-text" onClick={() => { setType('privacy'); setPrivacyModal(true) }}>Privacy Policy&nbsp;<RightOutlined size={12} /></span></p>
                <p className="mb5 fs14"><span className="rule-text" onClick={() => { setType('rule'); setPrivacyModal(true) }}>Terms and Conditions&nbsp;<RightOutlined size={12} /></span></p>
            </div>

            {privacyModal &&
                <PrivacyPolicy
                    type={type}
                    showModal={privacyModal}
                    onCancel={() => setPrivacyModal(false)}
                />
            }

            {visible &&
                <OrganizationModal
                    visible={visible}
                    rowData={rowData}
                    modalType={modalType}
                    from="detail-page"
                    setVisible={() => {
                        setRowData("");
                        setVisible(false);
                    }}
                />
            }
        </div>
    );
};
export default Detail;
