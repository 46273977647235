import React from "react";
import { Card, Form, Select } from 'antd';
import GoldImg from '../../../../assets/golf.png';
// import Map1 from '../../../../assets/map/map1.png';
import Map1 from '../../../../assets/map/map1.png';
import DragImg from '../../../../assets/drag.svg';
import { getWinDirectionName } from "../../../../utils/functions";
import { useSelector } from "react-redux";
import { WIND_SPEED } from "../../../../utils/constant";
const { Option } = Select;

const SingleCourseCard = React.forwardRef(({ item, index, isEdit = true }, ref) => {
    const { isWindLoading, windDirectionList } = useSelector(state => state.common);
    return (
        <div key={item.id}>
            <Card className="mb4" key={item.id}>
                <div className="course-card">
                    {isEdit && <div className="mb0 mr5 draggable-icon">
                        <img src={DragImg} alt="Golf" />
                    </div>}
                    <div className="d-flex">
                        <img src={item.image !== null ? item.image : Map1} className="mapimg" alt="Golf" />
                    </div>
                    <div className="ml5">
                        <p className="mb2 title">{item.name}</p>
                        {/* <p className="mb0 sub-title">{item.distance} Yards</p> */}
                        <p className="mb0 sub-text"> {item?.distance} Yards {`${item?.wind ? ` • Wind direction: ${getWinDirectionName(item?.wind?.name)}` : ''}`} {`${item?.wind_speed ? ` • Wind speed: ${item?.wind_speed}` : 'Wind speed: not set'}`}</p>
                    </div>
                    {isEdit &&
                        <div className="direction">
                            <Form.Item
                                // name={`course_${item.id}_${index}`}
                                name={`course_${item.id}_${index}`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select wind direction',
                                    }
                                ]}>
                                <Select
                                    loading={isWindLoading}
                                    placeholder="Wind Direction"
                                    style={{ width: "150px" }}
                                >
                                    {windDirectionList.map((wind) =>
                                        <Option key={wind.id} value={wind.id} className="title-case">{wind.name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                className={"ml5"}
                                name={`speed_${index}_${item.id}`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select wind speed',
                                    }
                                ]}>
                                <Select
                                    loading={false}
                                    placeholder="Wind Speed"
                                    style={{ width: "150px" }}
                                >
                                    {WIND_SPEED.map((wind) =>
                                        <Option key={`${wind.key}_wind.id"`} value={wind.key}>{wind.title}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>
                    }
                </div>
            </Card>
        </div>
    );
});
export default SingleCourseCard;
