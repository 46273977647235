const initialState = {
    isPrizeLoading: false,
    prizeList: [],

    isProvinceLoading: true,
    provinceList: [],

    isPrizeLinkLoading: false,
    prizeLinkMsg: "",

    error: ""
};

const reducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case "PRIZE_LIST_REQUEST":
            state = {
                ...state,
                isPrizeLoading: true,
                prizeList: [],

            };
            break;

        case "PRIZE_LIST_SUCCESS":
            state = {
                ...state,
                isPrizeLoading: false,
                prizeList: action.data
            };
            break;

        case "PRIZE_LIST_ERROR":
            state = {
                ...state,
                isPrizeLoading: false,
                prizeList: [],
                error: action.error
            };
            break;

        case "PRIZE_LINK_REQUEST":
            state = {
                ...state,
                isPrizeLinkLoading: true,
                prizeLinkMsg: "",

            };
            break;

        case "PRIZE_LINK_SUCCESS":
            state = {
                ...state,
                isPrizeLinkLoading: false,
                prizeLinkMsg: "success"
            };
            break;

        case "PRIZE_LINK_ERROR":
            state = {
                ...state,
                isPrizeLinkLoading: false,
                prizeLinkMsg: "",
                error: action.error
            };
            break;

        case "CLEAR_STEP_5":
            state = {
                ...state,
                isPrizeLinkLoading: false,
                prizeLinkMsg: "",
                error: ""
            };
            break;

        default:

    }
    return state;
};

export default reducer;