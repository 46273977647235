import { all } from 'redux-saga/effects'


import AuthSaga from './auth';
import CampgaignsSaga from './campaigns';
import Fundraisersaga from './fundraiser';
import CommonSaga from './common';
import Step5Saga from './step5';
import TicketSaga from './ticket';
import AdminSaga from './admin';
import PlayerSaga from './player';

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
function* rootSaga() {
    yield all([
        AuthSaga(),
        CampgaignsSaga(),
        Fundraisersaga(),
        CommonSaga(),
        Step5Saga(),
        TicketSaga(),
        AdminSaga(),
        PlayerSaga()
    ])
}

export default rootSaga;