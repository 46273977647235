/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const StartFundraiserEarly = (props) => {
    const { visible, setVisible, fundId } = props;

    const { isDeleteLoading, deleteMsg } = useSelector(state => state.common);
    const { isFundStatusUplading } = useSelector(state => state.admin);

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [confirmText, setconfirmText] = useState("");

    const handleSubmit = () => {
        if (props.type === "fundAdmin") {
            dispatch({ type: 'UPDATE_STATUS_REQUEST', formData: {}, id: fundId, updateFor: 'fundAdmin' });
        } else {
            dispatch({ type: 'UPDATE_STATUS_REQUEST', formData: { status: 'fundraiser_started', comment: confirmText }, id: fundId, updateFor: 'fundraiser' });
        }
        // dispatch({ type: 'START_FUNDRAISER_REQUEST', id: fundId });
    }

    useEffect(() => {
        if (!isDeleteLoading && deleteMsg === "success") {
            dispatch({ type: "CLEAR_COMMON" });
            setVisible(false);
        }
    }, [isDeleteLoading, deleteMsg])

    return (
        <Modal
            className={`prize-modal step4-modal org-modal`}
            title={`Start Fundraiser Early?`}
            open={visible}
            onOk={() => handleSubmit()}
            onCancel={() => {
                dispatch({ type: "CLEAR_COMMON" });
                setVisible(false);
                form.resetFields();
            }}
            centered
            width={550}
            okText={`Yes! Start`}
            okButtonProps={{ disabled: isFundStatusUplading, loading: isFundStatusUplading }}
            cancelText="Cancel"

        >
            <div className="mt2">
                <p>Are you sure you want to start your fundraiser early?</p>
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    form={form}
                    layout="vertical"
                >
                    {/* <Form.Item
                        className='delete-field'
                        name="comment"
                        label={`Comment`}
                        validateStatus={validationState}
                    >
                        <TextArea
                            placeholder="Add the comment"
                            size="large"
                            value={confirmText}
                            onChange={(e) => {
                                setconfirmText(e.target.value)
                            }} />
                    </Form.Item> */}
                </Form>
                <p></p>
            </div>
        </Modal>
    );
};

export default StartFundraiserEarly;