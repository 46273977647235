/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input, Select, Row, Col } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const { Option } = Select;
const { TextArea } = Input;

const PrizeToBeDeliveredModal = (props) => {
    const { visible, setVisible, modalType = "edit", rowData } = props;
    const { isCommonUpdateLoading } = useSelector(state => state.admin);

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(()=>{
        form.resetFields();
    },[visible])
    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                let tmpValues = {};
                for (const property in values) {
                    if (values[property] !== undefined) {
                        tmpValues[property] = values[property];
                    }
                }
                dispatch({ type: 'UPDATE_COMMON_REQUEST', formData: tmpValues, 'requestType': 'put', id: rowData.id, updateFor: 'prizeToBeDelivered' });
            })
            .catch((errorInfo) => {
                console.log(errorInfo)
            });
    }

    return (
        <Modal
            className={`prize-modal step4-modal org-modal ${modalType === "view" ? "view-modal" : ""}`}
            title={`Update Status`}
            open={visible}
            onOk={() => handleSubmit()}
            onCancel={() => {
                dispatch({ type: "CLEAR_COMMON" });
                setVisible(false);
                form.resetFields();
            }}
            centered
            width={650}
            okText={`${modalType === "delete" ? "Delete" : modalType === "edit" ? 'Update' : "Save"}`}
            okButtonProps={{ disabled: isCommonUpdateLoading, loading: isCommonUpdateLoading }}

            cancelText="Cancel"
            maskClosable={modalType === "view" ? true : false}

        >
            {(modalType === "edit") &&
                <div className="mt4">
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        form={form}
                        layout="vertical"
                    >
                        <Row className='mb5'>
                            <Col xs={24} sm={24} md={20} lg={20} xl={20} className="org-view">
                                <p className='mb4'><b>Prize Name:</b>&nbsp;&nbsp;{rowData?.prize_name}</p>
                                <p className='mb6'><b>Fundraiser Name:</b>&nbsp;&nbsp;{rowData?.campaign_name}</p>
                                <p className='mb5'><b>Player Name:</b>&nbsp;&nbsp;{rowData?.player_name}</p>
                                <p className='mb5'><b>Player Email:</b>&nbsp;&nbsp;{rowData?.player_email}</p>
                                <p className='mb5'><b>Player Phone:</b>&nbsp;&nbsp;{rowData?.player_phone}</p>
                                <p className='mb5'><b>Shipping Address:</b>&nbsp;&nbsp;{rowData?.shipping_address}</p>

                            </Col>
                        </Row>
                        <Form.Item
                            name="status"
                            label="Status"
                            rules={[{ required: true, message: 'Select status' }]}
                        >
                            <Select
                                placeholder="Select"
                                size="large"
                                autoComplete="new-state"
                            >
                                <Option key={"claimed"} value={"claimed"}>Claimed</Option>
                                <Option key={"shipped"} value={"shipped"}>Shipped</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Note"
                            name="status_notes"
                            rules={[
                                { required: true, message: 'Enter note' },
                                { max: 5000, message: 'Maximum 5000 characters only allowed!' }
                            ]}
                        >
                            <TextArea rows={3} placeholder={"Note"} />
                        </Form.Item>
                    </Form>
                </div>
            }

        </Modal>
    );
};

export default PrizeToBeDeliveredModal;