/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Spin, Col } from "antd";
import { GAME_DETAIL_TABS, PER_PAGE } from '../../../utils/constant';
import GameTabs from '../../common/gameTabs';
import FundraiserDetail from "./fundraiserDetail";
import PlayerRanking from "./playerRanking";
import WinnerPrizes from "./winnerPrizes";

const AdminGamesDetails = (props) => {

    const [activeTab, setActiveTab] = useState('fundraiserDetail');

    return (
        <div>
            <div className="sub-header">
                <p className="mb0 title">
                    Games Detail
                </p>

            </div>


            <Row className='campaign-table mt10'>
                <GameTabs
                    onChange={(e) => setActiveTab(e)}
                    item={GAME_DETAIL_TABS}
                    active={activeTab}
                />
            </Row>
            <Row className="mt5 game-analytics">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {activeTab === "fundraiserDetail" &&
                        <FundraiserDetail />
                    }
                    {activeTab === "playersRanking" &&
                        <PlayerRanking />
                    }
                    {activeTab === "winnerPrizes" &&
                        <WinnerPrizes />
                    }
                </Col>

            </Row>
        </div>
    );
};
export default AdminGamesDetails;