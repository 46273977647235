/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Spin, Col, Tag, Tooltip } from "antd";
// import { TICKET_DETAIL_TAG } from '../../../../utils/constant';
import { displayStatusText, getColor, getPaymentColor } from '../../../../utils/functions';
// import GameTabs from '../../../common/gameTabs';
import PrintTicket from "./printTicket";
// import StolenTicket from "./stolenTickets";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { WalletOutlined } from '@ant-design/icons';

const TicketDetail = (props) => {

    const [activeTab, setActiveTab] = useState('printTickets');
    const dispatch = useDispatch();
    const { id } = useParams();
    const { isGetFundraiserLoading, fundraiserData } = useSelector(state => state.fundraiser);

    useEffect(() => {
        if (id) {
            dispatch({ type: 'GET_FUNDRAISER_DATA_REQUEST', formData: { id: id } });
        }

    }, []);
    return (
        <div>
            <div className="sub-header">
                <p className="mb0 title">
                    Ticket Management &nbsp;{!isGetFundraiserLoading ? "| " + fundraiserData?.title : <Spin></Spin>}
                </p>
                {!isGetFundraiserLoading &&
                    <p className="mb0 title">
                        <Tooltip title="Fundraiser Status">
                            <Tag color={(getColor(fundraiserData?.status))} key={fundraiserData?.status}>
                                {displayStatusText(fundraiserData?.status || "")}
                            </Tag>
                        </Tooltip>
                        <Tooltip title="Payment Status">
                            <Tag color={(getPaymentColor(fundraiserData?.paid_status))} key={fundraiserData?.paid_status}>
                                <WalletOutlined /> &nbsp;{displayStatusText(fundraiserData?.paid_status || "")}
                            </Tag>
                        </Tooltip>
                    </p>
                }
            </div>
            <PrintTicket id={id} fundraiserData={fundraiserData} />
            {/* <Row className='campaign-table mt10'>
                <GameTabs
                    onChange={(e) => setActiveTab(e)}
                    item={TICKET_DETAIL_TAG}
                    active={activeTab}
                />
            </Row>
            <Row className="mt5 game-analytics">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {activeTab === "printTickets" &&
                        <PrintTicket id={id} fundraiserData={fundraiserData} />
                    }
                    {activeTab === "lostStolen" &&
                        <StolenTicket id={id} />
                    }
                </Col>
            </Row> */}
        </div>
    );
};
export default TicketDetail;