import React from "react";
import { Modal, Button, Spin } from 'antd';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';

const ProcessingModal = ({ showModal, onCancel }) => {
    return (
        <Modal
            className="success-modal-parent"
            footer={false}
            visible={showModal}
            onCancel={() => onCancel(false)}
            width={600}
            maskClosable={false}
        >
            <div className="success-modal-child">
                <Spin size={60} />
                <p className="fs18 bold mt5 mb4">Processing...</p>
                <p className="mb6 text">Your payment has been processing, please wait!</p>
                <Button
                    type="primary"
                    size="large"
                    className="br20 pr8 pl8"
                    htmlType="submit"
                    onClick={() => onCancel(false)}
                >
                    Close
                </Button>
            </div>
        </Modal>
    );
};
export default ProcessingModal;
