import React from "react";
import { Badge } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const Tabs = ({ item, active, isShowCount = false, onChange, pageStatusCount }) => {
    const { isStatusCountLoading } = useSelector(state => state.campgaigns);
    
    const renderTabs = () => {
        let totalCount = 0;
        if (Object.keys(pageStatusCount).length > 0) {
            totalCount = Object.keys(pageStatusCount).reduce((sum, key) => sum + parseFloat(pageStatusCount[key] || 0), 0)
        }

        return item.map((data) => {
            let counts = 0;
            if (data.key === "all") {
                counts = totalCount;
            } else {
                counts = pageStatusCount[data.key];
            }
            return (
                <p
                    className={`mb0 ${data.key === active ? 'active' : ''}`}
                    onClick={() => onChange(data.key, 'type')}
                >
                    <span>{data.title}</span>
                    {isShowCount &&
                        <span className="badge">
                            <Badge count={
                                isStatusCountLoading ? <ClockCircleOutlined
                                    style={{
                                        color: '#f5222d',
                                    }}
                                /> : counts
                            } overflowCount={999} showZero size="default">
                            </Badge>
                        </span>
                    }
                </p>
            )
        })
    }
    return (
        <div className='tab'>
            {renderTabs()}

        </div>
    )
}
export default Tabs;