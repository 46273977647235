/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from 'antd';
import React from 'react';
const DeleteModal = (props) => {
    const { visible, setVisible, subTitle, onDelete, isDeleteLoading = false, deleteId = "" } = props;
    return (
        <Modal
            title={"Are you sure?"}
            visible={visible}
            onOk={() => {
                if (deleteId !== "") {
                    onDelete(deleteId)
                } else {
                    onDelete()
                }
            }}
            onCancel={() => setVisible()}
            okText={'Delete'}
            okButtonProps={{ disabled: isDeleteLoading, loading: isDeleteLoading }}
            cancelText={'Cancel'}

        >
            <p>{subTitle || ""}</p>
        </Modal>
    );
};

export default DeleteModal;