/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input, Select } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DatePicker } from 'antd';
import moment from 'moment';


const { Option } = Select;

const CouponModal = (props) => {
    const { visible, setVisible, modalType, rowData } = props;
    const { isDeleteLoading } = useSelector(state => state.common);

    const { isCommonUpdateLoading } = useSelector(state => state.admin);
    const [suffix, setSuffix] = useState('%');
    const [formError, setFormError] = useState("");
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                let tmpValues = {};
                for (const property in values) {
                    if (values[property] !== undefined) {
                        tmpValues[property] = values[property];
                    }
                }
                tmpValues.expires_at = moment(form.getFieldValue().expires_at).format('YYYY-MM-DD HH:mm:ss')
                tmpValues.usage_count = 0
                tmpValues.is_available = 0
                if (modalType === "edit") {
                    dispatch({ type: 'UPDATE_COMMON_COUPON', formData: tmpValues, 'requestType': 'put', id: rowData.id });
                } else if (modalType === "delete") {
                    dispatch({ type: 'DELETE_REQUEST', id: rowData.id, deleteType: 'coupon' });
                } else {
                    dispatch({ type: 'UPDATE_COMMON_COUPON', formData: tmpValues, 'requestType': 'post' });
                }
            })
            .catch((errorInfo) => {
                console.log(errorInfo)
            });
    }

    useEffect(() => {
        if (modalType === 'add') {
            setTimeout(() => {
                form.resetFields();
                setSuffix('%')
            }, 100);
        }
    }, [modalType])

    useEffect(() => {
        if (form.getFieldValue().type === 'percentage' && form.getFieldValue()?.value?.length > 2) {
            setFormError("Discount value field in not valid")
        } else {
            setFormError("")
        }
    }, [form.getFieldValue().type, suffix])

    useEffect(() => {
        dispatch({ type: "CLEAR_COMMON" });
    }, []);

    useEffect(() => {
        if (rowData !== "" && Object.keys(rowData).length > 0) {
            form.setFieldsValue({
                code: rowData?.code || "",
                type: rowData?.type || "",
                value: rowData?.value?.replace(/\..*/, '') || "",
                expires_at: moment(rowData?.expires_at) || "",
                usage_limit: rowData?.usage_limit || "",
            });
            setSuffix(rowData?.type === 'percentage' ? '%' : '$')
        } else {
            setSuffix('%')
            setFormError(false)
        }
    }, [rowData])


    return (
        <Modal
            className={`prize-modal step4-modal org-modal ${modalType === "view" ? "view-modal" : ""}`}
            title={`${modalType === "view" ? "" : modalType === "add" ? "Create New Coupon" : modalType === "edit" ? "Edit Coupon" : modalType === "delete" ? "Are you sure?" : ""}`}
            open={visible}
            onOk={() => handleSubmit()}
            onCancel={() => {
                dispatch({ type: "CLEAR_COMMON" });
                setVisible(false);
                form.resetFields();
                setSuffix('%')
            }}
            centered
            width={650}
            okText={`${modalType === "delete" ? "Delete" : modalType === "edit" ? 'Update' : "Save"}`}
            okButtonProps={{ disabled: isCommonUpdateLoading || isDeleteLoading || formError, loading: isCommonUpdateLoading || isDeleteLoading }}
            cancelText="Cancel"
            maskClosable={modalType === "view" ? true : false}

        >
            {(modalType === "edit" || modalType === "add") &&
                <div className="mt4">
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        form={form}
                        layout="vertical"
                    >

                        <Form.Item
                            label="Coupon Code"
                            name="code"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter coupon code'
                                },
                                { max: 20, message: 'Maximum 20 characters only allowed!' }
                            ]}
                        >
                            <Input size='large' placeholder={"Coupon Code"} />
                        </Form.Item>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Form.Item
                                name="value"
                                label={"Discount Value"}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter Discount value'
                                    },
                                    {
                                        max: suffix === '%' ? 2 : 6,
                                        message: suffix === '%' ? 'Maximum 2 characters only allowed!' : 'Maximum 6 characters only allowed!',
                                    },
                                    {
                                        pattern: /^\d+$/,
                                        message: 'Please input numbers'
                                    }
                                ]}
                                style={{ width: '73%' }}
                            >
                                <Input inputMode='numeric' size="large" placeholder={"Discount value in number"} suffix={suffix} />
                            </Form.Item>
                            <Form.Item
                                name="type"
                                label="Discount Type"
                                initialValue='percentage'
                                rules={[{ required: true, message: 'Select discount type' }]}
                                style={{ width: '25%' }}
                            >
                                <Select
                                    placeholder="Select"
                                    size="large"
                                    defaultValue='percentage'
                                    onChange={(value) => {
                                        if (value === 'percentage') {
                                            setSuffix('%');
                                        } else {
                                            setSuffix('$');
                                        }
                                    }}
                                >
                                    <Option value="percentage">Percent</Option>
                                    <Option value="flat">Flat</Option>
                                </Select>
                            </Form.Item>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Form.Item
                                name="usage_limit"
                                label={"Usage Limit"}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter Discount value'
                                    },
                                    {
                                        pattern: /^.{1,7}$/,
                                        message: 'Maximum 7 characters only allowed!',
                                    },
                                    {
                                        pattern: /^\d+$/,
                                        message: 'Please input numbers'
                                    }
                                ]}
                                style={{ width: '64%' }}
                            >
                                <Input size="large" placeholder={"Usage limit in number"} />
                            </Form.Item>
                            <Form.Item
                                name="expires_at"
                                label={"Expiry Date"}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select expiry date'
                                    }]
                                }
                            >
                                <DatePicker
                                    disabledDate={(current) => {
                                        return current && current < moment().endOf('day');
                                    }} size="large" placeholder={"Select Date"} />
                            </Form.Item>
                        </div>
                        <div style={{ color: "red" }}>{formError}</div>
                    </Form>
                </div>
            }
            {modalType === "delete" &&
                <div className="mt2">
                    <p>You want to delete this coupon</p>
                </div>
            }


        </Modal>
    );
};

export default CouponModal;