import React, { useEffect } from "react";
import { Spin, Card, Tag, Button, Row, Col, Input, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { PlusOutlined, EditFilled, DeleteFilled } from '@ant-design/icons';
import NoData from './noData';
import { checkIsAllPaid, formatDate, getFundraiserStatus } from "../../utils/functions";
import { useState } from "react";
import { isApproved } from '../../utils/functions';
import ApproveModal from "../common/modal/approveModal";
import DeleteModal from "../common/modal/deleteModal";
import WarningModal from "../common/modal/warningModal";

const { Search } = Input;

const FundraiserDashboard = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isFCampgaignLoading, fundraiserCampgaignList } = useSelector(state => state.fundraiser);
    const [searchText, setSearchText] = useState("");
    const [isSearch, setIsSearch] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [visible, setVisible] = useState(false);
    const [rowData, setRowData] = useState("");
    const [allowNew, setAllowNew] = useState({});
    const [warningModal, setWarningModal] = useState(false);

    useEffect(() => {
        getAllCampaignsList({}, false);
    }, []);

    useEffect(() => {
        if (!isFCampgaignLoading && fundraiserCampgaignList.length > 0) {
            setAllowNew(checkIsAllPaid(fundraiserCampgaignList));
        } else if (!isFCampgaignLoading && fundraiserCampgaignList.length === 0) {
            setAllowNew({ status: true, data: [] });
        }
    }, [isFCampgaignLoading, fundraiserCampgaignList])

    const getAllCampaignsList = (params = {}, isSearch = false) => {
        params.per_page = 5000;
        setIsSearch(isSearch);
        dispatch({ type: 'FUNDRAISER_CAPMAIGN_LIST_REQUEST', formData: params, fundraiserFor: "my" });
    }

    const onSearch = (e) => {
        setSearchText(e);
        getAllCampaignsList({ 'search': e }, true);
    };

    const openDeleteModal = (record) => {
        setVisible(true);
        setRowData(record);
    }

    return (
        <div>
            <div className="sub-header mb7">
                <p className="mb0 title">
                    My Campaigns
                </p>
            </div>

            {((!isFCampgaignLoading && fundraiserCampgaignList.length !== 0) || isSearch) &&
                <div className="filter  mb8 new-fundraising-parent">
                    <Row className='nav-sub-header'>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Search
                                defaultValue={searchText}
                                size="large"
                                allowClear
                                placeholder="Search"
                                style={{ width: "80%" }}
                                enterButton
                                onSearch={(e) => onSearch(e, 'search')}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fr text-right">

                            {allowNew.status && <Button
                                type="primary"
                                size="large"
                                className="br20"
                                htmlType="submit"
                                onClick={() => navigate('/new-fundraising?step=1')}
                                icon={<PlusOutlined />}
                            >
                                New Fundraiser
                            </Button>}
                            {!allowNew.status &&
                                <Button
                                    type="primary"
                                    size="large"
                                    className="br20"
                                    htmlType="submit"
                                    onClick={() => setWarningModal(true)}
                                    icon={<PlusOutlined />}
                                >
                                    New Fundraiser
                                </Button>
                            }

                        </Col>
                    </Row>
                </div>
            }
            {isFCampgaignLoading && <div className="new-fundraising-parent" ><Spin /></div>}
            {!isFCampgaignLoading && fundraiserCampgaignList.length > 0 && (
                <div className="new-fundraising-parent mb10">

                    {fundraiserCampgaignList.map((item) => {
                        const isETransferPending = (item?.e_transfers?.length && item?.e_transfers?.some((val) => val.status === 'pending'))
                        return (
                            <Card className="mt4">
                                <div className="card" onClick={(e) => {
                                    if (item.paid_status === "pending" && item.status !== "fundraiser_closed" && item.status !== "prizes_shipped" && !isETransferPending) {
                                        e.stopPropagation();
                                        navigate(`new-fundraising/${item.id}?step=1`)
                                    } else {
                                        isApproved(item.status) ? setOpenModal(true) : navigate(`fundraiser-details/${item.id}`)

                                    }
                                    // navigate(`new-fundraising/${item.id}?step=1`)
                                }}>
                                    <div>
                                        <p className="mb2 title">{item.title === null ? <span>No Campaign Title <span className="redfv">(Draft)</span></span> : item.title}</p>
                                        <p className="mb0 sub-title">{item?.organization?.name}</p>
                                    </div>
                                    <div className="d-flex">
                                        <div>
                                            <p className="mb2 status-tag">
                                                <Tag
                                                    color={`${(item.status === 'fundraiser_closed' || (item.paid_status === "pending" &&  !isETransferPending)) ? "red" : "green"}`}
                                                    className={`${(item.status === "fundraiser_closed" || (item.paid_status === "pending" &&  !isETransferPending)) ? "status-error" : "status-success"}`}>
                                                    {getFundraiserStatus(item.status, isETransferPending ? 'pending_approval' : item.paid_status)}
                                                </Tag>
                                            </p>
                                            <p className="mb0 date-style">{
                                                item.start_date !== null ? `${formatDate(item.start_date)} • ${formatDate(item.end_date)}` : ''}
                                            </p>
                                        </div>
                                        <div className="action">
                                            {item.paid_status === "pending" && item.status !== "fundraiser_closed" && item.status !== "prizes_shipped" && !isETransferPending && 
                                                <div className="edit-fundraiser pointer" >
                                                    <Tooltip title="Update details" placement="top">
                                                        <EditFilled
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                navigate(`new-fundraising/${item.id}?step=1`)
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            }
                                            {item.paid_status === "pending" && !isETransferPending &&
                                                < div className="edit-fundraiser delete pointer">
                                                    <Tooltip placement="bottom" title="Delete">
                                                        <DeleteFilled
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                openDeleteModal(item)
                                                            }}
                                                            className={`cursor`}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </Card>
                        )
                    })}
                </div>
            )
            }
            {
                !isFCampgaignLoading && fundraiserCampgaignList.length === 0 &&
                <NoData isSearch={isSearch} />
            }

            {
                openModal &&
                <ApproveModal
                    visible={openModal}
                    onCancel={(type) => {
                        setOpenModal(false);
                    }}
                />
            }
            {
                warningModal &&
                <WarningModal
                    visible={warningModal}
                    data={allowNew.data}
                    onCancel={(type) => {
                        setWarningModal(false);
                    }}
                />
            }

            {
                visible &&
                <DeleteModal
                    visible={visible}
                    rowData={rowData}
                    setVisible={() => {
                        setRowData("");
                        setVisible(false);
                    }}
                    deleteType="fundraiser"
                    deleteText="fundraiser"
                    onReferesh={() => getAllCampaignsList()}
                />
            }

        </div >


    );
};
export default FundraiserDashboard;
