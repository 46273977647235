/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col, Card, Progress } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { formatAmount, percentageCalculate, getAllTicketCount } from '../../../../utils/functions';
import { useParams } from 'react-router-dom';
import TicketPreviewModal from "../../../common/modal/ticketPreviewModal";

const Ticket = ({ fData }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { isTicketStateLoading, ticketStats, isTicketCreateLoading, ticketMsg, isReassignLoading, reassignMsg } = useSelector(state => state.ticket);
    const [tmpState, settmpState] = useState("");
    const [showTicket, setShowTicket] = useState(false);

    useEffect(() => { getSellerList({}) }, []);
    useEffect(() => {
        if (!isTicketStateLoading && Object.keys(ticketStats).length > 0) {
            settmpState(ticketStats);
        }
    }, [isTicketStateLoading, ticketStats]);

    useEffect(() => {
        getTicketState();
    }, [])
    const getTicketState = () => {
        dispatch({ type: 'TICKET_STATE_REQUEST', id: id });
    }


    useEffect(() => {
        if ((!isTicketCreateLoading && ticketMsg === "success") || (!isReassignLoading && reassignMsg === "success")) {
            getTicketState();
        }
    }, [isTicketCreateLoading, ticketMsg, isReassignLoading, reassignMsg]);

    const getSellerList = (params = {}) => {
        dispatch({ type: 'GET_SELLER_LIST_REQUEST', formData: { 'per_page': 5000 } });
    }

    return (
        <div className="ticket-section">
            <Row gutter={[20, 25]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Card className="card-child ticket">
                        {fData.fundraiser_package === null && <div><p className="no-data">Package not selected</p></div>}
                        {fData.fundraiser_package !== null &&
                            <div>
                                <div className="parent">
                                    <div className="mb4">
                                        <p className="cmp-title">{"Ticket Title"}</p>
                                        <p className="cmp-sub-title mb4">{fData.ticket?.title}</p>
                                    </div>
                                    <br></br>
                                    {showTicket &&
                                        <TicketPreviewModal
                                            visible={showTicket}
                                            onCancel={() => setShowTicket(false)}
                                            data={fData}
                                        />
                                    }
                                </div>
                                <p className="links pointer" onClick={() => setShowTicket(true)}>Preview Ticket</p>
                            </div>
                        }

                    </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Card className="card-child ticket">
                        <div className="parent">
                            <div className="mb4">
                                <p className="cmp-title">Shipping Address</p>
                                <p className="cmp-sub-title mb4">{fData?.ticket?.suite_apt_no_unit || ""} {fData?.ticket?.shipping_address || ""}</p>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Card className="card-child ticket" loading={isTicketStateLoading}>
                        <div className="parent">
                            <div className="mb4">
                                <p className="cmp-title b500 fs14">Tickets Assigned</p>
                                <p className="cmp-title">{`${getAllTicketCount(tmpState, fData)}`}</p>
                                <p className="cmp-sub-title mb4">{`/${tmpState !== "" ? tmpState.tickets_allowed_count : 0}`}</p>
                            </div>
                            <Progress
                                strokeColor={{
                                    '0%': '#1B35B3',
                                    '100%': '#1B35B3',
                                }}
                                strokeLinecap="butt"
                                strokeWidth={6}
                                type="circle"
                                // percent={percentageCalculate(tmpState !== "" ? tmpState.tickets_allowed_count : 0, tmpState !== "" ? tmpState.tickets_assigned_count : 0)}
                                percent={percentageCalculate(tmpState !== "" ? tmpState.tickets_allowed_count : 0, getAllTicketCount(tmpState, fData))}
                                width={70}
                            />
                        </div>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Card className="card-child ticket" loading={isTicketStateLoading}>
                        <div className="parent">
                            <div className="mb4">
                                <p className="cmp-title b500 fs14">Total Collected</p>
                                <p className="cmp-title">{formatAmount(tmpState.total_amount_collected)}</p>
                                <p className="cmp-sub-title mb4">${`${tmpState !== "" ? parseFloat(tmpState.total_amount_to_be_generated).toFixed(2) : 0.00}`}</p>
                            </div>
                            <Progress
                                strokeColor={{
                                    '0%': '#1B35B3',
                                    '100%': '#1B35B3',
                                }}
                                strokeLinecap="butt"
                                strokeWidth={6}
                                type="circle"
                                percent={percentageCalculate(tmpState !== "" ? tmpState.total_amount_to_be_generated : 0, tmpState !== "" ? tmpState.total_amount_collected : 0)}
                                width={70}
                            />
                        </div>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Card className="card-child ticket" loading={isTicketStateLoading}>
                        <div className="parent">
                            <div className="mb4">
                                <p className="cmp-title b500 fs14">Tickets Reported Stolen/Lost</p>
                                <p className="cmp-title">{`${tmpState !== "" ? tmpState.tickets_reported_stolen : 0}`}</p>
                                {fData.tickets_blocked !== 0 && <p className="cmp-title b500 fs14">Tickets Blocked <b>({`${fData.tickets_blocked}`})</b></p>}
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>

        </div >
    );
};
export default Ticket;
