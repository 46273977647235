const initialState = {
    isTicketCreateLoading: false,
    ticketMsg: "",
    isGetTicketLoading: false,
    assignTicketList: [],

    isReassignLoading: false,
    reassignMsg: "",

    isPaymentLoading: false,
    paymentHistoryList: [],
    isTicketStateLoading: false,
    ticketStats: {},

    isTicketDowndloading: false,
    ticketPDF: "",

    isTicketGenerating: false,
    generateStatus: "",

    isDeactivateLoading: false,
    deactiveMsg: "",

    isTicketValidating: false,
    validateMsg: "",
    validErrorMsg: "",
    validTicketData: {},

    isFundraiserLoading: false,
    fundData: {},
    isUnassignLoading: false,
    unAssignTicketList: [],

    error: ""
};

const reducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case "ASSIGN_TICKET_TO_SELLER_REQUEST":
            state = {
                ...state,
                isTicketCreateLoading: true,
                ticketMsg: "",

            };
            break;

        case "ASSIGN_TICKET_TO_SELLER_SUCCESS":
            state = {
                ...state,
                isTicketCreateLoading: false,
                ticketMsg: "success",
            };
            break;

        case "ASSIGN_TICKET_TO_SELLER_ERROR":
            state = {
                ...state,
                isTicketCreateLoading: false,
                ticketMsg: "",
            };
            break;

        case "ASSIGN_TICKET_LIST_REQUEST":
            state = {
                ...state,
                isGetTicketLoading: true,
                assignTicketList: [],

            };
            break;

        case "ASSIGN_TICKET_LIST_SUCCESS":
            state = {
                ...state,
                isGetTicketLoading: false,
                assignTicketList: action.data,
            };
            break;

        case "ASSIGN_TICKET_LIST_ERROR":
            state = {
                ...state,
                isGetTicketLoading: false,
                assignTicketList: "",
            };
            break;

        /** **/
        case "UNASSIGN_TICKET_LIST_REQUEST":
            state = {
                ...state,
                isUnassignLoading: true,
                unAssignTicketList: [],

            };
            break;

        case "UNASSIGN_TICKET_LIST_SUCCESS":
            state = {
                ...state,
                isUnassignLoading: false,
                unAssignTicketList: action.data,
            };
            break;

        case "UNASSIGN_TICKET_LIST_ERROR":
            state = {
                ...state,
                isUnassignLoading: false,
                unAssignTicketList: [],
            };
            break;

        case "REASSIGN_SELLER_REQUEST":
            state = {
                ...state,
                isReassignLoading: true,
                reassignMsg: [],

            };
            break;

        case "UPDATE_TICKET_PAYMENT_REQUEST":
            state = {
                ...state,
                isReassignLoading: true,
                reassignMsg: [],

            };
            break;

        case "REASSIGN_SELLER_SUCCESS":
            state = {
                ...state,
                isReassignLoading: false,
                reassignMsg: "success",
            };
            break;

        case "REASSIGN_SELLER_ERROR":
            state = {
                ...state,
                isReassignLoading: false,
                reassignMsg: "",
            };
            break;

        case "SELLER_TICKET_PAYMENT_REQUEST":
            state = {
                ...state,
                isPaymentLoading: true,
                paymentHistoryList: [],

            };
            break;

        case "SELLER_TICKET_PAYMENT_SUCCESS":
            state = {
                ...state,
                isPaymentLoading: false,
                paymentHistoryList: action.data,
            };
            break;

        case "SELLER_TICKET_PAYMENT_ERROR":
            state = {
                ...state,
                isPaymentLoading: false,
                paymentHistoryList: false,
            };
            break;

        case "TICKET_STATE_REQUEST":
            state = {
                ...state,
                isTicketStateLoading: true,
                ticketStats: {},

            };
            break;

        case "TICKET_STATE_SUCCESS":
            state = {
                ...state,
                isTicketStateLoading: false,
                ticketStats: action.data,
            };
            break;

        case "TICKET_STATE_ERROR":
            state = {
                ...state,
                isTicketStateLoading: false,
                ticketStats: {},
            };
            break;

        case "TICKET_PDF_REQUEST":
            state = {
                ...state,
                isTicketDowndloading: true,
                ticketPDF: {},

            };
            break;

        case "TICKET_PDF_SUCCESS":
            state = {
                ...state,
                isTicketDowndloading: false,
                ticketPDF: action.data,
            };
            break;

        case "TICKET_PDF_ERROR":
            state = {
                ...state,
                isTicketDowndloading: false,
                ticketPDF: {},
            };
            break;

        /**--------------------**/
        case "GENERATE_TICKET_REQUEST":
            state = {
                ...state,
                isTicketGenerating: true,
                generateStatus: "",

            };
            break;

        case "GENERATE_TICKET_SUCCESS":
            state = {
                ...state,
                isTicketGenerating: false,
                generateStatus: "success",
            };
            break;

        case "GENERATE_TICKET_ERROR":
            state = {
                ...state,
                isTicketGenerating: false,
                generateStatus: "",
            };
            break;

        /**--------------------**/
        case "DEACTIVATE_TICKET_REQUEST":
            state = {
                ...state,
                isDeactivateLoading: true,
                deactiveMsg: "",

            };
            break;

        case "DEACTIVATE_TICKET_SUCCESS":
            state = {
                ...state,
                isDeactivateLoading: false,
                deactiveMsg: "success",
            };
            break;

        case "DEACTIVATE_TICKET_ERROR":
            state = {
                ...state,
                isDeactivateLoading: false,
                deactiveMsg: "",
            };
            break;

        /**--------------------**/
        case "VALIDATE_TICKET_REQUEST":
            state = {
                ...state,
                isTicketValidating: true,
                validateMsg: "",
                validErrorMsg: "",
                validTicketData: {}

            };
            break;

        case "VALIDATE_TICKET_SUCCESS":
            state = {
                ...state,
                isTicketValidating: false,
                validateMsg: action.isValid ? "success" : "error",
                validErrorMsg: action.isValid ? 'Valid Ticket' : "Invalid Ticket",
                validTicketData: action.responseData

            };
            break;

        case "VALIDATE_TICKET_ERROR":
            state = {
                ...state,
                isTicketValidating: false,
                validateMsg: action.isValid ? "success" : "error",
                validErrorMsg: action.isValid ? 'Valid Ticket' : "Invalid Ticket",
                validTicketData: action.responseData
            };
            break;

        /**--------------------**/
        case "TICKET_FUNDRAISER_DATA_REQUEST":
            state = {
                ...state,
                isFundraiserLoading: true,
                fundData: {},

            };
            break;

        case "TICKET_FUNDRAISER_DATA_SUCCESS":
            state = {
                ...state,
                isFundraiserLoading: false,
                fundData: action.data
            };
            break;

        case "TICKET_FUNDRAISER_DATA_ERROR":
            state = {
                ...state,
                isFundraiserLoading: false,
                fundData: {}
            };
            break;


        case "CLEAR_TICKET":
            state = {
                ...state,
                ticketMsg: "",
                reassignMsg: "",
                generateStatus: "",
                deactiveMsg: "",
                validateMsg: "",
                validErrorMsg: "",
                successMsg: ""
            };
            break;

        default:

    }
    return state;
};

export default reducer;