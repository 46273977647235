//Import NPM Packages.
import axios from 'axios';
import { API_URL } from '../utils/constant';
import { accessToken } from '../utils/functions';
import { message } from 'antd';

//Request Middleware.
function makeHttpRequest(apiCall) {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await apiCall();
      resolve(data);
    } catch (e) {
      console.log("e");
      console.log("e");
      console.log(e.response);
      console.log(e.response.message);
     
      if (e.response?.status === 401) {
        if (e.response.data.message !== "" && e.response.data.message === "These credentials do not match our records.") {
          resolve("Permission Denied");
        } else {
          if(e.response.data.message !== "Invalid OTP"){
            message.error({ content: "Permission Denied", className: 'error-class' });
          }
        }
        resolve("Permission Denied");
      } else if (e.response?.status === 404) {
        resolve("something went wrong!");
      } else if (e.response?.status === 500) {
        resolve("something went wrong!");
      } else {
        resolve(e.response?.data || "something went wrong!");
      }

    }
  });
}

export function getRequest(path, params) {
  return makeHttpRequest(() => axios.get(`${API_URL}${path}`, {
    params,
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken()}`,
    }
  }));
}

export function getRequestWithBlob(path, params) {
  return makeHttpRequest(() => axios.get(`${API_URL}${path}`, {
    params,
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken()}`,
      responseType: 'blob',
    }
  }));
}

export function postRequest(path, data, options) {
  return makeHttpRequest(() => axios.post(`${API_URL}${path}`, data, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken()}`,
    }
  }));
}

export function putRequest(path, data, options) {
  return makeHttpRequest(() => axios.put(`${API_URL}${path}`, data, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken()}`,
    }
  }));
}

export function deleteRequest(path, data, options) {
  return makeHttpRequest(() => axios.delete(`${API_URL}${path}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken()}`,
    }
  }));
}