/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Row, Col, Input, Table, Space, Tooltip } from "antd";
import { PlusOutlined, EditFilled, DeleteFilled, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import UserModal from "../../common/modal/userModal";
import { CSVLink } from "react-csv";
import { isAuthorized } from "../../../utils/functions";
import { CURRENT_ROLE_NAME } from "../../../utils/constant";

const { Search } = Input;
const { Column } = Table;

let filterParams = { search: "" };

const Users = (props) => {

    const dispatch = useDispatch();
    const { isToggleLoading, toggleStatus, isCommonUpdateLoading, updateMsg } = useSelector(state => state.admin);
    const { isUserLoading, userList, } = useSelector(state => state.admin);
    const { isDeleteLoading, deleteMsg } = useSelector(state => state.common);
    const [tableData, setTableData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [rowData, setRowData] = useState("");
    const [modalType, setModalType] = useState("");

    useEffect(() => {
        getAllUsers({});
        getRoleList();
    }, []);

    useEffect(() => {
        if (!isUserLoading && userList?.length) {
            let tmpuserList = [];
            userList.map((item) => {
                let tmpObj = item;
                tmpObj.name = `${item?.first_name || ""}  ${item?.last_name || ""}`;
                tmpObj.location = `${item?.city || ""} ${item?.province?.name || ""}`;
                tmpObj.role = `${item?.role?.label || ""}`;
                tmpuserList.push(tmpObj);
                return 1;
            })
            setTableData(tmpuserList);
        }
    }, [isUserLoading, userList]);

    const getAllUsers = (params = {}) => {
        console.log(params)
        let tmpParams = { ...params };
        tmpParams.per_page = 5000;
        dispatch({ type: 'ADMIN_USERS_LIST_REQUEST', formData: tmpParams });
    }
    const getRoleList = (params = {}) => {
        let tmpParams = { ...params };
        tmpParams.per_page = 5000;
        tmpParams.only = "streamline_users";
        dispatch({ type: 'ROLE_LIST_REQUEST', formData: tmpParams });
    }

    const openModal = (record, type) => {
        setVisible(true);
        if (type === "view") {
            setRowData(record);
            dispatch({ type: "ADMIN_USERS_DETAIL_REQUEST", id: record.id });
        } else {
            setRowData(record);
        }
        setModalType(type);
    }

    useEffect(() => {
        if (!isCommonUpdateLoading && updateMsg === "success") {
            dispatch({ type: "ADMIN_DATA_CLEAR" });
            getAllUsers({});
            setVisible(false);
            setModalType("");
        }
    }, [isCommonUpdateLoading, updateMsg])

    useEffect(() => {
        if (!isDeleteLoading && deleteMsg === "success") {
            dispatch({ type: "CLEAR_COMMON" });
            getAllUsers({});
            setVisible(false);
            setModalType("");
        }
    }, [isDeleteLoading, deleteMsg]);


    useEffect(() => {
        if (!isToggleLoading && toggleStatus === "success") {
            dispatch({ type: "TOGGLE_CLEAR" })
        }
    }, [isToggleLoading, toggleStatus]);

    const onChange = (e, type) => {
        if (type === "search") {
            filterParams[type] = e;
        }
        getAllUsers(filterParams)
    }

    return (
        <div>
            <div className="sub-header">
                <p className="mb0 title">
                    Streamline Users
                </p>
            </div>
            <div className="filter mt8">
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Search
                            placeholder="Search"
                            style={{ width: "60%" }}
                            enterButton
                            onSearch={(e) => onChange(e, 'search')}
                        />
                    </Col>
                    {isAuthorized('create_users', 'admin') &&
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fr text-right">
                            <Space>
                                <Button
                                    type="primary"
                                    size="large"
                                    className="br1"
                                    htmlType="submit"
                                    onClick={() => openModal("", "add")}
                                    icon={<PlusOutlined />}
                                >
                                    New User
                                </Button>
                            </Space>
                        </Col>
                    }
                </Row>
            </div>
            <Row className="mt5">
                <Table
                    style={{ width: "100%" }}
                    dataSource={tableData}
                    loading={isUserLoading}
                    pagination={true}
                    scroll={{ x: "max-content" }}
                >

                    <Column
                        title="Name"
                        dataIndex="name"
                        key="name"
                    />
                    <Column
                        title="Email"
                        dataIndex="email"
                        key="email"
                    />

                    <Column
                        title="Role"
                        dataIndex="role"
                        key="role"
                    />

                    <Column
                        title={"Actions"}
                        key='actions'
                        width={"70px"}
                        render={(_, record) => {
                            return (
                                <Space size="middle" className={`cursor ${record.isDisabled ? 'disabled-edit' : 'not-disabled'}`}>
                                    {isAuthorized('view_users') &&
                                        <Tooltip placement="top" title={"View Detail"}>
                                            <EyeOutlined
                                                onClick={() => openModal(record, 'view')}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }
                                    {isAuthorized('update_users', 'admin') &&
                                        <Tooltip placement="top" title={"Edit"} >
                                            <EditFilled
                                                onClick={() => openModal(record, 'edit')}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }
                                    {isAuthorized('delete_users', 'admin') &&
                                        <Tooltip placement="top" color={"red"} title={"Delete"}>
                                            <DeleteFilled
                                                onClick={() => openModal(record, 'delete')}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }
                                </Space>
                            )
                        }}
                    />
                </Table>
            </Row>

            <UserModal
                userType="streamlineUser"
                visible={visible}
                rowData={rowData}
                modalType={modalType}
                setVisible={() => {
                    setRowData("");
                    setVisible(false);
                }}
            />
        </div >
    );
};
export default Users;