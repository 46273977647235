import React from "react";
import { Modal, Button } from 'antd';
import { IoCheckmarkCircleSharp, IoCloseCircleSharp } from 'react-icons/io5';

const ApproveModal = ({ visible, onCancel, forbidden = false }) => {
    return (
        <Modal
            className="success-modal-parent"
            footer={false}
            visible={visible}
            onCancel={() => onCancel(false)}
            width={600}
            maskClosable={false}
        >
            <div className="success-modal-child">
                {forbidden &&
                    <>
                        <IoCloseCircleSharp style={{ fill: "red" }} size={60} />
                        <p className="fs18 bold mt5 mb8">Unauthorized</p>
                        <Button
                            type="primary"
                            size="large"
                            className="br20 pr8 pl8"
                            htmlType="submit"
                            onClick={() => onCancel(false)}
                        >
                            Close
                        </Button>
                    </>
                }
                {!forbidden && <>
                    <IoCheckmarkCircleSharp size={60} />
                    <p className="fs18 bold mt5 mb4">We have received your fundraising request!</p>
                    <p className="mb6 text">Our Team is reviewing your details, once your fundraiser is approved, you will receive a confirmation email.</p>
                    <Button
                        type="primary"
                        size="large"
                        className="br20 pr8 pl8"
                        htmlType="submit"
                        onClick={() => onCancel(false)}
                    >
                        Close
                    </Button>
                </>}
            </div>
        </Modal>
    );
};
export default ApproveModal;
