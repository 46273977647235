/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input, Select, Row, Col, Spin, Tag } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formatDate, formatDateTime } from '../../../utils/functions';

const { Option } = Select;

const FundraisingTypeModal = (props) => {
    const { visible, setVisible, modalType, rowData } = props;
    const { isDeleteLoading } = useSelector(state => state.common);
    const { isFundTypeDetailLoading, fundTypeDetail, isFundraisingUpdateLoading } = useSelector(state => state.admin);

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [pageFundraisingDetail, setPageFundraisingDetail] = useState({});

    useEffect(() => {
        if (modalType === "view") {
            if (!isFundTypeDetailLoading && Object.keys(fundTypeDetail).length > 0) {
                console.log("fundTypeDetail");
                console.log(fundTypeDetail);
                setPageFundraisingDetail(fundTypeDetail);
            }
        }
    }, [isFundTypeDetailLoading, fundTypeDetail])

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                console.log(values)
                let tmpValues = {};
                for (const property in values) {
                    if (values[property] !== undefined) {
                        tmpValues[property] = values[property];
                    }
                }
                tmpValues.auto_approve = tmpValues.auto_approve === "Yes" ? true : false;
                if (modalType === "edit") {
                    dispatch({ type: 'UPDATE_FUNDRAISING_TYPE_REQUEST', formData: tmpValues, 'requestType': 'put', id: rowData.id });
                } else if (modalType === "delete") {
                    dispatch({ type: 'DELETE_REQUEST', id: rowData.id, deleteType: 'fundraisingType' });
                } else {
                    tmpValues.status = 'active';
                    dispatch({ type: 'UPDATE_FUNDRAISING_TYPE_REQUEST', formData: tmpValues, 'requestType': 'post' });
                }
            })
            .catch((errorInfo) => {
                console.log(errorInfo)
            });
    }

    useEffect(() => {
        if (rowData !== "" && Object.keys(rowData).length > 0) {
            console.log(rowData);
            form.setFieldsValue({
                name: rowData?.name || "",
                data: rowData?.data || [],
                auto_approve: rowData?.auto_approve || "",
            });
        }
    }, [rowData])

    return (
        <Modal
            className={`prize-modal step4-modal org-modal ${modalType === "view" ? "view-modal" : ""}`}
            title={`${modalType === "view" ? "" : modalType === "add" ? "Create New Fundraising Type" : modalType === "edit" ? "Edit Fundraising Type" : modalType === "delete" ? "Are you sure?" : ""}`}
            open={visible}
            onOk={() => handleSubmit()}
            onCancel={() => {
                dispatch({ type: "CLEAR_COMMON" });
                setVisible(false);
                form.resetFields();
            }}
            centered
            width={650}
            okText={`${modalType === "delete" ? "Delete" : modalType === "edit" ? 'Update' : "Save"}`}
            okButtonProps={{ disabled: isFundraisingUpdateLoading || isDeleteLoading, loading: isFundraisingUpdateLoading || isDeleteLoading }}
            cancelText="Cancel"
            maskClosable={modalType === "view" ? true : false}

        >
            {(modalType === "edit" || modalType === "add") &&
                <div className="mt4">
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        form={form}
                        layout="vertical"
                    >
                        <Form.Item
                            name="name"
                            label={"Type name"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter type name'
                                },
                                {
                                    max: 100,
                                    message: 'Maximum 100 characters only allowed!',
                                }]
                            }
                        >
                            <Input size="large" placeholder={"Type name"} />
                        </Form.Item>

                        <Form.Item
                            name="data"
                            label="Category"
                            rules={[{ required: false, message: 'Enter category name' }]}
                        >

                            <Select
                                mode="tags"
                                placeholder="Enter category"
                                size="large"
                                showSearch
                                autoComplete="new-state"
                                filterOption={(input, option) => option?.children?.toLowerCase().includes(input?.toLowerCase())}
                            >
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="auto_approve"
                            label="Auto approve"
                            rules={[{ required: true, message: 'Select option' }]}
                        >

                            <Select
                                placeholder="Select"
                                size="large"
                                showSearch
                                autoComplete="new-state"
                                filterOption={(input, option) => option?.children?.toLowerCase().includes(input?.toLowerCase())}
                            >
                                <Option value="Yes">Yes</Option>
                                <Option value="No">No</Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </div>
            }
            {modalType === "view" &&
                <div className="mt4">
                    {isFundTypeDetailLoading && <div className='no-data'><Spin /></div>}
                    {!isFundTypeDetailLoading &&
                        <>
                            <Row className='section1'>
                                <Col xs={24} sm={24} md={20} lg={20} xl={20} className="org-view">
                                    <p className='title'>{pageFundraisingDetail.name}</p>
                                    <p className='mb2'><b>Auto approve:</b>&nbsp;&nbsp;{pageFundraisingDetail.auto_approve}</p>
                                    <p className='mb2'><b>Category:</b>&nbsp;&nbsp;{pageFundraisingDetail?.data && pageFundraisingDetail.data.length > 0 ? pageFundraisingDetail.data.join(" | ") : ""} </p>
                                    <p className='mb0'><b>Created at:</b>&nbsp;&nbsp;{formatDateTime(pageFundraisingDetail?.created_at)} </p>
                                    <p className='mb5'><b>Updated at:</b>&nbsp;&nbsp;{formatDateTime(pageFundraisingDetail?.updated_at)} </p>
                                </Col>
                                <Col xs={24} sm={24} md={4} lg={4} xl={4} className="org-view">
                                    <p className='mb2 mt2'>
                                        <Tag color={pageFundraisingDetail.status === "active" ? 'green' : 'default'}>
                                            {pageFundraisingDetail.status === "active" ? "Active" : "Archived"}
                                        </Tag>
                                    </p>
                                </Col>
                            </Row>
                            <Row className=''>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="org-view">
                                    <p className='fs18 bold mt5'>Fundraisers</p>
                                    {pageFundraisingDetail.fundraisers !== undefined && pageFundraisingDetail.fundraisers !== null && pageFundraisingDetail.fundraisers.length > 0 && pageFundraisingDetail.fundraisers.map((fData) => {
                                        return (
                                            <div className=''>
                                                <p className='mb0 title fs16'>{fData?.title}</p>
                                                <p className="mb4 date-style">{
                                                    fData.start_date !== null ? `${formatDate(fData.start_date)} • ${formatDate(fData.end_date)}` : ''}
                                                </p>
                                            </div>
                                        )
                                    })}
                                    {pageFundraisingDetail.fundraisers !== undefined && pageFundraisingDetail.fundraisers !== null && pageFundraisingDetail.fundraisers.length === 0 &&
                                        <p>Not used in any fundraiser</p>
                                    }
                                </Col>
                            </Row>
                        </>
                    }
                </div>
            }
            {modalType === "delete" &&
                <div className="mt2">
                    <p>You want to delete this fundraising type</p>
                </div>
            }


        </Modal>
    );
};

export default FundraisingTypeModal;