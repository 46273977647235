const initialState = {
    isCityLoading: false,
    cityList: [],

    isProvinceLoading: false,
    provinceList: [],

    isDeleteLoading: false,
    deleteMsg: "",
    uploadImageURL: "",
    isImageUploading: false,

    currentStep: 1,
    paymentType: '',

    paymentObject: {},
    isCourseLoading: false,
    courseList: [],

    isSecretLoading: true,
    paymentSecretKey: '',
    isUpdatePaymentLoading: true,
    updatePaymentMsg: '',
    isTypeUpdating: false,

    isCourseUpdating: false,
    courseUpdateMsg: '',

    isSellerLoading: true,
    sellerList: [],
    isWindLoading: false,
    windDirectionList: [],

    isExistCourseLoading: false,
    existCourseList: [],

    isSupportLoading: false,
    supportMsg: '',

    isSellerCreateLoading: false,
    statusMsg: '',

    isClosing: false,

    isImportLoading: false,
    isProgressLoading: false,
    fundProgress: {},
    isReferencesLoading: false,
    referencesList: [],
    isCommentLoading: false,
    fundProgressComments: {},

    isGetPaymentsLoading: false,
    paymentsData: [],
    isStart: false,

    isETransferLoading: false,

    isDocUploadLoading: false,
    docURL: "",

    error: "",
    isTokenLoading: false,
    paymentToken: "",

    isPaymentAmountLoading: false,
    paymentAmount: {},
    isPaymentCouponAmountLoading: false,
    isCouponApplyLoading: false,
    isCouponRemoveLoading: false,

    isCouponApplied: false,
    couponData: {}
};

const reducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case "CITY_LIST_REQUEST":
            state = {
                ...state,
                isCityLoading: true,
                cityList: [],

            };
            break;

        case "CITY_LIST_SUCCESS":
            state = {
                ...state,
                isCityLoading: false,
                cityList: action.data
            };
            break;

        case "CITY_LIST_ERROR":
            state = {
                ...state,
                isCityLoading: false,
                cityList: [],
                error: action.error
            };
            break;

        case "PROVINCE_LIST_REQUEST":
            state = {
                ...state,
                isProvinceLoading: true,
                provinceList: [],

            };
            break;

        case "PROVINCE_LIST_SUCCESS":
            state = {
                ...state,
                isProvinceLoading: false,
                provinceList: action.data
            };
            break;

        case "PROVINCE_LIST_ERROR":
            state = {
                ...state,
                isProvinceLoading: false,
                provinceList: [],
                error: action.error
            };
            break;

        case "DELETE_REQUEST":
            state = {
                ...state,
                isDeleteLoading: true,
                deleteMsg: ''
            };
            break;

        case "DELETE_SUCCESS":
            state = {
                ...state,
                isDeleteLoading: false,
                deleteMsg: 'success'
            };
            break;

        case "DELETE_ERROR":
            state = {
                ...state,
                isDeleteLoading: false,
                deleteMsg: '',
                error: action.error
            };
            break;

        case "GET_COURSE_LIST_REQUEST":
            state = {
                ...state,
                isCourseLoading: true,
                courseList: []
            };
            break;

        case "GET_COURSE_LIST_SUCCESS":
            state = {
                ...state,
                isCourseLoading: false,
                courseList: action.data
            };
            break;

        case "GET_COURSE_LIST_ERROR":
            state = {
                ...state,
                isCourseLoading: false,
                paymentSecretKey: '',
                error: action.error
            };
            break;

        case "GET_PAYMENT_INTENT_REQUEST":
            state = {
                ...state,
                isSecretLoading: true,
                paymentSecretKey: ''
            };
            break;

        case "GET_PAYMENT_INTENT_SUCCESS":
            state = {
                ...state,
                isSecretLoading: false,
                paymentSecretKey: action.data.ticket || ""
            };
            break;

        case "GET_PAYMENT_INTENT_ERROR":
            state = {
                ...state,
                isSecretLoading: false,
                paymentSecretKey: '',
                error: action.error
            };
            break;

        case "PAYMENT_UPDATE_REQUEST":
            state = {
                ...state,
                isUpdatePaymentLoading: true,
                updatePaymentMsg: ''
            };
            break;

        case "PAYMENT_UPDATE_SUCCESS":
            state = {
                ...state,
                isUpdatePaymentLoading: false,
                updatePaymentMsg: "success"
            };
            break;

        case "PAYMENT_UPDATE_ERROR":
            state = {
                ...state,
                isUpdatePaymentLoading: false,
                updatePaymentMsg: '',
                error: action.error
            };
            break;

        // --- Update image stepi
        case "UPLOAD_DOCUMENT_REQUEST":
            state = {
                ...state,
                isImageUploading: true,
                uploadImageURL: ""
            };
            break;

        // --- Update image stepi
        case "UPLOAD_NEW_DOCUMENT_REQUEST":
            state = {
                ...state,
                isDocUploadLoading: true,
                docURL: ""
            };
            break;

        case "UPLOAD_NEW_DOCUMENT_SUCCESS":
            state = {
                ...state,
                isDocUploadLoading: false,
                docURL: action.data
            };
            break;

        case "UPLOAD_NEW_DOCUMENT_ERROR":
            state = {
                ...state,
                isDocUploadLoading: false,
                docURL: ""
            };
            break;

        // --- Update image stepi
        case "UPLOAD_IMAGE_REQUEST":
            state = {
                ...state,
                isImageUploading: true,
                uploadImageURL: ""
            };
            break;

        case "UPLOAD_IMAGE_SUCCESS":
            state = {
                ...state,
                isImageUploading: false,
                uploadImageURL: action.data
            };
            break;

        case "UPLOAD_IMAGE_ERROR":
            state = {
                ...state,
                isImageUploading: false,
                uploadImageURL: ""
            };
            break;

        case "UPDATE_PAYMENT_TYPE_REQUEST":
            state = {
                ...state,
                isTypeUpdating: true,
                updateObjectMsg: ""
            };
            break;

        case "UPDATE_PAYMENT_TYPE_SUCCESS":
            state = {
                ...state,
                isTypeUpdating: false,
                updateObjectMsg: "success"
            };
            break;

        case "UPDATE_PAYMENT_TYPE_ERROR":
            state = {
                ...state,
                isTypeUpdating: false,
                updateObjectMsg: ""
            };
            break;

        case "UPDATE_PAYMENT_TYPE":
            state = {
                ...state,
                paymentType: action.data
            };
            break;

        case "UPDATE_PAYMENT_OBJECT":
            state = {
                ...state,
                paymentObject: action.data,
                updateObjectMsg: "success"
            };
            break;

        case "UPDATE_STEP":
            state = {
                ...state,
                currentStep: action.data
            };
            break;

        case "UPDATE_COURSE_DATA_REQUEST":
            state = {
                ...state,
                isCourseUpdating: true,
                courseUpdateMsg: ''
            };
            break;

        case "UPDATE_COURSE_DATA_SUCCESS":
            state = {
                ...state,
                isCourseUpdating: false,
                courseUpdateMsg: 'success'
            };
            break;

        case "UPDATE_COURSE_DATA_ERROR":
            state = {
                ...state,
                isCourseUpdating: false,
                courseUpdateMsg: '',
            };
            break;

        case "CLEAR_COMMON":
            state = {
                ...state,
                isDeleteLoading: false,
                deleteMsg: '',
                uploadImageURL: "",
                paymentType: {},
                paymentObject: {},
                updatePaymentMsg: "",
                currentStep: 1,
                supportMsg: "",
                statusMsg: "",
                docURL: ""
            };
            break;

        case "CLEAR_UPDATE_MSG":
            state = {
                ...state,
                updateObjectMsg: "",

            };
            break;

        case "GET_SELLER_LIST_REQUEST":
            state = {
                ...state,
                isSellerLoading: true,
                sellerList: [],
            };
            break;

        case "GET_SELLER_LIST_SUCCESS":
            state = {
                ...state,
                isSellerLoading: false,
                sellerList: action.data
            };
            break;

        case "GET_SELLER_LIST_ERROR":
            state = {
                ...state,
                isSellerLoading: false,
                sellerList: [],
                error: action.error
            };
            break;

        case "GET_WIND_DIRECTION_LIST_REQUEST":
            state = {
                ...state,
                isWindLoading: true,
                windDirectionList: []
            };
            break;

        case "GET_WIND_DIRECTION_LIST_SUCCESS":
            state = {
                ...state,
                isWindLoading: false,
                windDirectionList: action.data
            };
            break;

        case "GET_WIND_DIRECTION_LIST_ERROR":
            state = {
                ...state,
                isWindLoading: false,
                windDirectionList: '',
            };
            break;

        case "GET_EXIST_COURSE_REQUEST":
            state = {
                ...state,
                isExistCourseLoading: true,
                existCourseList: []
            };
            break;

        case "GET_EXIST_COURSE_SUCCESS":
            state = {
                ...state,
                isExistCourseLoading: false,
                existCourseList: action.data
            };
            break;

        case "GET_EXIST_COURSE_ERROR":
            state = {
                ...state,
                isExistCourseLoading: false,
                existCourseList: [],
            };
            break;

        case "SELLER_IMPORT_REQUEST":
            state = {
                ...state,
                isImportLoading: true,
                statusMsg: '',
            };
            break;

        case "SELLER_IMPORT_SUCCESS":
            state = {
                ...state,
                isImportLoading: false,
                statusMsg: "success"
            };
            break;

        case "SELLER_IMPORT_ERROR":
            state = {
                ...state,
                isImportLoading: false,
                statusMsg: '',
            };
            break;

        case "CONTACT_SUPPORT_REQUEST":
            state = {
                ...state,
                isSupportLoading: true,
                supportMsg: '',
            };
            break;

        case "CONTACT_SUPPORT_SUCCESS":
            state = {
                ...state,
                isSupportLoading: false,
                supportMsg: "success"
            };
            break;

        case "CONTACT_SUPPORT_ERROR":
            state = {
                ...state,
                isSupportLoading: false,
                supportMsg: '',
            };
            break;

        case "CLOSE_FUNDRAISER_REQUEST":
            state = {
                ...state,
                isClosing: true,
                statusMsg: '',
            };
            break;

        case "CLOSE_FUNDRAISER_SUCCESS":
            state = {
                ...state,
                isClosing: false,
                statusMsg: "success"
            };
            break;

        case "CLOSE_FUNDRAISER_ERROR":
            state = {
                ...state,
                isClosing: false,
                statusMsg: '',
            };
            break;

        /** **********/
        case "START_FUNDRAISER_REQUEST":
            state = {
                ...state,
                isStart: true,
                statusMsg: '',
            };
            break;

        case "START_FUNDRAISER_SUCCESS":
            state = {
                ...state,
                isStart: false,
                statusMsg: "success"
            };
            break;

        case "START_FUNDRAISER_ERROR":
            state = {
                ...state,
                isStart: false,
                statusMsg: '',
            };
            break;

        case "FUNDRAISING_PROGRESS_REQUEST":
            state = {
                ...state,
                isProgressLoading: true,
                fundProgress: {},
            };
            break;

        case "FUNDRAISING_PROGRESS_SUCCESS":
            state = {
                ...state,
                isProgressLoading: false,
                fundProgress: action.data
            };
            break;

        case "FUNDRAISING_PROGRESS_ERROR":
            state = {
                ...state,
                isProgressLoading: false,
                fundProgress: {},
            };
            break;

        /***********************/
        case "FUNDRAISING_PROGRESS_COMMENT_REQUEST":
            state = {
                ...state,
                isCommentLoading: true,
                fundProgressComments: {},
            };
            break;

        case "FUNDRAISING_PROGRESS_COMMENT_SUCCESS":
            state = {
                ...state,
                isCommentLoading: false,
                fundProgressComments: action.data
            };
            break;

        case "FUNDRAISING_PROGRESS_COMMENT_ERROR":
            state = {
                ...state,
                isCommentLoading: false,
                fundProgressComments: {},
            };
            break;

        case "REFERENCES_LIST_REQUEST":
            state = {
                ...state,
                isReferencesLoading: true,
                referencesList: [],
            };
            break;

        case "REFERENCES_LIST_SUCCESS":
            state = {
                ...state,
                isReferencesLoading: false,
                referencesList: action.data
            };
            break;

        case "REFERENCES_LIST_ERROR":
            state = {
                ...state,
                isReferencesLoading: false,
                referencesList: [],
            };
            break;

        case "PAYMENT_TOKEN_REQUEST":
            state = {
                ...state,
                isTokenLoading: true,
                paymentToken: "",
            };
            break;

        case "PAYMENT_TOKEN_SUCCESS":
            state = {
                ...state,
                isTokenLoading: false,
                paymentToken: action.data
            };
            break;

        case "PAYMENT_TOKEN_ERROR":
            state = {
                ...state,
                isTokenLoading: false,
                paymentToken: "",
            };
            break;

        case "CREATE_SELLER_REQUEST":
            state = {
                ...state,
                isSellerCreateLoading: true,
                statusMsg: '',
            };
            break;

        case "CREATE_SELLER_SUCCESS":
            state = {
                ...state,
                isSellerCreateLoading: false,
                statusMsg: "success"
            };
            break;

        case "CREATE_SELLER_ERROR":
            state = {
                ...state,
                isSellerCreateLoading: false,
                statusMsg: '',
            };
            break;

        /**------------**/
        case "FUNDRAISER_PAYMENT_REQUEST":
            state = {
                ...state,
                isGetPaymentsLoading: true,
                paymentsData: [],
            };
            break;

        case "FUNDRAISER_PAYMENT_SUCCESS":
            state = {
                ...state,
                isGetPaymentsLoading: false,
                paymentsData: action.data
            };
            break;

        case "FUNDRAISER_PAYMENT_ERROR":
            state = {
                ...state,
                isGetPaymentsLoading: false,
                paymentsData: [],
            };
            break;

        /**------------**/
        case " E_TRANSFER_CREATE_REQUEST":
            state = {
                ...state,
                isETransferLoading: true,
            };
            break;

        case " E_TRANSFER_CREATE_SUCCESS":
            state = {
                ...state,
                isETransferLoading: false,
            };
            break;

        case " E_TRANSFER_CREATE_ERROR":
            state = {
                ...state,
                isETransferLoading: false,
            };
            break;

        /**------------**/
        case "PAYMENT_AMOUNT_REQUEST":
            state = {
                ...state,
                isPaymentAmountLoading: true,
                paymentAmount: {},
            };
            break;

        case "PAYMENT_AMOUNT_SUCCESS":
            state = {
                ...state,
                isPaymentAmountLoading: false,
                paymentAmount: action.data
            };
            break;

        case "PAYMENT_AMOUNT_ERROR":
            state = {
                ...state,
                isPaymentAmountLoading: false,
                paymentAmount: {},
            };
            break;

        /**------------**/
        case "PAYMENT_COUPON_REQUEST":
            state = {
                ...state,
                isPaymentCouponAmountLoading: true,
                couponData: {}
            };
            break;

        case "PAYMENT_COUPON_SUCCESS":
            state = {
                ...state,
                isPaymentCouponAmountLoading: false,
                isCouponApplied: action.data.code ? true : false,
                couponData: action.data,
            };
            break;

        case "PAYMENT_COUPON_ERROR":
            state = {
                ...state,
                isPaymentCouponAmountLoading: false,
                couponData: {}
            };
            break;

        /**------------**/

        case "PAYMENT_COUPON_APPLY_REQUEST":
            state = {
                ...state,
                isCouponApplyLoading: true,
            };
            break;

        case "PAYMENT_COUPON_APPLY_SUCCESS":
            state = {
                ...state,
                isCouponApplyLoading: false,
                isCouponApplied: action.data.coupon.code ? true : false,
                couponData: action.data.coupon
            };
            break;

        case "PAYMENT_COUPON_APPLY_ERROR":
            state = {
                ...state,
                isCouponApplyLoading: false,
            };
            break;

        /**------------**/

        case "PAYMENT_COUPON_REMOVE_REQUEST":
            state = {
                ...state,
                isCouponRemoveLoading: true,
            };
            break;

        case "PAYMENT_COUPON_REMOVE_SUCCESS":
            state = {
                ...state,
                isCouponRemoveLoading: false,
                isCouponApplied: false,
                couponData: {}
            };
            break;

        case "PAYMENT_COUPON_REMOVE_ERROR":
            state = {
                ...state,
                isCouponRemoveLoading: false,
            };
            break;

        case "CLEAR_COURSE":
            state = {
                ...state,
                existCourseList: [],
                courseUpdateMsg: "",
                courseList: []
            };
            break;

        default:

    }
    return state;
};

export default reducer;