/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input, Button, Row, Col, Upload, Space } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSVLink } from "react-csv";

const SellerModal = (props) => {
    const { visible, setVisible, modalType, rowData } = props;
    const { isSellerCreateLoading, isImportLoading } = useSelector(state => state.common);

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [fileName, setfileName] = useState("");
    const [fileObject, setFileObject] = useState({});
    const [sampleExportData, setSampleExportData] = useState([]);
    const [exportLoading, setExportLoading] = useState(false);
    const [footer, setFooter] = useState("");

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                let tmpValues = {};
                for (const property in values) {
                    if (values[property] !== undefined) {
                        tmpValues[property] = values[property];
                    }
                }
                if (modalType === "edit") {
                    dispatch({ type: 'CREATE_SELLER_REQUEST', formData: tmpValues, 'requestType': 'put', id: rowData.id });
                } else {
                    dispatch({ type: 'CREATE_SELLER_REQUEST', formData: tmpValues, 'requestType': 'post' });
                }
                setTimeout(() => {
                    form.resetFields();
                }, 1000);
            })
            .catch((errorInfo) => {
                console.log(errorInfo)
            });
    }

    useEffect(() => {
        if (rowData !== "" && Object.keys(rowData).length > 0) {
            form.setFieldsValue({
                name: rowData?.name || "",
                email: rowData?.email || "",
                phone: rowData?.phone || "",
            });
        }
    }, [rowData])

    const handleFileChange = (info) => {

        if (info.file.status === "uploading") {
            return;
        }
        if (info.file.status === "done") {
            setFileObject(info.file.originFileObj);
            setfileName(info.file.name);
        }
    };

    const importFile = () => {
        const formData = new FormData();
        formData.append("file", fileObject);
        dispatch({ type: 'SELLER_IMPORT_REQUEST', formData: formData, 'requestType': 'post' });
    }

    const downladCSV = () => {
        setExportLoading(true);
        setSampleExportData([["name", "email", "phone"]]);
        setTimeout(() => {
            setExportLoading(false)
            document.getElementById('exportToCSVLink').click();
        }, 1000);
    }
    useEffect(() => {
        if (modalType === "import") {
            setFooter("footer");
        }
    }, [modalType])

    return (
        <Modal
            className={`prize-modal step4-modal org-modal ${modalType === "import" ? "import" : ""}`}
            title={`${modalType === "view" ? "" : modalType === "add" ? "Create New Seller" : modalType === "edit" ? "Edit Seller" : modalType === "import" ? "Import Seller Contacts" : ""}`}
            open={visible}
            onOk={() => handleSubmit()}
            onCancel={() => {
                dispatch({ type: "CLEAR_COMMON" });
                setVisible(false);
                form.resetFields();
            }}
            centered
            width={650}
            okText="Save"
            okButtonProps={{ disabled: isSellerCreateLoading || isImportLoading, loading: isSellerCreateLoading || isImportLoading }}
            cancelText="Cancel"
            maskClosable={modalType === "view" ? true : false}

        >
            {(modalType === "edit" || modalType === "add") &&
                <div className="mt4">
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        form={form}
                        layout="vertical"
                    >
                        <Form.Item
                            label="Seller Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter seller name'
                                },
                                {
                                    max: 100,
                                    message: 'Maximum 100 characters only allowed!',
                                }]
                            }
                        >
                            <Input size="large" placeholder={"Seller Name"} />
                        </Form.Item>


                        <Form.Item
                            label="Email"
                            className="mt5"
                            name="email"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please enter email address'
                                },
                                {
                                    type: 'email',
                                    message: 'Please enter valid email address',
                                },
                            ]}
                        >
                            <Input size="large" placeholder={"Email Address"} autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                            label="Phone"
                            name="phone"
                            rules={[{ required: false, message: 'Please enter phone number' }]}
                        >
                            <Input size="large" prefix="(+1)" placeholder={"Phone"} />
                        </Form.Item>

                    </Form>
                </div>
            }

            {modalType === "import" &&
                <div className="mt4">
                    <CSVLink id="exportToCSVLink" filename="Sellers.csv" data={sampleExportData} />
                    <Row className='section11'>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <p>Download our excel template, enter all your seller information onto the spreadsheet, save, then upload the file.</p>
                        </Col>

                    </Row>
                    <Row className='section22 mt5 mb5'>
                        <Upload
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            name="avatar"
                            className="avatar-uploader"
                            onChange={handleFileChange}
                            showUploadList={true}
                            maxCount={1}
                            listType="picture"
                            customRequest={({ file, onSuccess }) =>
                                setTimeout(() => onSuccess("ok"), 0)
                            }
                        >
                            <Button>Choose File</Button>
                        </Upload>
                    </Row>
                    <Row>
                        <p>Don't have the contacts template? <span className="link pointer" onClick={() => downladCSV()}>{exportLoading ? "Downloading..." : "Download it now"}</span></p>
                    </Row>
                    <div className='center'>
                        <Row className='section22 mt5 mb5 center'>
                            <Space className='center'>
                                <Button
                                    type="secondary"
                                    size="large"
                                    className="br20 pr6 pl6"
                                    htmlType="submit"
                                    onClick={() => {
                                        dispatch({ type: "CLEAR_COMMON" });
                                        setVisible(false);
                                        form.resetFields();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    size="large"
                                    className="br20"
                                    htmlType="submit"
                                    disabled={fileName === "" || isImportLoading}
                                    loading={isImportLoading}
                                    onClick={() => importFile()}
                                >
                                    Upload
                                </Button>
                            </Space>
                        </Row>
                    </div>
                </div>
            }

        </Modal>
    );
};

export default SellerModal;