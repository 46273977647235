import { put, takeLatest, call, delay } from 'redux-saga/effects'
import { message } from 'antd';
//Import API Service.
import { getRequestAPI } from '../../services/API/api';

function* getCampaignList({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `fundraisers`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'CAMPGAIGNS_LIST_SUCCESS', data: response.data || [] });
        } else {
            yield put({ type: 'CAMPGAIGNS_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'CAMPGAIGNS_LIST_ERROR', error: error.message || "" });
    }
}
function* getStatusCount({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `status-count`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'STATUS_COUNT_SUCCESS', data: response.data || [] });
        } else {
            yield put({ type: 'STATUS_COUNT_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'STATUS_COUNT_ERROR', error: error.message || "" });
    }
}


function* watchDonorConfigSagasRequests() {
    yield takeLatest('CAMPGAIGNS_LIST_REQUEST', getCampaignList);
    yield takeLatest('STATUS_COUNT_REQUEDT', getStatusCount);
}

function* donorConfigSagas() {
    yield watchDonorConfigSagasRequests();
}

export default donorConfigSagas;