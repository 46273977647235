/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Row, Col } from 'antd';
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import PasswordComponent from '../common/password';

function ResetPasswordComponent(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();
    const [type, setType] = useState("")
    const [fieldValue, setFieldValue] = useState({})

    const { isRequestSending, statusMsg, isResendLoading, resendMsg } = useSelector(state => state.auth);

    const onFinish = (values) => {
        setFieldValue(values)
        dispatch({ type: 'SET_PASSWORD_REQUEST', formData: values, pageType: type });
    };

    useEffect(() => {
        if (!isRequestSending && statusMsg === "success") {
            if (type === "set") {
                message.success("Account is verified and password is set successfully");
            } else {
                message.success("Password reset successfully, please login to access");
            }
            dispatch({ type: "CLEAR_INVITE" });
            navigate('/login');
        } else if (!isRequestSending && statusMsg?.errors) {
            let tmpObj = statusMsg.errors !== undefined ? statusMsg.errors : statusMsg?.message;
            let tmpError = [];
            for (const property in tmpObj) {
                tmpError.push({
                    name: property,
                    value: fieldValue[property],
                    errors: tmpObj[property]
                })
            }
            form.setFields(tmpError);
        } else if (!isRequestSending && statusMsg?.message) {
            message.error({ content: statusMsg?.message, className: 'error-class', duration: 3 });
        }
        dispatch({ type: "CLEAR_INVITE" });
    }, [isRequestSending, statusMsg]);


    useEffect(() => {
        if (!isResendLoading && resendMsg === "success") {
            message.success(`Verification code send successfully to ${searchParams.get('email') !== null ? searchParams.get('email') : "email"}`);
        } else if (resendMsg?.message || resendMsg?.errors) {
            message.error({ content: statusMsg?.message || "Something went wrong", className: 'error-class', duration: 3 });
        }
        dispatch({ type: "CLEAR_INVITE" });
    }, [isResendLoading, resendMsg]);

    const onFinishFailed = (errorInfo) => { console.log('Failed:', errorInfo) };

    useEffect(() => {
        setType(props.type);
    }, [])

    const resendCode = (e) => {
        e.preventDefault()
        dispatch({ type: 'RESEND_CODE_REQUEST', formData: { email: searchParams.get('email') !== null ? searchParams.get('email') : "" } });
    }

    return (
        <div className="forget-template">
            <div className="center-section">
                <div className="center-section-child">
                    <p className="mb0 title text-center">Streamline Fundraising</p>
                    <p className="mb0 sub-title text-center">
                        {type === "set" ? "Set Password" : "Reset Password"}</p>
                    <div className="mt10">
                        <Form
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            form={form}
                        >
                            <Form.Item
                                name="email"
                                rules={[{ required: false }]}
                                initialValue={searchParams.get('email') !== null ? searchParams.get('email') : ""}
                            >
                                <Input disabled />
                            </Form.Item>

                            <Form.Item
                                extra='Pin sent to your email, if you didn’t receive, click "Resend Pin" below'
                                name="pin"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your pin!',
                                    },
                                ]}
                            >
                                <Input placeholder="Pin" showCount maxLength={5} />
                            </Form.Item>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <PasswordComponent />
                                    {/* {isMsgShow && content} */}
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="password_confirmation"
                                        dependencies={['password']}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please confirm your password',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password placeholder="Confirm Password" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24} className={"mt5"}>
                                <Col span={12}>
                                    <Button
                                        style={{ width: "100%" }}
                                        type="primary"
                                        size="large"
                                        className="login-btn rbg"
                                        htmlType="button"
                                        disabled={isResendLoading}
                                        loading={isResendLoading}
                                        onClick={(e) => resendCode(e)}
                                    >
                                        Resend Pin
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button
                                        style={{ width: "100%" }}
                                        type="primary"
                                        size="large"
                                        className="login-btn"
                                        htmlType="submit"
                                        disabled={isRequestSending}
                                        loading={isRequestSending}
                                    >
                                        {type === "set" ? "Set Password" : "Reset Password"}
                                    </Button>
                                </Col>
                            </Row>
                            <div className="login-btn-section mt5">
                                <Link to={'/login'} className="forget-text">Back to Login</Link>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ResetPasswordComponent;