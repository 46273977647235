import React, { useEffect, useState } from "react";
import { Card, Collapse, Space, Row, Col, Spin, Avatar, Tag } from "antd";
import StepTemplate from './stepTemplate';
import { PREVIEW_TITLE, PAYMENT_STATUS, PRIZE_LIST } from '../../../utils/constant';
import { displayStartEndDate, displayStatusText, formatAmount, showAvtarOrgName } from '../../../utils/functions';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import otherImg from '../../../assets/other.png';
import PrizeImg from '../../../assets/prize.png';
import ProcessingModal from "../fundraisingDetails/modals/processingModal";
import { PaymentModal } from "../../common/modal/paymentModal";
import NextPrevButton from "./nextPrevButton";
import ETransferModal from "../../common/modal/eTransferModal";
const { Panel } = Collapse;

const Step8 = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [showModal, setShowModal] = useState(false);
    const { isGetFundraiserLoading, fundraiserData } = useSelector(state => state.fundraiser);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [openETransferModal, setOpenETransferModal] = useState(false);

    useEffect(() => {
        if (id) {
            dispatch({ type: 'GET_FUNDRAISER_DATA_REQUEST', formData: { id: id } });
        }
    }, []);

    useEffect(() => {
        if (!isGetFundraiserLoading && Object.keys(fundraiserData).length > 0) {
            if ((fundraiserData.paid_status === PAYMENT_STATUS.FULLY_PAIED || fundraiserData.paid_status === PAYMENT_STATUS.PARTAILY_PAIED || fundraiserData?.e_transfers?.length) && fundraiserData.e_transfers.some((val) => val.status === 'pending')) {
                navigate(`/fundraiser-details/${id}`);
            }
        }
    }, [isGetFundraiserLoading, fundraiserData])

    const genExtra = (redirectId) => (
        <EditOutlined
            onClick={(event) => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
                navigate(`/new-fundraising/${id}?step=${redirectId}`)
            }}
        />
    );

    const displayPreview = () => {
        return (
            <>
                {PREVIEW_TITLE.map((item, index) => {
                    return (
                        <Collapse defaultActiveKey={[index]} style={{ width: "700px" }}>
                            <Panel header={`${item.previewTitle}`} key={index} extra={item.stepId !== 5 && genExtra(item.stepId)}>
                                {loadStepData(item)}
                            </Panel>
                        </Collapse>
                    )
                })}
            </>
        )
    }

    const showStatus = (fundData) => {
        if (fundData.paid_status !== "pending") {
            return <Tag color={"green"} className={"status-success"}>{displayStatusText(fundData.paid_status)}</Tag>
        } else {
            return <Tag color={"red"} className={"status-error"}>Pending</Tag>
        }
    }

    const displayPrize = (item, item2) => {
        if (item.id === item2.rank) {
            return (<>
                <div className="existing-prize">
                    <img src={item2?.image !== undefined && item2?.image !== null ? item2.image : PrizeImg} alt="prize" />
                    <div>
                        <p className="mb2 text">{item2.name}</p>
                        <p className="sub-text">{item2.description}</p>
                    </div>
                </div>
            </>)
        } else {
            return null;
        }
    }
    const loadStepData = (item) => {
        if (item.id === 1) { // fundraiser details
            return (
                <Row gutter={[]} >
                    <Col span={8} className="display-text"><p>Fundraising Type</p></Col>
                    <Col span={16} className="display-subtext"><p>{fundraiserData?.fundraiser_type?.name || ""}</p></Col>

                    <Col span={8} className="display-text"><p>Campaign Title</p></Col>
                    <Col span={16} className="display-subtext"><p><p>{fundraiserData?.title || ""}</p></p></Col>

                    <Col span={8} className="display-text"><p>Campaign Description</p></Col>
                    <Col span={16} className="display-subtext"><p><p>{fundraiserData?.description || ""}</p></p></Col>

                    <Col span={8} className="display-text"><p>Start / End Date</p></Col>
                    <Col span={16} className="display-subtext"><p>{displayStartEndDate(fundraiserData)}</p></Col>

                    <Col span={8} className="display-text"><p>Number of Estimated Sellers</p></Col>
                    <Col span={16} className="display-subtext"><p>{fundraiserData?.no_of_sellers}</p></Col>

                    <Col span={8} className="display-text title-case"><p>Progress frequency</p></Col>
                    <Col span={16} className="display-subtext"><p className="title-case">{fundraiserData?.updates_frequency || "Not selected"}</p></Col>

                </Row>

            )
        } else if (item.id === 2) { // Select Package
            return (<Card style={{ width: 700 }} className={`mb4 main-card pointer maxw100`} >
                <div className="camp-card step2">
                    <div className="">
                        {/* <p className="mb1 black fs16">{fundraiserData?.fundraiser_package?.name}</p> */}
                        <p className="mb1 black fs16">Raise up to {formatAmount(fundraiserData?.fundraiser_package?.amount_to_be_generated || "")}</p>
                        <p className="mb0 sub-text">{fundraiserData?.fundraiser_package?.no_tickets_allowed} tickets typically  {fundraiserData?.fundraiser_package?.no_sellers_allowed} sellers</p>
                    </div>
                    <div className="section2">
                        <p className="mb0 amount">{formatAmount(fundraiserData?.fundraiser_package?.regular_fees || "")}</p>
                    </div>
                </div>
            </Card>)
        } else if (item.id === 3) { // Organization
            return (
                <div className="preview-org pointer mb4" key={item.id} >
                    {(fundraiserData.organization !== null) ?
                        <>
                            <div className="box1">
                                {(fundraiserData?.organization.image === null || fundraiserData?.organization.image === "") &&
                                    <Avatar size="large" width={40} className='pointer' >{showAvtarOrgName(fundraiserData?.organization?.name || "")}</Avatar>
                                }
                                {(fundraiserData?.organization.image !== null && fundraiserData?.organization.image !== "") &&
                                    <img src={fundraiserData?.organization.image} width={40} className="icon" alt="icon" />
                                }
                            </div>
                            <div className="box2">
                                <p className="mb0 black fs14 bold">{fundraiserData?.organization?.name || ""}</p>
                                <p className="mb0 sub-text"> {fundraiserData?.organization?.phone} {`${fundraiserData?.organization?.email ? ` • ` : ''}`} {fundraiserData?.organization.email}</p>
                            </div>
                        </>
                        :
                        <div className="preview-org step4">
                            <div className="box1">
                                <img src={otherImg} width={40} className="icon" alt="icon" />
                            </div>
                            <div className="box2">
                                <p className="mb0 text">Not affiliated with any organization</p>
                            </div>
                        </div>
                    }
                </div>
            )
        }
        else if (item.id === 4) { // Prize
            return (<div>
                {isGetFundraiserLoading && <Spin />}
                {!isGetFundraiserLoading && PRIZE_LIST.map((item) => {
                    return (
                        <Card style={{ width: 700 }} className="mb4 prize-card maxw100">
                            <div className="prize-card-child step5">
                                <div className="flex-center">
                                    <p className="mb1 text black">{item.title}</p>

                                </div>
                                {fundraiserData.prizes.length > 0 && fundraiserData.prizes.map((item2, index) => {
                                    return displayPrize(item, item2, index);
                                })
                                }
                            </div>
                        </Card>
                    )
                })}
            </div>)
        }
        else if (item.id === 5) {
            return (
                <Row >
                    <Col span={8} className="display-text"><p>Ticket Title</p></Col>
                    <Col span={16} className="display-subtext"><p>{fundraiserData?.ticket?.title || ""}</p></Col>

                    <Col span={8} className="display-text"><p>Shipping Address</p></Col>
                    <Col span={16} className="display-subtext"><p>{fundraiserData?.ticket?.suite_apt_no_unit || ""} {fundraiserData?.ticket?.shipping_address || ""}</p></Col>

                    <Col span={8} className="display-text"><p>Postal Code</p></Col>
                    <Col span={16} className="display-subtext"><p>{fundraiserData?.ticket?.postal_code || ""}</p></Col>

                    <Col span={8} className="display-text"><p>City</p></Col>
                    <Col span={16} className="display-subtext"><p>{fundraiserData?.ticket?.city || ""}</p></Col>

                    <Col span={8} className="display-text"><p>Province</p></Col>
                    <Col span={16} className="display-subtext"><p>{fundraiserData?.ticket?.province?.name || ""}</p></Col>

                </Row>

            )
        } else if (item.id === 6) {
            return (
                <>
                    <div className="preview-payment-card">
                        <div className="">
                            <p className="mb0 text">
                                {PAYMENT_STATUS.FULLY_PAIED === fundraiserData.payment_status ? "Full Payment" : "Partial Payment"} :
                                {PAYMENT_STATUS.FULLY_PAIED === fundraiserData.payment_status ?
                                    formatAmount(fundraiserData.fundraiser_package?.regular_fees || 0) : ` ${formatAmount(fundraiserData.fundraiser_package?.reduced_fees || 0)}`}</p>
                            <p className="mb0 sub-text">{
                                PAYMENT_STATUS.FULLY_PAIED === fundraiserData.payment_status ? fundraiserData.fundraiser_package?.full_payment_description : fundraiserData.fundraiser_package?.partial_payment_description}</p>
                            {/* {fundraiserData.paid_status !== "unpaid" &&
                                <p className="mb0 vis-ending mt4">
                                    {`${fundraiserData?.card.brand} ending with ${fundraiserData?.card.last4}`} • {`Expiry ${fundraiserData?.card.exp_month}/${fundraiserData?.card.exp_year}`}</p>
                            } */}
                        </div>
                        <div className="">
                            {showStatus(fundraiserData)}

                        </div>
                    </div>

                    {/* {fundraiserData.paid_status !== null && fundraiserData.paid_status !== "paid" && */}
                    {/* <PaymentSection
                            fData={fundraiserData}
                            callPayment={callPayment}
                            setIsLoading={setIsLoading}
                        /> */}
                    {/* } */}
                </>
            )
        }
    }

    const handleConfirmBtn = () => {
        setOpenPaymentModal(true);
        // if (fundraiserData.paid_status === "paid" || (fundraiserData.paid_status === "unpaid" && fundraiserData.payment_status === "partially_paid")) {
        //     navigate(`/fundraiser-details/${id}`);
        // } else {
        //     setOpenPaymentModal(true);
        // }
    }

    const onSuccess = () => {
        setShowModal(false);
        setOpenPaymentModal(false);
        navigate(`/`);
    }

    return (
        <>
            <StepTemplate
                handleNext={handleConfirmBtn}
                handleBack={props.handleBack}
                step={props.step}
                isLoading={isGetFundraiserLoading}
                isDisabled={isGetFundraiserLoading}
                nextBtnText={!isGetFundraiserLoading && (fundraiserData.paid_status === "paid" || (fundraiserData.paid_status === "unpaid" && fundraiserData.payment_status === "partially_paid") ? 'Confirm' : 'Confirm & Pay')}
            >
                <NextPrevButton
                    handleNext={handleConfirmBtn}
                    handleBack={props.handleBack}
                    step={props.step}
                    isLoading={isGetFundraiserLoading}
                    isDisabled={isGetFundraiserLoading}
                    nextBtnText={!isGetFundraiserLoading && (fundraiserData.paid_status === "paid" || (fundraiserData.paid_status === "unpaid" && fundraiserData.payment_status === "partially_paid") ? 'Confirm' : 'Confirm & Pay')}
                />
                <div className="step8 content-container">
                    <p className="title mb2">Confirm Fundraiser Details</p>
                    <p className="sub-title mb5">Please review and verify all information is correct before confirming your order. <br></br>Changes or corrections after the tickets are printed will result in additional charges.</p>
                    {isGetFundraiserLoading && <Spin style={{ textAlign: "center", width: "100%" }} />}
                    {!isGetFundraiserLoading && Object.keys(fundraiserData).length > 0 &&
                        <Space size="large" direction="vertical">
                            {displayPreview()}
                        </Space>
                    }
                </div>
                {showModal &&
                    <ProcessingModal
                        showModal={showModal}
                        onCancel={() => setShowModal(false)}
                    />
                }
                {openPaymentModal &&
                    <PaymentModal
                        fData={fundraiserData}
                        showModal={openPaymentModal}
                        onCancel={() => setOpenPaymentModal(false)}
                        onSuccess={() => onSuccess(false)}
                        setTransferVisible={() => setOpenETransferModal(true)}
                        closeTransfer={() => setOpenETransferModal(false)}
                    />
                }
                <ETransferModal
                    visible={openETransferModal}
                    setVisible={() => setOpenETransferModal(false)}
                    onSuccess={() => onSuccess(false)}
                />
            </StepTemplate>
        </>
    );
};
export default Step8;
