import React from "react";
const Dashboard = (props) => {

    return (
        <>
            <div>ADMIN DASHBOARD</div>
        </>
    );
};
export default Dashboard;
