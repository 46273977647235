/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Spin, Card, Col, Tag, Input, Button } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PrizeImg from '../../../assets/prize.png';
import { displayStatusText, getClaimedDate } from "../../../utils/functions";
import TextArea from "antd/lib/input/TextArea";

const WinnerPrizes = (props) => {

    const dispatch = useDispatch();
    const { id } = useParams();

    const { isGamePrizeLoading, gamePrizeDetail, isAddUpdating, successMsg } = useSelector(state => state.player);
    const [address, setAddress] = useState("");
    useEffect(() => { getAllGamesList({}) }, []);

    const getAllGamesList = (params = {}) => {
        dispatch({ type: 'ADMIN_GAME_PRIZE_DETAIL_REQUEST', id: id });
    }

    useEffect(() => {
        console.log("isGamePrizeLoading-", isGamePrizeLoading)
    }, [isGamePrizeLoading]);

    const changeAddress = (tmpAddress, id) => {
        let tAdd = "";
        if (address === "") {
            tAdd = tmpAddress;
        } else {
            tAdd = address;
        }
        dispatch({ type: 'UPDATE_USER_ADDRESS_REQUEST', formData: { address: tAdd }, id: id })
    }

    useEffect(() => {
        if (!isAddUpdating && successMsg === "success") {
            dispatch({ type: "CLEAR_PLAYER" });
            setAddress("");
            getAllGamesList();
        }
    }, [isAddUpdating, successMsg])

    const loadData = () => {
        let tmpHtml = [];
        if (gamePrizeDetail.length > 0) {
            gamePrizeDetail.map((item) => {
                tmpHtml.push(
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Card>
                            <div className="design-prize-card">
                                <div className="section1">
                                    <img src={item?.image !== undefined && item?.image !== null ? item.image : PrizeImg} alt="prize img" className="card-prize-img" />
                                    <div>
                                        <p className="title">{item?.name}</p>
                                        <p className="mb5">{item?.description}</p>

                                        <div >
                                            <p>Rank : {item?.rank}</p>
                                            <p>Player Name : {item?.player_name}</p>
                                            {item.shipping_details !== undefined && item.shipping_details !== null &&
                                                <>
                                                    <p>Shipping Address:</p>
                                                    <TextArea
                                                        style={{
                                                            width: "80%"
                                                        }}
                                                        rows={3}
                                                        onChange={(e) => setAddress(e.target.value)}
                                                        defaultValue={item?.shipping_details}
                                                    />
                                                    <Button
                                                        onClick={() => changeAddress(item.shipping_details, item.id)}
                                                        type="secondary"
                                                        className="br1 mt3"
                                                        size="large">
                                                        Update Address</Button>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="section2">
                                    <p><Tag color={item.claimed_status === undefined || item.claimed_status === null ? 'yellow' : item.claimed_status === "unclaimed" ? 'red' : 'green'}>
                                        {item.claimed_status === undefined || item.claimed_status === null ? "Pending" : displayStatusText(item.claimed_status)}
                                    </Tag></p>
                                    {(item.claimed_status !== undefined && item.claimed_status !== null) &&
                                        <p>{getClaimedDate(item)}</p>
                                    }
                                </div>
                            </div>
                        </Card>
                    </Col>
                )
            })
        } else {
            tmpHtml = <div className="no-data"><p>No winner prizes found</p></div>
        }
        return tmpHtml;

    }
    return (
        <div>
            {isGamePrizeLoading && <div className="no-data"> <Spin /></div>}
            {!isGamePrizeLoading &&
                <>
                    <Row gutter={[0, 25]} className="game-prize-card">
                        {loadData()}
                    </Row>
                </>
            }
        </div>
    );
};
export default WinnerPrizes;