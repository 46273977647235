/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Spin, Card, Col, Space, DatePicker } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { displayStatusText, formatDate } from "../../../utils/functions";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

const startDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
const endDate = moment().format('YYYY-MM-DD');

let filterParams = { start_date: startDate, end_date: endDate };
let initialFilter = { start_date: "", end_date: "" };

export const options = {
    responsive: true,

    plugins: {
        scales: {
            xAxes: [
                {
                    type: 'time',
                    time: {
                        unit: 'day',
                        tooltipFormat: 'lll',
                    },
                    ticks: {
                        maxTicksLimit: 12,
                    },
                },
            ],
            yAxes: [
                {
                    stacked: true,
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        suggestedMin: 100,
                        suggestedMax: 0,
                        beginAtZero: true,
                        min: 0
                    }
                },
            ],
        },
        legend: {
            position: 'bottom',
            display: false
        },
        title: {
            display: true,
            text: 'Number of Days Vs tickets played',
            position: "bottom"
        },
    },
};

const FundraiserDetail = (props) => {

    const dispatch = useDispatch();
    const { id } = useParams();

    const { isGameDetailLoading, gameDetail, isGraphLoading, graphData } = useSelector(state => state.player);
    const [chartData, setChartData] = useState([]);

    const [endDateDisabled, setEndDateDisabled] = useState(moment(filterParams.start_date).add(30, 'day'));


    const [customOptions, setCustomOptions] = useState({
        labels: [],
        datasets: [
            {
                labels: "",
                data: [],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
        ],
    });

    useEffect(() => {
        const data = {
            labels: chartData.map(object => object.key),
            datasets: [
                {
                    labels: "",
                    data: chartData.map(object => object.value),
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                }
            ],
            scales: {

            }
        };

        setCustomOptions(data);
    }, [chartData]);

    useEffect(() => {
        let tmpChartData = [];
        if (!isGraphLoading && graphData.length > 0) {
            graphData.map((item) => {
                let tmpObj = {};
                tmpObj.key = moment(item.key).format('MMM DD');
                tmpObj.value = item.value;
                tmpChartData.push(tmpObj);
                return true;
            });
            setChartData(tmpChartData);
        }
    }, [isGraphLoading, graphData]);

    // const onChange = (e, type) => {

    //     if (type === 'start_date') {
    //         filterParams[type] = e !== null && e !== "" ? moment(e).format("YYYY-MM-DD") : "";
    //     } else if (type === 'end_date') {
    //         filterParams[type] = e !== null && e !== "" ? moment(e).format("YYYY-MM-DD") : "";
    //     } else if (type === 'clear') {
    //         filterParams = initialFilter;
    //     }
    //     getGraphData(filterParams)
    // }

    useEffect(() => {
        getAllGamesList({});
        // getGraphData({})
    }, []);

    const getAllGamesList = (params = {}) => {
        dispatch({ type: 'ADMIN_GAME_DETAIL_REQUEST', id: id });
    }

    const getGraphData = (params = {}) => {
        dispatch({ type: 'ADMIN_GAME_GRAPH_REQUEST', formData: params, id: id });
    }
    useEffect(() => {
        if (!isGameDetailLoading && Object.keys(gameDetail).length > 0) {
            getGraphData(
                {
                    'start_date': moment(gameDetail.start_date).subtract(10, 'd').format('YYYY-MM-DD'),
                    'end_date': moment(gameDetail.close_date).format('YYYY-MM-DD'),
                }
            )
        }
    }, [isGameDetailLoading, gameDetail])

    const disabledEndDateInPicker = (current) => {
        return current <= endDateDisabled;
    };

    return (
        <div className="">
            {isGameDetailLoading && <div className="no-data mt5"> <Spin /></div>}
            {!isGameDetailLoading &&
                <>
                    <Card style={{ width: "100%" }}>
                        <Row className="" >
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Row>
                                    <Col span={12} className="display-text"><p>Campaign Title</p></Col>
                                    <Col span={12} className="display-subtext"><p>{gameDetail?.campaign_title || ""} </p></Col>

                                    <Col span={12} className="display-text"><p>Fundraiser Company name</p></Col>
                                    <Col span={12} className="display-subtext"><p>{gameDetail?.organization_name || ""}</p></Col>

                                    <Col span={12} className="display-text"><p>Fundraiser admin</p></Col>
                                    <Col span={12} className="display-subtext"><p>{gameDetail?.fundraising_admin || ""}</p></Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Row>
                                    <Col span={12} className="display-text"><p>Status</p></Col>
                                    <Col span={12} className="display-subtext"><p>{displayStatusText(gameDetail?.status || "")}</p></Col>

                                    <Col span={12} className="display-text"><p>Start date & time</p></Col>
                                    <Col span={12} className="display-subtext"><p>{formatDate(gameDetail?.start_date)}</p></Col>

                                    <Col span={12} className="display-text"><p>Close date & time </p></Col>
                                    <Col span={12} className="display-subtext"><p>{formatDate(gameDetail?.close_date)}</p></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>

                    <div className="mt5 mr0 cards-box" >
                        <Space size="middle" className="space-box" style={{ width: "100%" }}>
                            <Card className="game-detail-analytics-card" >
                                <div className="parent">
                                    <div className="mb0">
                                        <p className="cmp-title  b500 fs15 mb1">Tickets Generated</p>
                                        <p className="cmp-title  b600 fs18 mb0">{gameDetail?.tickets_generated}</p>
                                    </div>
                                </div>
                            </Card>
                            <Card className="game-detail-analytics-card" >
                                <div className="parent">
                                    <div className="mb0">
                                        <p className="cmp-title  b500 fs15 mb1">Tickets Played</p>
                                        <p className="cmp-title  b600 fs18 mb0">{gameDetail?.tickets_used}</p>
                                    </div>
                                </div>
                            </Card>
                            <Card className="game-detail-analytics-card" >
                                <div className="parent">
                                    <div className="mb0">
                                        <p className="cmp-title  b500 fs15 mb1">Number of Players</p>
                                        <p className="cmp-title  b600 fs18 mb0">{gameDetail?.no_of_players}</p>
                                    </div>
                                </div>
                            </Card>
                        </Space>
                    </div>
                    {/* <Row gutter={30} className="mt7">
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <DatePicker
                                size={"large"}
                                className="mr5"
                                style={{ width: "100%" }}
                                placeholder={"From Date"}
                                format={'YYYY-MM-DD'}
                                value={(filterParams['start_date'] !== undefined && filterParams['start_date'] !== "" && filterParams['start_date'] !== null) ? moment(filterParams['start_date'], 'YYYY-MM-DD') : null}
                                onChange={(e) => onChange(e, 'start_date')}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>

                            <DatePicker
                                size={"large"}
                                style={{ width: "100%" }}
                                placeholder={"To Date"}
                                format={'YYYY-MM-DD'}
                                disabledDate={(e) => disabledEndDateInPicker(e)}
                                value={(filterParams['end_date'] !== undefined && filterParams['end_date'] !== "" && filterParams['end_date'] !== null) ? moment(filterParams['end_date'], 'YYYY-MM-DD') : null}
                                onChange={(e) => onChange(e, 'end_date')}
                            />
                        </Col>
                    </Row> */}
                    <Card className="mt8">
                        {Object.keys(customOptions).length > 0 &&
                            <Line options={options} data={customOptions} />
                        }
                    </Card>
                </>
            }
        </div>
    );
};
export default FundraiserDetail;