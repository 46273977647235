const initialState = {

    isOrgListLoading: false,
    orgList: [],

    isFundTypeDetailLoading: false,
    fundTypeDetail: {},

    isFundraisingUpdateLoading: false,
    updateMsg: "",

    isFundPackageLoading: false,
    fundPackageDetail: {},

    isToggleLoading: false,
    toggleStatus: "",

    isCouponToggleLoading: false,
    couponToggleStatus: "",

    isFundPackageUpdating: false,
    isFundStatusUplading: false,
    isExportLoading: false,
    exportData: "",

    isPrizeListLoading: false,
    prizeList: [],
    isPrizeDetailLoading: false,
    prizeDetail: {},
    isCommonUpdateLoading: false,

    isCouponUpdateLoading: false,
    couponMsg: "",

    isUserLoading: false,
    userList: [],

    isUserDetailLoading: false,
    userDetail: [],

    isGetETransferLoading: false,
    eTransferList: [],

    isETransferConfirmLoading: false,
    isETransferRejectLoading: false,
    
    isFundUsersLoading: false,
    fundUserList: [],

    isStoleTicketLoading: false,
    stolenTicketList: [],

    isPrintTicketLoading: false,
    printTicketList: [],

    isTransactionLoading: false,
    transactionList: [],
    isError: false,
    isChangePassLoading: false,
    changePassMsg: "",
    error: "",

    isFundUpdating: false,
    fundUpdateMsg: "",
};

const reducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case "ADMIN_ORGANIZATION_LIST_REQUEST":
            state = {
                ...state,
                isOrgListLoading: true,
                orgList: [],
            };
            break;

        case "ADMIN_ORGANIZATION_LIST_SUCCESS":
            state = {
                ...state,
                isOrgListLoading: false,
                orgList: action.data
            };
            break;

        case "ADMIN_ORGANIZATION_LIST_ERROR":
            state = {
                ...state,
                isOrgListLoading: false,
                orgList: [],
                error: action.error
            };
            break;

        case "ADMIN_FUNDRAISING_TYPE_DETAIL_REQUEST":
            state = {
                ...state,
                isFundTypeDetailLoading: true,
                fundTypeDetail: {},
            };
            break;

        case "ADMIN_FUNDRAISING_TYPE_DETAIL_SUCCESS":
            state = {
                ...state,
                isFundTypeDetailLoading: false,
                fundTypeDetail: action.data
            };
            break;

        case "ADMIN_FUNDRAISING_TYPE_DETAIL_ERROR":
            state = {
                ...state,
                isFundTypeDetailLoading: false,
                fundTypeDetail: {},
                error: action.error
            };
            break;

        case "ADMIN_FUNDRAISING_PACKAGE_DETAIL_REQUEST":
            state = {
                ...state,
                isFundPackageLoading: true,
                fundPackageDetail: {},
            };
            break;

        case "ADMIN_FUNDRAISING_PACKAGE_DETAIL_SUCCESS":
            state = {
                ...state,
                isFundPackageLoading: false,
                fundPackageDetail: action.data.data
            };
            break;

        case "ADMIN_FUNDRAISING_PACKAGE_DETAIL_ERROR":
            state = {
                ...state,
                isFundPackageLoading: false,
                fundPackageDetail: {},
                error: action.error
            };
            break;

        case "UPDATE_FUNDRAISING_TYPE_REQUEST":
            state = {
                ...state,
                isFundraisingUpdateLoading: true,
                updateMsg: "",
            };
            break;

        case "UPDATE_FUNDRAISING_TYPE_SUCCESS":
            state = {
                ...state,
                isFundraisingUpdateLoading: false,
                updateMsg: "success"
            };
            break;

        case "UPDATE_FUNDRAISING_TYPE_ERROR":
            state = {
                ...state,
                isFundraisingUpdateLoading: false,
                updateMsg: "",
                error: action.error
            };
            break;

        case "UPDATE_FUNDRAISING_PACKAGE_REQUEST":
            state = {
                ...state,
                isFundPackageUpdating: true,
                updateMsg: "",
            };
            break;

        case "UPDATE_FUNDRAISING_PACKAGE_SUCCESS":
            state = {
                ...state,
                isFundPackageUpdating: false,
                updateMsg: "success"
            };
            break;

        case "UPDATE_FUNDRAISING_PACKAGE_ERROR":
            state = {
                ...state,
                isFundPackageUpdating: false,
                updateMsg: "",
                error: action.error
            };
            break;

        case "CHANGE_PASSWORD_REQUEST":
            state = {
                ...state,
                isChangePassLoading: true,
                changePassMsg: "",
            };
            break;
        case "CHANGE_PASSWORD_SUCCESS":
            state = {
                ...state,
                isChangePassLoading: false,
                changePassMsg: action.data,
            };
            break;
        case "CHANGE_PASSWORD_ERROR":
            state = {
                ...state,
                isChangePassLoading: true,
                changePassMsg: "",
            };
            break;

        case "UPDATE_COMMON_REQUEST":
            state = {
                ...state,
                isCommonUpdateLoading: true,
                updateMsg: "",
            };
            break;

        case "UPDATE_COMMON_SUCCESS":
            state = {
                ...state,
                isCommonUpdateLoading: false,
                updateMsg: "success"
            };
            break;

        case "UPDATE_COMMON_ERROR":
            state = {
                ...state,
                isCommonUpdateLoading: false,
                updateMsg: "",
                error: action.error
            };
            break;

        /*******************/
        case "UPDATE_COUPON_REQUEST":
            state = {
                ...state,
                isCouponUpdateLoading: true,
                couponMsg: "",
            };
            break;

        case "UPDATE_COUPON_SUCCESS":
            state = {
                ...state,
                isCouponUpdateLoading: false,
                couponMsg: "success"
            };
            break;

        case "UPDATE_COUPON_ERROR":
            state = {
                ...state,
                isCouponUpdateLoading: false,
                couponMsg: "",
                error: action.error
            };
            break;

        /*******************/
        case "ADMIN_USERS_LIST_REQUEST":
            state = {
                ...state,
                isUserLoading: true,
                userList: [],
            };
            break;

        case "ADMIN_USERS_LIST_SUCCESS":
            state = {
                ...state,
                isUserLoading: false,
                userList: action.data
            };
            break;

        case "ADMIN_USERS_LIST_ERROR":
            state = {
                ...state,
                isUserLoading: false,
                userList: [],
            };
            break;

        /*******************/
        case "ADMIN_FUNDRAISING_USERS_LIST_REQUEST":
            state = {
                ...state,
                isFundUsersLoading: true,
                fundUserList: [],
            };
            break;

        case "ADMIN_FUNDRAISING_USERS_LIST_SUCCESS":
            state = {
                ...state,
                isFundUsersLoading: false,
                fundUserList: action.data
            };
            break;

        case "ADMIN_FUNDRAISING_USERS_LIST_ERROR":
            state = {
                ...state,
                isFundUsersLoading: false,
                fundUserList: [],
            };
            break;


        /*******************/
        case "ADMIN_TICKET_PRINT_LIST_REQUEST":
            state = {
                ...state,
                isPrintTicketLoading: true,
                printTicketList: [],
            };
            break;

        case "ADMIN_TICKET_PRINT_LIST_SUCCESS":
            state = {
                ...state,
                isPrintTicketLoading: false,
                printTicketList: action.data
            };
            break;

        case "ADMIN_TICKET_PRINT_LIST_ERROR":
            state = {
                ...state,
                isPrintTicketLoading: false,
                printTicketList: [],
            };
            break;



        /*******************/
        case "ADMIN_TICKET_STOLEN_LIST_REQUEST":
            state = {
                ...state,
                isStoleTicketLoading: true,
                stolenTicketList: [],
            };
            break;

        case "ADMIN_TICKET_STOLEN_LIST_SUCCESS":
            state = {
                ...state,
                isStoleTicketLoading: false,
                stolenTicketList: action.data
            };
            break;

        case "ADMIN_TICKET_STOLEN_LIST_ERROR":
            state = {
                ...state,
                isStoleTicketLoading: false,
                stolenTicketList: [],
            };
            break;

        /*******************/
        case "ADMIN_TRANSACTION_LIST_REQUEST":
            state = {
                ...state,
                isTransactionLoading: true,
                transactionList: [],
            };
            break;

        case "ADMIN_TRANSACTION_LIST_SUCCESS":
            state = {
                ...state,
                isTransactionLoading: false,
                transactionList: action.data
            };
            break;

        case "ADMIN_TRANSACTION_LIST_ERROR":
            state = {
                ...state,
                isTransactionLoading: false,
                transactionList: [],
            };
            break;
        /*******************/
        case "ADMIN_UPDATE_FUND_REQUEST":
            state = {
                ...state,
                isFundUpdating: true,
                fundUpdateMsg: "",
            };
            break;

        case "ADMIN_UPDATE_FUND_SUCCESS":
            state = {
                ...state,
                isFundUpdating: false,
                fundUpdateMsg: "success"
            };
            break;

        case "ADMIN_UPDATE_FUND_ERROR":
            state = {
                ...state,
                isFundUpdating: false,
                fundUpdateMsg: "",
            };
            break;


        /*******************/
        case "ADMIN_USERS_DETAIL_REQUEST":
            state = {
                ...state,
                isUserDetailLoading: true,
                userDetail: [],
            };
            break;

        case "ADMIN_USERS_DETAIL_SUCCESS":
            state = {
                ...state,
                isUserDetailLoading: false,
                userDetail: action.data
            };
            break;

        case "ADMIN_USERS_DETAIL_ERROR":
            state = {
                ...state,
                isUserDetailLoading: false,
                userDetail: [],
            };
            break;

        case "TOGGLE_COUPON_STATUS_REQUEST":
            state = {
                ...state,
                isCouponToggleLoading: true,
                couponToggleStatus: ""
            };
            break;

        case "TOGGLE_COUPON_STATUS_SUCCESS":
            state = {
                ...state,
                isCouponToggleLoading: false,
                couponToggleStatus: "success"
            };
            break;

        case "TOGGLE_COUPON_STATUS_ERROR":
            state = {
                ...state,
                isCouponToggleLoading: false,
                couponToggleStatus: ""
            };
            break;

        case "TOGGLE_REQUEST":
            state = {
                ...state,
                isToggleLoading: true,
                toggleStatus: ""
            };
            break;

        case "TOGGLE_SUCCESS":
            state = {
                ...state,
                isToggleLoading: false,
                toggleStatus: "success"
            };
            break;

        case "TOGGLE_ERROR":
            state = {
                ...state,
                isToggleLoading: false,
                toggleStatus: ""
            };
            break;

        case "UPDATE_STATUS_REQUEST":
            state = {
                ...state,
                isFundStatusUplading: true,
                updateMsg: "",
                isError: false
            };
            break;

        case "UPDATE_STATUS_SUCCESS":
            let stat = action.data.data.id;
            state = {
                ...state,
                isFundStatusUplading: false,
                updateMsg: "success",
                isError: stat === undefined ? true : false
            };
            break;

        case "UPDATE_STATUS_ERROR":
            state = {
                ...state,
                isFundStatusUplading: false,
                updateMsg: "",
                isError: false
            };
            break;

        case "EXPORT_REQUEST":
            state = {
                ...state,
                isExportLoading: true,
                exportData: ""
            };
            break;

        case "EXPORT_SUCCESS":
            state = {
                ...state,
                isExportLoading: false,
                exportData: action.data
            };
            break;

        case "ADMIN_PRIZE_LIST_REQUEST":
            state = {
                ...state,
                isPrizeListLoading: true,
                prizeList: [],
            };
            break;

        case "ADMIN_PRIZE_LIST_SUCCESS":
            state = {
                ...state,
                isPrizeListLoading: false,
                prizeList: action.data
            };
            break;

        case "ADMIN_PRIZE_LIST_ERROR":
            state = {
                ...state,
                isPrizeListLoading: false,
                prizeList: [],
                error: action.error
            };
            break;

        case "ADMIN_PRIZE_DETAIL_REQUEST":
            state = {
                ...state,
                isPrizeDetailLoading: true,
                prizeDetail: {},
            };
            break;

        case "ADMIN_PRIZE_DETAIL_SUCCESS":
            state = {
                ...state,
                isPrizeDetailLoading: false,
                prizeDetail: action.data?.data
            };
            break;

        case "ADMIN_PRIZE_DETAIL_ERROR":
            state = {
                ...state,
                isPrizeDetailLoading: false,
                prizeDetail: {},
                error: action.error
            };
            break;

        /**------------**/
        case "GET_ALL_E_TRANSFER_REQUEST":
            state = {
                ...state,
                isGetETransferLoading: true,
                eTransferList: []
            };
            break;

        case "GET_ALL_E_TRANSFER_SUCCESS":
            state = {
                ...state,
                isGetETransferLoading: false,
                eTransferList: action.data
            };
            break;

        case "GET_ALL_E_TRANSFER_ERROR":
            state = {
                ...state,
                isGetETransferLoading: false,
                eTransferList: []
            };
            break;

        /**------------**/

        case "CONFIRM_E_TRANSFER_REQUEST":
            state = {
                ...state,
                isETransferConfirmLoading: true,
            };
            break;

        case "CONFIRM_E_TRANSFER_REQUEST_SUCCESS":
            state = {
                ...state,
                isETransferConfirmLoading: false,
            };
            break;

        case "CONFIRM_E_TRANSFER_REQUEST_ERROR":
            state = {
                ...state,
                isETransferConfirmLoading: false,
            };
            break;

        /**------------**/


        case "REJECT_E_TRANSFER_REQUEST":
            state = {
                ...state,
                isETransferRejectLoading: true,
            };
            break;

        case "REJECT_E_TRANSFER_REQUEST_SUCCESS":
            state = {
                ...state,
                isETransferRejectLoading: false,
            };
            break;

        case "REJECT_E_TRANSFER_REQUEST_ERROR":
            state = {
                ...state,
                isETransferRejectLoading: false,
            };
            break;

        /**------------**/

        case "EXPORT_ERROR":
            state = {
                ...state,
                isExportLoading: false,
                exportData: ""
            };
            break;

        case "TOGGLE_CLEAR":
            state = {
                ...state,
                toggleStatus: ""
            };
            break;

        case "CLEAR_COUPON":
            state = {
                ...state,
                couponToggleStatus: "",
            };
            break;

        case "ADMIN_DATA_CLEAR":
            state = {
                ...state,
                updateMsg: "",
                couponMsg: "",
                isExportLoading: false,
                exportData: "",
                isError: false,
                changePassMsg: "",
                fundUpdateMsg: ""
            };
            break;


        default:

    }
    return state;
};

export default reducer;