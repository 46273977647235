/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Row, Col, Table, Spin, Checkbox } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { sankeCaseToTitleCase, checkedIsExist } from '../../../utils/functions';
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io';
import PrivilegesModal from "../../common/modal/privilegeModal";

const { Column } = Table;
const Privileges = (props) => {



    const dispatch = useDispatch();
    const { isRoleLoading, roleList, isPermissionLoading, permissionList, isPermissionUpdating, permissionStatus } = useSelector(state => state.auth);
    const { isCommonUpdateLoading, updateMsg } = useSelector(state => state.admin);

    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [expandeRow, setExpandedRow] = useState([]);
    const [visible, setVisible] = useState(false);
    const [rowData, setRowData] = useState("");

    useEffect(() => {
        getRoleList();
    }, []);

    useEffect(() => {
        if (!isPermissionUpdating && permissionStatus === "error") {
            dispatch({ type: "CLEAR_INVITE" });
            getRoleList();
        }
    }, [isPermissionUpdating, permissionStatus,])

    const getRoleList = (params = {}) => {
        let tmpParams = { ...params };
        tmpParams.per_page = 5000;
        tmpParams.only = "streamline_users";
        dispatch({ type: 'ROLE_LIST_REQUEST', formData: tmpParams });
        dispatch({ type: 'PERMISSION_LIST_REQUEST', formData: tmpParams });
    }

    useEffect(() => {
        if (!isRoleLoading && roleList.length > 0) {
            let tmpColummn = [];
            let staticColumn = [{
                title: "",
                dataIndex: "module",
                key: "module",
                width: "200px"
            }]

            roleList.map((item, index) => {
                let tmpObj = {};
                tmpObj.title = item?.label || item?.name;
                tmpObj.dataIndex = item.name;
                tmpObj.key = item.name;
                tmpObj.width = "200px";
                tmpColummn.push(tmpObj);
                return 1;
            })
            setColumns([...staticColumn, ...tmpColummn]);


        }
    }, [isRoleLoading, roleList])

    useEffect(() => {
        let tmpData = [];
        if (!isPermissionLoading && Object.keys(permissionList).length > 0 && columns.length > 0) {
            for (const property in permissionList) {
                let tmpObj = {};
                let permissions = permissionList[property];
                tmpObj.module = sankeCaseToTitleCase(property);
                tmpObj.key = property;
                tmpObj.permissionType = loadPermissionType(permissions);
                tmpData.push(tmpObj);
            }
            setExpandedRow(tmpData.length > 0 ? tmpData.map(e => e.key) : [])
            setData(tmpData)
        }
    }, [isPermissionLoading, permissionList, columns])


    const loadPermissionType = (permissionType) => {


        const dataSource = [];
        const columnsSource = [...columns];
        if (permissionType.length > 0) {
            permissionType.map((item, index2) => {
                let childArr = { ...item };
                columnsSource.map((colmn, index) => {
                    if (index === 0) {
                        childArr['module'] = "";
                        childArr.key = index2 + index;
                    } else {
                        childArr[colmn['key']] = item.label;
                    }
                });
                dataSource.push(childArr);
            });
        }

        return <Table
            size="middle"
            loading={isPermissionLoading} showHeader={false} pagination={false} dataSource={dataSource} >
            <Column
                title=""
                dataIndex="module"
                key="module"
                width={"250px"}
            />
            {loadClearField(roleList)}

        </Table>;

    }

    const onChange = (e, record, roleId) => {
        dispatch({ type: 'PERMISSION_TOGGLE_REQUEST', roleId: roleId, permissionId: record.id });
    }

    const loadClearField = () => {
        let html = [];
        roleList.map((item1) => {
            html.push(<Column
                width={"200px"}
                title={item1.name}
                dataIndex={item1.name}
                key={item1.name}
                render={(_, record) => {
                    const tmpRoleList = roleList.filter((it) => it.name === item1.name);
                    console.log(tmpRoleList);
                    let isDisabled = false;
                    if (tmpRoleList.length > 0) {
                        if (tmpRoleList[0].name === "streamline_admin") {
                            isDisabled = true;
                        }
                    }
                    let checkIsExist = checkedIsExist(record, tmpRoleList, item1.name);
                    return (
                        <Checkbox
                            key={`${item1.id}_${record.id}`}
                            onChange={(e) => onChange(e, record, item1.id)}
                            defaultChecked={checkIsExist}
                            disabled={isDisabled || isPermissionUpdating}
                        > {_}</Checkbox>
                    )
                }}
            />)
            return 1;
        });

        return (<>{html}</>
        )
    }

    useEffect(() => {
        if (!isCommonUpdateLoading && updateMsg === "success") {
            dispatch({ type: "ADMIN_DATA_CLEAR" });
            getRoleList({});
            setVisible(false);
        }
    }, [isCommonUpdateLoading, updateMsg])



    return (
        <div>
            <div className="filter mt0">
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <div className="sub-header">
                            <p className="mb0 title">
                                User Privileges
                            </p>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fr text-right">

                        <Button
                            type="primary"
                            size="large"
                            className="br1"
                            htmlType="submit"
                            onClick={() => setVisible(true)}
                            icon={<PlusOutlined />}
                        >
                            New User Type
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className="mt10 permission-table">
                <div>
                    <Table
                        className={"permission-table"}
                        pagination={false}
                        style={{ width: "100%" }}
                        rowClassName={"row-class-name"}
                        columns={columns}
                        defaultExpandAllRows={true}
                        expandable={{
                            expandRowByClick: true,
                            defaultExpandAllRows: true,
                            expandedRowKeys: expandeRow,

                            expandedRowRender: (record) => (
                                <div className="m0" >
                                    {record.permissionType}
                                </div>
                            ),
                            expandIcon: ({ expanded, onExpand, record }) => {
                                if (record.name !== 'Not Expandable') {
                                    return expanded ? (
                                        <IoIosArrowDown onClick={e => {
                                            let tmpKey = [...expandeRow];
                                            tmpKey = tmpKey.filter(item => item !== record.key)
                                            setExpandedRow(tmpKey);
                                            onExpand(record, e)
                                        }} />
                                    ) : (
                                        <IoIosArrowForward onClick={e => {
                                            let tmpKey = [...expandeRow];
                                            tmpKey.push(record.key);
                                            setExpandedRow(tmpKey);
                                            onExpand(record, e)
                                        }} />
                                    )
                                }
                            }
                        }}
                        dataSource={data}
                        loading={isRoleLoading}
                        scroll={{ x: "max-content" }}
                    />
                </div>
                <PrivilegesModal
                    visible={visible}
                    rowData={rowData}
                    setVisible={() => {
                        setRowData("");
                        setVisible(false);
                    }}
                />
            </div>
        </div>
    );
};
export default Privileges;