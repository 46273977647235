/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input } from 'antd';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';


const PrivilegesModal = (props) => {
    const { visible, setVisible } = props;

    const { isCommonUpdateLoading } = useSelector(state => state.admin);

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                let tmpValues = {};
                if(values.label !== ""){
                    tmpValues.name = values.label.replaceAll(' ', '_').toLowerCase();
                }
                
                for (const property in values) {
                    if (values[property] !== undefined) {
                        tmpValues[property] = values[property];
                    }
                }
                dispatch({ type: 'UPDATE_COMMON_REQUEST', formData: tmpValues, 'requestType': 'post', updateFor: 'userPrivilege' });
                setTimeout(() => {
                    form.resetFields();
                }, 1000);
            })
            .catch((errorInfo) => {
                console.log(errorInfo)
            });
    }

    return (
        <Modal
            className={`prize-modal step4-modal org-modal`}
            title={`Create New User Type`}
            open={visible}
            onOk={() => handleSubmit()}
            onCancel={() => {
                dispatch({ type: "CLEAR_COMMON" });
                setVisible(false);
                form.resetFields();
            }}
            centered
            width={650}
            okText={`Save`}
            okButtonProps={{ disabled: isCommonUpdateLoading, loading: isCommonUpdateLoading }}
            cancelText="Cancel"
            maskClosable={false}

        >
            <div className="mt4">
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    form={form}
                    layout="vertical"
                >
                    <Form.Item
                        name="label"
                        label={"Name"}
                        rules={[
                            {
                                required: true,
                                message: "Please enter name",
                            },
                            {
                                max: 100,
                                message: "Maximum 50 characters are allowed",
                            },
                            {
                                pattern: new RegExp(/^[a-zA-Z ]*$/),
                                message: "Please enter only alphabets and space",
                            }
                        ]}
                    >
                        <Input size="large" placeholder={"Name"} />
                    </Form.Item>
                </Form>
            </div>

        </Modal>
    );
};

export default PrivilegesModal;