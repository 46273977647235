/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Row, Col, Input, Table, Space, Tooltip } from "antd";
import { EditFilled, DeleteFilled, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { CSVLink } from "react-csv";
import PlayerModal from "../../common/modal/playerModal";
import { isAuthorized } from "../../../utils/functions";

const { Search } = Input;
const { Column } = Table;

let filterParams = { search: "" };

const Players = (props) => {

    const dispatch = useDispatch();
    const { isToggleLoading, toggleStatus, isCommonUpdateLoading, updateMsg, isExportLoading, exportData } = useSelector(state => state.admin);
    const { isPlayerLoading, playerList, paginationMeta } = useSelector(state => state.player);
    const { isDeleteLoading, deleteMsg } = useSelector(state => state.common);
    const [tableData, setTableData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [rowData, setRowData] = useState("");
    const [modalType, setModalType] = useState("");
    const [tmpExportData, setTmpExportData] = useState("");
    const [exportLoading, setIsexportLoading] = useState(isExportLoading);

    const [perPage,] = useState(20);
    const [pageNo, setPageNo] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);

    useEffect(() => {
        getAllPlayers({});
    }, []);

    const getAllPlayers = (params = {}) => {
        dispatch({ type: 'ADMIN_PLAYER_LIST_REQUEST', formData: { ...filterParams, per_page: perPage, ...params } });
    }

    useEffect(() => {
        if (!isPlayerLoading && playerList?.length) {
            let tmpCmapList = [];
            playerList.map((item) => {
                let tmpObj = item;
                tmpCmapList.push(tmpObj);
                return 1;
            });
            setTotalRecord(paginationMeta.total);
            setTableData(tmpCmapList);
        } else {
            if (!isPlayerLoading && playerList.length === 0) {
                setTableData([]);
                setTotalRecord(0);
                setPageNo(1);
            }
        }
    }, [isPlayerLoading, playerList]);

    const openModal = (record, type) => {
        setVisible(true);
        if (type === "view") {
            setRowData(record);
            dispatch({ type: "ADMIN_Players_DETAIL_REQUEST", id: record.id });
        } else {
            setRowData(record);
        }
        setModalType(type);
    }

    useEffect(() => {
        if (!isCommonUpdateLoading && updateMsg === "success") {
            dispatch({ type: "ADMIN_DATA_CLEAR" });
            getAllPlayers({});
            setVisible(false);
            setModalType("");
        }
    }, [isCommonUpdateLoading, updateMsg])

    useEffect(() => {
        if (!isDeleteLoading && deleteMsg === "success") {
            dispatch({ type: "CLEAR_COMMON" });
            getAllPlayers({});
            setVisible(false);
            setModalType("");
        }
    }, [isDeleteLoading, deleteMsg]);

    const exportFundraiser = () => {
        setIsexportLoading(true);
        dispatch({ type: 'EXPORT_REQUEST', formData: filterParams, exportFor: 'player' });
    }

    useEffect(() => {
        if (!isExportLoading && exportData !== "") {
            setTmpExportData(exportData);
            setTimeout(() => {
                dispatch({ type: "ADMIN_DATA_CLEAR" });
                setIsexportLoading(isExportLoading);
                document.getElementById('exportToCSVLink').click();
            }, 1000);
        }
    }, [isExportLoading, exportData]);

    useEffect(() => {
        if (!isToggleLoading && toggleStatus === "success") {
            dispatch({ type: "TOGGLE_CLEAR" })
        }
    }, [isToggleLoading, toggleStatus]);

    const onChange = (e, type) => {
        if (type === "search") {
            filterParams[type] = e;
        }
        getAllPlayers(filterParams)
    }

    const handlePagination = (e) => {
        setPageNo(e);
        getAllPlayers({ page: e })
    }

    return (
        <div>
            <div className="sub-header">
                <p className="mb0 title">
                    Players
                </p>
            </div>
            <div className="filter mt8">
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Search
                            allowClear
                            placeholder="Search"
                            style={{ width: "60%" }}
                            enterButton
                            onSearch={(e) => onChange(e, 'search')}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fr text-right">

                        <Space>

                            <Button
                                type="primary"
                                size="large"
                                className="br1"
                                htmlType="submit"
                                loading={exportLoading}
                                disabled={exportLoading}
                                onClick={() => exportFundraiser()}
                                icon={<DownloadOutlined />}
                            >
                                Export Players
                            </Button>
                            <CSVLink id="exportToCSVLink" filename="Players.csv" data={tmpExportData} />
                        </Space>


                    </Col>
                </Row>
            </div>
            <Row className="mt5">
                <Table
                    style={{ width: "100%" }}
                    dataSource={tableData}
                    loading={isPlayerLoading}
                    pagination={{
                        current: pageNo,
                        onChange: handlePagination,
                        pageSize: perPage,
                        total: totalRecord
                    }}
                    scroll={{ x: "max-content" }}
                >

                    <Column
                        title="Name"
                        dataIndex="full_name"
                        key="full_name"
                        width={"200px"}
                    />
                    <Column
                        title="Email"
                        dataIndex="email"
                        key="email"
                        width={"250px"}
                    />
                    <Column
                        title="Phone"
                        dataIndex="phone"
                        key="phone"
                        width={"200px"}
                    />

                    <Column
                        title="Location"
                        dataIndex="location"
                        key="location"
                        width={"150px"}
                    />
                    <Column
                        title="# of Fundraisers participated"
                        dataIndex="number_of_fundraisers_participated"
                        key="number_of_fundraisers_participated"
                        width={"140px"}
                    />

                    <Column
                        title={"Actions"}
                        key='actions'
                        width={"70px"}
                        render={(_, record) => {
                            return (
                                <Space size="middle" className={`cursor ${record.isDisabled ? 'disabled-edit' : 'not-disabled'}`}>
                                    {isAuthorized('view_users') &&
                                        <Tooltip placement="top" title={"View Detail"}>
                                            <EyeOutlined
                                                onClick={() => openModal(record, 'view')}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }
                                    {isAuthorized('update_users', 'admin') &&
                                        <Tooltip placement="top" title={"Edit"} >
                                            <EditFilled
                                                onClick={() => openModal(record, 'edit')}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }
                                    {isAuthorized('delete_users', 'admin') &&
                                        <Tooltip placement="top" color={"red"} title={"Delete"}>
                                            <DeleteFilled
                                                onClick={() => openModal(record, 'delete')}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }
                                </Space>
                            )
                        }}
                    />
                </Table>
            </Row>

            <PlayerModal
                userType="player"
                visible={visible}
                rowData={rowData}
                modalType={modalType}
                setVisible={() => {
                    setRowData("");
                    setVisible(false);
                }}
            />
        </div >
    );
};
export default Players;