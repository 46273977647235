import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, Spin, Select } from 'antd';
import StepTemplate from './stepTemplate';
// import Qrcode from '../../../assets/qrcode.png';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PrintTicket from "../../common/printTicket";
import NextPrevButton from "./nextPrevButton";

const { Option } = Select;

const Step6 = (props) => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const [form] = Form.useForm();

    const { isStep6Loading, updateMsg, isGetFundraiserLoading, fundraiserData } = useSelector(state => state.fundraiser);
    const { provinceList, isProvinceLoading, isCityLoading, cityList, } = useSelector(state => state.common);

    const [provinceId, setProvinceId] = useState("");
    const [cityId, setCityId] = useState("");

    useEffect(() => {
        if (id) {
            dispatch({ type: 'GET_FUNDRAISER_DATA_REQUEST', formData: { id: id } });
        }
    }, []);

    useEffect(() => {
        if (provinceList.length === 0) {
            dispatch({ type: 'PROVINCE_LIST_REQUEST', formData: { per_page: 5000 } });
        }
    }, []);

    const callCities = (e) => {
        setCityId("");
        setProvinceId(e);
        form.setFieldsValue({ city_id: "" });
        dispatch({ type: 'CITY_LIST_REQUEST', formData: { per_page: 5000 }, provinceId: e });
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values) => {
                if (id) {
                    console.log(values)
                    dispatch({ type: 'STEP_6_CREATE_REQUEST', formData: values, 'requestType': 'put', id: id });
                }
            })
            .catch((errorInfo) => {
                console.log(errorInfo)
            });
    };


    useEffect(() => {
        if (!isGetFundraiserLoading && Object.keys(fundraiserData).length > 0) {
            // callCities(fundraiserData?.ticket?.province_id);
            setTimeout(() => {

            }, 1000);
            form.setFieldsValue({
                title: fundraiserData?.title || "",
                suite_apt_no_unit: fundraiserData?.ticket?.suite_apt_no_unit || "",
                shipping_address: fundraiserData?.ticket?.shipping_address || "",
                postal_code: fundraiserData?.ticket?.postal_code || "",
                province_id: fundraiserData?.ticket?.province_id || "",
                city: fundraiserData?.ticket?.city
            });
        }
    }, [isGetFundraiserLoading, fundraiserData])


    useEffect(() => {
        if (!isStep6Loading && updateMsg === "success") {
            dispatch({ type: 'CLEAR_FUNDRAISER_STATUS' });
            props.handleNext();
        }
    }, [isStep6Loading, updateMsg]);

    return (
        <>
            <StepTemplate
                handleNext={handleFormSubmit}
                handleBack={props.handleBack}
                step={props.step}
                isLoading={isStep6Loading}
            >
                <NextPrevButton
                    handleNext={handleFormSubmit}
                    handleBack={props.handleBack}
                    step={props.step}
                    isLoading={isStep6Loading}
                />
                <div className="content-container">
                    <p className="title mb2">Confirm Ticket Details</p>
                    <p className="sub-title">Please ensure all ticket information and spelling is correct. This will appear on the printed tickets.</p>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <div className="mt5">
                                <Form
                                    name="basic"
                                    initialValues={{ remember: true }}
                                    form={form}
                                    layout="vertical"
                                >
                                    <Form.Item
                                        name="title"
                                        rules={[
                                            { required: true, message: 'please enter ticket title' },
                                            { max: 60, message: 'Maximum 60 characters are allowed' }
                                        ]}
                                    >
                                        <Input size="large" readOnly disabled placeholder={"Ticket Title"} autoComplete="off" />
                                    </Form.Item>
                                    <div className="mt8 mb8">
                                        <p className="title mb2 fs16">Where would you like the tickets shipped?</p>
                                        <p className="">Your address does not appear on play ticket.</p>
                                    </div>
                                    <Row gutter={30}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <Form.Item
                                                label="Street"
                                                name="shipping_address"
                                                rules={[{ required: true, message: 'please enter street' }]}
                                            >
                                                <Input size="large" placeholder={"Street"} autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <Form.Item
                                                label="City"
                                                name="city"
                                                rules={[{ required: true, message: 'Please enter city' }]}
                                            >
                                                <Input size="large" placeholder={"City"} autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={30}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <Form.Item
                                                label="Province"
                                                name="province_id"
                                                rules={[{ required: true, message: 'Please select province' }]}
                                            >
                                                <Select
                                                    loading={isProvinceLoading}
                                                    placeholder="Select Province"
                                                    size="large"
                                                    showSearch
                                                    autoComplete="new-state"
                                                    defaultValue={provinceId}
                                                    // onChange={(e) => callCities(e)}
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                >
                                                    {!isProvinceLoading && provinceList.map((item, index) => (
                                                        <Option key={index} value={item.id}>{item.name}</Option>))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <Form.Item
                                                label="Postal Code"
                                                name="postal_code"
                                                rules={[{ required: true, message: 'please enter postal code' }]}
                                            >
                                                <Input size="large" placeholder={"A1B2C3"} autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                    </Row>


                                </Form>
                            </div>
                        </Col>
                        {/* <Col xs={24} sm={24} md={10} lg={10} xl={10} >
                            <div className="ml10 qr-img mt5">
                                <p className="mb0 qr-text">{campaignName}</p>
                                <img src={Qrcode} alt="qr" />
                            </div>
                        </Col> */}
                    </Row>
                    {isGetFundraiserLoading && <div className="no-data"><Spin></Spin></div>}

                    {!isGetFundraiserLoading && Object.keys(fundraiserData).length > 0 &&
                        <div className="mt8">
                            <PrintTicket data={fundraiserData} />
                        </div>
                    }

                </div>
            </StepTemplate>
        </>
    );
};
export default Step6;
