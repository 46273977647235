import React, { useState, useEffect } from "react";
import { Table, Input, Space, Button, Popover, Spin, Select, Tooltip, Row, Col } from 'antd';
import SelectOptionsSeller from "./SelectOptionsSeller";
import { useParams } from 'react-router-dom';
import { HiDotsHorizontal } from 'react-icons/hi';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io';
import axios from 'axios';
import { API_URL } from '../../../../utils/constant';
import { accessToken, ticketStatus } from '../../../../utils/functions';
import UpdateTicketModal from "./updateTicketModal";
import SellerModal from "../../../common/sellerModal";
import UnAssignTicketModal from "../../../common/modal/unAssignTicketsList";
import { AiOutlineVideoCamera } from 'react-icons/ai';
import { VideoPlayer } from "../../../common/modal/videoPlayer";

const { Column } = Table;

const initialFieldValue = {
    key: '',
    seller_id: '',
    quantity_from: '',
    quantity_to: '',
    isAdded: true,
    amount_total: "$",
    amount_paid: "$",
    quantity_count: "#",
    name: 'Not Expandable'
}

const TicketTable = ({ fData }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { isSellerLoading, sellerList, isSellerCreateLoading, statusMsg, isImportLoading } = useSelector(state => state.common);
    const { isTicketCreateLoading, ticketMsg, isGetTicketLoading, assignTicketList, isReassignLoading, reassignMsg } = useSelector(state => state.ticket);
    const [tableData, setTableData] = useState([]);
    const [fieldValue, setFieldValue] = useState(initialFieldValue);
    const [defaultSellerValue, setDefaultSellerValue] = useState("")
    const [expandData, setExpandData] = useState("");
    const [isExpandLoading, setIsExpandLoading] = useState(false);
    const [expandedRow, setExpandedRow] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState("");
    const [selectedBatch, setSelectedBatch] = useState({});
    const [visible, setVisible] = useState(false);
    const [sellerModalType, setSllerModalType] = useState("");
    const [visibleUnassign, setVisibleUnassign] = useState(false);
    const [videoType, setVideoType] = useState(false);
    const [showVideo, setShowVideo] = useState(false);

    useEffect(() => { getAssignTicket(); }, [])
    const getAssignTicket = () => {
        dispatch({ type: 'ASSIGN_TICKET_LIST_REQUEST', id: id });
        dispatch({ type: 'UNASSIGN_TICKET_LIST_REQUEST', id: id });
    }
    useEffect(() => {
        let tmpObj = [{
            key: '',
            seller_id: '',
            quantity_from: '',
            quantity_to: '',
            isAdded: true,
            amount_total: "$",
            amount_paid: "$",
            quantity_count: "#",
            name: 'Not Expandable'
        }];

        let tmpAssignTicketList = [];
        if (!isGetTicketLoading && assignTicketList.length) {
            assignTicketList.map((item) => {
                let tmpObj = { ...item };
                tmpObj.key = item.id;
                tmpObj.seller_id = item.seller_id;
                tmpObj.name = item?.seller?.name;
                tmpObj.isAdded = false;
                tmpObj.available_tickets = item.available_tickets !== null ? item.available_tickets.join(", ") : "";
                tmpObj.available_tickets_arr = item.available_tickets !== null ? item.available_tickets : [];
                tmpAssignTicketList.push(tmpObj);
            });
        }
        setTableData([...tmpObj, ...tmpAssignTicketList]);
    }, [isGetTicketLoading, assignTicketList]);

    const onChange = (e, type) => {
        let tmpFieldValue = { ...fieldValue };
        if (type === "seller_id") {
            tmpFieldValue['seller_id'] = e;
            setDefaultSellerValue(e);
        } else {
            tmpFieldValue[type] = e;
        }
        setFieldValue(tmpFieldValue);
    }

    const title = (
        <span className="numeric-input-title">
            'To' Value should be greate than 'From' value
        </span>
    );

    const inputOptions = (text, record, index, type, fieldType, width = "100%") => {
        if (record.isAdded) {
            if (fieldType === "select") {
                let list = sellerList;
                let isLoading = isSellerLoading;
                let placeholder = "Select Seller";
                let defaultValue = defaultSellerValue;
                return (
                    <>
                        <SelectOptionsSeller
                            status={fieldValue[type] === "" ? "error" : ""}
                            options={list}
                            isLoading={isLoading}
                            type={type}
                            filterName={type}
                            borderLess={true}
                            placeholder={placeholder}
                            width={width}
                            defaultValue={defaultValue}
                            onChange={(e) => onChange(e, type)}
                        />
                    </>
                )

            } else if (fieldType === "input") {
                let placeholder = "0", defaultValue = "";
                let tmpHtml = [];
                if (type === "available_tickets") {
                    ['quantity_from', 'quantity_to'].map((itemVal) => {
                        width = "125px";
                        if (itemVal === "quantity_from") {
                            placeholder = "From";
                            defaultValue = fieldValue[itemVal];
                        } else if (itemVal === "quantity_to") {
                            placeholder = "To";
                            defaultValue = fieldValue[itemVal];
                        }
                        if (itemVal === "quantity_from") {
                            tmpHtml.push(
                                <Input
                                    type="number"
                                    bordered={fieldValue[itemVal] === "" ? true : false}
                                    status={fieldValue[itemVal] === "" ? "error" : ""}
                                    value={defaultValue}
                                    style={{ width: width }}
                                    placeholder={placeholder}
                                    onChange={(e) => onChange(e.target.value, itemVal)} />
                            )
                        } else if (itemVal === "quantity_to") {
                            tmpHtml.push(
                                <Tooltip trigger={['focus']} title={title} placement="topLeft" overlayClassName="numeric-input">
                                    <Input
                                        type="number"
                                        bordered={fieldValue[itemVal] === "" ? true : false}
                                        status={fieldValue[itemVal] === "" ? "error" : ""}
                                        value={defaultValue}
                                        style={{ width: width }}
                                        placeholder={placeholder}
                                        onChange={(e) => onChange(e.target.value, itemVal)} />
                                </Tooltip>
                            )
                        } else {
                            tmpHtml.push(
                                <Input
                                    type="number"
                                    bordered={fieldValue[itemVal] === "" ? true : false}
                                    status={fieldValue[itemVal] === "" ? "error" : ""}
                                    value={defaultValue}
                                    style={{ width: width }}
                                    placeholder={placeholder}
                                    onChange={(e) => onChange(e.target.value, itemVal)} />
                            )
                        }



                    })
                    return <div className="table-input">{tmpHtml}</div>;
                } else {
                    if (type === "quantity_from") {
                        placeholder = "From";
                        defaultValue = fieldValue['quantity_from'];
                    } else if (type === "quantity_to") {
                        placeholder = "To";
                        defaultValue = fieldValue['quantity_to'];
                    }
                    return (
                        <>
                            <Input
                                type="number"
                                bordered={fieldValue[type] === "" ? true : false}
                                status={fieldValue[type] === "" ? "error" : ""}
                                value={defaultValue}
                                style={{ width: width }}
                                placeholder={placeholder}
                                onChange={(e) => onChange(e.target.value, type)} />
                        </>
                    )
                }

            }
            if (type === "amount_total" || type === "amount_paid") {
                return "$";
            }
        } else {
            if (type === "amount_total" || type === "amount_paid") {
                return (text !== null && text !== "") ? `$${parseFloat(text).toFixed(2)}` : text;
            } else if (type === "seller_id") {
                return <span className="b500">{record?.seller?.name}</span>;
            } else {
                return text;
            }
        }
    }

    const handleAddNewBatch = (e) => {
        let tmpPostObj = {};
        tmpPostObj.seller_id = fieldValue['seller_id'];
        tmpPostObj.quantity_from = fieldValue['quantity_from'];
        tmpPostObj.quantity_to = fieldValue['quantity_to'];
        dispatch({ type: 'ASSIGN_TICKET_TO_SELLER_REQUEST', formData: tmpPostObj, id: id });
    }

    useEffect(() => {
        if ((!isTicketCreateLoading && ticketMsg === "success") || (!isReassignLoading && reassignMsg === "success")) {
            resetModalStates();
            getAssignTicket();
            setFieldValue(initialFieldValue);
            setDefaultSellerValue("");
            dispatch({ type: "CLEAR_TICKET" });
        }
    }, [isTicketCreateLoading, ticketMsg, isReassignLoading, reassignMsg]);

    const resetModalStates = () => {
        setShowModal(false);
        setModalType('');
        setSelectedBatch({});
    }

    const openModal = (record, type) => {
        if (type === "record") {
            dispatch({ type: 'SELLER_TICKET_PAYMENT_REQUEST', formData: {}, id: id, ticketId: record.id });
        }
        setSelectedBatch(record);
        setShowModal(true)
        setModalType(type);
    }

    const actionsDropdown = (id, record = {}) => {
        return (
            <div onClick={(e) => e.stopPropagation()}>
                <p className="pointer" onClick={() => openModal(record, 'report')}>Report stolen/lost tickets</p>
                <p className="pointer" onClick={() => openModal(record, 'reasign')}>Reassign tickets</p>
                <p className="pointer" onClick={() => openModal(record, 'record')}>Record payment/history</p>
            </div>
        );
    }

    const callExpand = async (record, e) => {
        setIsExpandLoading(true);
        axios.get(`${API_URL}seller-tickets/${record.id}/details`, { headers: { "Authorization": `Bearer ${accessToken()}` } })
            .then(res => {
                if (res.status === 200) {
                    setIsExpandLoading(false);
                    setExpandData(res.data.data);
                }
            }).catch(error => {
                console.log(error, "error")
            })
    }

    const renderSelect = () => {
        return (
            <Select
                style={{ width: "200px" }}
                placeholder={"Action"}
            >
            </Select>
        )
    }

    const loadExpandedRow = () => {
        if (!isExpandLoading) {
            const nestedTableData = expandData.map(row => ({
                key: row.id,
                Id: row.id,
                // name: <span className="b500">{row.id}&nbsp;&nbsp;&nbsp;&nbsp;{row.title}</span>
                name: <span className="b500">{row.ticket_number}&nbsp;&nbsp;&nbsp;&nbsp;{ticketStatus(row.status)}</span>
            }))
            const nestedColumns = [
                { title: '', dataIndex: "name", key: "name" },
            ];
            return <div className="mb5 child-table">
                <Table
                    // rowSelection={{}}
                    columns={nestedColumns}
                    dataSource={nestedTableData}
                    pagination={false}
                />
            </div>
        } else {
            return <div className="no-data"><Spin /></div>
        }

    };

    useEffect(() => {
        if ((!isSellerCreateLoading && statusMsg === "success") || (!isImportLoading && statusMsg === "success")) {
            dispatch({ type: "CLEAR_COMMON" });
            getSellerList();
            setVisible(false);
            setSllerModalType("");
        }
    }, [isSellerCreateLoading, statusMsg, isImportLoading]);

    const getSellerList = (params = {}) => {
        dispatch({ type: 'GET_SELLER_LIST_REQUEST', formData: { 'per_page': 5000 } });
    }
    const openVideo = (videoType) => {
        setVideoType(videoType);
        setShowVideo(true);
    }

    return (
        <div className="ticket-section mb20">
            <div className="mt8 ticket-table">
                {fData.status !== "fundraiser_closed" && fData.status !== "prizes_shipped" &&
                    <div className="ticket-table-header mb3">
                        <Row className="mt10">
                            <Col xs={24} sm={24} md={13} lg={13} xl={13}>
                                <div className="d-flex-a-center">
                                    <div className="fs16 bold mr8 mb0">Create batches and assign tickets to sellers</div>
                                    <Button
                                        type="primary"
                                        size="large"
                                        className="br20 mr3 btn-seller"
                                        htmlType="submit"
                                        onClick={() => { setVisible(true); setSllerModalType('add') }}
                                        icon={<PlusOutlined />}
                                    >
                                        New Seller
                                    </Button>
                                    <Button
                                        type="secondary"
                                        size="large"
                                        className="br20 pr6 pl6 btn-seller"
                                        htmlType="submit"
                                        onClick={() => { setVisible(true); setSllerModalType('import') }}
                                    >
                                        Import
                                    </Button>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                <div>
                                    <div className="d-flex-a-center link pointer" onClick={() => openVideo('seller')}><AiOutlineVideoCamera />&nbsp;Help Video(Add Seller)</div>
                                    <div className="d-flex-a-center link pointer" onClick={() => openVideo('assign')}><AiOutlineVideoCamera />&nbsp;Help Video(Assign Seller)</div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                <div className="unassign">

                                    <Button
                                        type="primary"
                                        size="large"
                                        className="br20 ml3 btn-seller"
                                        htmlType="submit"
                                        onClick={() => { setVisibleUnassign(true); }}
                                        icon={<PlusOutlined />}
                                    >
                                        Unassigned Tickets
                                    </Button>
                                </div>

                            </Col>
                        </Row>



                    </div>
                }
                <Table
                    pagination={false}
                    dataSource={tableData}
                    loading={isGetTicketLoading}
                    scroll={{ x: "max-content" }}
                    expandable={{
                        expandedRowKeys: expandedRow,
                        expandedRowRender: loadExpandedRow,
                        rowExpandable: (record) => record.name !== 'Not Expandable',
                        expandIcon: ({ expanded, onExpand, record }) => {
                            if (record.name !== 'Not Expandable') {
                                return expanded ? (
                                    <IoIosArrowDown onClick={e => {
                                        // callExpand(record, e);
                                        setExpandedRow([]);
                                        onExpand(record, e)
                                    }} />
                                ) : (
                                    <IoIosArrowForward onClick={e => {
                                        setExpandedRow([record.id]);
                                        callExpand(record, e);
                                        onExpand(record, e)
                                    }} />
                                )
                            }
                        }
                    }}
                >

                    <Column title="ID" dataIndex="id" key="id" width={"25px"} />
                    <Column title="Batch #" dataIndex="batch" key="batch" width={"90px"} />
                    <Column
                        title="Seller Name"
                        dataIndex="seller_id"
                        key="seller_id"
                        width={"250px"}
                        render={(text, record, index) => {
                            return inputOptions(text, record, index, "seller_id", "select")
                        }}
                    />
                    <Column
                        title="Available Tickets"
                        dataIndex="available_tickets"
                        key="available_tickets"
                        width={"350px"}
                        render={(text, record, index) => {
                            return inputOptions(text, record, index, "available_tickets", "input")
                        }}
                    />
                    {/* <Column
                        title="key"
                        dataIndex="quantity_to"
                        key="quantity_to"
                        width={"150px"}
                        render={(text, record, index) => {
                            return inputOptions(text, record, index, "quantity_to", "input")
                        }}
                    /> */}
                    <Column
                        title="# of Tickets"
                        dataIndex="quantity_count"
                        key="quantity_count"
                        width={"120px"}
                    />
                    <Column
                        title="Value"
                        dataIndex="amount_total"
                        key="amount_total"
                        render={(text, record, index) => {
                            return inputOptions(text, record, index, "amount_total")
                        }}

                    />
                    <Column
                        title="Paid"
                        dataIndex="amount_paid"
                        key="amount_paid"
                        render={(text, record, index) => {
                            return inputOptions(text, record, index, "amount_paid")
                        }}
                    />
                    <Column
                        title={""}
                        key="action"
                        width={"100px"}
                        fixed="right"
                        className="text-center"
                        render={(text, record) => {
                            if (record.isAdded) {
                                return (
                                    <Space size="middle">
                                        <Button
                                            type="primary"
                                            size="small"
                                            className="br20 batch-btn"
                                            htmlType="submit"
                                            disabled={(fieldValue['seller_id'] === "" || fieldValue['quantity_from'].trim() === "" || fieldValue['quantity_to'].trim() === "") || isTicketCreateLoading}
                                            loading={isTicketCreateLoading}
                                            onClick={(e) => handleAddNewBatch(e)}
                                            icon={<PlusOutlined />}
                                        >
                                            Add Batch
                                        </Button>

                                    </Space>
                                )
                            } else {
                                return (
                                    <Space size="middle" className="table-icon">
                                        <Popover placement="right" content={actionsDropdown(record.key, record)}>
                                            <HiDotsHorizontal size={18} />
                                        </Popover>
                                    </Space>
                                )
                            }
                        }}

                    />
                </Table>
            </div>
            {showModal &&
                <UpdateTicketModal
                    visible={showModal}
                    setVisible={() => { setModalType(""); setShowModal(false) }}
                    row={selectedBatch}
                    modalType={modalType}
                />
            }
            {visible &&
                <SellerModal
                    rowData={{}}
                    visible={visible}
                    modalType={sellerModalType}
                    setVisible={() => { setVisible(false) }}
                />
            }
            {visibleUnassign &&
                <UnAssignTicketModal
                    visible={visibleUnassign}
                    setVisible={() => { setVisibleUnassign(false) }}
                />
            }
            {showVideo &&
                <VideoPlayer
                    visible={showVideo}
                    setVisible={() => setShowVideo(false)}
                    videoType={videoType}
                />
            }

        </div >
    );
};
export default TicketTable;
