import React from 'react';
import { Layout } from 'antd';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
const { Header } = Layout;
const FundraisingHeader = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    return (
        <>
            <Header className="header fundraising-admin">
                <div className='d-flex'>
                    <div className="title" >
                        New Fundraiser
                    </div>
                    <div className='header-menu'>
                        <AiOutlineClose size={"22px"} className="pointer" onClick={() => {
                            dispatch({ type: 'UPDATE_STEP', data: 1 });
                            dispatch({ type: "FUNDRAISER_DATA_CLEAR" });
                            navigate('/')
                        }} />
                    </div>
                </div>
            </Header>

        </>
    );
};

export default FundraisingHeader;