import React, { useState, useEffect } from "react";
import NewFundraising from "./";

const AdminNewFundraising = (props) => {
    return (
        <div className="fundraising-content" >
            <NewFundraising />
        </div>

    );
};
export default AdminNewFundraising;
