import React from "react";
import Iframe from 'react-iframe'
const TermsConditions = (props) => {
    return (
        <div className="mt0 mb10">
            <Iframe url="https://app.termly.io/document/terms-of-use-for-website/17a3a351-468a-42d9-a181-f24076dd08b4"
                width="100%"
                className="iframe"
                id=""
                display="block"
                position="relative"
                frameBorder={0}
            />

        </div>
    );
};
export default TermsConditions;
