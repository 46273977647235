/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Input, Table, Space, Tag, Tooltip, DatePicker } from "antd";
import { EditFilled } from '@ant-design/icons';
import { PER_PAGE } from '../../../utils/constant';
import { displayStatusText, formatDate, getGameStatusColor } from '../../../utils/functions';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import PrizeToBeDeliveredModal from "../../common/modal/prizeToBeDeliveredModal";

const { Search } = Input;
const { Column } = Table;

let filterParams = { status: "", start_date: "", end_date: "", search: "" };
let initialFilter = { status: "", start_date: "", end_date: "", search: "" };

const PrizeTobeDelivered = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const { isPrizeDeliveredLoading, prizeDeliveredList } = useSelector(state => state.player);
    const { isCommonUpdateLoading, updateMsg } = useSelector(state => state.admin);

    const [visible, setVisible] = useState(false);
    const [rowData, setRowData] = useState("");

    useEffect(() => { getReportedTickets({}) }, []);

    const getReportedTickets = (params = {}) => {
        dispatch({ type: 'ADMIN_PRIZE_DELIVERED_LIST_REQUEST', formData: { ...filterParams, per_page: 50000, ...params } });
    }

    useEffect(() => {
        if (!isPrizeDeliveredLoading && prizeDeliveredList?.length) {
            let tmpCmapList = [];
            prizeDeliveredList.map((item) => {
                let tmpObj = item;
                tmpObj.status = item?.status;
                tmpObj.key = item?.id;
                tmpObj.display_status = displayStatusText(item?.status || "");
                tmpCmapList.push(tmpObj);
                return 1;
            });
            setTableData(tmpCmapList);
        } else {
            if (!isPrizeDeliveredLoading && prizeDeliveredList.length === 0) {
                setTableData([]);
            }
        }
    }, [isPrizeDeliveredLoading, prizeDeliveredList]);


    const onChange = (e, type) => {
        filterParams.page = 1;

        if (type === "search") {
            filterParams[type] = e;
        } else if (type === "status") {
            if (e === "all") {
                filterParams[type] = "";
            } else {
                filterParams[type] = e;
            }
        } else if (type === 'start_date') {
            filterParams[type] = e !== null && e !== "" ? moment(e).format("YYYY-MM-DD") : "";
        } else if (type === 'end_date') {
            filterParams[type] = e !== null && e !== "" ? moment(e).format("YYYY-MM-DD") : "";
        } else if (type === 'clear') {
            filterParams = initialFilter;
        }
        getReportedTickets(filterParams)
    }

    const openModal = (record, type) => {
        setVisible(true);
        setRowData(record);
    }

    useEffect(() => {
        if (!isCommonUpdateLoading && updateMsg === "success") {
            setVisible(false);
            getReportedTickets({});
            dispatch({ type: "ADMIN_DATA_CLEAR" });
        }
    }, [isCommonUpdateLoading, updateMsg])


    return (
        <div>
            <div className="sub-header">
                <p className="mb0 title">
                    Prizes to be Delivered
                </p>

            </div>
            <div className="filter mt8">
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Search
                            defaultValue={filterParams['search']}
                            size="large"
                            allowClear
                            placeholder="Search"
                            style={{ width: "60%" }}
                            enterButton
                            onSearch={(e) => onChange(e, 'search')}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fr text-right date-filter">
                        <DatePicker
                            size={"large"}
                            className="mr5"
                            style={{ width: "40%" }}
                            placeholder={"From Date"}
                            format={'YYYY-MM-DD'}
                            value={(filterParams['start_date'] !== undefined && filterParams['start_date'] !== "" && filterParams['start_date'] !== null) ? moment(filterParams['start_date'], 'YYYY-MM-DD') : null}
                            onChange={(e) => onChange(e, 'start_date')}
                        />
                        <DatePicker
                            size={"large"}
                            style={{ width: "40%" }}
                            placeholder={"To Date"}
                            format={'YYYY-MM-DD'}
                            value={(filterParams['end_date'] !== undefined && filterParams['end_date'] !== "" && filterParams['end_date'] !== null) ? moment(filterParams['end_date'], 'YYYY-MM-DD') : null}
                            onChange={(e) => onChange(e, 'end_date')}
                        />
                    </Col>
                </Row>
            </div>

            <Row className="mt8">
                <Table
                    style={{ width: "100%" }}
                    dataSource={tableData}
                    loading={isPrizeDeliveredLoading}
                    pagination={true}
                    scroll={{ x: "max-content" }}
                >

                    <Column
                        title="Ticket#"
                        dataIndex="ticket_number"
                        key="ticket_number"
                        width={"70px"}
                    />
                    <Column
                        title="Player name"
                        dataIndex="player_name"
                        key="player_name"
                        width={"150px"}
                    />
                    <Column
                        title="Campaign name"
                        dataIndex="campaign_name"
                        key="campaign_name"
                        width={"170px"}
                    />
                    <Column
                        title="Fundraising admin"
                        dataIndex="fundraising_admin"
                        key="fundraising_admin"
                        width={"170px"}
                    />
                    <Column
                        title="Prize name"
                        dataIndex="prize_name"
                        key="prize_name"
                        width={"130px"}
                    />
                    <Column
                        title="Claimed on"
                        dataIndex="claimed_on"
                        key="claimed_on"
                        render={(text, record) => formatDate(text)}
                        width={"130px"}
                    />
                    <Column
                        title="Updated by"
                        dataIndex="status_updated_by"
                        key="status_updated_by"
                        width={"130px"}
                    />
                    <Column
                        title="Status"
                        dataIndex="display_status"
                        key="display_status"
                        width={"70px"}
                        render={(_, { display_status, status }) => {
                            let color = 'default';
                            if (status === 'shipped') {
                                color = 'yellow'
                            } else if (status === 'delivered') {
                                color = '#87d068';
                            } else if (status === 'claimed') {
                                color = 'green';
                            }
                            return (
                                <Space size="middle">
                                    <Tag color={color} key={status}>
                                        {display_status}
                                    </Tag>
                                </Space>)
                        }}
                    />
                    <Column
                        title="Notes"
                        dataIndex="status_note"
                        key="status_note"
                        width={"200px"}
                    />

                    <Column
                        title={"Action"}
                        key='action'
                        fixed='right'
                        width={"100px"}
                        render={(_, record) => {
                            return (
                                <Space size="middle" className={`cursor ${record.isDisabled ? 'disabled-edit' : 'not-disabled'}`}>
                                    <Tooltip placement="top" title={"Edit"} >
                                        <EditFilled
                                            onClick={() => openModal(record)}
                                            disabled={record.isDisabled}
                                            className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                            style={{ fontSize: "18px" }}
                                        />
                                    </Tooltip>
                                </Space>
                            )
                        }}
                    />
                </Table>
            </Row>
            <PrizeToBeDeliveredModal
                visible={visible}
                rowData={rowData}
                setVisible={() => {
                    setRowData("");
                    setVisible(false);
                }}
            />
        </div>
    );
};
export default PrizeTobeDelivered;