import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { accessToken, displayPaymentText, formatAmount, formatDate } from '../../../../utils/functions';
import { useSelector, useDispatch } from 'react-redux';
import Column from "antd/lib/table/Column";
import { API_URL, PAYMENT_STATUS } from "../../../../utils/constant";
// import ReactToPrint from 'react-to-print';
// import PrintPDFDocument from "../../../common/printInvoice";
import { PaymentModal } from "../../../common/modal/paymentModal";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import ETransferModal from "../../../common/modal/eTransferModal";
import { Tooltip } from "antd";

const Billing = ({ fData }) => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const { isGetPaymentsLoading, paymentsData } = useSelector(state => state.common);
    const navigate = useNavigate();
    const [receiptLoading, setReceiptLaoding] = useState(false);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [openETransferModal, setOpenETransferModal] = useState(false);


    const getPaymentData = (params = {}) => {
        dispatch({ type: 'FUNDRAISER_PAYMENT_REQUEST', formData: { 'per_page': 5000 }, id: id });
    }


    useEffect(() => {
        getPaymentData()
    }, []);

    useEffect(() => {
        if (!isGetPaymentsLoading && paymentsData.length > 0) {
            setPaymentHistory(paymentsData);
            let tmpArr = [];
            paymentsData.map((item) => {
                let last4 = item.first6last4.toString().slice(-4);
                let obj = { ...item };
                obj.status = "Paid";
                obj.description = <p className="mb0">{`${item.card_type} - Ending ${last4}`} <br></br>{`Transaction ID: ${item.transaction_no}`}</p>;
                obj.date = formatDate(item.created_at);
                obj.amount = item.amount_paid_with_taxes || item.amount_paid;
                tmpArr.push(obj);
            })

            setPaymentHistory(tmpArr);
        } else if (!isGetPaymentsLoading && paymentsData.length === 0) {
            setPaymentHistory([]);
        }
    }, [isGetPaymentsLoading, paymentsData])

    const onSuccess = () => {
        setOpenPaymentModal(false)
        navigate(`/`);
        window.location.reload();
    }


    const printInvoice = async () => {
        setReceiptLaoding(true);
        await axios({
            url: `${API_URL}fundraisers/${id}/payment/receipt`,
            method: "GET",
            responseType: "blob",
            headers: { "Authorization": `Bearer ${accessToken()}` }
        }).then(response => {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            setReceiptLaoding(false);
        }).catch(error => {
            setReceiptLaoding(false);
        })
    }

    return (
        <div className="billing-section">
            <Row gutter={[30, 30]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Card className="card-child">
                        {fData.fundraiser_package === null && <div><p className="no-data">Package not selected</p></div>}
                        {fData.fundraiser_package !== null &&
                            <div>
                                <div className="parent">
                                    <div className="mb4">
                                        <p>Package details</p>
                                        <p className="cmp-title">{fData.fundraiser_package?.name}</p>
                                        <p className="cmp-sub-title mb4">{fData.fundraiser_package?.description}</p>
                                    </div>
                                    <p className="cmp-title">{formatAmount(fData.fundraiser_package?.regular_fees)}</p>
                                </div>
                                {/* {fData.status !== "fundraiser_closed" && fData.tickets_total !== 0 && (fData.tickets_played + fData.tickets_blocked + fData.tickets_stolen) >= fData.tickets_total &&
                                    <p className="links pointer" onClick={() => setVisible(true)}>Close fundraiser early</p>
                                } */}
                            </div>
                        }

                    </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Card className="card-child">
                        <div>
                            <div className="parent">
                                <div className="mb4">
                                    <p>Status</p>
                                    <p className="cmp-title">
                                        {displayPaymentText(fData.payment_status)} <br></br><p className="cmp-sub-element">(Discounts and taxes excluded, print Invoice for full details)</p>
                                    </p>
                                    {/* <p className="cmp-sub-title lh mb4"></p> */}
                                </div>
                                <p className="cmp-title">
                                    {PAYMENT_STATUS.FULLY_PAIED === fData.payment_status ?
                                        formatAmount(fData.fundraiser_package?.regular_fees || 0) : ` ${formatAmount(fData.fundraiser_package?.reduced_fees || 0)}`}</p>
                            </div>
                            <p className="links" style={{ width: "90%" }}>
                                {/* {fData.paid_status !== PAYMENT_STATUS.FULLY_PAIED && <span className="pointer mr25" onClick={() => setOpenPaymentModal(true)}>Pay Amount</span>} */}
                                {fData.paid_status !== PAYMENT_STATUS.FULLY_PAIED && (
                                    fData?.e_transfers?.some((val) => val.status === 'pending') ? (
                                        <Tooltip title='E-transfer approval pending'>
                                            < span className="mr10" style={{ color: 'gray' }}>Pay Amount</span>
                                        </Tooltip>
                                    ) : (
                                        < span className="pointer mr10" onClick={() => setOpenPaymentModal(true)}>Pay Amount</span>
                                    )
                                )}
                                {(fData.paid_status === PAYMENT_STATUS.FULLY_PAIED || fData.paid_status === PAYMENT_STATUS.PARTAILY_PAIED) &&
                                    <>
                                        {receiptLoading && <span className="pointer">Print Invoice&nbsp;<Spin></Spin></span>}
                                        {!receiptLoading && <span className="pointer" onClick={() => { printInvoice() }}>Print Invoice</span>}
                                    </>
                                }
                            </p>
                        </div>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Card>
                        <div className="payment-detail">
                            <Table
                                dataSource={paymentHistory}
                                loading={isGetPaymentsLoading}
                                pagination={false}
                            >
                                <Column
                                    title="Date"
                                    dataIndex="date"
                                    key="date"
                                    width={"250px"}
                                />
                                <Column
                                    title="Description"
                                    dataIndex="description"
                                    key="description"
                                    width={"350px"}
                                />
                                <Column
                                    title="Status"
                                    dataIndex="status"
                                    key="status"
                                    width={"250px"}
                                />
                                <Column
                                    title="Amount"
                                    dataIndex="amount"
                                    key="amount"
                                    width={"100px"}
                                    render={(_, record) => {
                                        let amt = formatAmount(record.amount)
                                        return (
                                            <>
                                                {amt}
                                            </>
                                        )
                                    }}

                                />
                            </Table>
                        </div>
                    </Card>
                </Col>
            </Row>
            {
                openPaymentModal &&
                <PaymentModal
                    fData={fData}
                    showModal={openPaymentModal}
                    onCancel={() => setOpenPaymentModal(false)}
                    onSuccess={() => onSuccess(false)}
                    setTransferVisible={() => setOpenETransferModal(true)}
                />
            }
            <ETransferModal
                visible={openETransferModal}
                setVisible={() => setOpenETransferModal(false)}
                onSuccess={() => onSuccess(false)}
            />
            {/* <div className="hide-on-print" id="pdf_element" ref={componentRef}>
                <PrintPDFDocument
                    transactionsList={paymentHistory}
                    fData={fData}
                />
            </div> */}
        </div >
    );
};
export default Billing;
