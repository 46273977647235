/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Table, Space, Tag, Tooltip, Button, message, InputNumber } from "antd";
import { displayStatusText, formatDate, accessToken } from '../../../../utils/functions';
import { useSelector, useDispatch } from 'react-redux';
import { PrinterOutlined, DownloadOutlined, EyeOutlined, LoadingOutlined, QrcodeOutlined, UnlockOutlined, LockOutlined, PoweroffOutlined } from '@ant-design/icons';
import axios from 'axios';
import { API_URL, BASE_URL, TICKET_DETAIL_TAG } from '../../../../utils/constant';
import GenerateTicketModal from "../../../common/modal/generateTicketModal";
import GameTabs from '../../../common/gameTabs';
import ConfirmationModal from "../../../common/modal/confirmationModal";
import TicketGenerateSuccess from "../../../common/modal/generateTicketSuccess";

const { Column } = Table;

let filterParams = {};

const PrintTickets = ({ id, fundraiserData }) => {

    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([]);
    const { isPrintTicketLoading, printTicketList } = useSelector(state => state.admin);
    const { isTicketDowndloading, ticketPDF, isTicketGenerating, generateStatus, isDeactivateLoading, deactiveMsg } = useSelector(state => state.ticket);
    const [printId, setPrintId] = useState("");
    const [downloadId, setDownloadId] = useState("");
    const [deactivateKey, setDeactivateKey] = useState({ id: "", type: "" });
    const [visible, setVisible] = useState(false);
    const [activeModal, setActiveModal] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isPrintAllSuccess, setIsPrintAllSuccess] = useState(false);
    const increment = useRef(null);
    const [activeTab, setActiveTab] = useState('');
    const [showModal, setShowModal] = useState(false);

    const [fromNo, setFromNo] = useState("");
    const [toNo, setToNo] = useState("");

    useEffect(() => { getPrintTickets({}) }, []);

    const getPrintTickets = (params = {}) => {
        dispatch({ type: 'ADMIN_TICKET_PRINT_LIST_REQUEST', formData: { ...filterParams, ...params }, id: id });
    }

    useEffect(() => {
        if (id && Object.keys(fundraiserData).length === 0) {
            dispatch({ type: 'GET_FUNDRAISER_DATA_REQUEST', formData: { id: id } });
        }
    }, []);

    useEffect(() => {
        if (!isPrintTicketLoading && printTicketList?.length) {
            let tmpCmapList = [];
            printTicketList.map((item, index) => {
                let tmpObj = item;
                tmpObj.status = item?.status;
                tmpObj.key = index + item?.id;
                tmpObj.key2 = index + "_" + item?.id;
                tmpObj.ticket_id = item?.id;
                tmpObj.display_status = displayStatusText(item?.status || "Created");
                tmpCmapList.push(tmpObj);
                return 1;
            });
            setTableData(tmpCmapList);
        } else {
            if (!isPrintTicketLoading && printTicketList?.length === 0) {
                setTableData([]);
            }
        }
    }, [isPrintTicketLoading, printTicketList]);

    useEffect(() => {
        if (!isTicketGenerating && generateStatus === "success") {
            setVisible(false);
            getPrintTickets({});
        }
    }, [isTicketGenerating, generateStatus]);

    useEffect(() => {
        if (isPrintAllSuccess) {
            clearInterval(increment.current);
            setIsPrintAllSuccess(false);
        }
    }, [isPrintAllSuccess])


    const printTicket = async (type, ticketId) => {
        console.log(`Bearer ${accessToken()}`)
        await axios({
            url: `${API_URL}admin-generated-tickets/${ticketId}/print`,
            method: "POST",
            responseType: "blob",
            headers: { "Authorization": `Bearer ${accessToken()}` }
        }).then(response => {


            if (type === "preview") {
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                setPrintId("")
            } else {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "ticket.pdf");
                document.body.appendChild(link);
                link.click();
                setDownloadId("");
            }

        }).catch(error => {
            setPrintId("");
            setDownloadId("");
            console.log(error, "error")
        })

    }


    const printAllTicket = async (type) => {
        console.log("fromNo", fromNo)
        console.log("toNo", toNo);
        let tmmObj = {};
        if (fromNo !== "" && toNo !== "") {
            tmmObj.from = fromNo;
            tmmObj.to = toNo;
        } else if (fromNo !== "" && toNo === "") {
            message.error({ content: "Please enter To Ticket Number", className: 'error-class', duration: 3 });
            return;
        } else if (fromNo === "" && toNo !== "") {
            message.error({ content: "Please enter From Ticket Number", className: 'error-class', duration: 3 });
            return;
        }
        setIsDownloading(true);

        await axios({
            url: `${API_URL}fundraisers/${id}/ticket/bulk-print`,
            method: "POST",
            data: tmmObj,
            headers: { "Authorization": `Bearer ${accessToken()}` }
        }).then(async parentResp => {
            if (parentResp.status === 201 || parentResp.status === 200) {
                setIsDownloading(false);
                setShowModal(true);
                setFromNo("");
                setToNo("");
                // callIntervalFunction(parentResp.data.data.id)
            }

        }).catch(error => {
            setFromNo("");
            setToNo("");
            setShowModal(false);
            setIsDownloading(false);
            console.log(error, "error")
        })


    }

    const callIntervalFunction = (generatedId) => {
        increment.current = setInterval(async () => {
            await axios({
                url: `${API_URL}fundraisers/${id}/ticket-list`,
                method: "GET",
                headers: { "Authorization": `Bearer ${accessToken()}` }
            }).then(response => {
                if (response.status === 200) {
                    console.log(response.data)
                    console.log(response.data.data)
                    if (response.data.data.length > 0) {
                        let downloadedId = response.data.data.filter(e => e.id === generatedId);
                        if (downloadedId.length > 0) {
                            setIsPrintAllSuccess(true);
                            setIsDownloading(false);
                            window.open(`${downloadedId[0].path}`, '_blank');
                        }
                    }
                } else {
                    message.error({ content: "Something went wrong! try again later", className: 'error-class', duration: 3 });
                    setIsDownloading(false);
                }
            }).catch(error => {
                setIsDownloading(false);
                console.log(error, "error")
            })
        }, 4000)
    }
    useEffect(() => {
        if (!isTicketDowndloading && ticketPDF !== "") {
            const url = window.URL.createObjectURL(new Blob([ticketPDF]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
        }
    }, [isTicketDowndloading, ticketPDF]);

    const onDeactivate = (recordData, type) => {
        setActiveModal(true);
        setDeactivateKey({ id: recordData.seller_ticket_details_id, type: type })
    }

    useEffect(() => {
        if (!isDeactivateLoading && deactiveMsg !== "") {
            setActiveModal(false);
            dispatch({ type: "CLEAR_TICKET" });
            getPrintTickets({});
            setDeactivateKey({ id: "", type: "" })
        }
    }, [isDeactivateLoading, deactiveMsg]);


    const onChange = (e, type) => {
        console.log("e, type")
        console.log(e, type)
        // filterParams.page = 1;

        if (type === "status") {
            setActiveTab(e);
            if (e === "") {
                delete filterParams.status;
            } else {
                filterParams[type] = e;
            }
        }
        getPrintTickets({});
    }

    const onChangeNumber = (e, type) => {
        console.log("e, type");
        console.log(e, type);
        if (type === "from") {
            if (e !== null && e !== "") {
                setFromNo(e);
            } else {
                setFromNo("");
            }
        } else if (type === "to") {
            if (e !== null && e !== "") {
                setToNo(e);
            } else {
                setToNo("");
            }
        }
    }

    return (

        <div>
            <Row className='campaign-table mt10'>
                <GameTabs
                    onChange={(e) => onChange(e, 'status')}
                    isShowCount={true}
                    item={TICKET_DETAIL_TAG}
                    active={activeTab}
                // onChange={(e) => setActiveTab(e)}
                // item={TICKET_DETAIL_TAG}
                // active={activeTab}
                />
            </Row>
            <Row className="mt5 game-analytics">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div className="filter mt8">
                        <Row className='nav-sub-header'>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="fr text-right">
                                {activeTab === "" &&
                                    <Button
                                        type="primary"
                                        size="large"
                                        className="br1 mr4"
                                        htmlType="submit"
                                        id="Generate"
                                        style={{ float: "left" }}
                                        disabled={isPrintTicketLoading || fundraiserData.paid_status === "pending" || tableData.length !== 0}
                                        icon={<QrcodeOutlined />}
                                        onClick={() => setVisible(true)}
                                    >
                                        Generate Tickets
                                    </Button>
                                }

                                <InputNumber
                                    value={fromNo}
                                    min={1}
                                    type='number'
                                    onChange={(e) => onChangeNumber(e, 'from')}
                                    style={{ width: "200px", marginRight: "10px" }}
                                    placeholder='From Ticket Number'
                                    size='large'
                                />

                                <InputNumber
                                    value={toNo}
                                    min={1}
                                    type='number'
                                    placeholder='To Ticket Number'
                                    onChange={(e) => onChangeNumber(e, 'to')}
                                    size='large'
                                    style={{ width: "200px", marginRight: "10px" }}
                                />

                                <Button
                                    type="primary"
                                    size="large"
                                    className="br1 mr4"
                                    htmlType="submit"
                                    id="print-multiple"
                                    disabled={tableData.length === 0 || isDownloading}
                                    loading={isDownloading}
                                    onClick={() => printAllTicket('preview')}
                                    icon={<PrinterOutlined />}
                                >
                                    {(fromNo !== "" || toNo !== "") ? "Print Tickets" : "Print All Tickets"}

                                </Button>
                            </Col>

                        </Row>
                    </div>
                    <Row className="mt8">
                        <Table
                            style={{ width: "100%" }}
                            dataSource={tableData}
                            loading={isPrintTicketLoading}
                            pagination={{
                                pageSize: 50,
                                showSizeChanger: false
                            }}
                            scroll={{ x: "max-content" }}
                        // rowSelection={{
                        //     onSelect: (record) => onselectCheckbox(record.id),
                        //     hideSelectAll: true
                        // }}
                        >

                            <Column
                                title="Ticket#"
                                dataIndex="ticket_number"
                                key="ticket_number"
                                width={"100px"}
                            />
                            <Column
                                title="Generated on"
                                dataIndex="created_at"
                                key="created_at"
                                render={(text, record) => formatDate(text)}
                                width={"200px"}
                            />
                            <Column
                                title="Generated by"
                                dataIndex="generated_by"
                                key="generated_by"
                                width={"200px"}
                            />

                            <Column
                                title="Status"
                                dataIndex="display_status"
                                key="display_status"
                                width={"100px"}
                                render={(_, { display_status, status }) => {
                                    let color = 'default';

                                    if (status === "played") {
                                        color = '#87d068';
                                    } else if (status === "not_played") {
                                        color = 'orange';
                                    } else if (status === "play_started") {
                                        color = 'yellow';
                                    } else if (status === "ready_to_play") {
                                        color = 'lime';
                                    } else if (status === "currently_playing") {
                                        color = 'lime';
                                    }


                                    return (
                                        <Space size="middle">
                                            <Tag color={color} key={status}>
                                                {display_status}
                                            </Tag>
                                        </Space>)
                                }}
                            />

                            <Column
                                title={"Action"}
                                key='action'
                                fixed='right'
                                width={"100px"}
                                render={(_, record) => {
                                    return (
                                        <Space size="middle" className={`cursor ${record.id === printId ? 'disabled-edit' : 'not-disabled'}`}>
                                            <Tooltip placement="top" title={"Preview Ticket PDF"}>
                                                {record.id === printId ?
                                                    <LoadingOutlined />
                                                    :
                                                    <EyeOutlined
                                                        className={`cursor ${record.id === printId ? 'disabled' : 'not-disabled'}`}
                                                        onClick={() => { setPrintId(record.id); printTicket('preview', record.id) }}
                                                        style={{ fontSize: "16px" }}
                                                    />
                                                }
                                            </Tooltip>
                                            <Tooltip placement="top" title={"Download Ticket PDF"}>
                                                {record.id === downloadId ?
                                                    <LoadingOutlined />
                                                    :
                                                    <DownloadOutlined
                                                        onClick={() => { setDownloadId(record.id); printTicket('download', record.id) }}
                                                        style={{ fontSize: "17px" }}
                                                        className={`cursor ${record.id === downloadId ? 'disabled' : 'not-disabled'}`}
                                                    />
                                                }
                                            </Tooltip>
                                            {record.status !== null && record.status !== "blocked" && record.status !== "stolen" && record.status !== "currently_playing" && record.status !== "played" && record.status !== "ready_to_play" &&
                                                <Tooltip placement="top" title={"Deactivate Ticket"}>
                                                    {record.seller_ticket_details_id === deactivateKey.id ?
                                                        <>
                                                            <LoadingOutlined />
                                                        </> :
                                                        <>
                                                            <LockOutlined
                                                                color={"red"}
                                                                onClick={() => { onDeactivate(record, 'deactive') }}
                                                                style={{ fontSize: "16px", color: "red" }}
                                                                className={`cursor ${record.id === deactivateKey.id ? 'disabled' : 'not-disabled'}`}
                                                            />
                                                        </>
                                                    }

                                                </Tooltip>
                                            }
                                            {record.status !== null && (record.status === "blocked" || record.status === "stolen") &&
                                                <Tooltip placement="top" title={"Activate Ticket"}>
                                                    {record.seller_ticket_details_id === deactivateKey.id ?
                                                        <>
                                                            <LoadingOutlined />
                                                        </> :
                                                        <>
                                                            <UnlockOutlined
                                                                onClick={() => { onDeactivate(record, 'active') }}
                                                                style={{ fontSize: "16px" }}
                                                                className={`cursor ${record.id === deactivateKey.id ? 'disabled' : 'not-disabled'}`}
                                                            />
                                                        </>
                                                    }

                                                </Tooltip>
                                            }
                                        </Space>
                                    )
                                }}
                            />
                        </Table>
                    </Row>
                    {visible &&
                        <GenerateTicketModal
                            visible={visible}
                            rowData={fundraiserData}
                            setVisible={() => {
                                setVisible(false);
                            }}
                        />
                    }
                    {activeModal &&
                        <ConfirmationModal
                            visible={activeModal}
                            rowData={fundraiserData}
                            deactivateKey={deactivateKey}
                            setVisible={() => {
                                setActiveModal(false);
                                setDeactivateKey({ id: "", type: "" });
                            }}
                        />
                    }
                    {<TicketGenerateSuccess
                        onCancel={() => setShowModal(false)}
                        isSuccessModal={showModal}
                    />}
                </Col>
            </Row>

        </div >
    );
};
export default PrintTickets;