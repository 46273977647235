import { put, takeLatest, call, delay } from 'redux-saga/effects'
import { message } from 'antd';
//Import API Service.
import { postRequestAPI, getRequestAPI } from '../../services/API/api';
import { ROLE_NAME } from '../../utils/constant';
import { decrypt, encrypt, setWithExpiry } from '../../utils/functions';

function* setPassword({ formData, pageType }) {
    try {
        let response = "";
        if (pageType === "set") {
            response = yield call(postRequestAPI, { url: `set-password`, data: formData });
        } else {
            response = yield call(postRequestAPI, { url: `reset-password`, data: formData });
        }
        if (response.status === 200) {
            yield put({ type: 'SET_PASSWORD_SUCCESS', data: response.data.data || "" });
        } else {
            yield put({ type: 'SET_PASSWORD_ERROR', error: response });
        }
    } catch (error) {
        console.log(formData, pageType);
        yield put({ type: 'SET_PASSWORD_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* resendRequest({ formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `resend`, data: formData });
        if (response.status === 200) {
            yield put({ type: 'RESEND_CODE_SUCCESS', data: response.data.data || "" });
        } else {
            yield put({ type: 'RESEND_CODE_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'RESEND_CODE_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* loginRequest({ formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `login`, data: formData });
        if (response.status === 200) {
            let loginPlainObj = response.data.user;
            let loginObj = JSON.stringify(response.data.user);
            setWithExpiry();
            // localStorage.setItem('jwt', response.data.token)
            // yield put({ type: 'LOGIN_SUCCESS', loginMsg: "success", data: loginPlainObj || "" });
            if (window.location.hostname !== "localhost" && loginPlainObj?.role?.name === ROLE_NAME.FUNDRAISER && (loginPlainObj.is_phone_verified === null || loginPlainObj.is_phone_verified === false)) {
                yield put({ type: 'LOGIN_SUCCESS', loginMsg: "notVerified", data: loginPlainObj || "" });
            } else {
                localStorage.setItem('loggedInUser', encrypt(loginObj))
                // localStorage.setItem('loggedInUser', JSON.stringify(response.data.user))
                localStorage.setItem('jwt', response.data.token);
                yield put({ type: 'LOGIN_SUCCESS', loginMsg: "success", data: loginPlainObj || "" });
            }

        } else {
            message.error({ content: "Email address or Password is invalid", className: 'error-class' });
            yield put({ type: 'LOGIN_ERROR', error: 'error' });
        }
    } catch (error) {
        message.error({ content: "Email address or Password is invalid", className: 'error-class' });
        yield put({ type: 'LOGIN_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* getLoggedInUser({ formData = {} }) {
    try {
        const response = yield call(getRequestAPI, { url: `auth/me`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'GET_LOGGED_IN_USER_SUCCESS', loginMsg: "success", data: response.data.data || "" });
        } else {
            message.error({ content: "Email address or Password is invalid", className: 'error-class' });
            yield put({ type: 'GET_LOGGED_IN_USER_ERROR', error: 'error' });
        }
    } catch (error) {
        // message.error({ content: "Email address or Password is invalid", className: 'error-class' });
        yield put({ type: 'GET_LOGGED_IN_USER_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* forgetPassword({ formData }) {
    try {
        const response = yield call(postRequestAPI, { url: `forgot-password`, data: formData });
        if (response.status === 200) {
            yield put({ type: 'FORGET_PASSWORD_SUCCESS', data: response.data.data || "" });
        } else {
            yield put({ type: 'FORGET_PASSWORD_ERROR', error: response });
        }
    } catch (error) {
        yield put({ type: 'FORGET_PASSWORD_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* getRoleListRequest({ formData }) {
    try {

        const response = yield call(getRequestAPI, { url: `roles`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'ROLE_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'ROLE_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ROLE_LIST_ERROR', error: error.message || "" });
    }

}

function* getPermissionRequest({ formData }) {
    try {

        const response = yield call(getRequestAPI, { url: `permissions`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'PERMISSION_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'PERMISSION_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'PERMISSION_LIST_ERROR', error: error.message || "" });
    }

}

function* togglePermission({ roleId, permissionId }) {
    try {

        const response = yield call(postRequestAPI, { url: `role/${roleId}/permission/${permissionId}/toggle` });
        if (response.status === 200) {
            yield put({ type: 'PERMISSION_TOGGLE_SUCCESS', data: response.data.data || [] });
        } else {
            message.error({ content: "Something went wrong, please try again", className: 'error-class', duration: 3 });
            yield put({ type: 'PERMISSION_TOGGLE_ERROR', error: "No record found" });
        }
    } catch (error) {
        message.error({ content: "Something went wrong, please try again", className: 'error-class', duration: 3 });
        yield put({ type: 'PERMISSION_TOGGLE_ERROR', error: error.message || "" });
    }

}

function* otpRequest({ formData, isResend = false }) {
    try {

        let tmpURl = `request-otp`;
        if (isResend) {
            tmpURl = `request-otp?force_send=true`;
        }
        const response = yield call(postRequestAPI, { url: tmpURl, data: formData });
        console.log("response");
        console.log(response);
        if (response.status === 200) {
            yield put({ type: 'OTP_SUCCESS', data: "success" });
        } else {
            yield put({ type: 'OTP_ERROR', data: "invalid" });
        }
    } catch (error) {
        message.error({ content: "Something went wrong, please try again", className: 'error-class', duration: 3 });
        yield put({ type: 'OTP_ERROR', data: "somethingWrong" });
    }

}

function* otpVerificationRequest({ formData }) {
    try {

        // const response = yield call(postRequestAPI, { url: `request-otp`, data: formData });
        const response = yield call(postRequestAPI, { url: `fundraisingadmin/verify-otp`, data: formData });
        if (response.status === 200) {
            yield put({ type: 'OTP_VERIFY_SUCCESS', data: "success" });
        } else {
            yield put({ type: 'OTP_VERIFY_ERROR', data: "invalid" });
        }
    } catch (error) {
        message.error({ content: "Something went wrong, please try again", className: 'error-class', duration: 3 });
        yield put({ type: 'OTP_VERIFY_ERROR', data: "somethingWrong" });
    }

}

function* signupRequest({ formData }) {
    try {

        const response = yield call(postRequestAPI, { url: `fundraisingadmin/onboard`, data: formData });
        console.log(response);
        console.log(response.data);
        if (response.status === 200) {
            yield put({ type: 'SIGNUP_SUCCESS', data: response.data?.user, status: "success" });
        } else {
            yield put({ type: 'SIGNUP_ERROR', data: response?.errors || response?.message, status: "error" });
        }
    } catch (error) {
        message.error({ content: "Something went wrong, please try again", className: 'error-class', duration: 3 });
        yield put({ type: 'SIGNUP_ERROR', data: "somethingWrong" });
    }

}

function* watchDonorConfigSagasRequests() {
    yield takeLatest('LOGIN_REQUEST', loginRequest);
    yield takeLatest('FORGET_PASSWORD_REQUEST', forgetPassword);
    yield takeLatest('SET_PASSWORD_REQUEST', setPassword);
    yield takeLatest('RESEND_CODE_REQUEST', resendRequest);
    yield takeLatest('ROLE_LIST_REQUEST', getRoleListRequest);
    yield takeLatest('PERMISSION_LIST_REQUEST', getPermissionRequest);
    yield takeLatest('PERMISSION_TOGGLE_REQUEST', togglePermission);
    yield takeLatest('GET_LOGGED_IN_USER_REQUEST', getLoggedInUser);

    yield takeLatest('OTP_REQUEST', otpRequest);
    yield takeLatest('OTP_VERIFY_REQUEST', otpVerificationRequest);
    yield takeLatest('SIGNUP_REQUEST', signupRequest);

}

function* donorConfigSagas() {
    yield watchDonorConfigSagasRequests();
}

export default donorConfigSagas;