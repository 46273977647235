import {
    DashboardOutlined,
    SoundOutlined,
    UsergroupAddOutlined,
    GiftOutlined,
    ApartmentOutlined,
    FundOutlined,
    WalletOutlined,
    UnlockOutlined,
    DribbbleOutlined,
    DollarOutlined,
    MailOutlined
} from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import { isMenuVisible } from "../../../utils/functions";
import './style.scss';
const { Sider } = Layout;


const Sidebar = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [active, setActive] = useState('admin/campaigns')
    const [collapsed, setCollapsed] = useState(false)
    const [items1, setItem1] = useState([]);

    useEffect(() => {
        setItem1([
            {
                key: "admin/campaigns",
                label: "Fundraising Campaigns",
                icon: <SoundOutlined />,
                style: { display: isMenuVisible('view_fundraisers') },
            },
            {
                key: "admin/dashboarddata",
                label: "Dashboard",
                icon: <DashboardOutlined />,
                style: { display: isMenuVisible('admin/analytics') },
                children: [
                    {
                        key: "admin/analytics",
                        label: "Analytics",
                        // icon: <UsergroupAddOutlined />,
                        style: { display: isMenuVisible('admin/analytics') },
                    },
                    {
                        key: "admin/reported-tickets",
                        label: "Reported Tickets",
                        // icon: <UsergroupAddOutlined />,
                        style: { display: isMenuVisible('admin/reported-tickets') },
                    },
                    {
                        key: "admin/prize-to-delivered",
                        label: "Prize Delivered",
                        // icon: <UsergroupAddOutlined />,
                        style: { display: isMenuVisible('admin/prize-to-delivered') },
                    },
                ]
            },
            {
                key: "admin/organizations",
                label: "Organizations",
                icon: <ApartmentOutlined />,
                style: { display: isMenuVisible("view_organizations") },
            },
            {
                key: "admin/e-transfer",
                label: "Manage E-Transfer",
                icon: <MailOutlined />,
                style: { display: isMenuVisible("view_prizes") },
            },
            {
                key: "admin/fundraising-type",
                label: "Fundraising Type",
                icon: <FundOutlined />,
                style: { display: isMenuVisible("view_fundraiser_types") },
            },
            {
                key: "admin/fundraising-package",
                label: "Fundraising Package",
                icon: <WalletOutlined />,
                style: { display: isMenuVisible("view_fundraiser_packages") },
            },
            {
                key: "admin/prizes",
                label: "Manage Prizes",
                icon: <GiftOutlined />,
                style: { display: isMenuVisible("view_prizes") },
            },
            {
                key: "admin/coupons",
                label: "Manage Coupons",
                icon: <DollarOutlined />,
                style: { display: isMenuVisible("view_prizes") },
            },
            {
                key: "admin/games",
                label: "Games",
                icon: <DribbbleOutlined />,
                style: { display: isMenuVisible("view_games") },
            },
            {
                key: "admin/usersparent",
                label: "Manage Users",
                icon: <UsergroupAddOutlined />,
                style: { display: isMenuVisible("view_users") },
                children: [
                    {
                        key: "admin/users",
                        label: "Streamline Users",
                        // icon: <UsergroupAddOutlined />,
                        style: { display: isMenuVisible("view_users", 'admin') },
                    },
                    {
                        key: "admin/fundraising-user",
                        label: "Fundraising Users",
                        // icon: <UsergroupAddOutlined />,
                        style: { display: isMenuVisible("view_users")},
                    },
                    {
                        key: "admin/players",
                        label: "Players",
                        // icon: <UsergroupAddOutlined />,
                        style: { display: isMenuVisible("view_users") },
                    },
                ]
            },
            {
                key: "admin/users/privileges",
                label: "User Privileges",
                icon: <UnlockOutlined />,
                style: { display: isMenuVisible("") },
            }
        ]);
    }, [])

    useEffect(() => {
        if (location.pathname.includes('admin/campaigns')) {
            setActive('admin/campaigns');
        } else if (location.pathname.includes('admin/analytics')) {
            setActive('admin/analytics');
        } else if (location.pathname.includes('admin/reported-tickets')) {
            setActive('admin/reported-tickets');
        } else if (location.pathname.includes('admin/prize-to-delivered')) {
            setActive('admin/prize-to-delivered');
        } else if (location.pathname.includes('admin/users/privileges')) {
            setActive('admin/users/privileges');
        } else if (location.pathname.includes('admin/organizations')) {
            setActive('admin/organizations');
        } else if (location.pathname.includes('admin/fundraising-type')) {
            setActive('admin/fundraising-type');
        } else if (location.pathname.includes('admin/fundraising-package')) {
            setActive('admin/fundraising-package');
        } else if (location.pathname.includes('admin/prizes')) {
            setActive('admin/prizes');
        } else if (location.pathname.includes('admin/games')) {
            setActive('admin/games');
        } else if (location.pathname.includes('admin/game/')) {
            setActive('admin/games');
        } else if (location.pathname.includes('admin/users')) {
            setActive('admin/users');
        } else if (location.pathname.includes('admin/fundraising-user')) {
            setActive('admin/fundraising-user');
        } else if (location.pathname.includes('admin/players')) {
            setActive('admin/players');
        }
    }, [location]);

    return (
        <Sider width={230}
            breakpoint="sm"
            onBreakpoint={(broken) => {
            }}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            className="site-layout-background">
            <Menu
                mode="inline"
                selectedKeys={[active]}
                onClick={(e) => {
                    setActive(e.key);
                    navigate(`/${e.key}`);
                }}
                defaultOpenKeys={['sub1']}
                items={items1}
                style={{
                    height: '100%',
                    borderRight: 0,
                }}
            >
            </Menu>
        </Sider>
    );
};

export default Sidebar;