import React, { useState, useEffect } from "react";
import { Row, Col, Progress } from "antd";
import { useNavigate, useParams, useSearchParams, } from 'react-router-dom';
import { API_URL, STEP_NAME } from '../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';

import { checkIsAllPaid, getStepTitle } from '../../../utils/functions';

import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step6 from './step6';
import Step7 from './step7';
import Step8 from './step8';

const NewFundraising = (props) => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [searchParams] = useSearchParams();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { isFCampgaignLoading, fundraiserCampgaignList, fundraiserData } = useSelector(state => state.fundraiser);

    useEffect(() => {
        getAllCampaignsList();
    }, []);

    useEffect(() => {
        if (!isFCampgaignLoading && fundraiserCampgaignList.length > 0) {
            let ans = checkIsAllPaid(fundraiserCampgaignList);
            if (!ans.status && !id) {
                navigate('/');
            }
        }
    }, [isFCampgaignLoading, fundraiserCampgaignList])

    const getAllCampaignsList = (params = {}, isSearch = false) => {
        params.per_page = 5000;
        dispatch({ type: 'FUNDRAISER_CAPMAIGN_LIST_REQUEST', formData: params, fundraiserFor: "my" });
    }
    useEffect(() => {
        let tmpStep = searchParams.get('step');
        if (tmpStep !== null) {
            let tmpStep = searchParams.get('step');
            dispatch({ type: 'UPDATE_STEP', data: parseInt(tmpStep) });
            setStep(parseInt(tmpStep));
        }
    }, [searchParams]);

    const displayStep = () => {

        if (step === 1) {
            return (
                <Step1
                    handleNext={handleNext}
                    handleBack={handleBack}
                    step={step}
                />
            );
        } else if (step === 2) {
            return (
                <Step2
                    handleNext={handleNext}
                    handleBack={handleBack}
                />
            );
        } else if (step === 4) {
            return (
                <Step3
                    handleNext={handleNext}
                    handleBack={handleBack}
                />
            );
        } else if (step === 3) {
            return (
                <Step4
                    handleNext={handleNext}
                    handleBack={handleBack}
                />
            );
        }
        else if (step === 5) {
            return (
                <Step6
                    handleNext={handleNext}
                    handleBack={handleBack}
                />
            );
        } else if (step === 6) {
            return (
                <Step7
                    handleNext={handleNext}
                    handleBack={handleBack}
                />
            );
        } else if (step === 7) {
            return (
                <Step8
                    handleNext={handleNext}
                    handleBack={handleBack}
                    step={step}
                />
            );
        }
    };

    const handleAddPrizes = async () => {
        dispatch({
            type: 'COMBINED_PRIZE_REQUEST',
            formData: {
                per_page: 5000,
                fundraiser_package_id: fundraiserData?.fundraiser_package_id,
                status: 'active',
            },
            id: id,
        });
    };

    const handleNext = (funndraiserId = "") => {
        setStep(step + 1);
        dispatch({ type: 'UPDATE_STEP', data: parseInt(step + 1) });
        if (id !== undefined && id !== "") {
            navigate(`/new-fundraising/${id}?step=${step + 1}`)
            if (step === 3) {
                handleAddPrizes()
            }
        } else if (funndraiserId !== "") {
            console.log(`new-fundraising/${funndraiserId}?step=${step + 1}`)
            navigate(`/new-fundraising/${funndraiserId}?step=${step + 1}`)
        } else {
            navigate(`/new-fundraising?step=${step + 1}`)
        }

    };

    const handleBack = () => {
        setStep(step - 1);
        dispatch({ type: 'UPDATE_STEP', data: parseInt(step - 1) });
        if (id !== "") {
            navigate(`/new-fundraising/${id}?step=${step - 1}`)
        } else {
            navigate(`/new-fundraising?step=${step - 1}`)
        }
    }
    function percentage(partialValue) {
        return (100 * partialValue) / 7;
    }

    return (
        <div className="fundraising-listing">
            <Row className="main-container minH">
                <Col xs={24} sm={24} md={5} lg={5} xl={5} className="left">
                    <Progress
                        strokeLinecap="butt"
                        strokeWidth={9}
                        width={85}
                        type="circle"
                        percent={percentage(step)} format={(percent) => `${step}/7`}
                        strokeColor={{
                            '0%': '#1B35B3',
                            '100%': '#A32B83',
                        }}
                    />
                    <p className="mt5 mb1 step_title">{getStepTitle(STEP_NAME, step).stepTitle}</p>
                    <p className="mb0 step_subtitle">{getStepTitle(STEP_NAME, step).nextStepTitle}</p>
                </Col>
                {displayStep()}
            </Row>
        </div >
    );
};
export default NewFundraising;
