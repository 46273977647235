import React, { useEffect, useState } from "react";
import { Form, Input, Button, message } from 'antd';
import "./style.scss";
import RightSide from "./RightSide";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import OtpModal from "../common/modal/otpModal";
import { useSearchParams } from "react-router-dom";
const Login = (props) => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { isLoginLoading, loginMsg, loginData, isOtpVerifyLoading, otpVerifyStatus } = useSelector(state => state.auth);
    const [visible, setVisible] = useState(false);
    const [loginLabel, setLoginLabel] = useState("Customer Login");

    const onFinish = (values) => {
        console.log('Success:', values);
        dispatch({ type: 'LOGIN_REQUEST', formData: values });
    };

    const onFinishFailed = (errorInfo) => { console.log('Failed:', errorInfo); };

    useEffect(() => {
        if (!isLoginLoading && loginMsg === "success") {
            dispatch({ type: 'CLEAR_LOGIN_DATA' });
            if (localStorage.getItem('redirectLink')) {
                window.location.href = localStorage.getItem('redirectLink');
            } else {
                window.location.href = "/";
            }
        } else if (!isLoginLoading && loginMsg === "notVerified" && Object.keys(loginData).length > 0) {
            console.log('otp', loginData.phone);
            setVisible(true);
        }
    }, [dispatch, isLoginLoading, loginMsg, loginData]);

    useEffect(() => {
        if (!isOtpVerifyLoading && otpVerifyStatus === "success") {
            dispatch({ type: 'CLEAR_LOGIN_DATA' });
            message.success("Email verified successfully, login to continue...")
            setVisible(false);
        } else if (!isOtpVerifyLoading && otpVerifyStatus === "invalid") {
            dispatch({ type: 'CLEAR_OTP_MESSAGE' });
            message.error({ content: "OTP is invalid or expired, please enter code again or resend send OTP" || "Something went wrong", className: 'error-class', duration: 5 });
        }
    }, [dispatch, isOtpVerifyLoading, otpVerifyStatus]);

    return (
        <div className="login-signup">
            <RightSide />
            <div className="left-side">
                <div className="login-form">
                    <div className="parent">
                        <div className="logo-parent">
                            <h1 className="login-text">{loginLabel}</h1>
                        </div>
                        <div className="mt10">
                            <Form
                                name="basic"
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Form.Item
                                    name="email"
                                    rules={[{ required: true, message: 'Please enter your email address' }]}
                                >
                                    <Input size="large" placeholder={"Email Address"} autoComplete="off" />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: 'Please enter your password' }]}
                                >
                                    <Input.Password size="large" placeholder="Password" autoComplete="off" />
                                </Form.Item>

                                <Form.Item className="mt10">
                                    <div className="login-btn-section">
                                        <div>
                                            <Link to={'/forget-password'} className="forget-text">Forget Password?</Link>
                                        </div>
                                        <Button
                                            type="primary"
                                            size="large"
                                            htmlType="submit"
                                            className="login-btn br1"
                                            loading={isLoginLoading}
                                            disabled={isLoginLoading}
                                        >
                                            Log In
                                        </Button>
                                    </div>
                                </Form.Item>

                            </Form>
                        </div>
                        <div className="draw-line mt8"></div>
                        <div className="mt8 no-accoint">
                            <p className="mb0">Don't have an account? <Link to="/signup">Sign Up Now</Link></p>
                        </div>

                    </div>
                </div>
            </div>
            {visible &&
                <OtpModal
                    visible={visible}
                    setVisible={() => {
                        setVisible(false);
                    }}
                    phoneNo={loginData.fundraising_admin_phone}
                />
            }
        </div>
    );
};

export default Login;
