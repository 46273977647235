import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Spin, Form } from 'antd';
import SingleCourseCard from './singleCourseCard';
import { ReactSortable } from "react-sortablejs";
import Sortable, { Swap } from "sortablejs";
import { AiOutlineVideoCamera } from 'react-icons/ai';
import { VideoPlayer } from "../../../common/modal/videoPlayer";

const _ = require("lodash");
Sortable.mount(new Swap());

const Course = (props) => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const [form] = Form.useForm();
    const { isCourseLoading, courseList, existCourseList, isExistCourseLoading, isCourseUpdating, courseUpdateMsg } = useSelector(state => state.common);
    const [tmpCourseList, setTmpCourseList] = useState(courseList);
    const [isAllowEdit, setIsAllowEdit] = useState(true);
    const [videoType, setVideoType] = useState(false);
    const [showVideo, setShowVideo] = useState(false);

    useEffect(() => {
        if (!isCourseLoading && courseList.length) {
            if (!isExistCourseLoading && existCourseList.length > 0) {
                let tmpList = _.orderBy(existCourseList, ['index'], ['asc']);
                setTmpCourseList(tmpList);
                setIsAllowEdit(false);
            } else {
                setTmpCourseList(courseList);
            }

        }
    }, [isCourseLoading, courseList, existCourseList, isExistCourseLoading, props.activeTab]);

    useEffect(() => {
        dispatch({ type: 'GET_COURSE_LIST_REQUEST', formData: { per_page: 5000 } });
        dispatch({ type: 'GET_WIND_DIRECTION_LIST_REQUEST', formData: { per_page: 5000 } });
    }, []);
    useEffect(() => {
        getExistingCourse();

    }, [props.activeTab]);

    const getExistingCourse = () => {
        dispatch({ type: 'GET_EXIST_COURSE_REQUEST', id: id });
        return () => {
            dispatch({ type: 'CLEAR_COURSE' });
        }
    }

    useEffect(() => {
        if (!isCourseUpdating && courseUpdateMsg === "success") {
            dispatch({ type: 'CLEAR_COURSE' });
            getExistingCourse();
        }
    }, [isCourseUpdating, courseUpdateMsg])


    const onFinish = (values) => {

        let tmpCourse = [];
        let tmpSpeed = [];
        for (const key in values) {
            let tmpCourseObj = {};
            let tmpSpeedObj = {};
            if (key.includes('course')) {
                let splitDirection = key.split('_');
                tmpCourseObj.golf_course_id = parseInt(splitDirection[1]);
                tmpCourseObj.wind_direction_id = values[key];
                tmpCourseObj.index = parseInt(splitDirection[2]);
                tmpCourse.push(tmpCourseObj);
            }
            if (key.includes('speed')) {
                let splitDirection = key.split('_');
                tmpSpeedObj.wind_speed = values[key];
                tmpSpeedObj.index = parseInt(splitDirection[1]);
                tmpSpeed.push(tmpSpeedObj);
            }
        }


        const mergeById = (a1, a2) =>
            a1.map(itm => ({
                ...a2.find((item) => (item.index === itm.index) && item),
                ...itm
            }));

        const tmpAdded = mergeById(tmpCourse, tmpSpeed)

        dispatch({ type: 'UPDATE_COURSE_DATA_REQUEST', formData: { "courses": tmpAdded }, id: id });

    }
    const onFinishFailed = (values) => {
        console.log(values)
    }

    const openVideo = (videoType) => {
        setVideoType(videoType);
        setShowVideo(true);
    }

    return (
        <div className="course-section">
            <p className="cmp-title mb2 d-flex-a-center space-between">
                <span>
                    Select your fairway order and wind for your fundraiser game
                </span>
                <div className="d-flex-a-center link pointer" onClick={() => openVideo('course')}><AiOutlineVideoCamera />&nbsp;Fairway Help</div>
            </p>
            <p className="cmp-sub-title">Select the wind direction and wind speed for each fairway. Move the fairways by selecting the area to the left of the picture and dragging them into the order you would like your competitors to play. Once saved, this is locked and all competitors will play with the same options. The ball closest-to-the-pin on the 4th attempt is used for ranking</p>
            <div className="mt7">
                {(isCourseLoading || isExistCourseLoading) && <div className="no-data"><Spin /></div>}
                {!isCourseLoading && !isExistCourseLoading && tmpCourseList.length > 0 &&
                    <div>
                        <Form layout="vertical" form={form}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >

                            {tmpCourseList.length &&
                                <ReactSortable
                                    // disabled={isAllowEdit}
                                    handle=".draggable-icon"
                                    list={tmpCourseList}
                                    setList={setTmpCourseList}
                                    animation={150}
                                >
                                    {tmpCourseList.map((item, index) => {
                                        return (<div key={item.index}>
                                            <SingleCourseCard isEdit={isAllowEdit} item={item} index={index + 1} ref={form} />
                                        </div>)
                                    })}
                                </ReactSortable>
                            }
                            {isAllowEdit &&
                                <div className="mt5" style={{ width: "100", textAlign: 'right' }}>
                                    <Button
                                        type="primary"
                                        size="large"
                                        className="br25 mr4"
                                        htmlType="submit"
                                        loading={isCourseUpdating}
                                        disabled={isCourseUpdating}
                                    >
                                        Save
                                    </Button>
                                </div>
                            }
                        </Form>
                    </div>
                }
            </div>
            {showVideo &&
                <VideoPlayer
                    visible={showVideo}
                    setVisible={() => setShowVideo(false)}
                    videoType={videoType}
                />
            }
        </div>
    );
};
export default Course;
