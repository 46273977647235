import React, { useEffect, useState } from "react";
import { Upload, Button } from "antd";
import noImage from "../../../src/assets/noimage.png";
import { useDispatch } from 'react-redux';

const UploadImage = (props) => {
    console.log("props");
    console.log(props);
    const { uploadImageURL, label } = props
    const [imageUrl, setImageUrl] = useState("");
    const dispatch = useDispatch();

    useEffect(() => { setImageUrl("") }, [])
    const handleChangeBookPicture = (info) => {

        if (info.file.status === "uploading") {
            return;
        }
        if (info.file.status === "done") {
            getBase64(info.file.originFileObj, (imageUrl) => {
                setImageUrl(imageUrl);
            });
            const formData = new FormData();
            formData.append("image", info.file.originFileObj);
            dispatch({ type: 'UPLOAD_IMAGE_REQUEST', formData: formData });
        }
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    return (
        <>
            <div className="project-building-image-parent">
                {(imageUrl || uploadImageURL) &&
                    <div className="project-building-image image">
                        <img src={imageUrl || uploadImageURL} alt={"Organziation Logo"} />
                    </div>
                }
                {noImage && !imageUrl && !uploadImageURL &&
                    <div className="project-building-image no-image">
                        <img src={imageUrl || uploadImageURL || noImage} alt={"Organziation Logo"} />
                    </div>
                }

                <Upload
                    accept="image/*"
                    name="avatar"
                    className="avatar-uploader"
                    onChange={handleChangeBookPicture}
                    showUploadList={false}
                    customRequest={({ file, onSuccess }) =>
                        setTimeout(() => onSuccess("ok"), 0)
                    }
                >
                    <p className="title">{label !== "" ? label : "Organization Logo"}</p>
                    <Button>Choose File</Button>
                </Upload>
            </div>
        </>
    );
};

export default UploadImage;
