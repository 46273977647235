/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Row, Col, Input, Table, Space, Tooltip } from "antd";
import { PlusOutlined, EditFilled, DeleteFilled } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment'
import { isAuthorized } from "../../../utils/functions";
import CouponModal from "../../common/modal/couponModal";

const { Search } = Input;
const { Column } = Table;

let filterParams = { search: "" };
const Coupons = (props) => {

    const dispatch = useDispatch();
    const { isFCouponLoading, fundraiserCouponList } = useSelector(state => state.fundraiser);
    const { isCouponToggleLoading, couponToggleStatus, isCouponUpdateLoading, couponMsg } = useSelector(state => state.admin);
    const { isDeleteLoading, deleteMsg } = useSelector(state => state.common);

    const [tableData, setTableData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [rowData, setRowData] = useState("");
    const [modalType, setModalType] = useState("");

    useEffect(() => {
        getAllCoupons();
    }, []);

    const getAllCoupons = (params = {}, isSearch = false) => {
        let tmpParams = { ...params };
        tmpParams.per_page = 5000;
        dispatch({ type: 'FUNDRAISER_COUPON_LIST_REQUEST', formData: tmpParams });
    }

    useEffect(() => {
        if (!isFCouponLoading && fundraiserCouponList?.length) {
            let tmpCouponList = [];
            fundraiserCouponList?.map((item) => {
                let tmpObj = item
                tmpCouponList.push(tmpObj);
                return 1;
            })
            setTableData(tmpCouponList);
        } else {
            setTableData([])
        }
    }, [isFCouponLoading, fundraiserCouponList]);

    const openModal = (record, type) => {
        setVisible(true);
        if (type === "view") {
            setRowData("");
            dispatch({ type: "ADMIN_PRIZE_DETAIL_REQUEST", id: record.id });
        } else {
            setRowData(record);
        }
        setModalType(type);
    }

    useEffect(() => {
        if (!isCouponUpdateLoading && couponMsg === "success") {
            dispatch({ type: "ADMIN_DATA_CLEAR" });
            getAllCoupons({})
            setVisible(false);
            setModalType("");
        }
    }, [isCouponUpdateLoading, couponMsg])

    useEffect(() => {
        if (!isDeleteLoading && deleteMsg === "success") {
            dispatch({ type: "CLEAR_COMMON" });
            getAllCoupons({})
            setVisible(false);
            setModalType("");
        }
    }, [isDeleteLoading, deleteMsg]);

    useEffect(() => {
        if (!isCouponToggleLoading && couponToggleStatus === "success") {
            dispatch({ type: "CLEAR_COUPON" });
        }
    }, [isCouponToggleLoading, couponToggleStatus]);

    useEffect(() => {
        if (!isCouponToggleLoading && couponToggleStatus === "success") {
            dispatch({ type: "CLEAR_COUPON" })
        }
    }, [isCouponToggleLoading, couponToggleStatus]);

    const onChange = (e, type) => {
        if (type === "search") {
            filterParams[type] = e;
            dispatch({ type: 'FUNDRAISER_COUPON_FILTER_LIST_REQUEST', formData: e });
        }
        if (!e) {
            getAllCoupons()
        }
    }

    return (
        <div>
            <div className="sub-header">
                <p className="mb0 title">
                    Coupons
                </p>
            </div>
            <div className="filter mt8">
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Search
                            placeholder="Search"
                            style={{ width: "60%" }}
                            enterButton
                            onChange={async (e) => {
                                if (!e.target.value)
                                    await getAllCoupons()
                            }}
                            onSearch={(e) => onChange(e, 'search')}
                        />
                    </Col>
                    {isAuthorized('create_coupons') &&
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fr text-right">
                            <Button
                                type="primary"
                                size="large"
                                className="br1"
                                htmlType="submit"
                                onClick={() => {
                                    dispatch({ type: "CLEAR_COMMON" })
                                    openModal("", "add")
                                }}
                                icon={<PlusOutlined />}
                            >
                                New Coupon
                            </Button>
                        </Col>
                    }
                </Row>
            </div>
            <Row className="mt5">
                <Table
                    style={{ width: "100%" }}
                    dataSource={tableData}
                    loading={isFCouponLoading}
                    scroll={{ x: "max-content" }}
                    pagination={{
                        pageSize: 20,
                        showSizeChanger: false
                    }}
                >
                    <Column
                        title="Code"
                        dataIndex="code"
                        key="code"
                        width={"100px"}
                    />
                    <Column
                        title="Discount"
                        dataIndex="value"
                        key="description"
                        width={"20px"}
                        render={(val, rec, index) => {
                            return (
                                rec.type === 'percentage' ? (
                                    <div>{`${val.replace(/\..*/, '')}%`}</div>
                                ) : (
                                    <div>{`Flat ${val}$`}</div>
                                )
                            )
                        }}
                    />
                    <Column
                        title="Usage Limit"
                        dataIndex="usage_limit"
                        key="usage_limit"
                        width={"50px"}
                    />

                    <Column
                        title="Expiry Date"
                        dataIndex="expires_at"
                        key="expires_at"
                        width={"150px"}
                        render={(val, rec, index) => {
                            return (
                                <div>{moment(val).format('DD MMM, YYYY')}</div>
                            )
                        }}
                    // sorter={(a, b) => a.rank - b.rank}
                    />
                    <Column
                        title={"Actions"}
                        key='actions'
                        width={"70px"}
                        render={(_, record) => {
                            return (
                                <Space size="middle" className={`cursor not-disabled`}>
                                    {/* {isAuthorized('view_prizes') &&
                                        <Tooltip placement="top" title={"View Detail"}>
                                            <EyeOutlined
                                                onClick={() => openModal(record, 'view')}
                                                className={`cursor not-disabled`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    } */}
                                    <Tooltip placement="top" title={"Edit"} >
                                        <EditFilled
                                            onClick={() => openModal(record, 'edit')}
                                            className={`cursor not-disabled`}
                                            style={{ fontSize: "18px" }}
                                        />
                                    </Tooltip>
                                    <Tooltip placement="top" color={"red"} title={"Delete"}>
                                        <DeleteFilled
                                            onClick={() => openModal(record, 'delete')}
                                            className={`cursor not-disabled`}
                                            style={{ fontSize: "18px" }}
                                        />
                                    </Tooltip>
                                </Space>
                            )
                        }}
                    />
                </Table>
            </Row>

            <CouponModal
                visible={visible}
                rowData={rowData}
                modalType={modalType}
                setVisible={() => {
                    setRowData("");
                    setVisible(false);
                }}
            />
        </div>
    );
};
export default Coupons;