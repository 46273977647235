/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form, Input, Select, Row, Col, Spin, Tag, Card } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formatDateTime } from '../../../utils/functions';
import PrizeImg from '../../../assets/prize.png';
import UploadImage from '../upload';

const { Option } = Select;
const { TextArea } = Input;

const PrizeModal = (props) => {
    const { visible, setVisible, modalType, rowData } = props;
    const { isDeleteLoading, uploadImageURL } = useSelector(state => state.common);

    const { isPrizeDetailLoading, prizeDetail, isCommonUpdateLoading } = useSelector(state => state.admin);
    const { isFPackageLoading, fundraiserPackageList, } = useSelector(state => state.fundraiser);

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [pagePrizeDetail, setpagePrizeDetail] = useState({});
    const [imgUrl, setImgUrl] = useState("");

    useEffect(() => {
        if (modalType === "view") {
            if (!isPrizeDetailLoading && Object.keys(prizeDetail).length > 0) {
                console.log(prizeDetail);
                setpagePrizeDetail(prizeDetail);
            }
        }
    }, [isPrizeDetailLoading, prizeDetail])

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                console.log(values)
                let tmpValues = {};
                for (const property in values) {
                    if (values[property] !== undefined) {
                        tmpValues[property] = values[property];
                    }
                }
                tmpValues.image = uploadImageURL;
                if (modalType === "edit") {
                    dispatch({ type: 'UPDATE_COMMON_REQUEST', formData: tmpValues, 'requestType': 'put', id: rowData.id, updateFor: 'prize' });
                } else if (modalType === "delete") {
                    dispatch({ type: 'DELETE_REQUEST', id: rowData.id, deleteType: 'prizes' });
                } else {
                    dispatch({ type: 'UPDATE_COMMON_REQUEST', formData: tmpValues, 'requestType': 'post', updateFor: 'prize' });
                }
                setTimeout(() => {
                    form.resetFields();
                    setImgUrl("");
                }, 1000);
            })
            .catch((errorInfo) => {
                console.log(errorInfo)
            });
    }

    useEffect(() => {
        setImgUrl("");
        dispatch({ type: "CLEAR_COMMON" });
    }, []);

    useEffect(() => {
        if (rowData !== "" && Object.keys(rowData).length > 0) {
            console.log(rowData);
            form.setFieldsValue({
                name: rowData?.name || "",
                rank: rowData?.rank || "",
                description: rowData?.description || "",
                fundraiser_package_id: rowData?.fundraiser_package_id || "",
            });
            setImgUrl(rowData.image !== null && rowData.image !== "" ? rowData.image : "")
        }
    }, [rowData])

    return (
        <Modal
            className={`prize-modal step4-modal org-modal ${modalType === "view" ? "view-modal" : ""}`}
            title={`${modalType === "view" ? "" : modalType === "add" ? "Create New Prize" : modalType === "edit" ? "Edit Prize" : modalType === "delete" ? "Are you sure?" : ""}`}
            open={visible}
            onOk={() => handleSubmit()}
            onCancel={() => {
                dispatch({ type: "CLEAR_COMMON" });
                setVisible(false);
                form.resetFields();
            }}
            centered
            width={650}
            okText={`${modalType === "delete" ? "Delete" : modalType === "edit" ? 'Update' : "Save"}`}
            okButtonProps={{ disabled: isCommonUpdateLoading || isDeleteLoading, loading: isCommonUpdateLoading || isDeleteLoading }}
            cancelText="Cancel"
            maskClosable={modalType === "view" ? true : false}

        >
            {(modalType === "edit" || modalType === "add") &&
                <div className="mt4">
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        form={form}
                        layout="vertical"
                    >
                        <Form.Item
                            name="name"
                            label={"Prize name"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter type name'
                                },
                                {
                                    max: 100,
                                    message: 'Maximum 100 characters only allowed!',
                                }]
                            }
                        >
                            <Input size="large" placeholder={"Type name"} />
                        </Form.Item>

                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter description'
                                },
                                { max: 5000, message: 'Maximum 5000 characters only allowed!' }
                            ]}
                        >
                            <TextArea rows={3} placeholder={"Description"} />
                        </Form.Item>

                        <Form.Item
                            name="fundraiser_package_id"
                            label="Fundraiser Package"
                            rules={[{ required: true, message: 'Select package' }]}
                        >
                            <Select
                                placeholder="Select"
                                size="large"
                                autoComplete="new-state"
                                loading={isFPackageLoading}
                            >
                                {!isFPackageLoading && fundraiserPackageList.map((item) =>
                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                )}

                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="rank"
                            label="Rank"
                            rules={[{ required: true, message: 'Select option' }]}
                        >
                            <Select
                                placeholder="Select"
                                size="large"
                                autoComplete="new-state"
                            >
                                <Option value="1">1</Option>
                                <Option value="2">2</Option>
                                <Option value="3">3</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item

                        >
                            <UploadImage label={"Prize Image"} uploadImageURL={uploadImageURL || imgUrl} />
                        </Form.Item>
                    </Form>
                </div>
            }
            {modalType === "view" &&
                <div className="mt4">
                    {isPrizeDetailLoading && <div className='no-data'><Spin /></div>}
                    {!isPrizeDetailLoading &&
                        <>
                            <Row className='section1' style={{ borderBottom: 'none' }}>
                                <Col xs={24} sm={24} md={14} lg={14} xl={14} className="org-view">
                                    <p className='title'>{pagePrizeDetail.name}</p>
                                    <p className='mb2'>
                                        <Tag color={pagePrizeDetail.status === "active" ? 'green' : 'default'}>
                                            {pagePrizeDetail.status === "active" ? "Active" : "Archived"}
                                        </Tag>
                                    </p>
                                    <p className='mb2'><b>Description:</b>&nbsp;&nbsp;{pagePrizeDetail.description}</p>
                                    <p className='mb3'><b>Rank:</b>&nbsp;&nbsp;{pagePrizeDetail.rank}</p>
                                    <p className='mb2'><b>Package:</b>&nbsp;&nbsp;{pagePrizeDetail?.fundraiser_package?.name} - ${pagePrizeDetail?.fundraiser_package?.regular_fees}<br></br>
                                        Raise up to ${pagePrizeDetail?.fundraiser_package?.amount_to_be_generated}<br></br>
                                        {pagePrizeDetail?.fundraiser_package?.no_tickets_allowed} tickets typically  {pagePrizeDetail?.fundraiser_package?.no_sellers_allowed} sellers <br></br>
                                    </p>

                                    <p className='mb0'><b>Created at:</b>&nbsp;&nbsp;{formatDateTime(pagePrizeDetail?.created_at)} </p>
                                    <p className='mb5'><b>Updated at:</b>&nbsp;&nbsp;{formatDateTime(pagePrizeDetail?.updated_at)} </p>
                                </Col>
                                <Col xs={24} sm={24} md={10} lg={10} xl={10} className="">
                                    <div className='org-img-section mb2'>
                                        <img src={pagePrizeDetail.image || PrizeImg} alt={"Prize Logo"} />
                                    </div>
                                </Col>
                            </Row>

                        </>
                    }
                </div>
            }
            {modalType === "delete" &&
                <div className="mt2">
                    <p>You want to delete this prize</p>
                </div>
            }


        </Modal>
    );
};

export default PrizeModal;