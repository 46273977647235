/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Card, Spin, Popover, Avatar } from "antd";
import newImg from '../../../assets/Ellipse.png';
import { InfoCircleOutlined } from '@ant-design/icons';
import StepTemplate from './stepTemplate';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from 'react-router-dom';
import { showAvtarOrgName } from '../../../utils/functions';
import NextPrevButton from "./nextPrevButton";

const Step1 = (props) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { isFTypeLoading, fundraiserTypeList, isFTypeCreateLoading, statusMsg, isGetFundraiserLoading, fundraiserData, fundraiserId } = useSelector(state => state.fundraiser);
    const [selectedId, setSelectedId] = useState('');

    useEffect(() => {
        dispatch({ type: 'FUNDRAISER_TYPE_LIST_REQUEST', formData: { status: 'active' } });
        if (id) {
            dispatch({ type: 'GET_FUNDRAISER_DATA_REQUEST', formData: { id: id } });
        }
    }, []);

    useEffect(() => {
        if (!isGetFundraiserLoading && Object.keys(fundraiserData).length > 0) {
            setSelectedId(fundraiserData?.fundraiser_type_id)
        }
    }, [isGetFundraiserLoading, fundraiserData])


    const pageNext = () => {
        // props.handleNext();
        if (id) {
            dispatch({ type: 'CREATE_FUNDRAISER_TYPE_REQUEST', formData: { 'fundraiser_type_id': selectedId }, 'requestType': 'put', id: id });
        } else {

            dispatch({ type: 'CREATE_FUNDRAISER_TYPE_REQUEST', formData: { 'fundraiser_type_id': selectedId }, 'requestType': 'post' });
        }

    }

    useEffect(() => {
        if (!isFTypeCreateLoading && statusMsg === "success") {
            console.log("fundraiserId", fundraiserId);
            props.handleNext(fundraiserId);
            dispatch({ type: 'CLEAR_FUNDRAISER_STATUS' });
        }
    }, [isFTypeCreateLoading, statusMsg, fundraiserId]);

    const content = (item) => {
        let tmpData = [];
        if (item.data !== undefined && item.data.length > 0) {
            item.data.map((it) => tmpData.push(<p className="mb2">{it}</p>))
        } else {
            return item.data !== undefined && item.data.length === 0 && <p>No Data Available</p>;
        }
        return tmpData;
    }



    return (
        <>
            <StepTemplate
                handleNext={(e) => pageNext(e)}
                handleBack={props.handleBack}
                step={props.step}
                isDisabled={selectedId === ""}
                isLoading={isFTypeCreateLoading}
            >
                <NextPrevButton
                    handleNext={(e) => pageNext(e)}
                    handleBack={props.handleBack}
                    step={props.step}
                    isDisabled={selectedId === ""}
                    isLoading={isFTypeCreateLoading}
                />
                <div className="content-container">
                    <p className="title mb2">Let's start your fundraising campaign.</p>
                    <p className="sub-title">What are you raising funds for?</p>
                    <div className="mt10">
                        {isFTypeLoading && <div className="no-data"><Spin /></div>}
                        {!isFTypeLoading && fundraiserTypeList.length > 0 && fundraiserTypeList.map((item, index) => {
                            return (
                                <Card style={{ width: 700 }} key={index} className={`mb4 main-card pointer ${selectedId === item.id ? 'active' : ''}`} onClick={() => setSelectedId(item.id)} >
                                    <div className="camp-card avatar-img">
                                        <div className="section1">
                                            <div>
                                                <Avatar size="large" width={40} className='pointer' >{showAvtarOrgName(item.name || "")}</Avatar>
                                            </div>
                                            <p className="mb0 text">{item.name}</p>
                                        </div>
                                        <div className="section2">
                                            <Popover placement="top" overlayClassName="info-tooltip" content={content(item)} trigger={'hover'} >
                                                <InfoCircleOutlined />
                                            </Popover >
                                        </div>
                                    </div>
                                </Card>
                            )
                        })}

                    </div>
                </div>
            </StepTemplate>
        </>
    );
};
export default Step1;
