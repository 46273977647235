import { put, takeLatest, call, delay, all, select } from 'redux-saga/effects'
import { message } from 'antd';
//Import API Service.
import { deleteRequestAPI, getRequestAPI, postRequestAPI, putRequestAPI } from '../../services/API/api';

function* getPrizeList({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `prizes`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'PRIZE_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'PRIZE_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'PRIZE_LIST_ERROR', error: error.message || "" });
    }
}
function* linkPrize({ formData, id }) {
    try {
        console.log(formData);
        const response = yield call(postRequestAPI, { url: `fundraisers/${id}/prizes`, data: formData });
        if (response.status === 200) {
            yield put({ type: 'PRIZE_LINK_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'PRIZE_LINK_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'PRIZE_LINK_ERROR', error: error.message || "" });
    }
}

function* combinedPrizeListAndLinkPrize({ formData, id }) {
    try {

        for (let rank = 1; rank <= 3; rank++) {

            yield call(getPrizeList, { formData: { ...formData, rank }, id });

            const state = yield select(state => state.step5);
            const prizeListData = state.prizeList;

            const prizeIds = prizeListData.map((prize) => prize.id);
            yield call(linkPrize, { formData: { rank, prizes: prizeIds }, id });
        }
    } catch (error) {
        yield put({ type: 'COMBINED_PRIZE_ERROR', error: error.message || '' });
    }
}



function* watchCommonSagaRequests() {
    yield takeLatest('PRIZE_LIST_REQUEST', getPrizeList);
    yield takeLatest('PRIZE_LINK_REQUEST', linkPrize);
    yield takeLatest('COMBINED_PRIZE_REQUEST', combinedPrizeListAndLinkPrize);
}

function* CommonSaga() {
    yield watchCommonSagaRequests();
}

export default CommonSaga;