/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Button } from 'antd';
import React from 'react';
import PrintTicket from "../../common/printTicket";

const TicketPreviewModal = (props) => {
    const { visible, onCancel, data } = props;
    console.log("visible, onCancel, data");
    console.log(visible, onCancel, data);
    return (
        <Modal
            className="success-modal-parent"
            footer={false}
            visible={visible}
            onCancel={() => onCancel(false)}
            width={1000}
            maskClosable={true}
        >
            <div className="success-modal-child">
                <PrintTicket
                    data={data}
                />
                
            </div>
        </Modal>
    );
};

export default TicketPreviewModal;