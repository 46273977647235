/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Spin, Card, Tag, Button, Row, Col, Input, Popover } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { useState } from "react";
import { showOrgNameWithDetail, getUserData } from '../../../utils/functions';
import OrganizationModal from "../../common/organizationModal";
import NoData from './noData';

const { Search } = Input;

const Organizations = (props) => {

    const dispatch = useDispatch();
    const { isFOrganizationLoading, fundraiserOrganizationList, isOrganizationCreateLoading, updateMsg } = useSelector(state => state.fundraiser);
    const { cityList, provinceList, isDeleteLoading, deleteMsg } = useSelector(state => state.common);

    const [searchText, setSearchText] = useState("");
    const [isSearch, setIsSearch] = useState(false);
    const [visible, setVisible] = useState(false);
    const [rowData, setRowData] = useState("");
    const [modalType, setModalType] = useState("");

    useEffect(() => {
        getAllOrganization({}, false);
    }, []);

    useEffect(() => {
        if (provinceList.length === 0) {
            dispatch({ type: 'PROVINCE_LIST_REQUEST', formData: { per_page: 5000 } });
        }
    }, [])

    const getAllOrganization = (params = {}, isSearch = false) => {
        let tmpParams = { ...params };
        tmpParams.per_page = 5000;
        setIsSearch(isSearch);
        dispatch({ type: 'FUNDRAISER_ORGANIZATION_LIST_REQUEST', formData: tmpParams, userId: getUserData().id });
    }

    useEffect(() => {
        if (!isOrganizationCreateLoading && updateMsg === "success") {
            dispatch({ type: "CLEAR_FUNDRAISER_STATUS" });
            getAllOrganization({});
            setVisible(false);
            setModalType("");
        }
    }, [isOrganizationCreateLoading, updateMsg])

    const onSearch = (e) => {
        setSearchText(e);
        getAllOrganization({ 'search': e }, true);
    };

    const openModal = (record, type) => {
        setVisible(true);
        setRowData(record);
        if (type === "view") {
            dispatch({ type: "GET_ORGANIZATION_DETAIL_REQUEST", orgId: record.id });
        }
        setModalType(type);
    }

    useEffect(() => {
        if (!isDeleteLoading && deleteMsg === "success") {
            dispatch({ type: "CLEAR_COMMON" });
            getAllOrganization({});
            setVisible(false);
            setModalType("");
        }
    }, [isDeleteLoading, deleteMsg])

    const actionsDropdown = (item) => {
        return (
            <div onClick={(e) => e.stopPropagation()}>
                <p className="pointer" onClick={() => openModal(item, 'view')}>View organization</p>
                <p className="pointer" onClick={() => openModal(item, 'edit')}>Edit organization</p>
                <p className="pointer" onClick={() => openModal(item, 'delete')}>Delete organization</p>
            </div>
        );
    }

    return (
        <div className="fundraising-org">
            <div className="sub-header mb7">
                <p className="mb0 title">
                    Organizations
                </p>
            </div>

            {((!isFOrganizationLoading && fundraiserOrganizationList.length !== 0) || isSearch) &&
                <div className="filter  mb8 new-fundraising-parent">
                    <Row className='nav-sub-header'>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Search
                                defaultValue={searchText}
                                size="large"
                                allowClear
                                placeholder="Search"
                                style={{ width: "80%" }}
                                enterButton
                                onSearch={(e) => onSearch(e, 'search')}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fr text-right">
                            <Button
                                type="primary"
                                size="large"
                                className="br20"
                                htmlType="submit"
                                onClick={() => openModal("", "add")}
                                icon={<PlusOutlined />}
                            >
                                New Organization
                            </Button>
                        </Col>
                    </Row>
                </div>
            }
            {isFOrganizationLoading && <div className="new-fundraising-parent" ><Spin /></div>}
            {!isFOrganizationLoading && fundraiserOrganizationList.length > 0 && (
                <div className="new-fundraising-parent mb10">

                    {fundraiserOrganizationList.map((item) => {
                        return (
                            <Card className="mt4" key={item.id}>
                                <div className="card">
                                    <div>
                                        <p className="mb2 title">{item?.name}</p>
                                        <p className="mb0 sub-text">
                                            {showOrgNameWithDetail(item)}
                                        </p>
                                    </div>
                                    <div className="section2">
                                        <div className="mr4">
                                            <p className="mb2 status-tag">
                                                <Tag color={`${item.status === "active" ? "green" : "red"}`} className={`${item.status === "active" ? "status-success" : "status-error"}`}>
                                                    {
                                                        item.status === "active" ? "Active" : "Archived"
                                                    }
                                                </Tag>
                                            </p>
                                            <p className="mb0 date-style">{`${item.fundraisers_count} Fundraisers`} </p>
                                        </div>
                                        <div>
                                            <Popover placement="right" content={actionsDropdown(item)}>
                                                <BiDotsVerticalRounded size={22} />
                                            </Popover>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        )
                    })}
                </div>
            )}
            {!isFOrganizationLoading && fundraiserOrganizationList.length === 0 &&
                <NoData isSearch={isSearch} addNewOrg={() => openModal("", "add")} />
            }
            <OrganizationModal
                visible={visible}
                rowData={rowData}
                modalType={modalType}
                setVisible={() => {
                    setRowData("");
                    setVisible(false);
                }}
            />

        </div>


    );
};
export default Organizations;
