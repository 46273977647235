const initialState = {


    isPlayerDetailLoading: false,
    playerDetail: {},

    isPlayerLoading: false,
    playerList: [],
    paginationMeta: {},

    isPlayerGameLoading: false,
    playerGamesList: [],

    isGameLoading: false,
    gameList: [],
    gamePaginationMeta: {},

    isGameDetailLoading: false,
    gameDetail: {},
    isGameRankLoading: false,
    gameRankDetailList: [],
    isGamePrizeLoading: false,
    gamePrizeDetail: [],
    isAnalyticsLoading: false,
    analyticsDetail: {},
    isRTloading: false,
    reportedTicketsList: [],

    isPrizeDeliveredLoading: false,
    prizeDeliveredList: [],
    isGraphLoading: false,
    graphData: [],
    isAddUpdating: false,
    successMsg: "",
    error: ""
};

const reducer = (state = initialState, action = {}) => {

    switch (action.type) {


        /*******************/
        case "ADMIN_PLAYER_LIST_REQUEST":
            state = {
                ...state,
                isPlayerLoading: true,
                playerList: [],
                paginationMeta: {}
            };
            break;

        case "ADMIN_PLAYER_LIST_SUCCESS":
            state = {
                ...state,
                isPlayerLoading: false,
                playerList: action.data?.data,
                paginationMeta: action.data?.meta,
            };
            break;

        case "ADMIN_PLAYER_LIST_ERROR":
            state = {
                ...state,
                isPlayerLoading: false,
                playerList: [],
                paginationMeta: {}
            };
            break;

        /*******************/
        case "ADMIN_PLAYER_DETAIL_REQUEST":
            state = {
                ...state,
                isPlayerDetailLoading: true,
                playerDetail: [],
            };
            break;

        case "ADMIN_PLAYER_DETAIL_SUCCESS":
            state = {
                ...state,
                isPlayerDetailLoading: false,
                playerDetail: action.data
            };
            break;

        case "ADMIN_PLAYER_DETAIL_ERROR":
            state = {
                ...state,
                isPlayerDetailLoading: false,
                playerDetail: {},
            };
            break;

        /*******************/
        case "PLAYER_GAME_LIST_REQUEST":
            state = {
                ...state,
                isPlayerGameLoading: true,
                playerGamesList: [],
            };
            break;

        case "PLAYER_GAME_LIST_SUCCESS":
            state = {
                ...state,
                isPlayerGameLoading: false,
                playerGamesList: action.data
            };
            break;

        case "PLAYER_GAME_LIST_ERROR":
            state = {
                ...state,
                isPlayerGameLoading: false,
                playerGamesList: [],
                error: action.error
            };
            break;

        /*******************/
        case "ADMIN_GAME_LIST_REQUEST":
            state = {
                ...state,
                isGameLoading: true,
                gameList: [],
                gamePaginationMeta: {}
            };
            break;

        case "ADMIN_GAME_LIST_SUCCESS":
            state = {
                ...state,
                isGameLoading: false,
                gameList: action.data?.data,
                gamePaginationMeta: action.data?.meta,
            };
            break;

        case "ADMIN_GAME_LIST_ERROR":
            state = {
                ...state,
                isGameLoading: false,
                gameList: [],
                error: action.error,
                gamePaginationMeta: {}
            };
            break;

        /*******************/
        case "ADMIN_GAME_DETAIL_REQUEST":
            state = {
                ...state,
                isGameDetailLoading: true,
                gameDetail: {},
            };
            break;

        case "ADMIN_GAME_DETAIL_SUCCESS":
            state = {
                ...state,
                isGameDetailLoading: false,
                gameDetail: action.data
            };
            break;

        case "ADMIN_GAME_DETAIL_ERROR":
            state = {
                ...state,
                isGameDetailLoading: false,
                gameDetail: {},
            };
            break;
        /*******************/
        case "ADMIN_GAME_RANK_DETAIL_REQUEST":
            state = {
                ...state,
                isGameRankLoading: true,
                gameRankDetailList: [],
            };
            break;

        case "ADMIN_GAME_RANK_DETAIL_SUCCESS":
            state = {
                ...state,
                isGameRankLoading: false,
                gameRankDetailList: action.data?.data
            };
            break;

        case "ADMIN_GAME_RANK_DETAIL_ERROR":
            state = {
                ...state,
                isGameRankLoading: false,
                gameRankDetailList: [],
            };
            break;
        /*******************/
        case "ADMIN_GAME_PRIZE_DETAIL_REQUEST":
            state = {
                ...state,
                isGamePrizeLoading: true,
                gamePrizeDetail: [],
            };
            break;

        case "ADMIN_GAME_PRIZE_DETAIL_SUCCESS":
            state = {
                ...state,
                isGamePrizeLoading: false,
                gamePrizeDetail: action.data?.data
            };
            break;

        case "ADMIN_GAME_PRIZE_DETAIL_ERROR":
            state = {
                ...state,
                isGamePrizeLoading: false,
                gamePrizeDetail: [],
            };
            break;

        /*******************/
        case "ADMIN_GAME_GRAPH_REQUEST":
            state = {
                ...state,
                isGraphLoading: true,
                graphData: [],
            };
            break;

        case "ADMIN_GAME_GRAPH_SUCCESS":
            state = {
                ...state,
                isGraphLoading: false,
                graphData: action.data?.data
            };
            break;

        case "ADMIN_GAME_GRAPH_ERROR":
            state = {
                ...state,
                isGraphLoading: false,
                graphData: [],
            };
            break;

        /*******************/
        case "ADMIN_ANALYTICS_DETAIL_REQUEST":
            state = {
                ...state,
                isAnalyticsLoading: true,
                analyticsDetail: {},
            };
            break;

        case "ADMIN_ANALYTICS_DETAIL_SUCCESS":
            state = {
                ...state,
                isAnalyticsLoading: false,
                analyticsDetail: action.data
            };
            break;

        case "ADMIN_ANALYTICS_DETAIL_ERROR":
            state = {
                ...state,
                isAnalyticsLoading: false,
                analyticsDetail: {},
            };
            break;

        /*******************/
        case "ADMIN_REPORTED_TICEKTS_LIST_REQUEST":
            state = {
                ...state,
                isRTloading: true,
                reportedTicketsList: [],
            };
            break;

        case "ADMIN_REPORTED_TICEKTS_LIST_SUCCESS":
            state = {
                ...state,
                isRTloading: false,
                reportedTicketsList: action.data
            };
            break;

        case "ADMIN_REPORTED_TICEKTS_LIST_ERROR":
            state = {
                ...state,
                isRTloading: false,
                reportedTicketsList: [],
            };
            break;

        /*******************/
        case "ADMIN_PRIZE_DELIVERED_LIST_REQUEST":
            state = {
                ...state,
                isPrizeDeliveredLoading: true,
                prizeDeliveredList: [],
            };
            break;

        case "ADMIN_PRIZE_DELIVERED_LIST_SUCCESS":
            state = {
                ...state,
                isPrizeDeliveredLoading: false,
                prizeDeliveredList: action.data
            };
            break;

        case "ADMIN_PRIZE_DELIVERED_LIST_ERROR":
            state = {
                ...state,
                isPrizeDeliveredLoading: false,
                prizeDeliveredList: [],
            };
            break;

        /**--------------------**/
        case "UPDATE_USER_ADDRESS_REQUEST":
            state = {
                ...state,
                isAddUpdating: true,
                successMsg: ""

            };
            break;

        case "UPDATE_USER_ADDRESS_SUCCESS":
            state = {
                ...state,
                isAddUpdating: false,
                successMsg: "success"
            };
            break;

        case "UPDATE_USER_ADDRESS_ERROR":
            state = {
                ...state,
                isAddUpdating: false,
                successMsg: ""
            };
            break;

        case "CLEAR_PLAYER":
            state = {
                ...state,
                successMsg: ""
            };
            break;

        default:

    }
    return state;
};

export default reducer;