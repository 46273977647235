/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Spin, Table } from "antd";
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { formatDateTime } from "../../../utils/functions";

const { Column } = Table;

const PlayerRanking = (props) => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const [expandeRow, setExpandedRow] = useState([]);
    const [tableData, setTableData] = useState([]);

    const { isGameRankLoading, gameRankDetailList } = useSelector(state => state.player);

    useEffect(() => { getAllGamesList({}) }, []);

    const getAllGamesList = (params = {}) => {
        dispatch({ type: 'ADMIN_GAME_RANK_DETAIL_REQUEST', formData: { per_page: 5000 }, id: id });
    }

    useEffect(() => {
        let tmpData = [];
        if (!isGameRankLoading && gameRankDetailList.length > 0) {
            gameRankDetailList.map(e => {
                let tmpObj = { ...e };
                tmpObj.key = e.id;
                tmpObj.attempt = e?.ticket_shot_histories?.length ? e['ticket_shot_histories'][0]?.shot_number : "";
                tmpObj.timestamp = formatDateTime(e.created_at);
                tmpObj.ranks_data = loadRanks(e?.ticket_shot_histories);
                tmpObj.distance = e?.ticket_shot_histories?.length ? parseFloat(e['ticket_shot_histories'][0]?.distance_from_hole).toFixed(2) : "";
                tmpObj.player_name = e?.player?.first_name + " " + e?.player?.last_name;
                tmpData.push(tmpObj);
                return 1;
            })
            setTableData(tmpData)
        }
        console.log("isGameRankLoading-", isGameRankLoading, gameRankDetailList)
    }, [isGameRankLoading, gameRankDetailList]);

    const loadColumns = () => {
        return (
            <>
                <Column
                    title="Rank"
                    dataIndex="rank"
                    key="rank"
                    width={"100px"}
                />
                <Column
                    title="Player name"
                    dataIndex="player_name"
                    key="player_name"
                    width={"250px"}
                />
                <Column
                    title="Ticket #"
                    dataIndex="ticket_number"
                    key="ticket_number"
                    width={"200px"}
                />
                <Column
                    title="Attempt"
                    dataIndex="attempt"
                    key="attempt"
                    width={"200px"}
                />
                <Column
                    title="Played timestamp"
                    dataIndex="timestamp"
                    key="timestamp"
                    width={"300px"}
                />
                <Column
                    title="Distance"
                    dataIndex="distance"
                    key="distance"
                    width={"200px"}
                />
            </>
        )
    }

    const loadRanks = (ranks) => {

        const dataSource = [];

        if (ranks.length > 0) {
            ranks.map((item, index) => {
                let childArr = { ...item };
                childArr.attempt = item?.shot_number;
                childArr.timestamp = formatDateTime(item?.created_at);
                childArr.distance = item.distance_from_hole !== undefined && item.distance_from_hole !== null ? parseFloat(item.distance_from_hole).toFixed(2) : "";
                childArr.key = "child" + index;
                dataSource.push(childArr);
            });
        }
        console.log(dataSource);
        return <Table
            size="middle"
            loading={isGameRankLoading}
            showHeader={false}
            pagination={false}
            dataSource={dataSource} >
            <Column
                title=""
                dataIndex="blank"
                key="blank"
                width={"90px"}
            />
            {loadColumns()}
        </Table>;

    }



    return (
        <div>
            {isGameRankLoading && <div className="no-data mt5"> <Spin /></div>}
            {!isGameRankLoading &&
                <>
                    <Row className="">
                        <Table
                            className={"rank-table"}
                            pagination={false}
                            style={{ width: "100%" }}
                            rowClassName={(record, index) => {
                                if (record.is_tied !== undefined && record.is_tied) {
                                    return "row-class-name tied";
                                } else {
                                    return "row-class-name";
                                }
                            }
                            }
                            // rowClassName={"row-class-name"}
                            defaultExpandAllRows={true}
                            expandable={{
                                expandRowByClick: true,
                                defaultExpandAllRows: true,
                                expandedRowKeys: expandeRow,
                                expandedRowRender: (record) => (
                                    <div className="m0" >
                                        {record.ranks_data}
                                    </div>
                                ),
                                expandIcon: ({ expanded, onExpand, record }) => {
                                    if (record.name !== 'Not Expandable') {
                                        return expanded ? (
                                            <IoIosArrowDown onClick={e => {
                                                let tmpKey = [...expandeRow];
                                                tmpKey = tmpKey.filter(item => item !== record.key)
                                                setExpandedRow(tmpKey);
                                                onExpand(record, e)
                                            }} />
                                        ) : (
                                            <IoIosArrowForward onClick={e => {
                                                let tmpKey = [...expandeRow];
                                                tmpKey.push(record.key);
                                                setExpandedRow(tmpKey);
                                                onExpand(record, e)
                                            }} />
                                        )
                                    }
                                }
                            }}
                            dataSource={tableData}
                            loading={isGameRankLoading}
                        >
                            {loadColumns()}
                        </Table>
                    </Row>
                </>
            }
        </div>
    );
};
export default PlayerRanking;