import React, { useState } from "react";
import { Modal, Button, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect } from "react";
import Rule1 from '../../../assets/rule1.png';
import Rule2 from '../../../assets/rule2.png';
import Rule3 from '../../../assets/rule3.png';
import SingleCourseCard from "../../fundraising/fundraisingDetails/course/singleCourseCard";

const RulesAndRegulationModal = ({ showModal, onCancel, type = "rules", fundId = "" }) => {
    const { id } = useParams();
    const { existCourseList, isExistCourseLoading } = useSelector(state => state.common);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: 'GET_EXIST_COURSE_REQUEST', id: fundId !== "" ? fundId : id });
    }, [])

    const [numPages, setNumPages] = useState(3);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <Modal
            className="rule-modal-parent"
            footer={false}
            visible={showModal}
            onCancel={() => onCancel(false)}
            width={1000}
            style={{
                top: 20,
            }}
            maskClosable={true}
            title={`Rules & Regulations`}
        >
            <div className="success-modal-child rules-regulations">
                <div className="pdf-img">
                    <img src={Rule1} alt="rule 1" />
                    <img src={Rule2} alt="rule 1" />
                    <img src={Rule3} alt="rule 1" />
                </div>
                <div className="course-section">
                    {!isExistCourseLoading && existCourseList.length > 0 && existCourseList.map((item, index) => {
                        return (<div key={item.index}>
                            <SingleCourseCard isEdit={false} item={item} index={index + 1} />
                        </div>)
                    })}
                </div>
                {/* <Document file={RulesPDF} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} />
                </Document>
                <p>
                    Page {pageNumber} of {numPages}
                </p> */}
                {/* <div style={{ width: "100%" }}>
                    <p className="title mb7">“Closest to the Pin” Golf</p>
                    <p className="title2 mb3">SCAN THE “VALIDATOR QR CODE” PRIOR TO PURCHASE TO ENSURE THE TICKET IS NOT FRADUALENT</p>

                    <div className="course-section">
                        {!isExistCourseLoading && existCourseList.length > 0 && existCourseList.map((item, index) => {
                            return (<div key={item.index}>
                                <SingleCourseCard isEdit={false} item={item} index={index + 1} />
                            </div>)
                        })}
                    </div>

                    <p className="main-title">Game requirements</p>
                    <ul>
                        <li>Minimum age to compete is 13 years. Age 13 to age of majority requires parental consent.</li>
                        <li>Game play is available only on an IOS or Android mobile phone</li>
                        <li>Competitors require an active and stable internet connection to participate in the competition.</li>
                        <li>Streamline Fundraising is not held accountable for loss of internet connection affecting gameplay.</li>
                        <li>In the event the connection is lost, play is suspended until an internet connection is re-established.</li>
                    </ul>
                    <p className="main-title">Directions</p>
                    <ul>
                        <li> Use your phone’s camera to scan the QR code revealed under the scratch off portion on your ticket.</li>
                        <li> Follow the link to the Apple App Store or the Google play store to download and install the free Streamline Fundraising App.</li>
                        <li> Open the app and follow the directions. You will be asked to enter the 8 digit alpha-numeric code that is under the scratch off surface below the QR code.</li>
                        <li> Create your account by entering your phone number, your name and email address.</li>
                        <li> Follow the app instructions through the remainder of the game setup.</li>
                    </ul>


                    <p className="main-title">Rules of the Game (Basic)</p>
                    <ul>
                        <li> Each competitor gets one swing per fairway off the tee box to get the ball closest to the flagstick.</li>
                        <li> There are 4 fairways giving you 4 attempts, one swing per fairway, to be “closest to the pin”.</li>
                        <li> Your distance from the flagstick on your 4th attempt is ranked against the other competitors.</li>
                        <li> (DYNAMIC AREA – SHOWS A PICTURE WITH THE ORDER OF THE FAIRWAYS AND THE WIND CONDITIONS FOR EACH ATTEMPT – THIS IS BASED ON WHAT WAS SELECTED BY THE FUNDRAISING ORGANIZER)</li>
                    </ul>
                    <p className="main-title">Rules of the Game (Advanced)</p>
                    <ul>
                        <li> Players can view each fairway, distance to the pin and wind conditions prior to each attempt.</li>
                        <li> Select your golf club based on the distance to the pin and conditions.</li>
                        <li> (LIST THE CLUBS AND THE RANGE THEY CAN HIT)</li>
                        <li> Determine the direction of the golf swing.</li>
                        <li> The strength of the swing is set by tapping on the power bar once. The power meter strength increases. Tap the power bar again to stop the power meter, which then sets the percentage of strength in your swing.</li>
                        <br></br>
                        <li> As the power meter decreases, tap the power bar again on the “sweet spot” (Show picture)</li>
                        <li> If the power meter is stopped to the left of the “sweet spot,” the ball will curve to the left.</li>
                        <li> If the power meter is stopped to the right of the “sweet spot,” the ball will curve to the right.</li>
                        <li> If the power meter is stopped in the “sweet spot,” the ball will be hit straight</li>
                        (This can be helpful when hitting around obstructions).
                        <br></br>
                        <br></br>

                        <li> After the swing, you are shown where ball lands in relation to the pin.</li>
                        <li> Each round begins the same way.</li>
                        <li> You play 4 fairways and the competition ranking is based on your 4th attempt.</li>
                    </ul>
                    <p className="main-title">Ties</p>
                    <ul>
                        <li>In the event of a tie in the 4th round, the competitor’s 3rd attempt is used to break the tie, followed by their 2nd attempt then the 1st.</li>
                        <li>If a tie after all 4 attempts must be broken, Streamline Fundraising will set up another competition within 2 business days of the event closing date commencing the day after the end date of the competition.</li>
                        <li>Players have 72 hours to compete in the tie breaking competition, starting on the new opening date and closed at 11:59ET on the 3rd day</li>
                    </ul>
                    <p className="main-title">Technical Difficulties</p>
                    <ul>
                        <li> Each step of the gameplay is uploaded to our server in real time.</li>
                        <li> If there is a loss of internet connection or the app crashes after a swing, the information is already stored and your ranking set for that attempt.</li>
                        <li> Once you restart the app or regain an internet connection, the gameplay begins where you left off.</li>
                        <li> At no point are previously played fairways reset.</li>
                    </ul>
                    <p className="main-title">Winners</p>
                    <ul>
                        <li> Winners are notified by email and through the app.</li>
                        <li> A link is sent to where the winners can select their prize based on their placement.</li>
                        <li> The prizes can be viewed prior to play within the app.</li>
                        <li> Winners have 30 days to claim their prize ending at 11:59ET on the last day.</li>
                        <li> Unclaimed prize values are transferred back to the fundraising organization in cash.</li>
                        <li> If the fundraising organization cannot accept the additional funds for any reason, the funds are transferred back Streamline Fundraising Inc.</li>
                    </ul>
                    <p className="main-title">In the Event of Competition Suspension</p>
                    <ul>
                        <li> Fundraising organizations are vetted prior to the beginning of each fundraiser.</li>
                        <li> In the event of misrepresentation or fraudulent activity by the fundraising organization, fundraisers may be suspended or cancelled.</li>
                        <li> If a fundraiser is cancelled prior to the end of the competition, prizes are still awarded based on competitors ranking.</li>
                    </ul>
                    <p className="main-title">Cheating</p>
                    <ul>
                        <li> Streamline Fundraisings takes any attempts to cheat very seriously. Integrity of the game is paramount.</li>
                        <li> Our security measures monitor each game played to determine any sort of fraudulent activity.</li>
                        <li> Streamline fundraising will prosecute to the full extent of the law if any participant is caught cheating or using BOTs to play.</li>
                    </ul>
                    <div className="center-text">
                        <Button
                            type="primary"
                            size="large"
                            className="mt4 mb5 br20 pr8 pl8 center"
                            htmlType="submit"
                            onClick={() => onCancel(false)}
                        >
                            Close
                        </Button>
                    </div>
                </div> */}

            </div>
        </Modal>
    );
};
export default RulesAndRegulationModal;
