import { put, takeLatest, call, delay } from 'redux-saga/effects'
import { message } from 'antd';
//Import API Service.
import { deleteRequestAPI, getRequestAPI, postRequestAPI, putRequestAPI } from '../../services/API/api';


function* getAdminOrganizationList({ formData, userId }) {
    try {
        const response = yield call(getRequestAPI, { url: `organizations`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'ADMIN_ORGANIZATION_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'ADMIN_ORGANIZATION_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_ORGANIZATION_LIST_ERROR', error: error.message || "" });
    }
}

function* getFundraisingTypeDetail({ orgId }) {
    try {

        const response = yield call(getRequestAPI, { url: `fundraiser-types/${orgId}`, params: {} });
        if (response.status === 200) {
            yield put({ type: 'ADMIN_FUNDRAISING_TYPE_DETAIL_SUCCESS', data: response.data || [] });
        } else {
            yield put({ type: 'ADMIN_FUNDRAISING_TYPE_DETAIL_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_FUNDRAISING_TYPE_DETAIL_ERROR', error: error.message || "" });
    }
}

function* getFundraisingPackageDetail({ id }) {
    try {

        const response = yield call(getRequestAPI, { url: `fundraiser-packages/${id}`, params: {} });
        if (response.status === 200) {
            yield put({ type: 'ADMIN_FUNDRAISING_PACKAGE_DETAIL_SUCCESS', data: response.data || [] });
        } else {
            yield put({ type: 'ADMIN_FUNDRAISING_PACKAGE_DETAIL_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_FUNDRAISING_PACKAGE_DETAIL_ERROR', error: error.message || "" });
    }
}

function* toggleCouponStatusRequest({ id, formData }) {
    try {
        let response = yield call(putRequestAPI, { url: `coupon/${id}`, data: formData })
        if (response.data.code === 201) {
            yield put({ type: 'TOGGLE_COUPON_STATUS_SUCCESS', data: response.data.coupon.is_available ? 'activated' : 'archived' });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'TOGGLE_COUPON_STATUS_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'TOGGLE_COUPON_STATUS_ERROR', error: error.message || "" });
    }
}

function* toggleRequest({ id, toggleFor = 'org' }) {
    try {
        let response = "";
        if (toggleFor === "organization") {
            response = yield call(postRequestAPI, { url: `organizations/${id}/toggle-status` });
        } else if (toggleFor === "fundraisingType") {
            response = yield call(postRequestAPI, { url: `fundraiser-types/${id}/toggle-status` });
        } else if (toggleFor === "fundraisingPackage") {
            response = yield call(postRequestAPI, { url: `fundraiser-packages/${id}/toggle-status` });
        } else if (toggleFor === "prizes") {
            response = yield call(postRequestAPI, { url: `prizes/${id}/toggle-status` });
        }
        if (response.status === 200) {
            yield put({ type: 'TOGGLE_SUCCESS', data: response.data.data.archive_at === null ? 'activated' : 'archived' });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'TOGGLE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'TOGGLE_ERROR', error: error.message || "" });
    }
}

function* createUpdateFundraisingType({ formData, requestType, id }) {
    try {
        let response = "";
        if (requestType === "post") {
            response = yield call(postRequestAPI, { url: `fundraiser-types`, data: formData });
        } else if (requestType === "put") {
            response = yield call(putRequestAPI, { url: `fundraiser-types/${id}`, data: formData });
        }
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'UPDATE_FUNDRAISING_TYPE_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong", className: 'error-class', duration: 3 });
            yield put({ type: 'UPDATE_FUNDRAISING_TYPE_ERROR', error: response });
        }
    } catch (error) {
        message.error(error?.message || "Something went wrong");
        yield put({ type: 'UPDATE_FUNDRAISING_TYPE_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* createUpdatePrize({ formData, requestType, id }) {
    try {
        let response = "";
        if (requestType === "post") {
            response = yield call(postRequestAPI, { url: `prizes`, data: formData });
        } else if (requestType === "put") {
            response = yield call(putRequestAPI, { url: `prizes/${id}`, data: formData });
        }
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'UPDATE_PRIZE_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong", className: 'error-class', duration: 3 });
            yield put({ type: 'UPDATE_PRIZE_ERROR', error: response });
        }
    } catch (error) {
        message.error(error?.message || "Something went wrong");
        yield put({ type: 'UPDATE_PRIZE_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* createUpdateCommon({ formData, requestType, id, updateFor = "" }) {
    try {
        console.log("formData, requestType, id, updateFor");
        console.log(formData, requestType, id, updateFor);
        // return;
        let response = "";
        if (updateFor === "prize") {
            if (requestType === "post") {
                response = yield call(postRequestAPI, { url: `prizes`, data: formData });
            } else if (requestType === "put") {
                response = yield call(putRequestAPI, { url: `prizes/${id}`, data: formData });
            }
        } else if (updateFor === "userType") {
            if (requestType === "post") {
                response = yield call(postRequestAPI, { url: `streamline-users`, data: formData });
            } else if (requestType === "put") {
                response = yield call(putRequestAPI, { url: `users/${id}`, data: formData });
            }
        } else if (updateFor === "prizeToBeDelivered") {
            if (requestType === "put") {
                response = yield call(putRequestAPI, { url: `fundraiser-user/${id}/change-status`, data: formData });
            }
        } else if (updateFor === "userPrivilege") {
            if (requestType === "post") {
                response = yield call(postRequestAPI, { url: `roles`, data: formData });
            }
        }

        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'UPDATE_COMMON_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong", className: 'error-class', duration: 3 });
            yield put({ type: 'UPDATE_COMMON_ERROR', error: response });
        }

    } catch (error) {
        message.error(error?.message || "Something went wrong");
        yield put({ type: 'UPDATE_COMMON_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* createUpdateCoupon({ formData, requestType, id }) {
    try {
        let response = "";
        if (requestType === "post") {
            response = yield call(postRequestAPI, { url: `coupon`, data: formData });
        } else if (requestType === "put") {
            response = yield call(putRequestAPI, { url: `coupon/${id}`, data: formData });
        }
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'UPDATE_COUPON_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong", className: 'error-class', duration: 3 });
            yield put({ type: 'UPDATE_COUPON_ERROR', error: response });
        }

    } catch (error) {
        message.error(error?.message || "Something went wrong");
        yield put({ type: 'UPDATE_COUPON_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* changePasswordRequest({ formData, requestType, id, updateFor = "" }) {
    try {
        console.log("formData, requestType, id, updateFor");
        console.log(formData, requestType, id, updateFor);
        const response = yield call(postRequestAPI, { url: `change-password`, data: formData });

        if (response.status === 200 || response.status === 201) {
            if (response.data.error !== undefined) {
                message.error({ content: response.data.error || "Something went wrong", className: 'error-class', duration: 3 });
                yield put({ type: 'CHANGE_PASSWORD_SUCCESS', data: 'error' });
            } else {
                console.log('here---------')
                yield put({ type: 'CHANGE_PASSWORD_SUCCESS', data: 'success' });
            }
        } else {
            message.error({ content: response.message || "Something went wrong", className: 'error-class', duration: 3 });
            yield put({ type: 'CHANGE_PASSWORD_ERROR', error: response });
        }

    } catch (error) {
        message.error(error?.message || "Something went wrong");
        yield put({ type: 'CHANGE_PASSWORD_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* createUpdateFundraisingPackage({ formData, requestType, id }) {
    try {
        let response = "";
        if (requestType === "post") {
            response = yield call(postRequestAPI, { url: `fundraiser-packages`, data: formData });
        } else if (requestType === "put") {
            response = yield call(putRequestAPI, { url: `fundraiser-packages/${id}`, data: formData });
        }
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'UPDATE_FUNDRAISING_PACKAGE_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong", className: 'error-class', duration: 3 });
            yield put({ type: 'UPDATE_FUNDRAISING_PACKAGE_ERROR', error: response });
        }
    } catch (error) {
        message.error(error?.message || "Something went wrong");
        yield put({ type: 'UPDATE_FUNDRAISING_PACKAGE_ERROR', error: error?.message || "Something went wrong" });
    }
}

function* updateStatus({ formData, id, updateFor = 'fundraiser' }) {
    try {
        let response = "";
        if (updateFor === "ticket") {
            response = yield call(putRequestAPI, { url: `fundraisers/${id}/ticket/change-status`, data: formData });
        } else if (updateFor === "fundAdmin") {
            response = yield call(putRequestAPI, { url: `fundraisers/${id}/start-early`, data: formData });
        } else {
            response = yield call(putRequestAPI, { url: `fundraisers/${id}/change-status`, data: formData });
        }
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'UPDATE_STATUS_SUCCESS', data: response.data });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'UPDATE_STATUS_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'UPDATE_STATUS_ERROR', error: error.message || "" });
    }
}

function* exportRequest({ formData, exportFor = 'fundraiser' }) {
    try {
        let response = "";
        if (exportFor === "organization") {
            response = yield call(getRequestAPI, { url: `organizations/export`, params: formData });
        } else if (exportFor === "fundraiser") {
            response = yield call(getRequestAPI, { url: `export-fundraisers`, params: formData });
        } else if (exportFor === "fundraisingAdmin") {
            response = yield call(getRequestAPI, { url: `fundraising-admins/export`, params: formData });
        } else if (exportFor === "player") {
            response = yield call(getRequestAPI, { url: `players/export`, params: formData });
        } else if (exportFor === "games") {
            response = yield call(getRequestAPI, { url: `games/export`, params: formData });
        }
        if (response.status === 200) {
            let removedDoubleQuote = response.data;
            removedDoubleQuote = removedDoubleQuote.replace(/['"]+/g, '');
            yield put({ type: 'EXPORT_SUCCESS', data: removedDoubleQuote });
        } else {
            message.error({ content: response.message || "Something went wrong!", className: 'error-class' });
            yield put({ type: 'EXPORT_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'EXPORT_ERROR', error: error.message || "" });
    }
}

function* getAdminPrizeList({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `prizes`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'ADMIN_PRIZE_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'ADMIN_PRIZE_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_PRIZE_LIST_ERROR', error: error.message || "" });
    }
}

function* getPrizeDetail({ id }) {
    try {
        const response = yield call(getRequestAPI, { url: `prizes/${id}`, params: {} });
        if (response.status === 200) {
            yield put({ type: 'ADMIN_PRIZE_DETAIL_SUCCESS', data: response.data || [] });
        } else {
            yield put({ type: 'ADMIN_PRIZE_DETAIL_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_PRIZE_DETAIL_ERROR', error: error.message || "" });
    }
}

function* getUserList({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `streamline-users`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'ADMIN_USERS_LIST_SUCCESS', data: response.data?.data || [] });
        } else {
            yield put({ type: 'ADMIN_USERS_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_USERS_LIST_ERROR', error: error.message || "" });
    }
}

function* getFundraisingUserList({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `fundraising-admins`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'ADMIN_FUNDRAISING_USERS_LIST_SUCCESS', data: response.data?.data || [] });
        } else {
            yield put({ type: 'ADMIN_FUNDRAISING_USERS_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_FUNDRAISING_USERS_LIST_ERROR', error: error.message || "" });
    }
}


function* getUserDetail({ id }) {
    try {

        const response = yield call(getRequestAPI, { url: `users/${id}`, params: {} });
        if (response.status === 200) {
            yield put({ type: 'ADMIN_USERS_DETAIL_SUCCESS', data: response.data || [] });
        } else {
            yield put({ type: 'ADMIN_USERS_DETAIL_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_USERS_DETAIL_ERROR', error: error.message || "" });
    }
}


function* gegAdminPrintTicketList({ formData, id }) {
    try {
        const response = yield call(getRequestAPI, { url: `fundraisers/${id}/manage-tickets`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'ADMIN_TICKET_PRINT_LIST_SUCCESS', data: response?.data?.data || [] });
        } else {
            yield put({ type: 'ADMIN_TICKET_PRINT_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_TICKET_PRINT_LIST_ERROR', error: error.message || "" });
    }
}

function* getAdminStolenTicketList({ formData, id }) {
    try {
        const response = yield call(getRequestAPI, { url: `fundraisers/${id}/manage-stolen-tickets`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'ADMIN_TICKET_STOLEN_LIST_SUCCESS', data: response.data?.data || [] });
        } else {
            yield put({ type: 'ADMIN_TICKET_STOLEN_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_TICKET_STOLEN_LIST_ERROR', error: error.message || "" });
    }
}

function* getAdminETransferRequest({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `e-transfer`, params: formData });
        if (response.status === 200 && response.data.e_transfers.data.length) {
            yield put({ type: 'GET_ALL_E_TRANSFER_SUCCESS', data: response.data.e_transfers.data || [] });
        } else {
            yield put({ type: 'GET_ALL_E_TRANSFER_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'GET_ALL_E_TRANSFER_ERROR', error: error.message || "" });
    }
}

function* confirmAdminETransferRequest({ formData, eTransferId }) {
    try {
        const response = yield call(postRequestAPI, { url: `e-transfer/${eTransferId}/confirm`, params: formData });
        if (response.status === 200 && response.data.e_transfers.data.length) {
            yield put({ type: 'CONFIRM_E_TRANSFER_REQUEST_SUCCESS', data: response.data.e_transfers.data || [] });
        } else {
            yield put({ type: 'CONFIRM_E_TRANSFER_REQUEST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'CONFIRM_E_TRANSFER_REQUEST_ERROR', error: error.message || "" });
    }
}

function* rejectAdminETransferRequest({ formData, eTransferId }) {
    try {
        const response = yield call(postRequestAPI, { url: `e-transfer/${eTransferId}/reject`, params: formData });
        if (response.status === 200 && response.data.e_transfers.data.length) {
            yield put({ type: 'REJECT_E_TRANSFER_REQUEST_SUCCESS', data: response.data.e_transfers.data || [] });
        } else {
            yield put({ type: 'REJECT_E_TRANSFER_REQUEST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'REJECT_E_TRANSFER_REQUEST_ERROR', error: error.message || "" });
    }
}

function* getAdminTransactions({ formData, id }) {
    try {
        // const response = yield call(getRequestAPI, { url: `fundraisers/${id}/manage-stolen-tickets`, params: formData });
        const response = {
            data: {
                "data": [
                    {
                        "id": 1,
                        "date": "2022-10-30T11:59:12.000000Z",
                        "amount": 1000,
                        "description": "Visa end 457",
                        "status": "fully_paid"
                    },
                    {
                        "id": 2,
                        "date": "2022-10-30T11:59:12.000000Z",
                        "amount": 2000,
                        "description": "Visa end 457",
                        "status": "partially_paid"
                    },
                    {
                        "id": 3,
                        "date": "2022-10-30T11:59:12.000000Z",
                        "amount": 6000,
                        "description": "Visa end 457",
                        "status": "pending"
                    }
                ]
            },
            status: 200
        }
        yield delay(500);
        if (response.status === 200) {
            yield put({ type: 'ADMIN_TRANSACTION_LIST_SUCCESS', data: response.data?.data || [] });
        } else {
            yield put({ type: 'ADMIN_TRANSACTION_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_TRANSACTION_LIST_ERROR', error: error.message || "" });
    }
}


function* updateFundDetails({ formData, id }) {
    try {

        const response = yield call(putRequestAPI, { url: `fundraisers/${id}/short-update`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'ADMIN_UPDATE_FUND_SUCCESS', data: response.data.data || "" });
        } else {
            message.error({ content: response.message || "Something went wrong", className: 'error-class', duration: 3 });
            yield put({ type: 'ADMIN_UPDATE_FUND_ERROR', error: response });
        }
    } catch (error) {
        message.error(error?.message || "Something went wrong");
        yield put({ type: 'ADMIN_UPDATE_FUND_ERROR', error: error?.message || "Something went wrong" });
    }
}


function* watchAdminRequests() {
    yield takeLatest('ADMIN_ORGANIZATION_LIST_REQUEST', getAdminOrganizationList);
    yield takeLatest('ADMIN_FUNDRAISING_TYPE_DETAIL_REQUEST', getFundraisingTypeDetail);
    yield takeLatest('UPDATE_FUNDRAISING_TYPE_REQUEST', createUpdateFundraisingType);
    yield takeLatest('ADMIN_FUNDRAISING_PACKAGE_DETAIL_REQUEST', getFundraisingPackageDetail);
    yield takeLatest('UPDATE_FUNDRAISING_PACKAGE_REQUEST', createUpdateFundraisingPackage);

    yield takeLatest('ADMIN_PRIZE_LIST_REQUEST', getAdminPrizeList);
    yield takeLatest('ADMIN_PRIZE_DETAIL_REQUEST', getPrizeDetail);
    yield takeLatest('UPDATE_PRIZE_REQUEST', createUpdatePrize);

    yield takeLatest('TOGGLE_COUPON_STATUS_REQUEST', toggleCouponStatusRequest)
    yield takeLatest('TOGGLE_REQUEST', toggleRequest);
    yield takeLatest('UPDATE_STATUS_REQUEST', updateStatus);
    yield takeLatest('EXPORT_REQUEST', exportRequest);

    yield takeLatest('UPDATE_COMMON_REQUEST', createUpdateCommon);
    yield takeLatest('UPDATE_COMMON_COUPON', createUpdateCoupon);
    yield takeLatest('CHANGE_PASSWORD_REQUEST', changePasswordRequest);
    yield takeLatest('ADMIN_USERS_LIST_REQUEST', getUserList);
    yield takeLatest('ADMIN_USERS_DETAIL_REQUEST', getUserDetail);

    yield takeLatest('ADMIN_FUNDRAISING_USERS_LIST_REQUEST', getFundraisingUserList);
    yield takeLatest('CONFIRM_E_TRANSFER_REQUEST', confirmAdminETransferRequest);
    yield takeLatest('REJECT_E_TRANSFER_REQUEST', rejectAdminETransferRequest);
    yield takeLatest('ADMIN_TICKET_PRINT_LIST_REQUEST', gegAdminPrintTicketList);
    yield takeLatest('ADMIN_TICKET_STOLEN_LIST_REQUEST', getAdminStolenTicketList);
    yield takeLatest('ADMIN_TRANSACTION_LIST_REQUEST', getAdminTransactions);
    yield takeLatest('ADMIN_UPDATE_FUND_REQUEST', updateFundDetails);
    yield takeLatest('GET_ALL_E_TRANSFER_REQUEST', getAdminETransferRequest)

}

function* Admin() {
    yield watchAdminRequests();
}

export default Admin;