/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Row, Col, Input, Table, Space, Tooltip, Switch } from "antd";
import { PlusOutlined, EditFilled, DeleteFilled, EyeOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import FundraisingTypeModal from "../../common/modal/fundraisingTypeModal";
import SwitchToggle from "../../common/SwitchToggle";
import { formatDate, isAuthorized } from '../../../utils/functions';

const { Search } = Input;
const { Column } = Table;

let filterParams = { search: "" };
const FundraisingTypes = (props) => {

    const dispatch = useDispatch();
    const { isToggleLoading, toggleStatus, isFundraisingUpdateLoading, updateMsg } = useSelector(state => state.admin);
    const { isFTypeLoading, fundraiserTypeList, } = useSelector(state => state.fundraiser);
    const { isDeleteLoading, deleteMsg } = useSelector(state => state.common);

    const [tableData, setTableData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [rowData, setRowData] = useState("");
    const [modalType, setModalType] = useState("");

    useEffect(() => {
        getAllFundraisingTypes({});
    }, []);

    useEffect(() => {
        if (!isFTypeLoading && fundraiserTypeList?.length) {
            let tmpfundraiserTypeList = [];
            fundraiserTypeList.map((item) => {
                let tmpObj = item;
                tmpObj.category = item.data.length > 0 ? item.data.join(' | ') : "";
                tmpfundraiserTypeList.push(tmpObj);
                return 1;
            })
            setTableData(tmpfundraiserTypeList);
        }
    }, [isFTypeLoading, fundraiserTypeList]);

    const getAllFundraisingTypes = (params = {}, isSearch = false) => {
        let tmpParams = { ...params };
        tmpParams.per_page = 5000;
        dispatch({ type: 'FUNDRAISER_TYPE_LIST_REQUEST', formData: tmpParams });
    }

    const openModal = (record, type) => {
        setVisible(true);
        if (type === "view") {
            setRowData("");
            dispatch({ type: "ADMIN_FUNDRAISING_TYPE_DETAIL_REQUEST", orgId: record.id });
        } else {
            setRowData(record);
        }
        setModalType(type);
    }

    useEffect(() => {
        if (!isFundraisingUpdateLoading && updateMsg === "success") {
            dispatch({ type: "ADMIN_DATA_CLEAR" });
            getAllFundraisingTypes({});
            setVisible(false);
            setModalType("");
        }
    }, [isFundraisingUpdateLoading, updateMsg])

    useEffect(() => {
        if (!isDeleteLoading && deleteMsg === "success") {
            dispatch({ type: "CLEAR_COMMON" });
            getAllFundraisingTypes({});
            setVisible(false);
            setModalType("");
        }
    }, [isDeleteLoading, deleteMsg]);

    const updateStatus = (fundraisingId) => {
        dispatch({ type: "TOGGLE_REQUEST", id: fundraisingId, toggleFor: 'fundraisingType' })
    }

    useEffect(() => {
        if (!isToggleLoading && toggleStatus === "success") {
            dispatch({ type: "TOGGLE_CLEAR" })
        }
    }, [isToggleLoading, toggleStatus]);

    const onChange = (e, type) => {
        if (type === "search") {
            filterParams[type] = e;
        }
        getAllFundraisingTypes(filterParams)
    }

    return (
        <div>
            <div className="sub-header">
                <p className="mb0 title">
                    Fundraising Type
                </p>
            </div>
            <div className="filter mt8">
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Search
                            placeholder="Search"
                            style={{ width: "60%" }}
                            enterButton
                            onSearch={(e) => onChange(e, 'search')}
                        />
                    </Col>
                    {isAuthorized('create_fundraiser_types') &&
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fr text-right">
                            <Button
                                type="primary"
                                size="large"
                                className="br1"
                                htmlType="submit"
                                onClick={() => openModal("", "add")}
                                icon={<PlusOutlined />}
                            >
                                New Type
                            </Button>
                        </Col>
                    }
                </Row>
            </div>
            <Row className="mt5">
                <Table
                    style={{ width: "100%" }}
                    dataSource={tableData}
                    loading={isFTypeLoading}
                    pagination={true}
                    scroll={{ x: "max-content" }}
                >

                    <Column
                        title="Name"
                        dataIndex="name"
                        key="name"
                        width={"350px"}
                    />
                    <Column
                        title="Category"
                        dataIndex="category"
                        key="category"
                        width={"250px"}
                    />
                    <Column
                        title="Auto approve"
                        dataIndex="auto_approve"
                        key="auto_approve"
                        width={"150px"}
                    />
                    <Column
                        title="Created on"
                        dataIndex="created_at"
                        key="created_at"
                        width={"120px"}
                        render={(text, record) => formatDate(text)}
                    />

                    <Column
                        title="Status"
                        dataIndex="status"
                        key="status"
                        width={"110px"}
                        render={(_, record) => {
                            let status = record.status === "active" ? true : false;
                            return (
                                <SwitchToggle
                                    record={record}
                                    status={status}
                                    onChange={(id) => updateStatus(id)}
                                    loading={isToggleLoading}
                                    isDisabled={!isAuthorized('update_fundraiser_types')}
                                />
                            )
                        }}
                    />
                    <Column
                        title={"Actions"}
                        key='actions'
                        width={"70px"}
                        render={(_, record) => {
                            return (
                                <Space size="middle" className={`cursor ${record.isDisabled ? 'disabled-edit' : 'not-disabled'}`}>
                                    {isAuthorized('view_fundraiser_types') &&
                                        <Tooltip placement="top" title={"View Detail"}>
                                            <EyeOutlined
                                                onClick={() => openModal(record, 'view')}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }
                                    {isAuthorized('update_fundraiser_types') &&
                                        <Tooltip placement="top" title={"Edit"} >
                                            <EditFilled
                                                onClick={() => openModal(record, 'edit')}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }
                                    {isAuthorized('delete_fundraiser_types') &&
                                        <Tooltip placement="top" color={"red"} title={"Delete"}>
                                            <DeleteFilled
                                                onClick={() => openModal(record, 'delete')}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }
                                </Space>
                            )
                        }}
                    />
                </Table>
            </Row>

            <FundraisingTypeModal
                visible={visible}
                rowData={rowData}
                modalType={modalType}
                setVisible={() => {
                    setRowData("");
                    setVisible(false);
                }}
            />
        </div>
    );
};
export default FundraisingTypes;