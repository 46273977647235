import React from "react";
import { Modal, Button, Card, Tag } from 'antd';
import { IoCheckmarkCircleSharp, IoCloseCircleSharp } from 'react-icons/io5';
import { formatDate, getFundraiserStatus } from "../../../utils/functions";

const WarningModal = ({ visible, onCancel, data = [] }) => {
    return (
        <Modal
            className="success-modal-parent remaing-payment"
            footer={false}
            visible={visible}
            onCancel={() => onCancel(false)}
            width={1000}
            maskClosable={false}
        >
            <div className="success-modal-child">
                <>
                    <IoCloseCircleSharp style={{ fill: "red" }} size={30} />
                    <p className="fs18 bold mt5 mb8 pl230 pr230">Please complete your payment for below old campaigns before creating new</p>
                    {data.map((item) => {
                        const isETransferPending = (item?.e_transfers?.length && item?.e_transfers?.some((val) => val.status === 'pending'))
                        return (<Card className="mt4">
                            <div className="card">
                                <div>
                                    <p className="mb2 title">{item.title === null ? <span>No Campaign Title <span className="redfv">(Draft)</span></span> : item.title}</p>
                                    {/* <p className="mb0 sub-title">{item?.organization?.name}</p> */}
                                </div>
                                <div className="d-flex">
                                    <div>
                                        <p className="mb2 status-tag">
                                            <Tag
                                                color={`${(item.status === 'fundraiser_closed' || (item.paid_status === "pending" && !isETransferPending)) ? "red" : "green"}`}
                                                className={`${(item.status === "fundraiser_closed" || (item.paid_status === "pending" && !isETransferPending)) ? "status-error" : "status-success"}`}>
                                                {getFundraiserStatus(isETransferPending ? 'pending_payment_approval' : item.status, item.paid_status)}
                                            </Tag>
                                        </p>
                                        <p className="mb0 date-style">{
                                            item.start_date !== null ? `${formatDate(item.start_date)} • ${formatDate(item.end_date)}` : ''}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Card>)
                    })}

                    <Button

                        type="primary"
                        size="large"
                        className="br20 pr8 pl8 mt10"
                        htmlType="submit"
                        onClick={() => onCancel(false)}
                    >
                        Close
                    </Button>
                </>

            </div>
        </Modal>
    );
};
export default WarningModal;
