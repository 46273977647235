/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Row, Col, Input, Table, Space, Tooltip, Switch } from "antd";
import { PlusOutlined, EditFilled, DeleteFilled, EyeOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import FundraisingPackageModal from "../../common/modal/fundraisingPackageModal";
import SwitchToggle from "../../common/SwitchToggle";
import { formatAmount, formatDate, isAuthorized } from '../../../utils/functions';

const { Search } = Input;
const { Column } = Table;

let filterParams = { search: "" };
const FundraisingPackage = (props) => {

    const dispatch = useDispatch();
    const { isToggleLoading, toggleStatus, isFundraisingUpdateLoading, updateMsg } = useSelector(state => state.admin);
    const { isFPackageLoading, fundraiserPackageList, } = useSelector(state => state.fundraiser);
    const { isDeleteLoading, deleteMsg } = useSelector(state => state.common);

    const [tableData, setTableData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [rowData, setRowData] = useState("");
    const [modalType, setModalType] = useState("");

    useEffect(() => {
        getAllFundraisingPackage({});
    }, []);

    useEffect(() => {
        if (!isFPackageLoading && fundraiserPackageList?.length) {
            let tmpfundraiserPackageList = [];
            fundraiserPackageList.map((item) => {
                let tmpObj = item;
                tmpfundraiserPackageList.push(tmpObj);
                return 1;
            })
            setTableData(tmpfundraiserPackageList);
        }
    }, [isFPackageLoading, fundraiserPackageList]);

    const getAllFundraisingPackage = (params = {}, isSearch = false) => {
        let tmpParams = { ...params };
        tmpParams.per_page = 5000;
        dispatch({ type: 'FUNDRAISER_PACKAGE_LIST_REQUEST', formData: tmpParams });
    }

    const openModal = (record, type) => {
        setVisible(true);
        if (type === "view") {
            setRowData("");
            dispatch({ type: "ADMIN_FUNDRAISING_PACKAGE_DETAIL_REQUEST", id: record.id });
        } else {
            setRowData(record);
        }
        setModalType(type);
    }

    useEffect(() => {
        if (!isFundraisingUpdateLoading && updateMsg === "success") {
            dispatch({ type: "ADMIN_DATA_CLEAR" });
            getAllFundraisingPackage({});
            setVisible(false);
            setModalType("");
        }
    }, [isFundraisingUpdateLoading, updateMsg])

    useEffect(() => {
        if (!isDeleteLoading && deleteMsg === "success") {
            dispatch({ type: "CLEAR_COMMON" });
            getAllFundraisingPackage({});
            setVisible(false);
            setModalType("");
        }
    }, [isDeleteLoading, deleteMsg]);

    const updateStatus = (fundraisingId) => {
        dispatch({ type: "TOGGLE_REQUEST", id: fundraisingId, toggleFor: 'fundraisingPackage' })
    }

    useEffect(() => {
        if (!isToggleLoading && toggleStatus === "success") {
            dispatch({ type: "TOGGLE_CLEAR" })
        }
    }, [isToggleLoading, toggleStatus]);

    const onChange = (e, type) => {
        if (type === "search") {
            filterParams[type] = e;
        }
        getAllFundraisingPackage(filterParams)
    }

    return (
        <div>
            <div className="sub-header">
                <p className="mb0 title">
                    Fundraising Package
                </p>
            </div>
            <div className="filter mt8">
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Search
                            // allowClear
                            placeholder="Search"
                            style={{ width: "60%" }}
                            enterButton
                            onSearch={(e) => onChange(e, 'search')}
                        />
                    </Col>
                    {isAuthorized('create_fundraiser_packages') &&
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fr text-right">
                            <Button
                                type="primary"
                                size="large"
                                className="br1"
                                htmlType="submit"
                                onClick={() => openModal("", "add")}
                                icon={<PlusOutlined />}
                            >
                                New Package
                            </Button>
                        </Col>
                    }
                </Row>
            </div>
            <Row className="mt5">
                <Table
                    style={{ width: "100%" }}
                    dataSource={tableData}
                    loading={isFPackageLoading}
                    pagination={true}
                    scroll={{ x: "max-content" }}
                >

                    <Column
                        title="Name"
                        dataIndex="name"
                        key="name"
                        width={"350px"}
                    />
                    <Column
                        title="# of tickets"
                        dataIndex="no_tickets_allowed"
                        key="no_tickets_allowed"
                        width={"110px"}
                    />
                    <Column
                        title="# of sellers"
                        dataIndex="no_sellers_allowed"
                        key="no_tickets_allowed"
                        width={"110px"}
                    />
                    <Column
                        title="Amount"
                        dataIndex="regular_fees"
                        key="regular_fees"
                        width={"110px"}
                        render={(text, record) => {
                            return (<>
                                {text !== null ? `${formatAmount(parseFloat(text))}` : ""}
                            </>)
                        }}
                    />
                    <Column
                        title="Created on"
                        dataIndex="created_at"
                        key="created_at"
                        width={"120px"}
                        render={(text, record) => formatDate(text)}
                    />

                    <Column
                        title="Status"
                        dataIndex="status"
                        key="status"
                        width={"110px"}
                        render={(_, record) => {
                            let status = record.status === "active" ? true : false;
                            return (
                                <SwitchToggle
                                    record={record}
                                    status={status}
                                    onChange={(id) => updateStatus(id)}
                                    loading={isToggleLoading}
                                    isDisabled={!isAuthorized('update_fundraiser_packages')}
                                />
                            )
                        }}
                    />
                    <Column
                        title={"Actions"}
                        key='actions'
                        width={"70px"}
                        render={(_, record) => {
                            return (
                                <Space size="middle" className={`cursor ${record.isDisabled ? 'disabled-edit' : 'not-disabled'}`}>
                                    {isAuthorized('view_fundraiser_packages') &&
                                        <Tooltip placement="top" title={"View Detail"}>
                                            <EyeOutlined
                                                onClick={() => openModal(record, 'view')}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }
                                    {isAuthorized('update_fundraiser_packages') &&
                                        <Tooltip placement="top" title={"Edit"} >
                                            <EditFilled
                                                onClick={() => openModal(record, 'edit')}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }
                                    {isAuthorized('delete_fundraiser_packages') &&
                                        <Tooltip placement="top" color={"red"} title={"Delete"}>
                                            <DeleteFilled
                                                onClick={() => openModal(record, 'delete')}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }
                                </Space>
                            )
                        }}
                    />
                </Table>
            </Row>

            <FundraisingPackageModal
                visible={visible}
                rowData={rowData}
                modalType={modalType}
                setVisible={() => {
                    setRowData("");
                    setVisible(false);
                }}
            />
        </div>
    );
};
export default FundraisingPackage;