import React from "react";
import { Button, Select } from "antd";
const { Option } = Select;
const SelectOptionsSeller = ({ defaultValue = "", options, type = "", onChange, filterName = "", borderLess = true, placeholder = "", status = "", width }) => {
    return (
        <>
            <Select
                value={defaultValue}
                showArrow={true}
                style={{ width: width }}
                status={status}
                onChange={(e) => onChange(e, filterName)}
                dropdownStyle={{ minWidth: "12%" }}
                bordered={false}
            >
                <Option value="">Select Seller</Option>
                {options.map((item) => {
                    return (
                        <Option key={item.id} value={item.id}>{item.name}</Option>
                    )
                })}

            </Select>

        </>
    );
};

export default SelectOptionsSeller;
