/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Select, message, Checkbox } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { COUNTRIES } from '../../utils/countries';
import "./style.scss";
import OtpModal from "../common/modal/otpModal";
import PrivacyPolicy from "../common/modal/privacyAndConditionsModal";

const { Option } = Select;

const Signup = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const { isLoginLoading, loginMsg, isSignupLoading, signupStatus, signupData, isOtpVerifyLoading, otpVerifyStatus } = useSelector(state => state.auth);
    const { provinceList, isProvinceLoading, isReferencesLoading, referencesList } = useSelector(state => state.common);
    const [visible, setVisible] = useState(false);

    const [privacyModal, setPrivacyModal] = useState(false);
    const [type, setType] = useState(false);

    const onFinish = (values) => {
        console.log('Success:', );
        let code = values.country_code.split('|');
        values.fundraising_admin_phone = `+${code[0]}${values.fundraising_admin_phone}`;
        values.get_updates_and_offers = form.getFieldValue().get_updates_and_offers ? '1' : '0'
        dispatch({ type: 'SIGNUP_REQUEST', formData: values });
    };

    useEffect(() => {
        if (provinceList.length === 0) {
            dispatch({ type: 'PROVINCE_LIST_REQUEST', formData: { per_page: 5000 } });
        }
        if (referencesList.length === 0) {
            dispatch({ type: 'REFERENCES_LIST_REQUEST', formData: { per_page: 5000 } });
        }
    }, []);
    useEffect(() => {
        if (!isSignupLoading && signupStatus === "error" && Object.keys(signupData).length > 0) {
            for (const property in signupData) {
                message.error({ content: signupData[property][0] || "Something went wrong", className: 'error-class', duration: 4 });
            }
            dispatch({ type: "CLEAR_LOGIN_DATA" });
        } else if (!isSignupLoading && signupStatus === "success" && Object.keys(signupData).length > 0) {
            setVisible(true);
            dispatch({ type: "OTP_REQUEST", formData: { phone: signupData?.fundraising_admin_phone } });
        }
    }, [isSignupLoading, signupStatus, signupData])

    const onFinishFailed = (errorInfo) => { console.log('Failed:', errorInfo); };

    useEffect(() => {
        if (!isLoginLoading && loginMsg === "success") {
            dispatch({ type: 'CLEAR_LOGIN_DATA' });
            window.location.href = "/"
        }
    }, [dispatch, isLoginLoading, loginMsg]);

    useEffect(() => {
        if (!isOtpVerifyLoading && otpVerifyStatus === "success") {
            dispatch({ type: 'CLEAR_LOGIN_DATA' });
            setVisible(false);
            message.success("We have sent OTP email to your email, Please setup password")
            navigate('/set-password?email=' + signupData?.email)
            // navigate('/login');
        } else if (!isOtpVerifyLoading && otpVerifyStatus === "invalid") {
            dispatch({ type: 'CLEAR_OTP_MESSAGE' });
            message.error({ content: "OTP is invalid or expired, please enter code again or resend send OTP" || "Something went wrong", className: 'error-class', duration: 5 });
        }
    }, [dispatch, isOtpVerifyLoading, otpVerifyStatus]);

    return (
        <div className="signup">
            <div className="right-side">
                <div className="main-text">
                    <div>
                        {/* <img src={MainLogo} onClick={() => navigate('/')} className="pointer" alt="Strealine Fundraiser Logo "></img> */}
                        <p className="fs38"> Start your fundraiser today.</p>
                        <ul className="ul-li-section">
                            <li>Manage the distribution and sales of tickets</li>
                            <li>Ticket Tracking</li>
                            <li>Provide added level of security to prevent fraudulent ticket sales</li>
                            <li>Realtime campaign updates</li>
                        </ul>
                    </div>
                </div>
                <div>

                </div>
            </div>
            <div className="left-side">
                <div className="login-form">
                    <div className="parent">
                        <div className="mt10">
                            <Form
                                name="basic"
                                initialValues={{ country_code: "1|CA" }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Row gutter={[15]}>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            name="first_name"
                                            rules={[
                                                {
                                                    pattern: new RegExp(/[A-za-z _]$/),
                                                    message: "Please enter only alphabets characters",
                                                },
                                                { required: true, message: 'Please enter first name' },
                                                { max: 100, message: 'Maximum 100 characters only allowed!' }]
                                            }
                                        >
                                            <Input size="large" type="text" placeholder={"First name"} />
                                        </Form.Item>

                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            name="last_name"
                                            rules={[
                                                {
                                                    pattern: new RegExp(/[A-za-z _]$/),
                                                    message: "Please enter only alphabets characters",
                                                },
                                                { required: true, message: 'Please enter last name' },
                                                { max: 100, message: 'Maximum 100 characters only allowed!' }
                                            ]}
                                        >
                                            <Input size="large" type="text" placeholder={"Last name"} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your email address'
                                        },
                                        {
                                            type: 'email',
                                            message: 'Please enter valid email address',
                                        }
                                    ]}
                                >
                                    <Input size="large" placeholder={"Email Address"} autoComplete="new-state" />
                                </Form.Item>

                                <Row gutter={[15]}>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                        <Form.Item
                                            name="country_code"
                                        >
                                            <Select
                                                size="large"
                                                showSearch
                                            >
                                                {COUNTRIES.map((item, index) =>
                                                    <Option value={`${item.code}|${item.iso}`} key={index + item.code}>{`+ (${item.code}) ${item.country}`}</Option>
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                                        <Form.Item
                                            extra="We will send a security PIN code to this number"
                                            name="fundraising_admin_phone"
                                            rules={[
                                                { required: true, message: "Please enter phone" },
                                                {
                                                    pattern: new RegExp(/^[0-9]{8,12}$/),
                                                    message: "Please enter valid phone",
                                                },

                                            ]}
                                        >
                                            <Input size="large" placeholder={"9876543210"} autoComplete="new-state" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item
                                    name="business_name"
                                >
                                    <Input size="large" placeholder={"Business name (optional)"} />
                                </Form.Item>
                                <Row gutter={[15]}>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            extra="Only avialable in Canada, excluding Quebec"
                                            name="province_id"
                                            rules={[{ required: true, message: 'Please select province' }]}
                                        >
                                            <Select
                                                loading={isProvinceLoading}
                                                placeholder="Select Province"
                                                size="large"
                                                showSearch
                                                autoComplete={"off"}
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {/* <Option key={'index'} value={668}>Ontario</Option> */}
                                                {!isProvinceLoading && provinceList.map((item, index) => (
                                                    <Option key={index} value={item.id}>{item.name}</Option>))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Form.Item name="reference_id" >
                                            <Select
                                                placeholder="How did you hear about us?"
                                                size="large"
                                                showSearch
                                                loading={isReferencesLoading}
                                            >
                                                {!isReferencesLoading && referencesList.map((item, index) => (
                                                    <Option key={index} value={item.id}>{item.name}</Option>))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item
                                    name="get_updates_and_offers"
                                    valuePropName="get_updates_and_offers"
                                    initialValue={true}
                                >
                                    <Checkbox onChange={e => {
                                        form.setFieldsValue({
                                            get_updates_and_offers: e.target.checked
                                        })
                                    }} defaultChecked className='promotion-policy'>
                                        I consent to receiving emails containing promotional material, offers and updates.
                                    </Checkbox>
                                </Form.Item>

                                <Form.Item
                                    name="copy"
                                    valuePropName="checked"
                                    initialValue={true}
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error('Please agreed to the terms and conditions and privacy policy')),
                                        },
                                    ]}
                                >
                                    <Checkbox defaultChecked={true} className='privacy-policy'>
                                        I agreed to the <span className="rule-text-signup" onClick={() => { setType('rule'); setPrivacyModal(true) }} >terms and conditions</span> and <span className="rule-text-signup" onClick={() => { setType('privacy'); setPrivacyModal(true) }}>privacy policy </span>
                                    </Checkbox>
                                </Form.Item>

                                <Form.Item className="mt10">
                                    <div className="login-btn-section">
                                        <Button
                                            style={{ width: "100%", height: "45px" }}
                                            type="primary"
                                            size="large"
                                            htmlType="submit"
                                            className="login-btn br20"
                                            loading={isSignupLoading}
                                            disabled={isSignupLoading}
                                        >
                                            Sign Up
                                        </Button>
                                    </div>
                                </Form.Item>


                            </Form>
                        </div>
                        <div className="draw-line mt8"></div>
                        <div className="mt8 no-accoint">
                            <p className="mb0">Already have an account? <Link to="/login">Login Now</Link></p>
                        </div>

                    </div>
                </div>
            </div>
            {visible &&
                <OtpModal
                    visible={visible}
                    setVisible={() => {
                        setVisible(false);
                    }}
                />
            }

            {privacyModal &&
                <PrivacyPolicy
                    type={type}
                    showModal={privacyModal}
                    onCancel={() => setPrivacyModal(false)}
                />
            }
        </div>
    );
};

export default Signup;
