/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col, Progress, Spin, Button, Modal, message, Skeleton, Tag, Tooltip } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import TabLayout from './tabLayout';
import { FUNDRAISER_ADMIN_TRAKING } from '../../../utils/constant';
import { displayStartEndDate, getFundraiserStatus, getUserData, isApproved, percentageCalculate, startInDays, startInDaysAndEnd, checkIsEanbleToStart, checkIsEanbleToClose, isFullyPaid } from '../../../utils/functions';
import { useSearchParams } from "react-router-dom";
import { CheckOutlined } from '@ant-design/icons';
import SuccessModal from './modals/successModal.js';
import ContactSupportModal from './modals/contactSupportModal';
import TicketTable from './ticket/table';
import ApproveModal from "../../common/modal/approveModal";
import { PaymentModal } from "../../common/modal/paymentModal";
import StartFundraiserEarly from "../../common/modal/startFundraiserEarly";


const FundraiserDetails = (props) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { isGetFundraiserLoading, fundraiserData } = useSelector(state => state.fundraiser);
    const { isSupportLoading, supportMsg, isProgressLoading, fundProgress } = useSelector(state => state.common);
    const { isTicketStateLoading, ticketStats } = useSelector(state => state.ticket);
    const { isUpdatePaymentLoading, updatePaymentMsg, isCourseUpdating, courseUpdateMsg, isClosing, statusMsg, } = useSelector(state => state.common);
    const { isFundStatusUplading, updateMsg } = useSelector(state => state.admin);

    const [isSuccessModal, setIsSuccessModal] = useState(false);
    const [isContactVisible, setIsContactVisible] = useState(false);
    const [currentActiveTab, setCurrentActiveTab] = useState("1");
    const [openModal, setOpenModal] = useState(false);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [forbidden, setForbidden] = useState(false);
    const [isStartVisible, setIsStartVisible] = useState(false);
    const [isCloseVisible, setIsCloseVisible] = useState(false);
    const [tmpState, settmpState] = useState("");


    useEffect(() => {
        callFunction();
    }, []);


    const closeFundraiser = () => {
        if (id) {
            dispatch({ type: 'CLOSE_FUNDRAISER_REQUEST', id: id });
        }
    }

    useEffect(() => {
        if (!isTicketStateLoading && Object.keys(ticketStats).length > 0) {
            settmpState(ticketStats);
        }
    }, [isTicketStateLoading, ticketStats]);

    useEffect(() => {
        getTicketState();
    }, []);

    const getTicketState = () => {
        dispatch({ type: 'TICKET_STATE_REQUEST', id: id });
    }

    const callFunction = () => {
        if (id) {
            dispatch({ type: 'GET_FUNDRAISER_DATA_REQUEST', formData: { id: id } });
        }
        dispatch({ type: 'CLEAR_COMMON' });
        getFundraisingProgress();
        getTicketState();
    }
    useEffect(() => {
        if (!isCourseUpdating && courseUpdateMsg === "success") {
            callFunction();
        }
    }, [isCourseUpdating, courseUpdateMsg])

    useEffect(() => {
        if (searchParams.get('status') && searchParams.get('status') === "success") {
            setIsSuccessModal(true);
        }
    }, [searchParams]);

    useEffect(() => {
        if (!isGetFundraiserLoading && Object.keys(fundraiserData).length > 0) {
            if (searchParams.get('payment') === "true" && getUserData().id === fundraiserData.user_id && fundraiserData.paid_status !== "fully_paid") {
                localStorage.removeItem('redirectLink');
                setOpenPaymentModal(true);
            }
        }
    }, [searchParams, isGetFundraiserLoading, fundraiserData]);



    useEffect(() => {
        if (!isFundStatusUplading && updateMsg === "success") {
            setIsStartVisible(false)
            dispatch({ type: 'ADMIN_DATA_CLEAR' });
            callFunction();
        }

    }, [isFundStatusUplading, updateMsg]);

    useEffect(() => {

    }, [isUpdatePaymentLoading, updatePaymentMsg])

    const onSuccess = () => {
        setOpenPaymentModal(false)
        navigate(`/fundraiser-details/${id}?tab=2`);
        window.location.reload();
    }

    useEffect(() => {
        if (!isGetFundraiserLoading && Object.keys(fundraiserData).length > 0) {
            if (isApproved(fundraiserData.status)) {
                setOpenModal(true);
            } else if (getUserData().id !== fundraiserData.user_id) {
                setForbidden(true);
                setOpenModal(true);
            }
        }

    }, [isGetFundraiserLoading, fundraiserData]);

    useEffect(() => {
        if (!isSupportLoading && supportMsg === "success") {
            setIsContactVisible(false);
            message.success("Support request sent successfully!");
            dispatch({ type: 'CLEAR_COMMON' });
        }
    }, [isSupportLoading, supportMsg]);

    const getFundraisingProgress = (params = {}) => {
        dispatch({ type: 'FUNDRAISING_PROGRESS_REQUEST', formData: params, id: id });
    }

    const loadProgress = (progress) => {

        let tmpHtml = [];

        FUNDRAISER_ADMIN_TRAKING.map((item) => {
            let isDone = false;
            if (progress.hasOwnProperty(item.key)) {
                isDone = progress[item.key];
            }
            tmpHtml.push(
                <div key={item.id} className={`child ${isDone ? 'done' : ''}`} >
                    <div className={`icon`}>
                        {isDone ? <CheckOutlined /> : <CheckOutlined className="blank" />}
                    </div>
                    <div className="title">{item.title}</div>
                </div>
            );
            return 1;
        });
        return tmpHtml;
    }



    useEffect(() => {
        if (!isClosing && statusMsg === "success") {
            dispatch({ type: "CLEAR_COMMON" });
            setIsCloseVisible(false);
            callFunction()
        }
    }, [isClosing, statusMsg]);


    return (
        <div className="new-fundraising-parent fundraising-details mt0">
            {isGetFundraiserLoading && <div className="no-data"><Spin /></div>}
            {!isGetFundraiserLoading && Object.keys(fundraiserData).length > 0 &&
                <Row className="main-container minH">
                    <Col xs={24} sm={24} md={18} lg={18} xl={18} className="left">
                        <div className="top-section">
                            <div className="d-flex">
                                <p className="mb1 id-text">ID: {fundraiserData?.id}</p>
                            </div>
                            <div className="d-flex">
                                <p className="mb1 title-text">{fundraiserData?.title}</p>
                                <p className="mb2 status-tag">
                                    <Tag
                                        color={`${fundraiserData.status === 'fundraiser_closed' ? "red" : "green"}`}
                                        className={`${fundraiserData.status === "fundraiser_closed" ? "status-error" : "status-success"}`}>
                                        {getFundraiserStatus(fundraiserData.status)}
                                    </Tag>
                                </p>
                            </div>
                            <p className="mb1 title-date">{displayStartEndDate(fundraiserData)}<span>{` (${startInDaysAndEnd(fundraiserData)})`}</span></p>
                            <p className="mt3 mb0">
                                <Progress
                                    strokeWidth={11}
                                    strokeColor={{
                                        '0%': '#1B35B3',
                                        '100%': '#A32B83',
                                    }}
                                    percent={percentageCalculate(fundraiserData?.tickets_total, fundraiserData?.tickets_played)}

                                />
                            </p>
                            <p className="mb0 ticket-text">
                                {`${fundraiserData?.tickets_played}/${fundraiserData?.tickets_total}`} Tickets played
                            </p>
                        </div>
                        <div>
                            <TabLayout
                                fundId={id}
                                fData={fundraiserData}
                                setCurrentActiveTab={(e) => setCurrentActiveTab(e)}
                                fundProgress={fundProgress}
                                isProgressLoading={isProgressLoading}
                                doReferesh={() => callFunction()}
                            />
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6} className="right">
                        {isProgressLoading &&
                            <Skeleton active paragraph={{ rows: 1, width: '100%' }} title={{ width: '100%' }} />
                        }
                        {!isProgressLoading && Object.keys(fundProgress).length > 0 &&
                            <>
                                <div className="rigt-navigation">
                                    <div className="parent">
                                        {loadProgress(fundProgress)}
                                        <Button
                                            type="primary"
                                            size="large"
                                            className="br20 mt5 w100"
                                            htmlType="submit"
                                            onClick={() => setIsContactVisible(true)}
                                        >
                                            Contact Support
                                        </Button>
                                    </div>
                                </div>
                                {checkIsEanbleToStart(fundProgress, fundraiserData) &&
                                    <div
                                        style={{ paddingRight: "30px", paddingLeft: "30px" }}>
                                        <Tooltip placement="bottom" title={checkIsEanbleToStart(fundProgress, fundraiserData) ? "You can start fundraiser early once all steps are completed" : "Start Fundraiser Early"}>
                                            <Button
                                                type="primary"
                                                size="large"
                                                className="br20 mt8 w100"
                                                htmlType="submit"
                                                style={{ width: "100%" }}
                                                onClick={() => setIsStartVisible(true)}
                                            >
                                                Start Fundraiser Early
                                            </Button>
                                        </Tooltip>
                                    </div>
                                }
                                {checkIsEanbleToClose(fundProgress, fundraiserData, tmpState?.tickets_assigned_count) &&

                                    <div
                                        style={{ paddingRight: "30px", paddingLeft: "30px" }}>
                                        {/* <Tooltip placement="bottom" title={checkIsEanbleToClose(fundraiserData) ? "If you made a partial payment the remaining funds must be paid before you can select this option." : "Close fundraiser early"}> */}
                                        <Button
                                            type="primary"
                                            size="large"
                                            className="br20 mt8 w100"
                                            htmlType="submit"
                                            style={{ width: "100%" }}
                                            onClick={() => setIsCloseVisible(true)}
                                        >
                                            Close fundraiser early
                                        </Button>
                                        {/* </Tooltip> */}
                                    </div>
                                }
                            </>

                        }
                    </Col>
                    {(currentActiveTab === "4" || currentActiveTab === 4) &&
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                            <TicketTable fData={fundraiserData} />
                        </Col>
                    }

                    {isSuccessModal &&
                        <SuccessModal
                            onCancel={(type) => {
                                setIsSuccessModal(type)
                                navigate(`/fundraiser-details/${id}`, { replace: true })
                            }}
                            isSuccessModal={isSuccessModal}
                        />
                    }
                    {isContactVisible &&
                        <ContactSupportModal
                            onCancel={(type) => {
                                setIsContactVisible(false)
                            }}
                            isContactVisible={isContactVisible}
                        />
                    }
                    {isStartVisible &&
                        <StartFundraiserEarly
                            setVisible={(type) => {
                                setIsStartVisible(false)
                            }}
                            visible={isStartVisible}
                            fundId={id}
                            type="fundAdmin"
                        />
                    }
                </Row>
            }
            {/* {openModal &&
                <ApproveModal
                    visible={openModal}
                    forbidden={forbidden}
                    onCancel={(type) => {
                        setForbidden(false);
                        setOpenModal(false);
                        navigate('/');
                    }}
                />
            } */}
            {openPaymentModal &&
                <PaymentModal
                    fData={fundraiserData}
                    showModal={openPaymentModal}
                    onCancel={() => setOpenPaymentModal(false)}
                    onSuccess={() => onSuccess(false)}
                />
            }

            {isCloseVisible &&
                <Modal
                    visible={isCloseVisible}
                    onOk={() => {
                        closeFundraiser()
                    }}
                    onCancel={() => setIsCloseVisible()}
                    okText={'Close It'}
                    okButtonProps={{ disabled: isFullyPaid(fundraiserData.paid_status) || isClosing, loading: isClosing }}
                    cancelText={'Cancel'}

                >
                    <p className="mt5">Are you sure you want to close your fundraiser early? <br></br>
                        If you made a partial payment the remaining funds must be paid before you can select this option.</p>
                </Modal>

            }
        </div>
    );
};
export default FundraiserDetails;
