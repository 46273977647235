/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Table } from "antd";
import { formatDateTime } from '../../../utils/functions'
import { useSelector, useDispatch } from 'react-redux';

const { Column } = Table;

const LoadOrganizations = (props) => {
    const { playerId } = props;
    const dispatch = useDispatch();

    const [tableData, setTableData] = useState([]);

    const { isPlayerGameLoading, playerGamesList } = useSelector(state => state.player);

    useEffect(() => {
        getAllOrganization({}, false);
    }, [playerId]);

    const getAllOrganization = (params = {}) => {
        let tmpParams = { ...params };
        tmpParams.per_page = 5000;
        dispatch({ type: 'PLAYER_GAME_LIST_REQUEST', formData: tmpParams, playerId: playerId });
    }

    useEffect(() => {
        let tmpOrgList = [];
        if (!isPlayerGameLoading && playerGamesList.length > 0) {
            playerGamesList.map((item) => {
                let tmpObj = item;
                tmpObj.last_played_timestamp = formatDateTime(item.last_played_timestamp)
                tmpOrgList.push(tmpObj);
                return 1;
            })
            setTableData(tmpOrgList);
        } else if (!isPlayerGameLoading && playerGamesList.length === 0) {
            setTableData([]);
        }
    }, [isPlayerGameLoading, playerGamesList]);



    return (
        <div>

            <Row className="mt5">
                <Table
                    style={{ width: "100%" }}
                    dataSource={tableData}
                    loading={isPlayerGameLoading}
                    pagination={true}
                    scroll={{ x: "max-content" }}
                >

                    <Column
                        title="Campaign Name"
                        dataIndex="campaign_name"
                        key="campaign_name"
                        width={"150px"}
                    />
                    <Column
                        title="Fundraiser Admin"
                        dataIndex="fundraiser_admin"
                        key="fundraiser_admin"
                        width={"150px"}
                    />
                    <Column
                        title="Fundraiser Organization"
                        dataIndex="fundraiser_organization"
                        key="fundraiser_organization"
                        width={"150px"}
                    />
                    <Column
                        title="Ticket#"
                        dataIndex="ticket_number"
                        key="ticket_number"
                        width={"50px"}
                    />
                    <Column
                        title="Rank"
                        dataIndex="rank_for_given_fundraiser"
                        key="rank_for_given_fundraiser"
                        width={"50px"}
                    />

                    <Column
                        title="Played Timestamp
                        (last attempt)"
                        dataIndex="last_played_timestamp"
                        key="last_played_timestamp"
                        width={"170px"}
                    />

                </Table>
            </Row>


        </div>
    );
};
export default LoadOrganizations;