/* eslint-disable react-hooks/exhaustive-deps */
import { Input } from 'antd';
import { Modal, Form } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const ETransferModal = (props) => {
    const { visible, setVisible, onSuccess } = props;
    // const { fundraiserData } = useSelector(state => state.fundraiser);
    const { isDeleteLoading, deleteMsg } = useSelector(state => state.common);

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [childVisible, setChildVisible] = useState(false);
    const [childAckVisible, setChildAckVisible] = useState(false);
    // const [fundraiserName, setFundraiserName] = useState(fundraiserData?.title)

    const closeAllModals = async () => {
        await setChildAckVisible(false)
        await setChildVisible(false)
        await setVisible(false)
    }

    const handleSubmit = () => {
        setChildVisible(true)
    }

    useEffect(() => {
        if (!isDeleteLoading && deleteMsg === "success") {
            dispatch({ type: "CLEAR_COMMON" });
            setVisible(false);
        }
    }, [isDeleteLoading, deleteMsg])

    return (
        <Modal
            className={`prize-modal step4-modal org-modal`}
            title={`E-Transfer`}
            open={visible}
            onOk={() => handleSubmit()}
            onCancel={() => {
                dispatch({ type: "CLEAR_COMMON" });
                setVisible(false);
                form.resetFields();
            }}
            centered
            width={550}
            okText={`Transfer Sent`}
            okButtonProps={{ disabled: isDeleteLoading, loading: isDeleteLoading }}
            cancelText="Cancel"
        >
            <ETransferConfirmationModal
                visible={childVisible}
                childAckVisible={childAckVisible}
                setChildVisible={setChildVisible}
                setChildAckVisible={setChildAckVisible}
                closeAll={() => closeAllModals()}
                onSuccess={() => onSuccess(true)}
            />
            <div className="mt2">
                Please send your E-Transfer to: sales@streamlinefundraising.com
            </div>
            <div className="mt2">
                Include the name of your fundraising campaign in the comment/message section when making the transfer.
            </div>
            {/* <br />
            <Input
                defaultValue={fundraiserName}
                placeholder='Fundraiser Name'
                onChange={(e) => setFundraiserName(e.target.value)}
            /> */}
        </Modal>
    );
};

function ETransferConfirmationModal({ visible, setChildVisible, childAckVisible, setChildAckVisible, closeAll, onSuccess }) {
    const { isETransferLoading, paymentAmount } = useSelector(state => state.common);
    const { fundraiserData } = useSelector(state => state.fundraiser);
    const dispatch = useDispatch();
    const { id } = useParams();
    const payload = {
        currency_code: 'CAD',
        amount: paymentAmount.total_owing,
        comment: fundraiserData.title,
        amount_held_in_trust: paymentAmount.amount_held_in_trust,
        pst: paymentAmount.pst,
        gst: paymentAmount.gst,
        hst: paymentAmount.hst,
        discount: paymentAmount.discount,
        pst_amount: paymentAmount.pst_amount,
        gst_amount: paymentAmount.gst_amount,
        hst_amount: paymentAmount.hst_amount,
        sub_total_amount_including_taxes: paymentAmount.sub_total_amount_including_taxes,
        total_owing: paymentAmount.total_owing,
        paid_amount_with_taxes: paymentAmount.paid_amount_with_taxes
    }
    return (
        <Modal
            className={`prize-modal step4-modal org-modal`}
            title={`E-Transfer Confirmation`}
            open={visible}
            centered
            okText={`Yes`}
            cancelText='No'
            confirmLoading={isETransferLoading}
            onOk={async () => {
                await dispatch({ type: "E_TRANSFER_CREATE_REQUEST", formData: payload, id: id })
                await setChildAckVisible(true)
            }}
            onCancel={() => {
                setChildVisible(false)
            }}
        >
            <ETransferModalAckModal
                visible={childAckVisible}
                closeAll={closeAll}
                onSuccess={onSuccess}
            />
            <div>
                Please verify that you have successfully submitted your payment
            </div>
            {/* child modal content */}
        </Modal>
    );
}

function ETransferModalAckModal({ visible, closeAll, onSuccess }) {
    return (
        <Modal
            className={`prize-modal step4-modal org-modal`}
            title={`E-Transfer Acknowledgement`}
            open={visible}
            centered
            okText={`Ok`}
            onOk={async () => {
                closeAll()
                await onSuccess()
            }}
            closable={false}
            cancelButtonProps={{ hidden: true }}
        >
            <div>
                Your payment will be processed within 48 hours. Once completed, you will receive a confirmation email and will be able to access to your fundraising admin panel.  Thank you.
            </div>
        </Modal>
    );
}

export default ETransferModal;