import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppAnimateGroup from './animate';
import { Button } from 'antd';
import { ReactComponent as Logo } from '../../../assets/403.svg';

const Error403 = () => {
    const navigate = useNavigate();

    const onGoBackToHome = () => {
        navigate('/');
    };

    return (
        <AppAnimateGroup type='bottom'>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div className='error-container' key='a'>
                    <div className='error-img'>
                        <Logo />
                    </div>
                    <div className='error-content mt10'>
                        <h2>
                            Unauthorized
                        </h2>
                        <div className='error-para'>
                            <p className='mb-0'>
                                You are not authorized for this page!
                            </p>
                        </div>
                        <Button type='primary' className='error-btn' onClick={onGoBackToHome}>
                            Back To Home
                        </Button>
                    </div>
                </div>
            </div>
        </AppAnimateGroup>
    );
};

export default Error403;
