import { put, takeLatest, call, delay } from 'redux-saga/effects'
import { message } from 'antd';
//Import API Service.
import { deleteRequestAPI, getRequestAPI, postRequestAPI, putRequestAPI } from '../../services/API/api';

function* assignTicketToSeller({ formData, id }) {
    try {

        const response = yield call(postRequestAPI, { url: `fundraisers/${id}/ticket/assign-sellers`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'ASSIGN_TICKET_TO_SELLER_SUCCESS', data: "" });
        } else {
            let tmpMst = response?.message || "Please enter valid ticket number";
            tmpMst.replace("The quantity to", "To");
            message.error({ content: tmpMst || "Please enter valid ticket number", className: 'error-class' });
            yield put({ type: 'ASSIGN_TICKET_TO_SELLER_ERROR', error: "No record found" });
        }
        // yield delay(1000)
    } catch (error) {
        let tmpMst = error?.message || "Please enter valid ticket number";
        tmpMst.replace("The quantity to", "To");
        message.error({ content: tmpMst || "Please enter valid ticket number", className: 'error-class' });
        yield put({ type: 'ASSIGN_TICKET_TO_SELLER_ERROR', error: error.message || "" });
    }
}

function* getAssignTicketList({ formData, id }) {
    try {

        const response = yield call(getRequestAPI, { url: `fundraisers/${id}/ticket/sellers`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'ASSIGN_TICKET_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'ASSIGN_TICKET_LIST_ERROR', error: "No record found" });
        }
        // yield delay(1000)
    } catch (error) {
        yield put({ type: 'ASSIGN_TICKET_LIST_ERROR', error: error.message || "" });
    }
}

function* getUnAssignTicketList({ formData = {}, id }) {
    try {

        const response = yield call(getRequestAPI, { url: `fundraisers/${id}/unassigned-tickets`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'UNASSIGN_TICKET_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'UNASSIGN_TICKET_LIST_ERROR', error: "No record found" });
        }
        // yield delay(1000)
    } catch (error) {
        yield put({ type: 'UNASSIGN_TICKET_LIST_ERROR', error: error.message || "" });
    }
}

function* reAssignTicket({ formData, id, ticketId, mode }) {
    try {
        let response = "";
        if (mode === "report") {
            response = yield call(putRequestAPI, { url: `fundraisers/${id}/seller-tickets/${ticketId}/report-lost`, data: formData });
        } else {
            response = yield call(putRequestAPI, { url: `fundraisers/${id}/seller-tickets/${ticketId}/reassign-sellers`, data: formData });
        }

        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'REASSIGN_SELLER_SUCCESS', data: response.data.data || [] });
        } else {
            message.error({ content: response?.messagae || "Please enter valid ticket number", className: 'error-class' });
            yield put({ type: 'REASSIGN_SELLER_ERROR', error: "No record found" });
        }
    } catch (error) {
        message.error({ content: error?.message || "Please enter valid ticket number", className: 'error-class' });
        yield put({ type: 'REASSIGN_SELLER_ERROR', error: error.message || "" });
    }
}

function* updateSellerTicketPayment({ formData, id, ticketId }) {
    try {

        const response = yield call(postRequestAPI, { url: `fundraisers/${id}/seller-tickets/${ticketId}/record-payment`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'REASSIGN_SELLER_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'REASSIGN_SELLER_ERROR', error: "No record found" });
        }
        yield delay(1000)
    } catch (error) {
        yield put({ type: 'REASSIGN_SELLER_ERROR', error: error.message || "" });
    }
}

function* getSellerTicketPayment({ formData, id, ticketId }) {
    try {

        const response = yield call(getRequestAPI, { url: `fundraisers/${id}/seller-tickets/${ticketId}/show-payment-history`, params: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'SELLER_TICKET_PAYMENT_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'SELLER_TICKET_PAYMENT_ERROR', error: "No record found" });
        }
        // yield delay(1000)
    } catch (error) {
        yield put({ type: 'SELLER_TICKET_PAYMENT_ERROR', error: error.message || "" });
    }
}
function* getTicketStatastics({ id }) {
    try {

        const response = yield call(getRequestAPI, { url: `fundraisers/${id}/ticket/ticket-stats`, params: {} });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'TICKET_STATE_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'TICKET_STATE_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'TICKET_STATE_ERROR', error: error.message || "" });
    }
}

function* getTicketPDF({ id }) {
    try {

        const response = yield call(getRequestAPI, { url: `seller-ticket-details/${id}/print`, params: {} });


        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'TICKET_PDF_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'TICKET_PDF_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'TICKET_PDF_ERROR', error: error.message || "" });
    }
}

function* generateTicket({ formData, requestType, id }) {

    try {
        let response = "";
        if (requestType === "post") {
            response = yield call(postRequestAPI, { url: `fundraisers/${id}/generate-tickets`, data: formData });
        }

        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'GENERATE_TICKET_SUCCESS', data: response.data.data || [] });
        } else {
            message.error({ content: response?.messagae || "Something went wrong", className: 'error-class' });
            yield put({ type: 'GENERATE_TICKET_ERROR', error: "No record found" });
        }
    } catch (error) {
        message.error({ content: error?.message || "Please enter valid ticket number", className: 'error-class' });
        yield put({ type: 'GENERATE_TICKET_ERROR', error: error.message || "" });
    }
}

function* deactivagteTicket({ id, actionType = "deactive" }) {
    try {

        let response = "";
        if (actionType === "active") {
            response = yield call(putRequestAPI, { url: `seller-ticket-details/${id}/activate-ticket`, data: {} });
        } else {
            response = yield call(putRequestAPI, { url: `seller-ticket-details/${id}/block-ticket`, data: {} });
        }
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'DEACTIVATE_TICKET_SUCCESS', data: response.data.data || [] });
        } else {
            message.error({ content: response?.messagae || "Please enter valid ticket number", className: 'error-class' });
            yield put({ type: 'DEACTIVATE_TICKET_ERROR', error: "No record found" });
        }
    } catch (error) {
        message.error({ content: error?.message || "Please enter valid ticket number", className: 'error-class' });
        yield put({ type: 'DEACTIVATE_TICKET_ERROR', error: error.message || "" });
    }
}

function* validateTicket({ formData }) {
    try {

        const response = yield call(getRequestAPI, { url: `fundraisers/${formData.id}/validate-uuid/${formData.uuid}`, params: {} });

        if (response.status === 200 || response.status === 201) {
            yield put({
                type: 'VALIDATE_TICKET_SUCCESS',
                isValid: response.data?.data?.is_valid,
                message: response.data?.data?.code,
                responseData: response?.data?.data
            });
        } else {
            console.log("response");
            console.log(response);
            yield put({
                type: 'VALIDATE_TICKET_ERROR',
                isValid: response.is_valid,
                message: response.code,
                responseData: response
            });
        }
    } catch (error) {
        console.log("error--------");
        console.log("error");
        console.log(error.message);

        yield put({ type: 'VALIDATE_TICKET_ERROR', message: error?.data?.code || "" });
    }
}

function* validateMatchTicket({ formData }) {
    try {

        const response = yield call(postRequestAPI, { url: `match-ticket-details`, data: formData });

    } catch (error) {
        console.log("error--------");
        console.log("error");
        console.log(error.message);

        yield put({ type: 'VALIDATE_TICKET_ERROR', message: error?.data?.code || "" });
    }
}

function* getFundraiserData({ id }) {
    try {

        const response = yield call(getRequestAPI, { url: `fundraisers/${id}/public` });
        if (response.status === 200) {
            yield put({ type: 'TICKET_FUNDRAISER_DATA_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'TICKET_FUNDRAISER_DATA_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'TICKET_FUNDRAISER_DATA_ERROR', error: error.message || "" });
    }
}


function* watchTicketRequests() {

    yield takeLatest('ASSIGN_TICKET_TO_SELLER_REQUEST', assignTicketToSeller);
    yield takeLatest('ASSIGN_TICKET_LIST_REQUEST', getAssignTicketList);
    yield takeLatest('UNASSIGN_TICKET_LIST_REQUEST', getUnAssignTicketList);
    yield takeLatest('REASSIGN_SELLER_REQUEST', reAssignTicket);

    yield takeLatest('SELLER_TICKET_PAYMENT_REQUEST', getSellerTicketPayment);
    yield takeLatest('UPDATE_TICKET_PAYMENT_REQUEST', updateSellerTicketPayment);
    yield takeLatest('TICKET_STATE_REQUEST', getTicketStatastics);
    yield takeLatest('TICKET_PDF_REQUEST', getTicketPDF);

    yield takeLatest('GENERATE_TICKET_REQUEST', generateTicket);
    yield takeLatest('DEACTIVATE_TICKET_REQUEST', deactivagteTicket);
    yield takeLatest('VALIDATE_TICKET_REQUEST', validateTicket);
    yield takeLatest('VALIDATE_MATHC_TICKET_REQUEST', validateMatchTicket);

    yield takeLatest('TICKET_FUNDRAISER_DATA_REQUEST', getFundraiserData);

}

function* Ticket() {
    yield watchTicketRequests();
}

export default Ticket;