import React from "react";
import MainLogo from '../../assets/streamline_logo.png';
import "./style.scss";

const RightSide = (props) => {
    return (
        <div className="right-side">
            <div className="main-text">
                <img src={MainLogo} alt="Stremline Fundraising"></img>
            </div>
        </div>
    );
};

export default RightSide;
