/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Button, Row, Col, Input, Space, Tooltip, Table } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import { PlusOutlined, EditFilled, DeleteFilled } from '@ant-design/icons';
import { useState } from "react";
import SellerModal from "../../common/sellerModal";
import DeleteModal from "../../common/deleteModal";

const { Search } = Input;
const { Column } = Table;

const Sellers = (props) => {

    const dispatch = useDispatch();
    const { isSellerLoading, sellerList, isSellerCreateLoading, statusMsg, isDeleteLoading, deleteMsg, isImportLoading } = useSelector(state => state.common);

    const [tableData, setTableData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [visible, setVisible] = useState(false);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [rowData, setRowData] = useState("");
    const [modalType, setModalType] = useState("");

    useEffect(() => {
        getSellerList({}, false);
    }, []);

    const getSellerList = (params = {}, isSearch = false) => {
        let tmpParams = { ...params };
        tmpParams.per_page = 50000;
        dispatch({ type: 'GET_SELLER_LIST_REQUEST', formData: tmpParams });
    }

    useEffect(() => {
        let tmpAssignTicketList = [];
        if (!isSellerLoading && sellerList.length > 0) {
            sellerList.map((item) =>
                tmpAssignTicketList.push(item)
            )
        }
        setTableData(tmpAssignTicketList);
    }, [isSellerLoading, sellerList]);

    const onSearch = (e) => {
        setSearchText(e);
        getSellerList({ 'search': e }, true);
    };

    const openModal = (record, type) => {
        if (type === "delete") {
            setDeleteVisible(true);
        } else {
            setVisible(true);
        }
        setRowData(record);
        setModalType(type);
    }

    useEffect(() => {
        if ((!isSellerCreateLoading && statusMsg === "success") || (!isDeleteLoading && deleteMsg === "success") || (!isImportLoading && statusMsg === "success")) {
            dispatch({ type: "CLEAR_COMMON" });
            setVisible(false);
            setDeleteVisible(false);
            getSellerList({}, false);
            setRowData("");
            setModalType("");

        }
    }, [isSellerCreateLoading, statusMsg, isDeleteLoading, deleteMsg]);

    const onDelete = (e) => {
        dispatch({ type: 'DELETE_REQUEST', id: e, deleteType: 'sellers' });
    }

    return (
        <div className="fundraising-org">
            <div className="sub-header mb7">
                <p className="mb0 title">
                    Sellers
                </p>
            </div>
            <div className="filter  mb8 new-fundraising-parent">
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Search
                            defaultValue={searchText}
                            size="large"
                            allowClear
                            placeholder="Search"
                            style={{ width: "80%" }}
                            enterButton
                            onSearch={(e) => onSearch(e, 'search')}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fr text-right">
                        <Space>
                            <Button
                                type="secondary"
                                size="large"
                                className="br20 pr6 pl6"
                                htmlType="submit"
                                onClick={() => openModal("", "import")}
                            >
                                Import
                            </Button>
                            <Button
                                type="primary"
                                size="large"
                                className="br20"
                                htmlType="submit"
                                onClick={() => openModal("", "add")}
                                icon={<PlusOutlined />}
                            >
                                New Seller
                            </Button>
                        </Space>
                    </Col>
                </Row>
                <Row className="mt10 mb">
                    <Table
                        style={{ width: "100%" }}
                        dataSource={tableData}
                        loading={isSellerLoading}
                        pagination={true}
                        scroll={{ x: "max-content" }}
                    >

                        <Column
                            title="Name"
                            dataIndex="name"
                            key="name"
                        />
                        <Column
                            title="Email"
                            dataIndex="email"
                            key="email"
                        />
                        <Column
                            title="Phone"
                            dataIndex="phone"
                            key="phone"
                        />

                        <Column
                            title={"Action"}
                            key='action'
                            render={(_, record) => {
                                return (
                                    <Space size="middle" className={`cursor ${record.isDisabled ? 'disabled-edit' : 'not-disabled'}`}>
                                        <Tooltip placement="top" title={"Edit"} >
                                            <EditFilled
                                                onClick={() => openModal(record, 'edit')}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                        {/* <Tooltip placement="top" color={"red"} title={"Delete"}>
                                            <DeleteFilled
                                                onClick={() => openModal(record, 'delete')}
                                                disabled={true}
                                                className={`cursor ${true ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip> */}
                                    </Space>
                                )
                            }}
                        />
                    </Table>
                </Row>
            </div>

            <SellerModal
                visible={visible}
                rowData={rowData}
                modalType={modalType}
                setVisible={() => { setVisible(false) }}
            />

            <DeleteModal
                visible={deleteVisible}
                deleteId={rowData.id}
                subTitle={"You want to delete this seller"}
                setVisible={() => setDeleteVisible(false)}
                onDelete={(e) => onDelete(e)}
                isDeleteLoading={isDeleteLoading}
            />

        </div >


    );
};
export default Sellers;
