import React, { memo, useState } from 'react';
import { Form, Input, Popover } from 'antd';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

function Password(props) {

    const [passwordError, setPasswordError] = useState("Please input new password");
    const [isLower, setIsLower] = useState(false);
    const [isUpper, setIsUpper] = useState(false);
    const [isNumber, setIsNumber] = useState(false);
    const [isSpecial, setIsSpecial] = useState(false);
    const [isMinimum, setIsMinumum] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [open, setIsOpen] = useState(false);

    const validatePassword = (rule, value, callback) => {
        var patt = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/);

        setIsLower(/[a-z]/.test(value) ? true : false);
        setIsUpper(/[A-Z]/.test(value) ? true : false);
        setIsNumber(/[0-9]/.test(value) ? true : false);
        setIsSpecial(/[@#$!%*?&]/.test(value) ? true : false);
        setIsMinumum((value !== undefined && value.length >= 8) ? true : false)

        var res = patt.test(value);

        setPasswordError('');
        if (value === "" || value === undefined) {
            callback();
        } else {
            if (!res) {
                setIsEmpty(false);
                setIsOpen(true);
                callback("Enter a password according to the policy.");
            } else {
                setIsOpen(false);
                callback();
            }
        }

    };

    const onPasswordFocus = (e) => {
        if (e.target.value == "") {
            setIsEmpty(true);
        } else {
            setIsEmpty(false);
        }
    }


    const onPasswordChange = (e) => {
        if (e.target.value === "") {
            setPasswordError('');
        }
    }

    const content = (
        <div className="password-popup-main">
            <p className={`${isEmpty ? 'gray' : isMinimum ? 'green' : 'red'}`}>{isMinimum ? <CheckCircleOutlined /> : <CloseCircleOutlined />}&nbsp;At least 8 characters</p>
            <p className={`${isEmpty ? 'gray' : isLower ? 'green' : 'red'}`}>{isLower ? <CheckCircleOutlined /> : <CloseCircleOutlined />}&nbsp;At least one small letter</p>
            <p className={`${isEmpty ? 'gray' : isUpper ? 'green' : 'red'}`}>{isUpper ? <CheckCircleOutlined /> : <CloseCircleOutlined />}&nbsp;At least one capital letter</p>
            <p className={`${isEmpty ? 'gray' : isNumber ? 'green' : 'red'}`}>{isNumber ? <CheckCircleOutlined /> : <CloseCircleOutlined />}&nbsp;At least one number</p>
            <p className={`${isEmpty ? 'gray' : isSpecial ? 'green' : 'red'}`}>{isSpecial ? <CheckCircleOutlined /> : <CloseCircleOutlined />}&nbsp;At least one special characters (!, @, #, $, %, &, *)</p>
        </div >
    );

    return (

        <Popover placement="top" onOpenChange={(e) => setIsOpen(e)} open={open} overlayClassName="password-tooltip" content={content} trigger={'click'} >

            {props.type === "reset" ?
                <Form.Item
                    label="New Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: passwordError
                        },
                        { validator: validatePassword }
                    ]}
                    hasFeedback
                >
                    <Input.Password
                        size='large'
                        placeholder="New Password"
                        onChange={onPasswordChange}
                        onFocus={onPasswordFocus}
                    />
                </Form.Item>
                :
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: passwordError
                        },
                        { validator: validatePassword }
                    ]}
                    hasFeedback
                >
                    <Input.Password placeholder="Password" onChange={onPasswordChange} onFocus={onPasswordFocus} />
                </Form.Item>}
        </Popover >
    )
}
export default memo(Password);
