/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Modal, Select, Radio, Space, Form, Input, Row, Col, InputNumber, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { REASSIGN_LIST } from '../../../../utils/constant';
import { formatDate } from '../../../../utils/functions';
import { useParams } from 'react-router-dom';
import { FaDollarSign } from 'react-icons/fa';
import { DragOutlined } from '@ant-design/icons';
import Draggable from 'react-draggable';

const { Option } = Select;

const UpdateTicketModal = (props) => {
    const [form] = Form.useForm();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { isSellerLoading, sellerList } = useSelector(state => state.common);
    const { isReassignLoading, isPaymentLoading, paymentHistoryList } = useSelector(state => state.ticket);
    const { visible, setVisible, row, modalType } = props;
    const [selectedType, setSelectedType] = useState("");

    const onChange = (e) => {
        setSelectedType(e.target.value)
    }

    const onFinish = () => {
        form.validateFields()
            .then((values) => {
                values.batch = row.batch;
                if (modalType === "report") {
                    // values.seller_id = row.seller_id;
                    if (values.lost_quantity === "list") {
                        values.list = values.list.split(',').map(i => parseInt(i));
                    }
                    dispatch({ type: 'REASSIGN_SELLER_REQUEST', formData: values, id: id, ticketId: row.id, mode: "report" });
                } else {
                    if (values.reassign_mode === "list") {
                        values.list = values.list.split(',').map(i => parseInt(i));
                    }
                    if (modalType === "record") {
                        dispatch({ type: 'UPDATE_TICKET_PAYMENT_REQUEST', formData: values, id: id, ticketId: row.id });
                    } else {
                        dispatch({ type: 'REASSIGN_SELLER_REQUEST', formData: values, id: id, ticketId: row.id, mode: "reassign" });
                    }
                }

            })
            .catch((errorInfo) => {
                console.log(errorInfo)
            });

    }
    const onFinishFailed = () => { }

    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [bounds, setBounds] = useState({
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
    });
    const draggleRef = useRef(null);
    
    const onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    return (
        <Modal
            // title={
            //     <div
            //         style={{
            //             width: '100%',
            //             cursor: 'move',
            //         }}
            //         onMouseOver={() => {
            //             if (disabled) {
            //                 setDisabled(false);
            //             }
            //         }}
            //         onMouseOut={() => {
            //             setDisabled(true);
            //         }}
            //         onFocus={() => { }}
            //         onBlur={() => { }}
            //     >
            //         <DragOutlined />
            //     </div>
            // }
            className="reassign-modal-parent"
            visible={visible}
            onCancel={() => setVisible(false)}
            width={600}
            maskClosable={false}
            okButtonProps={{ disabled: isReassignLoading, loading: isReassignLoading }}
            okText="Confirm"
            onOk={() => onFinish()}
            modalRender={(modal) => (
                <Draggable
                    disabled={disabled}
                    bounds={bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}
        >
            <div className="reassign-modal-child">
                {modalType === "reasign" &&
                    <>
                        <p className="fs18 bold mt3 mb5"><DragOutlined />&nbsp;Reassign tickets from {row?.name || ""} to...</p>
                        <Form
                            name="basic"
                            initialValues={{ remember: true }}
                            form={form}
                        >
                            <Form.Item
                                name="seller_id"
                                rules={[{ required: true, message: 'Please select seller name' }]}
                            >
                                <Select
                                    loading={isSellerLoading}
                                    style={{ width: "100%" }}
                                    placeholder={"Select Seller"}
                                    size={"large"}
                                >
                                    {!isSellerLoading && sellerList.length > 0 && sellerList.map((seller) =>
                                        seller.name !== row.name && <Option key={seller.id} value={seller.id} >{seller.name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="reassign_mode"
                                rules={[{ required: true, message: 'Please enter select atleast one option' }]}
                            >
                                <Radio.Group className={"mt3"} onChange={onChange}>
                                    <Space direction="vertical" size={'middle'} style={{ width: "100%" }}>
                                        {REASSIGN_LIST.map((item) => {
                                            return (
                                                <>
                                                    <Radio key={item.id} value={item.key}>
                                                        {item.key === "full_batch" ? item.title.replace('{count}', row.available_tickets_arr.length) : item.title}</Radio>
                                                    {item.key === "partial_batch" && selectedType === "partial_batch" &&
                                                        <Row gutter={[30]}>
                                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                                <Form.Item
                                                                    name={"quantity_from"}
                                                                    rules={[{ required: true, message: 'Please enter from' }]}
                                                                >
                                                                    <InputNumber min={row.quantity_from} max={row.quantity_to} type='number' placeholder='From' size='large' />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                                <Form.Item
                                                                    name={"quantity_to"}
                                                                    rules={[{ required: true, message: 'Please enter to' }]}
                                                                >
                                                                    <InputNumber min={row.quantity_from} max={row.quantity_to} placeholder='To' size='large' />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    {item.key === "list" && selectedType === "list" &&
                                                        <Row gutter={[30]}>
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <Form.Item
                                                                    name={"list"}
                                                                    extra={`Available Tickets: ${row.available_tickets}`}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Enter ticket numbers separated by comma'
                                                                        },
                                                                        {
                                                                            pattern: new RegExp(/^[0-9 , ]*$/),
                                                                            message: "Enter ticket numbers separated by comma",
                                                                        }
                                                                    ]}
                                                                >
                                                                    <Input placeholder='Enter ticket numbers separated by comma' size='large' />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </>
                                            )
                                        }
                                        )}
                                    </Space>
                                </Radio.Group>

                            </Form.Item>
                        </Form>
                    </>
                }
                {modalType === "report" &&
                    <>
                        <p className="fs18 bold mt3 mb5"><DragOutlined />&nbsp;Report stolen/lost tickets for {row?.name || ""}</p>
                        <Form
                            name="basic"
                            initialValues={{ remember: true }}
                            form={form}
                        >
                            <Form.Item
                                name="lost_quantity"
                                rules={[{ required: true, message: 'Please enter select atleast one option' }]}
                            >
                                <Radio.Group className={"mt3"} onChange={onChange}>
                                    <Space direction="vertical" size={'middle'} style={{ width: "100%" }}>
                                        {REASSIGN_LIST.map((item) => {
                                            return (
                                                <>
                                                    <Radio key={item.id} value={item.key}>
                                                        {item.key === "full_batch" ? item.title.replace('{count}', row.available_tickets_arr.length) : item.title}</Radio>
                                                    {item.key === "partial_batch" && selectedType === "partial_batch" &&
                                                        <Row gutter={[30]}>
                                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                                <Form.Item
                                                                    name={"from"}
                                                                    rules={[{ required: true, message: 'Please enter from' }]}
                                                                >
                                                                    <InputNumber min={row.quantity_from} max={row.quantity_to} type='number' placeholder='From' size='large' />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                                <Form.Item
                                                                    name={"to"}
                                                                    rules={[{ required: true, message: 'Please enter to' }]}
                                                                >
                                                                    <InputNumber min={row.quantity_from} max={row.quantity_to} placeholder='To' size='large' />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    {item.key === "list" && selectedType === "list" &&
                                                        <Row gutter={[30]}>
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <Form.Item
                                                                    name={"list"}
                                                                    extra={`Available Tickets: ${row.available_tickets}`}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Enter ticket numbers separated by comma'
                                                                        },
                                                                        {
                                                                            pattern: new RegExp(/^[0-9 , ]*$/),
                                                                            message: "Enter ticket numbers separated by comma",
                                                                        }
                                                                    ]}
                                                                >
                                                                    <Input placeholder='Enter ticket numbers separated by comma' size='large' />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </>
                                            )
                                        }
                                        )}
                                    </Space>
                                </Radio.Group>

                            </Form.Item>
                        </Form>
                    </>
                }

                {modalType === "record" &&
                    <>
                        <p className="fs18 bold mt3 mb5"><DragOutlined />&nbsp; Record Payment for {row?.name || ""}</p>
                        <Form
                            name="basic"
                            initialValues={{ remember: true }}
                            form={form}
                        >
                            <Form.Item
                                name="amount_paid"
                                rules={[{ required: true, message: 'Please enter amount' }]}
                            >
                                <InputNumber type={"number"} placeholder="Payment amount" size='large' prefix={<FaDollarSign />} />
                            </Form.Item>

                            <div className="draw-line  pt8 mt8">
                                <p className="links">
                                    <span className="fs16">Payment history</span>
                                </p>
                                <div>
                                    {isPaymentLoading && <Spin></Spin>}
                                    {!isPaymentLoading && paymentHistoryList.length > 0 &&
                                        paymentHistoryList.map((pay) =>
                                            <p key={pay.id}>
                                                <span className='bold'>${`${parseFloat(pay.amount_paid).toFixed(2)}`} Paid</span>
                                                &nbsp;&nbsp;•&nbsp;&nbsp;
                                                <span className='b500'>{formatDate(pay.created_at)} </span>
                                            </p>
                                        )
                                    }
                                    {!isPaymentLoading && paymentHistoryList.length === 0 &&
                                        <p>History not available</p>
                                    }
                                </div>
                            </div>
                        </Form>
                    </>
                }
            </div>
        </Modal >
    );
};

export default UpdateTicketModal;