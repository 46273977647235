import { put, takeLatest, call, delay } from 'redux-saga/effects'
import { message } from 'antd';
//Import API Service.
import { getRequestAPI, putRequestAPI } from '../../services/API/api';


function* getPlayerList({ formData }) {
    try {
        const response = yield call(getRequestAPI, { url: `players`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'ADMIN_PLAYER_LIST_SUCCESS', data: response.data || [] });
        } else {
            yield put({ type: 'ADMIN_PLAYER_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_PLAYER_LIST_ERROR', error: error.message || "" });
    }
}

function* getPlayerDetails({ id }) {
    try {

        const response = yield call(getRequestAPI, { url: `players/${id}`, params: {} });
        if (response.status === 200) {
            yield put({ type: 'ADMIN_PLAYER_DETAIL_SUCCESS', data: response.data || [] });
        } else {
            yield put({ type: 'ADMIN_PLAYER_DETAIL_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_PLAYER_DETAIL_ERROR', error: error.message || "" });
    }
}

function* getPlayerGame({ formData, playerId }) {
    try {

        const response = yield call(getRequestAPI, { url: `players/${playerId}/games`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'PLAYER_GAME_LIST_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'PLAYER_GAME_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'PLAYER_GAME_LIST_ERROR', error: error.message || "" });
    }
}

function* getAdminGameList({ formData, playerId }) {
    try {

        const response = yield call(getRequestAPI, { url: `games/list-games`, params: formData });

        if (response.status === 200) {
            yield put({ type: 'ADMIN_GAME_LIST_SUCCESS', data: response.data || [] });
        } else {
            yield put({ type: 'ADMIN_GAME_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_GAME_LIST_ERROR', error: error.message || "" });
    }
}



function* getAdminGameDetails({ id }) {
    try {

        const response = yield call(getRequestAPI, { url: `games/fundraisers/${id}/fundraiser-details`, params: {} });
        if (response.status === 200) {
            yield put({ type: 'ADMIN_GAME_DETAIL_SUCCESS', data: response.data?.data || [] });
        } else {
            yield put({ type: 'ADMIN_GAME_DETAIL_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_GAME_DETAIL_ERROR', error: error.message || "" });
    }
}

function* getAdminGameRankDetails({ formData, id }) {
    try {

        const response = yield call(getRequestAPI, { url: `games/fundraisers/${id}/player-ranking`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'ADMIN_GAME_RANK_DETAIL_SUCCESS', data: response.data || [] });
        } else {
            yield put({ type: 'ADMIN_GAME_RANK_DETAIL_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_GAME_RANK_DETAIL_ERROR', error: error.message || "" });
    }
}

function* getAdminGamePrizeDetails({ id }) {
    try {

        const response = yield call(getRequestAPI, { url: `games/fundraisers/${id}/winner-prizes`, params: {} });

        if (response.status === 200) {
            yield put({ type: 'ADMIN_GAME_PRIZE_DETAIL_SUCCESS', data: response.data || [] });
        } else {
            yield put({ type: 'ADMIN_GAME_PRIZE_DETAIL_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_GAME_PRIZE_DETAIL_ERROR', error: error.message || "" });
    }
}

function* getGraphData({ formData, id }) {
    try {

        const response = yield call(getRequestAPI, { url: `games/fundraisers/${id}/graph`, params: formData });

        if (response.status === 200) {
            yield put({ type: 'ADMIN_GAME_GRAPH_SUCCESS', data: response.data || [] });
        } else {
            yield put({ type: 'ADMIN_GAME_GRAPH_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_GAME_GRAPH_ERROR', error: error.message || "" });
    }
}

function* getAnalyticsData({ formData }) {
    try {

        const response = yield call(getRequestAPI, { url: `dashboard/analytics`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'ADMIN_ANALYTICS_DETAIL_SUCCESS', data: response.data.data || [] });
        } else {
            yield put({ type: 'ADMIN_ANALYTICS_DETAIL_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_ANALYTICS_DETAIL_ERROR', error: error.message || "" });
    }
}

function* getReportedTicketsList({ formData }) {
    try {

        // const response = {
        //     data: {
        //         "data": [
        //             {
        //                 "id": 1,
        //                 "ticket": 1,
        //                 "campaign_name": "Fundraiser 1",
        //                 "fundraising_admin": "Yunus Shaikh",
        //                 "reported_on": '2022-10-30T11:59:12.000000Z',
        //                 "status": "stolen",
        //                 "reported_by": "Ravi Khunt"
        //             }
        //         ],
        //     },
        //     status: 200
        // }
        const response = yield call(getRequestAPI, { url: `dashboard/reported-tickets`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'ADMIN_REPORTED_TICEKTS_LIST_SUCCESS', data: response.data?.data || [] });
        } else {
            yield put({ type: 'ADMIN_REPORTED_TICEKTS_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_REPORTED_TICEKTS_LIST_ERROR', error: error.message || "" });
    }
}

function* getPrizeTobeDeliveredList({ formData }) {
    try {

        // const response = {
        //     data: {
        //         "data": [
        //             {
        //                 "id": 1,
        //                 "ticket": 1,
        //                 "campaign_name": "Fundraiser 1",
        //                 "player_name": "Ravi Khunt",
        //                 "fundraising_admin": "Yunus Shaikh",
        //                 "claimed_on": '2022-10-30T11:59:12.000000Z',
        //                 "status": "stolen",
        //                 "updated_by": "Ravi Khunt",
        //                 "prize_name": "winner 1",
        //                 "shipping_address": "Street View",
        //                 "rank": "1"
        //             }
        //         ],
        //     },
        //     status: 200
        // }
        const response = yield call(getRequestAPI, { url: `prizes-to-be-delivered`, params: formData });
        if (response.status === 200) {
            yield put({ type: 'ADMIN_PRIZE_DELIVERED_LIST_SUCCESS', data: response.data?.data || [] });
        } else {
            yield put({ type: 'ADMIN_PRIZE_DELIVERED_LIST_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'ADMIN_PRIZE_DELIVERED_LIST_ERROR', error: error.message || "" });
    }
}

function* updateUserAddress({ formData, id }) {
    try {
        const response = yield call(putRequestAPI, { url: `fundraiser-prize/${id}/change-address`, data: formData });
        if (response.status === 200 || response.status === 201) {
            yield put({ type: 'UPDATE_USER_ADDRESS_SUCCESS', data: [] });
        } else {
            yield put({ type: 'UPDATE_USER_ADDRESS_ERROR', error: "No record found" });
        }
    } catch (error) {
        yield put({ type: 'UPDATE_USER_ADDRESS_ERROR', error: error.message || "" });
    }
}


function* watchPlayerRequests() {

    yield takeLatest('ADMIN_PLAYER_LIST_REQUEST', getPlayerList);
    yield takeLatest('ADMIN_PLAYER_DETAIL_REQUEST', getPlayerDetails);
    yield takeLatest('PLAYER_GAME_LIST_REQUEST', getPlayerGame);

    yield takeLatest('ADMIN_GAME_LIST_REQUEST', getAdminGameList);
    yield takeLatest('ADMIN_GAME_DETAIL_REQUEST', getAdminGameDetails);
    yield takeLatest('ADMIN_GAME_RANK_DETAIL_REQUEST', getAdminGameRankDetails);
    yield takeLatest('ADMIN_GAME_PRIZE_DETAIL_REQUEST', getAdminGamePrizeDetails);
    yield takeLatest('ADMIN_GAME_GRAPH_REQUEST', getGraphData);

    yield takeLatest('ADMIN_ANALYTICS_DETAIL_REQUEST', getAnalyticsData);
    yield takeLatest('ADMIN_REPORTED_TICEKTS_LIST_REQUEST', getReportedTicketsList);
    yield takeLatest('ADMIN_PRIZE_DELIVERED_LIST_REQUEST', getPrizeTobeDeliveredList);
    yield takeLatest('UPDATE_USER_ADDRESS_REQUEST', updateUserAddress);


}

function* Player() {
    yield watchPlayerRequests();
}

export default Player;