/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Spin, Card, Col, Space, Input, DatePicker } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const { Search } = Input;
let filterParams = { status: "", start_date: "", end_date: "", search: "" };
let initialFilter = { status: "", start_date: "", end_date: "", search: "" };

const Analytics = (props) => {

    const dispatch = useDispatch();
    const { id } = useParams();

    const { isAnalyticsLoading, analyticsDetail } = useSelector(state => state.player);

    useEffect(() => { getAnalyticsData({}) }, []);

    const getAnalyticsData = (params = {}) => {
        dispatch({ type: 'ADMIN_ANALYTICS_DETAIL_REQUEST', formData: { ...filterParams, ...params } });
    }

    const onChange = (e, type) => {
        if (type === "search") {
            filterParams[type] = e;
        } else if (type === 'start_date') {
            filterParams[type] = e !== null && e !== "" ? moment(e).format("YYYY-MM-DD") : "";
        } else if (type === 'end_date') {
            filterParams[type] = e !== null && e !== "" ? moment(e).format("YYYY-MM-DD") : "";
        } else if (type === 'clear') {
            filterParams = initialFilter;
        }
        getAnalyticsData(filterParams)
    }


    return (
        <div className="game-analytics">
            <div className="sub-header">
                <p className="mb0 title">
                    Analytics Dashboard
                </p>

            </div>
            <div className="filter mt8">
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fr date-filter">
                        <DatePicker
                            size={"large"}
                            className="mr5"
                            style={{ width: "40%" }}
                            placeholder={"From Date"}
                            format={'YYYY-MM-DD'}
                            value={(filterParams['start_date'] !== undefined && filterParams['start_date'] !== "" && filterParams['start_date'] !== null) ? moment(filterParams['start_date'], 'YYYY-MM-DD') : null}
                            onChange={(e) => onChange(e, 'start_date')}
                        />
                        <DatePicker
                            size={"large"}
                            style={{ width: "40%" }}
                            placeholder={"To Date"}
                            format={'YYYY-MM-DD'}
                            value={(filterParams['end_date'] !== undefined && filterParams['end_date'] !== "" && filterParams['end_date'] !== null) ? moment(filterParams['end_date'], 'YYYY-MM-DD') : null}
                            onChange={(e) => onChange(e, 'end_date')}
                        />
                    </Col>
                </Row>
            </div>
            {isAnalyticsLoading && <div className="no-data mt5"> <Spin /></div>}
            {!isAnalyticsLoading &&
                <>
                    <div className="mt15 mr0 cards-box analytics" >
                        <Row gutter={30}>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                <Card className="game-detail-analytics-card" >
                                    <div className="parent">
                                        <div className="mb0">
                                            <p className="cmp-title  b500 fs16 mb2">Fundraisers to Vetted</p>
                                            <p className="cmp-title  b600 fs22 mb0">{analyticsDetail?.fundraisers_to_vetted || 0}</p>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                <Card className="game-detail-analytics-card" >
                                    <div className="parent">
                                        <div className="mb0">
                                            <p className="cmp-title  b500 fs16 mb2">Lost/Stolen Tickets</p>
                                            <p className="cmp-title  b600 fs22 mb0">{analyticsDetail?.played_tickets || 0}</p>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                <Card className="game-detail-analytics-card" >
                                    <div className="parent">
                                        <div className="mb0">
                                            <p className="cmp-title  b500 fs16 mb2">Prizes to be  Delivered</p>
                                            <p className="cmp-title  b600 fs22 mb0">{analyticsDetail?.prizes_to_be_delivered || 0}</p>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                <Card className="game-detail-analytics-card" >
                                    <div className="parent">
                                        <div className="mb0">
                                            <p className="cmp-title  b500 fs16 mb2">Tickets Played</p>
                                            <p className="cmp-title  b600 fs22 mb0">{analyticsDetail?.stolen_tickets || 0}</p>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </>
            }
        </div >
    );
};
export default Analytics;