import styles from "./payment.module.css";
import { useState, useRef } from "react";
import {
    PayPalScriptProvider,
    PayPalHostedFieldsProvider,
    PayPalHostedField,
    usePayPalHostedFields
} from "@paypal/react-paypal-js";
import { Modal, Spin, Button, Input } from 'antd';
import { API_URL, PAYMENT_STATUS, STRIPE_CLIENT_ID } from "../../../utils/constant";
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { accessToken, formatAmount } from "../../../utils/functions";
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import paypalsecure from '../../../assets/paypalsecure.png';
import PrintInvoice from '../../../components/common/printInvoice'

export const PaymentModal = (props) => {
    const dispatch = useDispatch();
    const { isTokenLoading, paymentToken, isPaymentAmountLoading, paymentAmount, couponData, isCouponApplied } = useSelector(state => state.common);
    const [loader, showLoader] = useState(false);
    const [success, showSuccess] = useState(false);
    const [error, showErrorMsg] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [CHName, setCHName] = useState("");
    const [couponCode, setCouponCode] = useState("");
    const [isPartialPayment] = useState(props.fData.payment_status === 'partially_paid' ? true : false);

    const { id } = useParams();


    useEffect(() => {
        getPaymentToken();
        getCouponToken();

        return () => {
        }
    }, [])

    const getPaymentToken = () => {
        dispatch({ type: 'PAYMENT_TOKEN_REQUEST', formData: {} });
        dispatch({ type: 'PAYMENT_AMOUNT_REQUEST', id: id });
        showLoader(false)
    }

    const getCouponToken = () => {
        dispatch({ type: 'PAYMENT_COUPON_REQUEST', id: id })
    }

    const cvvRef = useRef(null);

    const SubmitPayment = () => {
        // Here declare the variable containing the hostedField instance
        const { cardFields } = usePayPalHostedFields();
        const cardHolderName = useRef(null);
        const submitHandler = () => {
            showErrorMsg(false);
            setErrorMsg("");
            setPaymentSuccess(false);
            if (typeof cardFields.submit !== "function") return; // validate that `submit()` exists before using it
            if (!cardHolderName?.current?.value) {
                showErrorMsg(true);
                setErrorMsg("Please enter card holder name");
                return;
            } else {
                setCHName(cardHolderName?.current?.value);
            }
            showLoader(true);
            showSuccess(false);
            cardFields
                .submit({
                    // The full name as shown in the card and billing addresss
                    // These fields are optional for Sandbox but mandatory for production integration
                    cardholderName: cardHolderName?.current?.value
                })
                .then(async (order) => {
                    const { orderId } = order;
                    return await axios({
                        url: `${API_URL}paypal/fundraisers/${id}/capture-order/${orderId}`,
                        method: "POST",
                        responseType: "json",
                        headers: { "Authorization": `Bearer ${accessToken()}` }
                    }).then(response => {
                        showLoader(false);
                        showSuccess(true);
                        setPaymentSuccess(true);
                        setTimeout(() => {
                            props.onSuccess();
                        }, 3000);
                    }
                    )
                        .catch(err => {
                            // Handle capture order error
                            showLoader(false);
                            showErrorMsg(true);
                            setErrorMsg(err);
                        })
                })
                .catch((err) => {
                    // Handle validate card fields error
                    let errorText = [];
                    err?.details.map((item) => {
                        if (item.field !== undefined) {
                            if (item?.field.includes("/expiry") && item.issue === "INVALID_STRING_LENGTH") {
                                errorText.push("Please enter valid expiry date");
                            }
                            if (item?.field.includes("/number") && item.issue === "CARD_NUMBER_REQUIRED") {
                                errorText.push("Please enter card number");
                            }
                            if (item?.field.includes("/number") && item.issue !== "CARD_NUMBER_REQUIRED") {
                                errorText.push("Please enter valid card number");
                            }
                            if (item?.field.includes("/security_code")) {
                                errorText.push("Please enter valid CVV number");
                            }
                        } else {
                            if (item.issue !== undefined && item.issue === "CARD_TYPE_NOT_SUPPORTED") {
                                errorText.push(item.description);
                            }

                        }

                    })
                    showLoader(false);
                    showErrorMsg(true);
                    setErrorMsg(errorText);
                });
        };

        return (
            <>
                <label className={styles.label} title="This represents the full name as shown in the card">
                    Card Holder Name
                </label>
                <input
                    ref={cardHolderName}
                    id="card-holder"
                    defaultValue={CHName}
                    className={styles.card_field}
                    type="text"
                    placeholder="Full name"
                />
                <div className="mt5 center">
                    <Button
                        type="primary"
                        size="large"
                        className="br1 mr5 button"
                        htmlType="submit"
                        id="export-fundraiser"
                        loading={loader}
                        disabled={loader}
                        onClick={() => submitHandler()}
                    >
                        Pay {formatAmount(paymentAmount.paid_amount_with_taxes)}
                    </Button>
                </div>
            </>



        );
    };

    const callCancel = async () => {
        if (isCouponApplied) {
            await dispatch({ type: 'PAYMENT_COUPON_REMOVE_REQUEST', formData: { subtract: 'usage_count', _method: 'delete' }, id: props.fData.id, couponId: couponData.id });
        }
        props.onCancel(false);
    }

    const loadTitle = () => {
        let regularFee = paymentAmount.paid_amount_with_taxes || 0;
        if (props?.fData?.payment_status === PAYMENT_STATUS.PARTAILY_PAIED) {
            return <span>About to pay <b>{formatAmount(regularFee)} (Partial Payment)</b></span>
        } else {
            return <span>About to pay <b>{formatAmount(regularFee)}</b> (Full Payment)</span>
        }
    }

    const handleCouponApply = async () => {
        showLoader(true)
        if (isCouponApplied) {
            await dispatch({ type: 'PAYMENT_COUPON_REMOVE_REQUEST', formData: { subtract: 'usage_count', _method: 'delete' }, id: props.fData.id, couponId: couponData.id });
            setCouponCode("")
        } else {
            await dispatch({ type: 'PAYMENT_COUPON_APPLY_REQUEST', formData: { coupon: couponCode }, id: props.fData.id });
        }
        setTimeout(() => {
            getPaymentToken()
        }, 800)
    }

    return (
        <Modal
            title={isPaymentAmountLoading ? 'Calculating payment...' : loadTitle()}
            className="success-modal-parent payment"
            footer={false}
            open={props.showModal}
            onCancel={() => callCancel()}
            width={700}
            maskClosable={false}
            style={{
                top: 20,
            }}
        >
            {isTokenLoading && <Spin />}
            {paymentSuccess &&
                <div className="success-modal-child">
                    <IoCheckmarkCircleSharp size={60} />
                    <p className="fs18 bold mt5 mb4">Payment Done Successfully</p>
                    <Button
                        type="primary"
                        size="large"
                        className="br20 pr8 pl8"
                        htmlType="submit"
                        onClick={() => props.callCancel(false)}
                    >
                        Close
                    </Button>
                </div>
            }
            {!isPaymentAmountLoading && !paymentSuccess && !isTokenLoading && paymentToken !== "" &&
                <>
                    <PayPalScriptProvider
                        options={{
                            "client-id": STRIPE_CLIENT_ID,
                            "data-client-token": paymentToken,
                            components: "hosted-fields"
                        }}>
                        <PayPalHostedFieldsProvider
                            createOrder={async () => {
                                console.log('Call the create order function below');
                                return await axios({
                                    url: `${API_URL}paypal/fundraisers/${id}/create-order`,
                                    method: "POST",
                                    responseType: "json",
                                    headers: { "Authorization": `Bearer ${accessToken()}` }
                                }).then(response => response.data.id)
                                    .catch(error => {
                                        showLoader(false);
                                        showErrorMsg(true);
                                        setErrorMsg("Something wen't wrong, please try again");
                                    })
                            }}>
                            <div style={{ flexDirection: "column", marginInline: "20px", marginBottom: '20px' }}>
                                <label style={{ fontWeight: '400' }} className={styles.coupon_label} htmlFor="coupon-code">Coupon</label>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
                                    <Input
                                        type="text"
                                        placeholder={isPartialPayment ? "Coupons can only be applied to full payments" : "Enter coupon code"}
                                        defaultValue={isCouponApplied ? couponData?.code : couponCode}
                                        disabled={isCouponApplied || isPartialPayment}
                                        onChange={e => setCouponCode(e.target.value)}
                                        size="large"
                                        maxLength={40}
                                        width={'70%'}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter' && couponCode.length > 4)
                                                handleCouponApply()
                                        }}
                                        style={{ borderRadius: '4px', fontSize: '14px', paddingBlock: '10px', marginTop: '5px', }}
                                    />
                                    <div className="center" style={{}}>
                                        <Button
                                            type="primary"
                                            size="large"
                                            className="br1 mr5 button"
                                            htmlType="submit"
                                            id="export-fundraiser"
                                            loading={loader}
                                            disabled={(!isCouponApplied && !(couponCode.length > 4)) || isPartialPayment}
                                            onClick={() => handleCouponApply()}
                                        >
                                            {isCouponApplied ? 'Remove' : 'Apply'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <PrintInvoice amountData={paymentAmount} />
                            <label onClick={() => props.setTransferVisible()} style={{ marginLeft: '20px', fontWeight: 'bold', color: 'blueviolet', cursor: 'pointer' }}>Click here to pay by E-transfer</label>
                            <section className={styles.container}>
                                <div className={styles.card_container}>
                                    {error && (
                                        <p style={{ color: "red", margin: "10px" }}>
                                            Something wen't wrong <br></br> {errorMsg}
                                        </p>
                                    )}
                                    <label className={styles.label} htmlFor="card-number">Card Number</label>
                                    <PayPalHostedField
                                        id="card-number"
                                        hostedFieldType="number"
                                        options={{
                                            selector: "#card-number",
                                            placeholder: "4111 1111 1111 1111"
                                        }}
                                        className={styles.card_field}
                                    />
                                    <section style={{ display: "flex" }}>
                                        <div style={{ flexDirection: "column", marginRight: "10px" }}>
                                            <label className={styles.label} htmlFor="cvv">CVV</label>
                                            <PayPalHostedField
                                                id="cvv"
                                                ref={cvvRef}
                                                hostedFieldType="cvv"
                                                options={{
                                                    selector: "#cvv",
                                                    placeholder: "123"
                                                }}
                                                className={styles.card_field}
                                            />
                                        </div>
                                        <div style={{ flexDirection: "column" }}>
                                            <label className={styles.label} htmlFor="expiration-date">Expiration Date</label>
                                            <PayPalHostedField
                                                id="expiration-date"
                                                hostedFieldType="expirationDate"
                                                className={styles.card_field}
                                                options={{
                                                    selector: "#expiration-date",
                                                    placeholder: "MM/YY"
                                                }}
                                            />
                                        </div>
                                    </section>
                                    <SubmitPayment />
                                </div>
                            </section>
                        </PayPalHostedFieldsProvider>
                    </PayPalScriptProvider>
                    <div className="paypal-img">
                        <img src={paypalsecure} alt='Secure Payment with Paypal' />
                    </div>
                </>
            }
        </Modal >
    );
};
