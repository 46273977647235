/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Row, Col, Input, Table, Space, Tooltip } from "antd";
import { PlusOutlined, EditFilled, DeleteFilled, EyeOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import SwitchToggle from "../../common/SwitchToggle";
import PrizeImg from '../../../assets/prize.png';
import PrizeModal from "../../common/modal/prizeModal";
import { isAuthorized } from "../../../utils/functions";

const { Search } = Input;
const { Column } = Table;

let filterParams = { search: "" };
const Prizes = (props) => {

    const dispatch = useDispatch();
    const { isPrizeListLoading, prizeList, isToggleLoading, toggleStatus, isCommonUpdateLoading, updateMsg } = useSelector(state => state.admin);
    const { isDeleteLoading, deleteMsg } = useSelector(state => state.common);

    const [tableData, setTableData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [rowData, setRowData] = useState("");
    const [modalType, setModalType] = useState("");

    useEffect(() => {
        getAllPrizes({});
        getAllFundraisingPackage();
    }, []);

    const getAllFundraisingPackage = (params = {}, isSearch = false) => {
        let tmpParams = { ...params };
        tmpParams.per_page = 5000;
        dispatch({ type: 'FUNDRAISER_PACKAGE_LIST_REQUEST', formData: tmpParams });
    }

    // useEffect(() => {
    //     if (!isFPackageLoading && fundraiserPackageList?.length) {
    //         let tmpfundraiserPackageList = [];
    //         fundraiserPackageList.map((item) => {
    //             let tmpObj = item;
    //             tmpfundraiserPackageList.push(tmpObj);
    //             return 1;
    //         })
    //         setTableData(tmpfundraiserPackageList);
    //     }
    // }, [isFPackageLoading, fundraiserPackageList]);


    useEffect(() => {
        if (!isPrizeListLoading && prizeList?.length) {
            let tmpprizeList = [];
            prizeList.map((item) => {
                let tmpObj = item;
                tmpObj.package = item?.fundraiser_package?.name || "";
                tmpprizeList.push(tmpObj);
                return 1;
            })
            setTableData(tmpprizeList);
        }
    }, [isPrizeListLoading, prizeList]);

    const getAllPrizes = (params = {}, isSearch = false) => {
        let tmpParams = { ...params };
        tmpParams.per_page = 5000;
        dispatch({ type: 'ADMIN_PRIZE_LIST_REQUEST', formData: tmpParams });
    }

    const openModal = (record, type) => {
        setVisible(true);
        if (type === "view") {
            setRowData("");
            dispatch({ type: "ADMIN_PRIZE_DETAIL_REQUEST", id: record.id });
        } else {
            setRowData(record);
        }
        setModalType(type);
    }

    useEffect(() => {
        if (!isCommonUpdateLoading && updateMsg === "success") {
            dispatch({ type: "ADMIN_DATA_CLEAR" });
            getAllPrizes({});
            setVisible(false);
            setModalType("");
        }
    }, [isCommonUpdateLoading, updateMsg])

    useEffect(() => {
        if (!isDeleteLoading && deleteMsg === "success") {
            dispatch({ type: "CLEAR_COMMON" });
            getAllPrizes({});
            setVisible(false);
            setModalType("");
        }
    }, [isDeleteLoading, deleteMsg]);

    const updateStatus = (prizeId) => {
        dispatch({ type: "TOGGLE_REQUEST", id: prizeId, toggleFor: 'prizes' })
    }

    useEffect(() => {
        if (!isToggleLoading && toggleStatus === "success") {
            dispatch({ type: "TOGGLE_CLEAR" })
        }
    }, [isToggleLoading, toggleStatus]);

    const onChange = (e, type) => {
        if (type === "search") {
            filterParams[type] = e;
        }
        getAllPrizes(filterParams)
    }

    return (
        <div>
            <div className="sub-header">
                <p className="mb0 title">
                    Prizes
                </p>
            </div>
            <div className="filter mt8">
                <Row className='nav-sub-header'>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Search
                            placeholder="Search"
                            style={{ width: "60%" }}
                            enterButton
                            onSearch={(e) => onChange(e, 'search')}
                        />
                    </Col>
                    {isAuthorized('create_prizes') &&
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fr text-right">
                            <Button
                                type="primary"
                                size="large"
                                className="br1"
                                htmlType="submit"
                                onClick={() => {
                                    dispatch({ type: "CLEAR_COMMON" })
                                    openModal("", "add")
                                }}
                                icon={<PlusOutlined />}
                            >
                                New Prize
                            </Button>
                        </Col>
                    }
                </Row>
            </div>
            <Row className="mt5">
                <Table
                    style={{ width: "100%" }}
                    dataSource={tableData}
                    loading={isPrizeListLoading}
                    scroll={{ x: "max-content" }}
                    pagination={{
                        pageSize: 50,
                        showSizeChanger: false
                    }}
                >

                    <Column
                        title="Image"
                        dataIndex="image"
                        key="image"
                        width={"100px"}
                        render={(text, record) => {
                            return (
                                <>
                                    {text !== null && text !== "" ?
                                        <img src={text} alt="prize" className="prize-img" /> :
                                        <img src={PrizeImg} alt="prize" className="prize-img" />
                                    }
                                </>
                            )
                        }}
                    />
                    <Column
                        title="Name"
                        dataIndex="name"
                        key="name"
                        width={"200px"}
                    />
                    <Column
                        title="Description"
                        dataIndex="description"
                        key="description"
                        width={"250px"}
                    />
                    <Column
                        title="Package"
                        dataIndex="package"
                        key="package"
                        width={"150px"}
                    />

                    <Column
                        title="Rank"
                        dataIndex="rank"
                        key="rank"
                        width={"100px"}
                        sorter={(a, b) => a.rank - b.rank}
                    />

                    <Column
                        title="Status"
                        dataIndex="status"
                        key="status"
                        width={"110px"}
                        render={(_, record) => {
                            let status = record.status === "active" ? true : false;
                            return (
                                <SwitchToggle
                                    record={record}
                                    status={status}
                                    onChange={(id) => updateStatus(id)}
                                    loading={isToggleLoading}
                                    isDisabled={!isAuthorized('update_prizes')}
                                />
                            )
                        }}
                    />
                    <Column
                        title={"Actions"}
                        key='actions'
                        width={"70px"}
                        render={(_, record) => {
                            return (
                                <Space size="middle" className={`cursor ${record.isDisabled ? 'disabled-edit' : 'not-disabled'}`}>
                                    {isAuthorized('view_prizes') &&
                                        <Tooltip placement="top" title={"View Detail"}>
                                            <EyeOutlined
                                                onClick={() => openModal(record, 'view')}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }
                                    {isAuthorized('update_prizes') &&
                                        <Tooltip placement="top" title={"Edit"} >
                                            <EditFilled
                                                onClick={() => openModal(record, 'edit')}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }
                                    {isAuthorized('delete_prizes') &&
                                        <Tooltip placement="top" color={"red"} title={"Delete"}>
                                            <DeleteFilled
                                                onClick={() => openModal(record, 'delete')}
                                                disabled={record.isDisabled}
                                                className={`cursor ${record.isDisabled ? 'disabled' : 'not-disabled'}`}
                                                style={{ fontSize: "18px" }}
                                            />
                                        </Tooltip>
                                    }
                                </Space>
                            )
                        }}
                    />
                </Table>
            </Row>

            <PrizeModal
                visible={visible}
                rowData={rowData}
                modalType={modalType}
                setVisible={() => {
                    setRowData("");
                    setVisible(false);
                }}
            />
        </div>
    );
};
export default Prizes;